import { Button, Dropdown, FormLabel, Group, IconCross, IconInfo, Radio } from '@screentone/core';

import { CollectionDtoStatusType } from 'data/generated/graphql';
import { toTitleCase } from 'utils/case';
import styles from './FilterStatus.module.scss';

const getEnumLabel = (value: string) => value.split('_').map(toTitleCase).join(' ');

type PublishStatusType = CollectionDtoStatusType | null;

interface PublishStatusProps {
  label: string;
  value?: PublishStatusType;
  onChange: (value: PublishStatusType) => void;
}

export const FilterStatus = ({ value, label, onChange }: PublishStatusProps) => {
  const handleChange = (e: { target: { value: CollectionDtoStatusType } }) => {
    onChange(e.target.value);
  };

  const handleRemove = () => {
    onChange(null);
  };

  return value ? (
    <Button
      active
      icon={IconCross as SvgComponent}
      tertiary
      onClick={handleRemove}
      data-testid="filter-status-button-active"
    >
      {getEnumLabel(value)}
    </Button>
  ) : (
    <Dropdown
      data-testid="filter-status-dropdown"
      trigger={
        <Group gap="xs" data-testid="filter-status-dropdown-button">
          <IconInfo /> {label}
        </Group>
      }
    >
      {Object.values(CollectionDtoStatusType).map((statusValue) => (
        <FormLabel
          key={statusValue}
          label={getEnumLabel(statusValue)}
          labelPosition="right"
          fullWidth
          className={styles.label}
          data-testid="filter-status-option"
        >
          <Radio
            name="publishStatus"
            value={statusValue}
            onChange={handleChange}
            data-testid="filter-status-option-radio"
          />
        </FormLabel>
      ))}
    </Dropdown>
  );
};
