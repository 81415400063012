import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { ContentIdTrackingProvider } from 'contexts/content-id-tracking/ContentIdTrackingContext';
import { DataModelProvider } from 'contexts/datamodel/DataModelContext';
import { DragAndDropProvider } from 'contexts/drag-and-drop/DragAndDropContext';
import { PagePublishProvider } from 'contexts/page-publish-dto/PagePublishContext';
import { PageTypeSettingsProvider } from 'contexts/page-type-settings/PageTypeSettingsContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { PageDto, PageType, usePageDtoByAllessehIdQuery } from 'data/generated/graphql';
import { SectionPageEdit } from 'features/section-page-edit/SectionPageEdit';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { useRequirePublisher } from 'hooks/roles';

const isAllessehId = (allessehIdOrSlug: string) => /^UCS(D|P)_/.test(allessehIdOrSlug);

const SectionPageEditPage = () => {
  useRequirePublisher();

  const { allessehIdOrSlug } = useParams();
  const { data: publicationSettings, isLoading: isPublicationSettingsLoading } = usePublicationSettings();
  const currentProperty = useConvertedProperty();
  const allessehId = useRef(allessehIdOrSlug);

  const [fetchError, setFetchError] = useState<string>('');

  if (allessehIdOrSlug && !isAllessehId(allessehIdOrSlug)) {
    const slug = allessehIdOrSlug;
    allessehId.current = publicationSettings?.publicationSetting.slugMappings?.[slug] as string;
  }

  const {
    data,
    isLoading: isPageDTOLoad,
    fetchStatus,
    error: loadingError
  } = usePageDtoByAllessehIdQuery(
    { allessehId: allessehId.current ?? '', publicationKey: currentProperty ?? '' },
    { enabled: !!allessehId.current && !!currentProperty }
  );

  useEffect(() => {
    if (loadingError) {
      console.error('Fetch error', loadingError);
      let error = '404';
      if (loadingError instanceof Error) {
        if (loadingError.message === 'Failed to fetch') {
          error = '503';
        }
      }
      setFetchError(error);
    }
  }, [loadingError]);

  if (fetchError) {
    return <ErrorPage type={String(fetchError)} />;
  }

  const isPageDTOLoading = isPageDTOLoad && fetchStatus !== 'idle';
  const pageDTO = data?.pageDTOByAllessehId as PageDto;

  return (
    <>
      {isPublicationSettingsLoading || isPageDTOLoading ? (
        <ResponsiveLoader />
      ) : (
        <SummarianProvider>
          <DataModelProvider type="PageDTO" root={pageDTO.root} metadata={pageDTO.metadata}>
            <ContentIdTrackingProvider>
              <PagePublishProvider>
                <PageTypeSettingsProvider pageType={pageDTO.root.attributes.pageType as PageType}>
                  <TrashProvider>
                    <DragAndDropProvider>
                      <SectionPageEdit />
                    </DragAndDropProvider>
                  </TrashProvider>
                </PageTypeSettingsProvider>
              </PagePublishProvider>
            </ContentIdTrackingProvider>
          </DataModelProvider>
        </SummarianProvider>
      )}
    </>
  );
};

export default SectionPageEditPage;
