export const getSentence = (titleCase: string) => titleCase.replace(/[A-Z]/g, ' $&').trim();
export const getNameFromEmail = (email: string) =>
  email
    .split('@')[0]
    .split('.')
    .map((name) => {
      const namePart = name.toLowerCase();
      return `${namePart.charAt(0).toUpperCase()}${namePart.substr(1)}`;
    })
    .join(' ');

export const isUUID = (str: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return uuidRegex.test(str);
};
