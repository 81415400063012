import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { GetAllOffPlatformQuery, OffPlatformDto } from 'data/generated/graphql';
import { useElementWidth } from 'hooks/dom';
import Card from './Card';
import styles from './OffPlatformSearch.module.scss';

interface ContentListProps {
  pages: GetAllOffPlatformQuery[];
  nextPageProps: {
    fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<GetAllOffPlatformQuery>>;
    hasNextPage?: boolean;
    isFetchingNextPage: boolean;
  };
}

const ContentList = ({ pages, nextPageProps }: ContentListProps) => {
  const { elementRef, elementWidth } = useElementWidth();

  if (pages.length === 0 || pages[0].getAllOffPlatform.edges.length === 0) {
    return <NoSearchResults title="Off-Platform Modules" bodyText="" />;
  }

  const offPlatformList = pages.reduce((acc: OffPlatformDto[], curr: GetAllOffPlatformQuery) => {
    const offPlatformNode = curr.getAllOffPlatform.edges.map((edges) => {
      const { node } = edges;
      return node as OffPlatformDto;
    });

    return acc.concat(offPlatformNode);
  }, []);

  return (
    <div ref={elementRef} className={styles.list}>
      {offPlatformList.map((offPlatform) => (
        <Card key={offPlatform.idCreatedUtc} offPlatform={offPlatform} containerWidth={elementWidth} />
      ))}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
    </div>
  );
};
export default ContentList;
