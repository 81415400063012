import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Button, IconArrowUp, IconTrash, Typography, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';

import { ResponsiveLoader } from 'components';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import CollectionContentCard from 'features/collection-edit/components/collection-content-card/CollectionContentCard';
import { usePublicationSettings } from 'hooks/publication-settings';
import {
  AllessehContent,
  AllessehContentQueryBody,
  AllessehQueryRule,
  useAllessehContentQuery
} from 'hooks/useAllessehContentQuery';
import { isNonEmptyQueryForAllessehQueryRules, mergeAllessehQueryBodies, validateValue } from 'utils/queryUtils';
import styles from './QueryRulesItems.module.scss';

interface QueryRulesItemsProps {
  allessehJsonQuery?: string | null;
  onMoveContentFromQueriedToCurated: (content: AllessehContent) => void;
  onExcludeQueryItem: (content: AllessehContent) => void;
  onNumItemsLoaded: (numItemsFromQuery: number) => void;
}
export const QueryRulesItems = ({
  allessehJsonQuery,
  onMoveContentFromQueriedToCurated,
  onExcludeQueryItem,
  onNumItemsLoaded
}: QueryRulesItemsProps) => {
  const [debouncedQueryBody, setDebouncedQueryBody] = useState<AllessehContentQueryBody>({});
  const [hasQuery, setHasQuery] = useState(false);
  const { data: publicationSettingsResp } = usePublicationSettings();

  const debouncedUpdate = debounce((allessehJsonQuery?: string | null) => {
    const parsedAllessehQuery = allessehJsonQuery ? (JSON.parse(allessehJsonQuery) as AllessehContentQueryBody) : null;
    const fullAllessehQuery = mergeAllessehQueryBodies(
      JSON.stringify({ count: 10 }),
      publicationSettingsResp?.publicationSetting.baseAllessehQuery,
      allessehJsonQuery
    );
    setDebouncedQueryBody(fullAllessehQuery);
    setHasQuery(isNonEmptyQueryForAllessehQueryRules(parsedAllessehQuery));
  }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDebouncedQueryBody = useCallback(debouncedUpdate, [publicationSettingsResp]);

  useEffect(() => {
    updateDebouncedQueryBody(allessehJsonQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allessehJsonQuery]);

  const { data, isLoading: isLoad, fetchStatus } = useAllessehContentQuery(debouncedQueryBody, { enabled: hasQuery });
  const isLoading = isLoad && fetchStatus !== 'idle';
  const contents = useMemo(
    () =>
      data
        ? (data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x) => !!x) as AllessehContent[])
        : [],
    [data]
  );

  const handleMoveUpQueryItem = (content: AllessehContent) => {
    onMoveContentFromQueriedToCurated(content);
  };

  const handleExcludeQueryItem = (content: AllessehContent) => {
    onExcludeQueryItem(content);
  };
  const { currentProperty } = useAuth();

  useEffect(() => {
    onNumItemsLoaded(contents.length);
  }, [contents, onNumItemsLoaded]);

  const parsedAllessehQuery = allessehJsonQuery ? (JSON.parse(allessehJsonQuery) as AllessehContentQueryBody) : null;

  const noResultsMessage = validateValue(
    currentProperty,
    parsedAllessehQuery?.query as { [key: string]: AllessehQueryRule[] }
  );

  if (!hasQuery) {
    return (
      <Wrapper margin={{ bottom: 'sm' }}>
        <Typography variant="note">No query rules set</Typography>
      </Wrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper padding={{ bottom: 'sm' }}>
        <ResponsiveLoader />
      </Wrapper>
    );
  }

  if (contents.length === 0) return <NoSearchResults title="Results" bodyText={noResultsMessage} />;

  return (
    <Wrapper className={styles.contentList}>
      {contents.map((content) => (
        <CollectionContentCard
          isDragging={false}
          key={content.data.id}
          content={content}
          renderActions={() => (
            <>
              <Button tertiary icon={IconArrowUp as SvgComponent} onClick={() => handleMoveUpQueryItem(content)}>
                Move to Top of List
              </Button>
              <Button
                tertiary
                margin={{ top: 'sm' }}
                icon={IconTrash as SvgComponent}
                color="lava"
                onClick={() => handleExcludeQueryItem(content)}
              >
                Exclude from Query
              </Button>
            </>
          )}
        />
      ))}
    </Wrapper>
  );
};
