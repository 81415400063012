import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';
import { AllessehContentData, AllessehContentErrorResponse } from './useAllessehContentQuery';
import { useConvertedProperty } from './useConvertedProperty';

export interface AllessehArticleQueryResponse {
  data: AllessehContentData | '';
  links: {
    related: { type: string }[];
  };
  response?: AllessehContentErrorResponse;
}

const getAllessehArticleQuery = (authToken: string, contentUrl: string, currentProperty: string | null) =>
  restClient.get<AllessehArticleQueryResponse>('allesseh/article', {
    headers: { Authorization: authToken },
    params: { contentUrl, publicationKey: currentProperty }
  });

export const useAllessehArticleQuery = (contentUrl: string, options?: { enabled: boolean }) => {
  const authToken = useAuthToken();
  const currentProperty = useConvertedProperty();

  return useAppQuery([contentUrl], () => getAllessehArticleQuery(authToken, contentUrl, currentProperty), options);
};
