import { useEffect } from 'react';
import Calendar, { calendarHooks } from '@screentone/addon-calendar';
import { Button, Dialog, Group } from '@screentone/core';

import styles from './ScheduleDialog.module.scss';

interface ScheduleDialogProps {
  dialogTitle: string;
  onSchedule: (publishUtc: number) => Promise<void> | void;
  onCancel: () => void;
  afterCalendarContent?: React.ReactNode;
  initialTime?: string;
  initialDate?: Date;
  minDate?: Date;
}

export const ScheduleDialog = ({
  dialogTitle,
  initialTime,
  initialDate,
  onSchedule,
  onCancel,
  afterCalendarContent,
  minDate
}: ScheduleDialogProps) => {
  const oneMinuteFromNow = new Date().getTime() + 60000;
  const oneYearFromNow = new Date().getTime() + 31536000000;
  const { onSelect, selectedDate, selectedTime } = calendarHooks.useDateAndTime();

  useEffect(() => {
    if (initialDate || initialTime) {
      onSelect({ selectedTime: initialTime, selectedDate: initialDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeSelect = (time: string) => {
    onSelect({ selectedTime: time });
  };

  const handleDateSelect = (date: Date) => {
    onSelect({ selectedDate: date });
  };

  const handleSchedule = async () => {
    if (selectedDate !== null) {
      await onSchedule(selectedDate.getTime());
    }
  };

  return (
    <Dialog status="open" className={styles.dialog} onDismiss={onCancel} focusTrap={false}>
      <Dialog.Title data-testid="schedule-dialog-title">{dialogTitle}</Dialog.Title>
      <Dialog.Content data-testid="schedule-dialog-content">
        <Group wrap={false} gap="none" valign="start" data-testid="schedule-dialog-calendar">
          <Calendar
            onSelect={handleDateSelect}
            selectedDate={selectedDate}
            placeholder="MM/DD/YYYY"
            showCalendarOnly
            minDate={minDate ?? new Date(oneMinuteFromNow)}
            maxDate={new Date(oneYearFromNow)}
          >
            <Calendar.TimeInput onSelect={handleTimeSelect} selectedTime={selectedTime} timeInterval={15} />
          </Calendar>
        </Group>
        <br />
        {afterCalendarContent}
      </Dialog.Content>
      <Dialog.Actions>
        <Button tertiary onClick={onCancel} data-testid="schedule-dialog-cancel-button">
          Cancel
        </Button>
        <Button
          primary
          onClick={handleSchedule}
          disabled={!(selectedDate && selectedTime)}
          data-testid="schedule-dialog-schedule-button"
        >
          Schedule
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
