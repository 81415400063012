import { createContext, useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { SnippetyImageData } from 'hooks/useSnippetyQuery';
import { AltSummFields, AltSummVariant, summarianReducer } from './summarianReducer';

const noop = async () => {
  /* do nothing */
};

export type { AltSummVariant };

export const DEFAULT_ALT_SUM_ID = 'U.S. Home';

interface SummarianContext {
  selectedAltSummVariant: AltSummVariant;
  setSelectedAltSummVariant: (selectedAltSummVariant: AltSummVariant) => void;
  trackContent: (content: AllessehContent) => void;
  getAltSummFields: (
    content: AllessehContent,
    altSummVariant?: AltSummVariant
  ) => {
    headline: string;
    body: string;
    image: string;
    proxy: SummarianProxy | undefined;
  };
  setAltSummFields: (contentId: AllessehContent, fields: Omit<AltSummFields, 'proxy'>) => void;
  eventTarget: EventTarget;
}

export interface SummarianChangeEvent {
  data: unknown;
  path: string[];
  prop: string;
}

const supportedProps = ['headline', 'body', 'images'] as const;
type SupportedProps = typeof supportedProps[number];
const supportedPropsSet = new Set<string>(supportedProps);

export const getDefaultHeadline = (content: AllessehContent) => content.data.attributes.headline?.text ?? '';

export const getDefaultBody = (content: AllessehContent) => content.data.attributes.standfirst?.content?.[0].text ?? '';

export const getDefaultImage = (content: AllessehContent) => {
  const summaryImage = content.data.attributes.alt_summaries?.find((altSummary) => altSummary.variant === 'Images');
  const summaryImageRef = summaryImage?.content?.[0].ref;

  const summaryImageUrl = content.links?.related?.find((link) => link.id === summaryImageRef)?.properties?.location;
  const fallbackAltSummImageUrl = content.links?.related?.find((link) => link.type === 'image')?.properties?.location;

  return summaryImageUrl ?? fallbackAltSummImageUrl ?? '';
};

const DEFAULT_SUMMARIAN_STATE: SummarianContext = {
  selectedAltSummVariant: 'U.S. Home',
  setSelectedAltSummVariant: noop,
  trackContent: noop,
  // @ts-expect-error - This is a dummy function, no need to match the signature
  getAltSummFields: noop,
  eventTarget: new EventTarget()
};

interface SummarianProviderProps {
  children: React.ReactNode;
}

export const SummarianContext = createContext(DEFAULT_SUMMARIAN_STATE);

export const SummarianProvider = ({ children }: SummarianProviderProps) => {
  const [isSummarianSettled, setIsSummarianSettled] = useState(window.hasSummarianSettled);
  const [state, dispatch] = useReducer(summarianReducer, {
    currentAltSummVariant: DEFAULT_ALT_SUM_ID,
    altSummCache: {
      'U.S. Home': {},
      ITP: {},
      NewsPlus: {},
      APIImage: {},
      Mobile: {},
      SEO: {},
      'Social Open Graph': {}
    },
    trackedItems: new Map<string, AllessehContent>()
  });
  const eventTarget = useRef(new EventTarget());

  useEffect(() => {
    const onSummarianSettled = () => {
      setIsSummarianSettled(true);
    };

    window.summarianSettledEventTarget.addEventListener('summarianSettled', onSummarianSettled);

    return () => {
      window.summarianSettledEventTarget.removeEventListener('summarianSettled', onSummarianSettled);
    };
  }, []);

  useEffect(() => {
    if (!window.isSummarianReady || state.trackedItems.size === 0) return;

    const trackNewItems = async () => {
      const promises = [...state.trackedItems.entries()].map(async ([contentId, content]) => {
        const hasAddedContentAlready = !!state.altSummCache[state.currentAltSummVariant]?.[contentId];
        if (hasAddedContentAlready) return;

        const { currentAltSummVariant } = state;

        // Use cached proxy if available
        let altSumProxy = state.altSummCache[currentAltSummVariant]?.[contentId]?.proxy;
        if (!altSumProxy) {
          altSumProxy = await window.summarianShareDbAPI.proxy(contentId, currentAltSummVariant);
        }

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const headline = (await altSumProxy.headline) || getDefaultHeadline(content);
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const body = (await altSumProxy.body) || getDefaultBody(content);
        const imagesFromProxy = await altSumProxy.images;
        const image = imagesFromProxy?.[0]?.href ?? getDefaultImage(content);

        // eslint-disable-next-line no-underscore-dangle
        altSumProxy.__proxy__.on('change', ({ path, data }: SummarianChangeEvent) => {
          if (!window.isSummarianReady) return;

          const prop = path[0];

          if (supportedPropsSet.has(prop)) {
            const supportedProp = prop as SupportedProps;

            if (supportedProp === 'images') {
              const imageData = data as SnippetyImageData | undefined;
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: {
                  id: contentId,
                  fields: { image: imageData?.href ?? getDefaultImage(content) }
                }
              });
            } else {
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: { id: contentId, fields: { [supportedProp]: data as string } }
              });
            }

            eventTarget.current.dispatchEvent(new CustomEvent('itemUpdated', { detail: contentId }));
          }
        });

        dispatch({
          type: 'UPDATE_CONTENT_ITEM',
          payload: { id: contentId, proxy: altSumProxy, fields: { headline, body, image } }
        });
      });

      await Promise.all(promises);
    };

    void trackNewItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trackedItems, state.currentAltSummVariant]);

  const setSelectedAltSummVariant = useCallback((selectedAltSummVariant: AltSummVariant) => {
    dispatch({ type: 'SET_CURRENT_ALT_SUMM_VARIANT', payload: selectedAltSummVariant });
  }, []);

  const trackContent = useCallback(
    (content: AllessehContent) => {
      const { altSummCache, currentAltSummVariant, trackedItems } = state;
      if (altSummCache[currentAltSummVariant]?.[content.data.id] || trackedItems.has(content.data.id)) return;

      dispatch({ type: 'TRACK_CONTENT_ITEM', payload: content });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.altSummCache, state.currentAltSummVariant, state.trackedItems]
  );

  const getAltSummFields = (content: AllessehContent, altSummVariant?: AltSummVariant) => {
    const selectedAltSummVariant = altSummVariant ?? state.currentAltSummVariant;
    const itemCache = state.altSummCache[selectedAltSummVariant]?.[content.data.id];

    return {
      headline: itemCache?.headline ?? getDefaultHeadline(content),
      body: itemCache?.body ?? getDefaultBody(content),
      image: itemCache?.image ?? getDefaultImage(content),
      proxy: itemCache?.proxy
    };
  };

  const setAltSummFields = (content: AllessehContent, fields: Omit<AltSummFields, 'proxy'>) => {
    dispatch({
      type: 'UPDATE_CONTENT_ITEM',
      payload: {
        id: content.data.id,
        fields: {
          /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
          headline: fields.headline || getDefaultHeadline(content),
          body: fields.body || getDefaultBody(content),
          image: fields.image || getDefaultImage(content)
          /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
        }
      }
    });
  };

  if (!isSummarianSettled) {
    // Show loader while Summarian is still loading
    return <ResponsiveLoader />;
  }

  const value = {
    selectedAltSummVariant: state.currentAltSummVariant,
    setSelectedAltSummVariant,
    trackContent,
    getAltSummFields,
    setAltSummFields,
    eventTarget: eventTarget.current
  };
  return <SummarianContext.Provider value={value}>{children}</SummarianContext.Provider>;
};
