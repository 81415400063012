import { useParams } from 'react-router';

import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { IssueProvider } from 'contexts/issue/IssueContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { HighlightProvider } from 'features/issues/hooks/useNewHighlight';
import { IssueEdit } from 'features/issues/IssueEdit';

const IssueEditPage = () => {
  const { issueDate, property } = useParams();

  return (
    <SummarianProvider>
      <TrashProvider>
        {issueDate && property && property === 'wsj' ? (
          <IssueProvider issueDate={issueDate} property={property}>
            <HighlightProvider>
              <IssueEdit />
            </HighlightProvider>
          </IssueProvider>
        ) : (
          <NoSearchResults
            title="ITP Issues"
            bodyText="This is a WSJ feature that is unavailable for other publications."
          />
        )}
      </TrashProvider>
    </SummarianProvider>
  );
};

export default IssueEditPage;
