export default {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  API_TIMEOUT: Number(process.env.REACT_APP_API_TIMEOUT ?? 0),
  OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER ?? '',
  OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID ?? '',
  OKTA_REDIRECT_URI: process.env.REACT_APP_OKTA_REDIRECT_URI ?? '',
  OKTA_ENV: process.env.REACT_APP_OKTA_ENV ?? '',
  ALLESSEH_PREVIEW_URL: process.env.REACT_APP_ALLESSEH_PREVIEW_URL ?? '',
  ALLESSEH_ADMIN_TOOL: process.env.REACT_APP_ALLESSEH_ADMIN_TOOL ?? ''
};
