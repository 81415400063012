import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import PublicationSettings from 'features/publication-settings/PublicationSettings';
import { useRequireAdmin } from 'hooks/roles';

const PublicationSettingsPage = () => {
  useRequireAdmin();

  return (
    <>
      <PageRootContainer>
        <NarrowPageWrapper>
          <PublicationSettings />
        </NarrowPageWrapper>
      </PageRootContainer>
    </>
  );
};

export default PublicationSettingsPage;
