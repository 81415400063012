import cloneDeep from 'lodash.clonedeep';

import { Page, PageModuleItemType } from 'data/generated/graphql';
import { AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';

export type ModuleIds = Record<string, string[]>;

export type AllContentIds = Record<string, ModuleIds>;

export const findDuplicates = (modules: AllContentIds) => {
  const allIds = Object.keys(modules)
    .map((moduleHierarchyId) => {
      const module = modules[moduleHierarchyId];
      return Object.keys(module).map((index: string) => module[index]);
    })
    .flat(2);

  const cache = {} as { [key: string]: number };

  return allIds.reduce((duplicates: string[], id: string) => {
    if (cache[id]) {
      if (cache[id] === 1) {
        duplicates.push(id);
      }
      cache[id]++;
    } else cache[id] = 1;
    return duplicates;
  }, []);
};

export const removeModuleItem = (prevState: AllContentIds, moduleHierarchyId: string) => {
  const newState = cloneDeep(prevState);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (newState[moduleHierarchyId]) {
    const lastKey = Object.keys(newState[moduleHierarchyId]).reduce((a, b) => Math.max(a, +b), -Infinity);
    delete newState[moduleHierarchyId][lastKey];
  }
  return newState;
};

export const addModuleItem = (
  prevState: AllContentIds,
  { moduleHierarchyId, index }: { moduleHierarchyId: string; index: number },
  contentIds: string[]
) => {
  const newState = cloneDeep(prevState);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!newState[moduleHierarchyId]) newState[moduleHierarchyId] = {};
  newState[moduleHierarchyId][index] = contentIds;
  return newState;
};

const getAllCuratedContentIds = (page: Page) => {
  const curatedContentIds: string[] = [];
  page.pageModules.forEach((module) => {
    module.moduleItems.forEach((moduleItem) => {
      if (moduleItem.itemType === PageModuleItemType.Content && moduleItem.itemFields.contentItem?.originId) {
        curatedContentIds.push(moduleItem.itemFields.contentItem.originId);
      }
    });
  });
  return curatedContentIds;
};

export const deDupePage = (page: Page) => {
  const curatedContentIds = getAllCuratedContentIds(page);
  const newPage = { ...page };
  newPage.pageModules.forEach((module) => {
    module.moduleItems.forEach((moduleItem) => {
      if (moduleItem.itemType === PageModuleItemType.Query) {
        const jsonQuery: string | undefined = moduleItem.itemFields.queryItem?.jsonQuery;
        if (!jsonQuery) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const parsedQuery: AllessehContentQueryBody = JSON.parse(jsonQuery);
        const notRules: AllessehQueryRule[] =
          parsedQuery.query?.not?.filter(
            (item: AllessehQueryRule) => 'term' in item && item.term.key !== 'UpstreamOriginId'
          ) ?? [];
        curatedContentIds.forEach((contentId) => {
          notRules.push({
            term: {
              key: 'UpstreamOriginId',
              value: contentId
            }
          });
        });
        if (!parsedQuery.query) parsedQuery.query = {};
        parsedQuery.query.not = notRules;
        if (moduleItem.itemFields.queryItem) {
          // eslint-disable-next-line no-param-reassign
          moduleItem.itemFields.queryItem.jsonQuery = JSON.stringify(parsedQuery);
        }
      }
    });
  });
  return newPage;
};
