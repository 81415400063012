import React, { useEffect, useRef, useState } from 'react';
import { Button, FormHelperText, FormLabel, Group, IconTrash, Input, Loader, Wrapper } from '@screentone/core';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';

import { SnippetyResponseWithImage, useSnippetyQuery } from 'hooks/useSnippetyQuery';
import { getIMImageAspectRatio, resizeIMImage } from 'utils/url';
import styles from './ImageInput.module.scss';

interface ImageInputProps {
  onAdd: (imageData: SnippetyResponseWithImage | null) => void;
  imDomain: string;
  imageUrl?: string;
  altText?: string;
}

const IMAGE_MANAGER_ID_PATTERN = /^im-\d+$/;

export const ImageInput = ({ onAdd, imageUrl, altText, imDomain }: ImageInputProps) => {
  const [imageId, setImageId] = useState('');
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [computedAspectRatio, setComputedAspectRatio] = useState(1);
  const [error, setError] = useState('');
  const {
    data,
    error: imageError,
    refetch: refetchImageData,
    isLoading: isImageLoading,
    fetchStatus,
    isFetched,
    isError
  } = useSnippetyQuery(`${imDomain}/${imageId}`, { enabled: false });

  const imgRef = useRef<HTMLImageElement>(null);
  const imageData = data as SnippetyResponseWithImage | null;

  useEffect(() => {
    if (imageData) {
      const clonedImageData = cloneDeep(imageData);
      const defaultBaseUrl = new URL(clonedImageData.url).origin;

      clonedImageData.tools.image = {
        ...clonedImageData.tools.image,
        href: clonedImageData.tools.image.href ?? clonedImageData.url,
        src: {
          ...clonedImageData.tools.image.src,
          baseUrl: clonedImageData.tools.image.src?.baseUrl ?? defaultBaseUrl,
          imageId: clonedImageData.tools.image.src?.imageId ?? imageId,
          path: clonedImageData.tools.image.src?.path ?? imageId
        }
      };

      onAdd(clonedImageData);
    }
  }, [imDomain, imageData, imageId, onAdd]);

  useEffect(() => {
    if (imageError) {
      setIsEditingImage(true);
      setError(
        (imageError as AxiosError<{ message: string }>).response?.data.message ??
          'An error has occurred while trying to fetch the image data'
      );
    }
  }, [imageError]);

  const onImageLoad = () => {
    const { naturalWidth = 1, naturalHeight = 1 } = imgRef.current ?? {};
    setComputedAspectRatio(naturalWidth / naturalHeight);
    setIsImageLoaded(true);
  };

  const onImageDelete = () => {
    setIsEditingImage(true);
    setIsImageLoaded(false);
    setImageId('');
    onAdd(null);
  };

  const onImageAdd = async () => {
    if (imageId.length === 0) {
      setError('You must enter an image ID');
      return;
    }

    if (!IMAGE_MANAGER_ID_PATTERN.test(imageId)) {
      setError('Invalid entry, please enter a valid image ID');
      return;
    }

    if (!isFetched || isError) {
      await refetchImageData();
    }
    setIsEditingImage(false);
  };

  const onImageIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setImageId(e.target.value);
  };

  const isLoading = isImageLoading && fetchStatus !== 'idle';
  if (isLoading) {
    return (
      <Group align="center">
        <Loader />
      </Group>
    );
  }

  const shouldDisplayImagePreview = imageUrl && !isEditingImage && !imageError;
  const resizedImage = resizeIMImage(imageUrl, { width: 270 });
  const imageAspectRatio = getIMImageAspectRatio(imageUrl);

  return shouldDisplayImagePreview ? (
    <Wrapper
      data-testid="image-input-preview"
      className={styles.imagePreviewWrapper}
      style={{ aspectRatio: imageAspectRatio ?? computedAspectRatio }}
    >
      <img
        data-testid="image-input-preview-image"
        className={styles.imagePreview}
        src={resizedImage}
        alt={altText}
        onLoad={onImageLoad}
        ref={imgRef}
      />
      {!isImageLoaded && (
        <Group
          data-testid="image-input-preview-loader"
          align="center"
          valign="center"
          fullWidth
          fullHeight
          className={styles.imageSkeletonLoader}
        >
          <Loader data-testid="image-input-preview-loader-icon" size="lg" />
        </Group>
      )}
      <Wrapper data-testid="image-input-preview-trash" className={styles.trashButtonWrapper}>
        <Button
          data-testid="image-input-preview-trash-icon"
          icon={IconTrash as SvgComponent}
          tertiary
          onClick={onImageDelete}
        />
      </Wrapper>
    </Wrapper>
  ) : (
    <>
      <Wrapper
        data-testid="image-input-form"
        background="page"
        padding={{ top: 'lg', right: 'sm', bottom: 'lg', left: 'sm' }}
      >
        <Group data-testid="image-input-form-container" valign="end" gap="xs" wrap={false}>
          <FormLabel data-testid="image-input-form-image-id" label="Image Manager ID">
            <Input
              data-testid="image-input-form-image-input"
              type="text"
              placeholder="Image ID"
              onChange={onImageIdChange}
              value={imageId}
              error={!!error}
            />
          </FormLabel>
          <Button data-testid="image-input-form-add-button" primary margin={{ left: 'sm' }} onClick={onImageAdd}>
            Add
          </Button>
        </Group>
        {error && (
          <FormHelperText data-testid="image-input-form-error" error>
            {error}
          </FormHelperText>
        )}
      </Wrapper>
    </>
  );
};
