export const URL_REGEX =
  /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/;

export function resizeIMImage(imageUrl: string | undefined, { width, size }: { width: number; size?: number }) {
  if (imageUrl) {
    const url = new URL(imageUrl);

    url.searchParams.set('width', `${width}`);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    url.searchParams.set('pixel_ratio', `${window.devicePixelRatio ?? 2}`);
    if (url.searchParams.get('height')) url.searchParams.delete('height');
    if (size) {
      url.searchParams.set('size', `${size}`);
    }

    return url.toString();
  }

  return imageUrl;
}

export function getIMImageAspectRatio(imageUrl: string | undefined) {
  if (imageUrl) {
    const url = new URL(imageUrl);

    const aspectRatio = url.searchParams.get('size');
    if (aspectRatio) {
      return parseFloat(aspectRatio);
    }
  }

  return null;
}

export function extractSizeFromUrl(url?: string): number {
  if (!url) return 0;
  const regex = /(?<=size=)[0-9.]+/;
  const sizeMatch = url.match(regex);
  if (sizeMatch) {
    return Number(sizeMatch[0]);
  }
  return 1;
}

export const isValidURL = (url: string): boolean => {
  try {
    const validUrl = new URL(url);
    return !!validUrl;
  } catch (e) {
    return false;
  }
};
