import { useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';
import { Group, Typography, Wrapper } from '@screentone/core';

import { ResponsiveLoader, WidePageWrapper } from 'components';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { AltSummVariant } from 'contexts/summarian/SummarianContext';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { AllessehCollectionType } from 'data/generated/graphql';
import { useManageCollectionItems } from 'hooks/collections/useManageCollectionItems';
import { useSaveCollection } from 'hooks/collections/useSaveCollection';
import { OffPlatformCollectionItemsSection } from './components/off-platform-collection-items-section/OffPlatformCollectionItemsSection';
import { OffPlatformCollectionMultitabSection } from './components/off-platform-collection-multitab-section/OffPlatformCollectionMultitabSection';
import { OffPlatformCollectionPublishingButtons } from './components/off-platform-collection-publishing-buttons/OffPlatformCollectionPublishingButtons';
import styles from './OffPlatformEdit.module.scss';

export const OffPlatformEdit = () => {
  const { idCreatedUtc } = useParams();
  const { setSelectedAltSummVariant } = useSummarianContext();

  const {
    collection,
    originalCollection,
    originalCollectionWhenInEditMode,
    hasCollectionChanged,
    hasRecentlyUpdated,
    hasRecentlyUpdatedNotificationText,
    isUpdating,
    isScheduling,
    isDeletingScheduled,
    isLoadingCollection,
    isRefetchingCollection,
    handlePartialCollectionChange,
    handleSave,
    handleCopyToCollection,
    handleSchedule,
    handleDeleteScheduled,
    handleShowHistoryEditMode,
    handleHideHistoryEditMode
  } = useSaveCollection(AllessehCollectionType.CurationOffPlatform, idCreatedUtc);

  // manage collection data
  const { handleAddContent, handleRemoveContent, handleClearAllContent, handleDragEnd, handleDragStart } =
    useManageCollectionItems({
      collection,
      onPartialCollectionChange: handlePartialCollectionChange
    });

  useEffect(() => {
    if (collection) {
      setSelectedAltSummVariant(collection.parameters.offPlatform!.defaultAltSummVariant as AltSummVariant);
    }
  }, [collection, setSelectedAltSummVariant]);

  if (!collection && !isRefetchingCollection && !isLoadingCollection) {
    return <ErrorPage type="404" />;
  }

  return (
    <PageRootContainer>
      <WidePageWrapper padding={{ all: 'md' }}>
        {!collection ? (
          <ResponsiveLoader />
        ) : (
          <>
            <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.secondHeader}>
              <div>
                <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="off-platform-header-title">
                  {collection.name}
                </Typography>
                {hasRecentlyUpdatedNotificationText && (
                  <Typography color="asphalt" margin={{ left: 'md' }}>
                    {hasRecentlyUpdatedNotificationText}
                  </Typography>
                )}
              </div>
              <OffPlatformCollectionPublishingButtons
                collection={collection}
                hasCollectionChanged={hasCollectionChanged}
                originalCollectionWhenInEditMode={originalCollectionWhenInEditMode}
                isUpdating={isUpdating}
                isScheduling={isScheduling}
                isDeletingScheduled={isDeletingScheduled}
                onPublish={handleSave}
                onSchedule={handleSchedule}
                onDeleteScheduled={handleDeleteScheduled}
                onHideHistoryEditMode={handleHideHistoryEditMode}
              />
            </Wrapper>
            <Wrapper margin={{ top: 'sm' }}>
              <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                <Group wrap={false} valign="start" gap="xl" fullWidth fullHeight>
                  <Group.Item flex>
                    <OffPlatformCollectionItemsSection
                      collection={collection}
                      editingScheduledRevision={!!originalCollectionWhenInEditMode}
                      onRemoveContent={handleRemoveContent}
                      onClearAllContent={handleClearAllContent}
                    />
                  </Group.Item>
                  <Group.Item flex>
                    <OffPlatformCollectionMultitabSection
                      collection={collection}
                      originalCollection={originalCollection}
                      originalCollectionWhenInEditMode={originalCollectionWhenInEditMode}
                      hasCollectionRecentlyUpdated={hasRecentlyUpdated}
                      handleCopyToCollection={handleCopyToCollection}
                      handleAddContent={handleAddContent}
                      handlePartialCollectionChange={handlePartialCollectionChange}
                      handleShowHistoryEditMode={handleShowHistoryEditMode}
                    />
                  </Group.Item>
                </Group>
              </DragDropContext>
            </Wrapper>
          </>
        )}
      </WidePageWrapper>
    </PageRootContainer>
  );
};
