import { ChangeEventHandler } from 'react';
import { FormLabel, Input } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { PageModule } from 'data/generated/graphql';

interface PageModuleRankedProps {
  pageModule: PageModule;
  onChange: (newPageModule: PageModule) => void;
}

export const PageModuleRanked = ({ pageModule, onChange }: PageModuleRankedProps) => {
  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newPageModule = clonedeep(pageModule);
    if (newPageModule.uiModuleFields.rankedModule) {
      newPageModule.uiModuleFields.rankedModule.title = e.target.value;
    }
    onChange(newPageModule);
  };

  return (
    <>
      <FormLabel key="title" label="Title" fullWidth>
        <Input
          placeholder="Enter title"
          value={pageModule.uiModuleFields.rankedModule?.title}
          margin={{ bottom: 'sm' }}
          onChange={handleChangeTitle}
          disabled={!onChange}
          data-testid="page-module-ranked-title"
        />
      </FormLabel>
    </>
  );
};
