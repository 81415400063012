import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Button, IconCopy, IconPackage, IconTrash, Loader } from '@screentone/core';

import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { useAlert } from 'contexts/alert/useAlert';
import {
  Collection,
  useCollectionArchiveMutation,
  useCollectionCopyMutation,
  useCollectionOffPlatformConvertMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { useIsAdmin } from 'hooks/roles';
import styles from './CollectionPublishingButtons.module.scss';
import {
  CollectionOffPlatformOverlay,
  TOffPlatformInput
} from '../collection-off-platform-overlay/CollectionOffPlatformOverlay';

interface CollectionPublishingButtonsProps {
  collection: Collection;
  hasCollectionChanged: boolean;
  isUpdating: boolean;
  onSave: () => Promise<void>;
}

export const CollectionPublishingButtons = ({
  collection,
  hasCollectionChanged,
  isUpdating,
  onSave
}: CollectionPublishingButtonsProps) => {
  const { currentProperty: routeProperty } = useAuth();
  const currentProperty = useConvertedProperty();
  const navigate = useNavigate();
  const { alertError } = useAlert();
  const { mutateAsync: collectionCopyMutateAsync, isLoading: isCopying } = useCollectionCopyMutation();
  const { mutateAsync: collectionArchiveMutateAsync, isLoading: isArchiving } = useCollectionArchiveMutation();
  const { mutateAsync: collectionOffPlatformConvert, isLoading: isConverting } =
    useCollectionOffPlatformConvertMutation();
  const [waitingForAllesseh, setWaitingForAllesseh] = useState(false);
  const isAdmin = useIsAdmin();
  const [offPlatformInputOpen, setOffPlatformInputOpen] = useState(false);

  const handleSave = async () => {
    await onSave();
  };

  const handleCopy = async () => {
    try {
      const resp = await collectionCopyMutateAsync({
        collectionCopyInput: {
          id: collection.id,
          allessehCollectionId: collection.allessehCollectionId,
          createdUtc: collection.createdUtc,
          publicationKey: currentProperty ?? ''
        }
      });
      navigate(`/${routeProperty ?? ''}/collections/${resp.collectionCopy.idCreatedUtc}`);
    } catch (e: unknown) {
      alertError('Could not update the collection. Please try again.');
    }
  };

  const handleArchive = async () => {
    try {
      await collectionArchiveMutateAsync({
        collectionArchiveInput: {
          id: collection.id,
          allessehCollectionId: collection.allessehCollectionId,
          createdUtc: collection.createdUtc,
          isArchived: !collection.isArchived,
          publicationKey: currentProperty ?? ''
        }
      });
      window.location.reload();
    } catch (e: unknown) {
      alertError('Could not archive the collection. Please try again.');
    }
  };

  const handleOffPlatformConvert = async (offPlatformInput: TOffPlatformInput) => {
    try {
      setWaitingForAllesseh(true);
      const resp = await collectionOffPlatformConvert({
        collectionOffPlatformInput: {
          allessehCollectionId: collection.allessehCollectionId,
          publicationKey: currentProperty ?? '',
          ...offPlatformInput
        }
      });

      /*
        TODO: This is a temp fix for ET-5557

        Adds a 5 sec timer before off-platform redirect.
        Allesseh's processing time may vary, this will
        probably need further adjustment.
      */
      setTimeout(() => {
        setWaitingForAllesseh(false);
        navigate(`/${routeProperty ?? ''}/off-platform/${resp.collectionOffPlatformConvert.idCreatedUtc}`);
      }, 5000);
    } catch (e: unknown) {
      let message = 'Could not convert to off-platform. Please try again.';

      if (e instanceof Error) message = e.message;

      alertError(message);
    }
  };

  return (
    <div className={styles.publishingButtons}>
      <ExternalLink
        contentId={collection.allessehCollectionId}
        type={ExternalLinkContentType.COLLECTION}
        isPublished={!!collection.updatedUtc}
        data-testid="collection-buttons-container"
      />
      <Button
        tertiary
        icon={IconTrash as SvgComponent}
        color="lava"
        disabled={isArchiving}
        margin={{ right: 'md' }}
        onClick={handleArchive}
        data-testid="collection-archive-button"
      >
        {collection.isArchived ? 'Unarchive' : 'Archive'}
      </Button>
      <Button
        data-testid="collection-publishing-buttons-make-copy-button"
        tertiary
        icon={IconCopy as SvgComponent}
        disabled={isCopying}
        margin={{ right: 'md' }}
        onClick={handleCopy}
      >
        Make a copy
      </Button>
      {isAdmin && (
        <Button
          tertiary
          icon={IconPackage as SvgComponent}
          disabled={collection.isArchived || hasCollectionChanged || isConverting || waitingForAllesseh}
          margin={{ right: 'md' }}
          onClick={() => setOffPlatformInputOpen(true)}
          data-testid="collection-convert-off-platform-button"
        >
          {(isConverting || waitingForAllesseh) && <Loader size="md" margin={{ right: 'md' }} />}
          Convert to Off-Platform
        </Button>
      )}
      {!collection.isArchived && (
        <Button
          primary
          onClick={handleSave}
          disabled={isUpdating || !hasCollectionChanged}
          data-testid="collection-save-button"
        >
          {isUpdating && <Loader size="md" margin={{ right: 'md' }} />}
          Save
        </Button>
      )}
      {offPlatformInputOpen && (
        <CollectionOffPlatformOverlay
          currentProperty={currentProperty}
          onDismiss={() => setOffPlatformInputOpen(false)}
          onConvert={handleOffPlatformConvert}
        />
      )}
    </div>
  );
};
