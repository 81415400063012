/* eslint-disable import/no-cycle */
import { useEffect, useMemo } from 'react';
import { Box, Dropdown, IconThreeDotsVer, List, Typography, Wrapper } from '@screentone/core';
import { clamp } from 'lodash';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { ArticleItem, ExternalCollectionItem, QueryItem } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehContentQueryBody, useAllessehContentQuery } from 'hooks/useAllessehContentQuery';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import { safelyParseContent } from 'utils/temp';
import styles from './Collection.module.scss';
import { Article } from '../Article/Article';
import { CommonContentProps } from '../commonContentProps';

interface CollectionProps extends CommonEntityProps, CommonContentProps {
  data: ExternalCollectionItem;
}

export const Collection = ({
  data: collection,
  onContentLoaded,
  // eslint-disable-next-line unused-imports/no-unused-vars
  isDraft,
  availableContentSlots,
  parentHierarchyId,
  index,
  isHistory
}: CollectionProps) => {
  const { generateHierarchyId, fromAllessehContent } = useDataModelContext();
  const { renderActions } = useContextMenuActions();
  const { data: publicationSettingsResp } = usePublicationSettings();
  const hierarchyId = generateHierarchyId(collection, parentHierarchyId, index);
  const isExternalCollection = !/^UCS(D|P)/.test(collection.attributes.id); // external collections are those were not created in Curation
  const actionsType = isHistory ? 'historyExternalCollection' : 'draftExternalCollection';

  const collectionManualContents = useMemo(
    () => collection.contentItems.filter((contentItem): contentItem is ArticleItem => contentItem.type === 'Article'),
    [collection.contentItems]
  );
  const queryItem = collection.contentItems.find(
    (contentItem): contentItem is QueryItem => contentItem.type === 'Query'
  );

  const jsonQuery = queryItem ? JSON.stringify(queryItem.attributes.query) : '';

  const numQueryItemsToLoad = availableContentSlots
    ? Math.max(availableContentSlots - collectionManualContents.length, 0)
    : 0;
  let fullAllessehQuery: AllessehContentQueryBody = {};
  if (jsonQuery) {
    fullAllessehQuery = mergeAllessehQueryBodies(
      publicationSettingsResp?.publicationSetting.baseAllessehQuery,
      jsonQuery,
      JSON.stringify({ count: numQueryItemsToLoad })
    );
  }

  const {
    data,
    isLoading: isQueryLoad,
    fetchStatus
  } = useAllessehContentQuery(fullAllessehQuery, {
    enabled: !!jsonQuery && !!availableContentSlots
  });
  const isQueryLoading = isQueryLoad && fetchStatus !== 'idle';
  const queryContents = useMemo(
    () =>
      data
        ? data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x): x is AllessehContent => !!x)
            .map((content) => fromAllessehContent(content))
        : [],
    [data, fromAllessehContent]
  );

  const allContents = useMemo(() => {
    if (isExternalCollection && typeof availableContentSlots === 'number') {
      // If collection is external, only take as many manual contents as there are available slots
      const slots = availableContentSlots >= 0 ? availableContentSlots : 0;
      const manualContents = collectionManualContents.slice(0, slots);
      return manualContents.concat(queryContents);
    }

    return collectionManualContents.concat(queryContents);
  }, [availableContentSlots, collectionManualContents, isExternalCollection, queryContents]);

  const isLoading = !!jsonQuery && isQueryLoading;
  const externalCollectionHasItems = isExternalCollection && collectionManualContents.length > 0;
  const remainingExternalCollectionItems = clamp(
    collectionManualContents.length - availableContentSlots!,
    0,
    collectionManualContents.length
  );

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(
        index!,
        allContents.map(({ content }) => safelyParseContent<AllessehContent>(content))
      );
    }
  }, [allContents, index, onContentLoaded]);

  return (
    <Box data-model-hierarchy-id={hierarchyId}>
      <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader} data-testid="collection-list-items">
        <Typography size="md" weight="bold">
          {collection.metadata.name}
        </Typography>
        <Dropdown
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
          data-testid="collection-ellipsis-icon"
        >
          {renderActions(actionsType, {
            hierarchyId,
            entity: collection,
            index,
            extraProperties: { isExternalCollection }
          })}
        </Dropdown>
      </Wrapper>
      {isLoading && <ResponsiveLoader />}
      {!isLoading && (
        <Wrapper padding={{ right: 'sm', left: 'xs', bottom: 'sm' }}>
          {!externalCollectionHasItems && allContents.length === 0 && <Typography size="sm">No items</Typography>}
          {allContents.length > 0 && (
            <List listStyle="bullet">
              {allContents.map((content, i) => (
                <Article
                  data={content}
                  noBorder
                  boxPadding="xs"
                  key={i}
                  index={i}
                  isHistory={isHistory}
                  isDraft={!isHistory}
                  parentHierarchyId={hierarchyId}
                />
              ))}
            </List>
          )}
          {!availableContentSlots && allContents.length > 0 && (
            <Typography variant="note" margin={{ left: 'sm' }}>
              + {(data?.pages[0].links?.total ?? 0) - numQueryItemsToLoad - collectionManualContents.length} more
            </Typography>
          )}
          {isExternalCollection && remainingExternalCollectionItems > 0 && (
            <Typography variant="note" margin={{ left: 'sm' }}>
              + {remainingExternalCollectionItems} more
            </Typography>
          )}
        </Wrapper>
      )}
    </Box>
  );
};
