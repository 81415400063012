import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, Dialog, Divider, FormLabel, Input, Typography, Wrapper } from '@screentone/core';

import { ResponsiveLoader, WidePageWrapper } from 'components';
import { useAlert } from 'contexts/alert/useAlert';
import { mockPageTypeSetting } from 'contexts/newsletter/mockCampaign';
import { useNewsletter } from 'contexts/newsletter/useNewsletter';
import {
  AllessehContentType,
  NewsletterCreateInput,
  NewsletterParametersInput,
  PageModule,
  PageModuleItemType,
  PublicationSettingSearchableContentType,
  useCreateNewsletterMutation,
  useCreatePreviewNewsletterMutation,
  useScheduleNewsletterMutation
} from 'data/generated/graphql';
import { useDragEditNewsletter } from 'features/newsletters/hooks/useDragEditNewsletter';
import { PageDraftSection } from 'features/page-edit/components/page-draft-section/PageDraftSection';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { formatInTimeZone } from 'utils/dates';
import { getTimeZoneOffsetByName } from 'utils/newsletters';
import { safelyParseContent } from 'utils/temp';
import { NewsletterMultitabSection } from './components/newsletter-multitab-section/NewsletterMultitabSection';
import { PageTitleHeader } from './components/page-title-header/PageTitleHeader';
import styles from './NewsletterEdit.module.scss';

export const formatNewsletterPageModulesForPublish = (pageModules?: PageModule[], lowercaseContentTypes = false) => {
  if (!pageModules) return [];
  /* eslint-disable */
  return pageModules.map((pageModule: PageModule) => {
    if (lowercaseContentTypes) {
      pageModule.uiModuleFields.basicModule!.contentTypes = pageModule.uiModuleFields.basicModule!.contentTypes.map(
        (contentType) => contentType.toLowerCase() as PublicationSettingSearchableContentType
      );
    }
    return {
      moduleItems: [
        ...pageModule.moduleItems.map((moduleItem) => {
          const apiContent = safelyParseContent(moduleItem.itemFields.contentItem?.content);
          return {
            itemType: PageModuleItemType.Content,
            itemFields: {
              contentItem: {
                product: apiContent.data.attributes.product,
                seo_id: apiContent.data.attributes.seo_id,
                hosted_url: apiContent.data.attributes.source_url,
                status: 'live',
                base_doc_id: apiContent.data.id,
                originContentType: apiContent.data.type,
                originId: apiContent.data.id,
                id: apiContent.data.id,
                link: `api/articles/v1/originid/${apiContent.data.id}`
              }
            }
          };
        })
      ],
      uiModuleFields: pageModule.uiModuleFields,
      uiModuleType: pageModule.uiModuleType
    };
  });
  /* eslint-enable */
};

export const NewsletterEdit = ({ newsletterId }: { newsletterId: string }) => {
  const currentProperty = useConvertedProperty();
  const {
    user,
    app: { env }
  } = useAuth();
  const { mutateAsync: createNewsletterMutateAsync } = useCreateNewsletterMutation();
  const { mutateAsync: createPreviewNewsletterMutateAsync } = useCreatePreviewNewsletterMutation();
  const { mutateAsync: scheduleNewsletterAsync } = useScheduleNewsletterMutation();

  const { newsletter, handlePartialNewsletterChange, newsletterItems } = useNewsletter();
  const newsletterItem = newsletterItems?.find((item) => item.id === newsletterId);

  const { alertSuccess, alertError } = useAlert();

  const navigate = useNavigate();

  const { handleDragEnd, handleDragStart } = useDragEditNewsletter({
    newsletter,
    onPartialNewsletterChange: handlePartialNewsletterChange
  });

  const { data: publicationSettingResp } = usePublicationSettings();

  const [sendNowOpen, setSendNowOpen] = useState<boolean>(false);
  const newCampaignId = `${Math.random()}`.substring(2, 7);

  const getDefaultCampaignSubject = () => {
    if (!newsletter || newsletter.pageModules[0].moduleItems.length < 1) return newsletterItem?.name ?? '';
    const articleContent: AllessehContent = safelyParseContent(
      newsletter.pageModules[0].moduleItems[0].itemFields.contentItem?.content
    );
    const headline: string = articleContent.data.attributes.headline?.text ?? '';
    return `${newsletterItem?.name ? `${newsletterItem.name}: ` : ''}${headline}`;
  };

  const [campaignParameters, setCampaignParameters] = useState<NewsletterParametersInput>({
    sender_name: '',
    reply_to_email: '',
    subject: '',
    pre_header_text: '',
    campaign_id: newCampaignId,
    newsletter_id: Number(newsletterItem?.id)
  });

  const validateNewsletter = () => {
    if (
      campaignParameters.sender_name === '' ||
      campaignParameters.reply_to_email === '' ||
      campaignParameters.subject === '' ||
      campaignParameters.pre_header_text === ''
    )
      return false;
    const formattedPageModules = formatNewsletterPageModulesForPublish(newsletter?.pageModules);
    if (formattedPageModules[0].moduleItems.length < 1) return false;
    return true;
  };

  const getNewsletterId = () =>
    `UCS${env === 'prd' ? 'P' : 'D'}_${currentProperty?.toUpperCase()}_NEWSLETTER_${newsletterId}`;

  const handleAddContentToNewsletter = (content: AllessehContent) => {
    if (!newsletter) return;
    const { pageModules } = newsletter;
    pageModules[0].moduleItems = [
      {
        itemType: PageModuleItemType.Content,
        itemFields: {
          contentItem: {
            originId: content.data.id,
            originContentType: content.data.type,
            base_doc_id: content.data.id,
            id: content.data.id,
            content: JSON.stringify(content),
            product: content.data.attributes.product,
            seo_id: content.data.attributes.seo_id,
            hosted_url: content.data.attributes.source_url,
            link: content.data.attributes.source_url,
            status: content.data.attributes.content_status
          }
        }
      }
    ];
    setCampaignParameters({
      ...campaignParameters,
      subject: getDefaultCampaignSubject()
    });
    handlePartialNewsletterChange({ pageModules });
  };

  useEffect(() => {
    if (newsletterItem) {
      setCampaignParameters({
        sender_name: newsletterItem.defaultCampaignDetails?.sender_name ?? '',
        reply_to_email: newsletterItem.defaultCampaignDetails?.reply_to_email ?? '',
        subject: getDefaultCampaignSubject(),
        pre_header_text: newsletterItem.defaultCampaignDetails?.pre_header_text ?? '',
        newsletter_id: Number(newsletterItem.id),
        campaign_id: newCampaignId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletterItem]);

  useEffect(() => {
    if (newsletter?.pageModules[0]?.moduleItems.length) {
      setCampaignParameters({
        ...campaignParameters,
        subject: getDefaultCampaignSubject()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletter?.pageModules]);

  const sendNow = async () => {
    if (!validateNewsletter()) {
      alertError('Cannot send newsletter. Please check that all fields are completed.');
      setSendNowOpen(false);
      return;
    }
    const formattedPageModules = formatNewsletterPageModulesForPublish(newsletter?.pageModules);
    const createInput = {
      id: getNewsletterId(),
      name: newsletterItem?.name ?? '',
      publicationKey: currentProperty ?? '',
      parameters: campaignParameters,
      pageModules: formattedPageModules
    };

    try {
      const publishedNewsletter = await createNewsletterMutateAsync({
        newsletterCreateInput: createInput
      });
      navigate(`/${currentProperty}/newsletters/${publishedNewsletter.createNewsletter.id}`);
      alertSuccess(
        `Campaign successfully sent on ${formatInTimeZone(
          publishedNewsletter.createNewsletter.createdUtc,
          publicationSettingResp?.publicationSetting.preferredTimezone,
          'MMMM dd, yyyy',
          { showTimezoneAbbreviation: false }
        )} at ${formatInTimeZone(
          publishedNewsletter.createNewsletter.createdUtc,
          publicationSettingResp?.publicationSetting.preferredTimezone,
          'h:mm aaaa',
          { showTimezoneAbbreviation: true }
        )}.`
      );
    } catch (e: unknown) {
      console.error(e);
      alertError(`Campaign failed with the following error: ${e as string}`);
    }
    setSendNowOpen(false);
  };

  const handleScheduleClick = async (publishUtc: number) => {
    let adjustedPublishUtc;
    const publishDateObj = new Date(publishUtc);
    const desiredTZOffset = getTimeZoneOffsetByName(
      publicationSettingResp?.publicationSetting.preferredTimezone.timezone
    );
    if (desiredTZOffset) {
      const currentTZOffset = publishDateObj.getTimezoneOffset();
      const offsetDifference = desiredTZOffset - currentTZOffset;
      adjustedPublishUtc = publishUtc + offsetDifference * 60 * 1000;
    }
    if (!validateNewsletter()) {
      alertError('Cannot schedule newsletter. Please check that all fields are completed.');
      return;
    }
    const allessehId = getNewsletterId();
    const createInput = {
      id: allessehId,
      name: newsletterItem?.name,
      publicationKey: currentProperty ?? '',
      updatedUtc: null,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      creatorUser: { djUserId: user?.dj_user_id },
      parameters: campaignParameters,
      pageModules: formatNewsletterPageModulesForPublish(newsletter?.pageModules, true),
      createdUtc: new Date().getTime()
    };
    const scheduledCreateInput = {
      allessehId,
      publishUtc: adjustedPublishUtc ?? publishUtc,
      contentType: AllessehContentType.Newsletter,
      body: { ...createInput }
    };
    try {
      const scheduledNewsletter = await scheduleNewsletterAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentCreateInput: scheduledCreateInput
      });
      navigate(
        `/${currentProperty}/newsletters/scheduled/${scheduledNewsletter.scheduleNewsletter.allessehId}/${
          adjustedPublishUtc ?? publishUtc
        }`
      );
      alertSuccess(
        `Campaign successfully scheduled for ${formatInTimeZone(
          scheduledNewsletter.scheduleNewsletter.publishUtc,
          publicationSettingResp?.publicationSetting.preferredTimezone,
          'MMMM dd, yyyy',
          { showTimezoneAbbreviation: false }
        )} at ${formatInTimeZone(
          scheduledNewsletter.scheduleNewsletter.publishUtc,
          publicationSettingResp?.publicationSetting.preferredTimezone,
          'h:mm aaaa',
          { showTimezoneAbbreviation: true }
        )}.`
      );
    } catch (e: unknown) {
      console.error(e);
      alertError(`Campaign scheduling failed with the following error: ${e as string}`);
    }
  };

  const handleSendTestClick = async (emails: string) => {
    const formattedPageModules = formatNewsletterPageModulesForPublish(newsletter?.pageModules);
    if (formattedPageModules[0].moduleItems.length < 1) {
      alertError('Could not send. Newsletter must contain one article.');
      return;
    }
    const createPreviewInput = {
      id: `UCS${env === 'prd' ? 'P' : 'D'}_${currentProperty?.toUpperCase()}_NEWSLETTER_${
        newsletterItem?.id
      }_${new Date().getTime()}`,
      name: newsletterItem?.name ?? '',
      publicationKey: currentProperty ?? '',
      parameters: { ...campaignParameters, recipients: emails },
      pageModules: formattedPageModules
    };

    try {
      await createPreviewNewsletterMutateAsync({
        newsletterCreateInput: createPreviewInput as NewsletterCreateInput
      });
      alertSuccess(
        `Preview campaign ${createPreviewInput.id} successfully published to Allesseh for recipient(s) ${emails
          .split(',')
          .join(', ')}.`
      );
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const updateCustomizableContent = (e: Event, fieldName: string) => {
    setCampaignParameters({
      ...campaignParameters,
      [fieldName]: (e.target as HTMLInputElement).value
    });
  };

  return (
    <WidePageWrapper>
      {!newsletter || !newsletterItem ? (
        <ResponsiveLoader />
      ) : (
        <>
          <PageTitleHeader
            newsletterName={newsletterItem.name}
            handleSendNowClick={() => setSendNowOpen(true)}
            handleScheduleClick={handleScheduleClick}
            handleSendTestClick={handleSendTestClick}
            publicationSetting={publicationSettingResp?.publicationSetting}
          />
          <Divider margin={{ top: 'md' }} />
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <div className={styles.container}>
              <Wrapper className={styles.draftWrapper} margin={{ right: 'md' }} padding={{ top: 'mlg' }}>
                <PageDraftSection
                  isNewsletter
                  disableAltSumm
                  newsletter={newsletter}
                  pageTypeSetting={mockPageTypeSetting}
                />
                <Wrapper>
                  <Box margin={{ all: 'md' }}>
                    <Box.Title>Custom Content</Box.Title>
                    <Box.Content>
                      <FormLabel labelPosition="top" label="Sender Name" margin={{ bottom: 'md' }} fullWidth>
                        <Input
                          value={campaignParameters.sender_name}
                          onChange={(evt: Event) => updateCustomizableContent(evt, 'sender_name')}
                        />
                      </FormLabel>
                      <FormLabel labelPosition="top" label="Reply-To Email" margin={{ bottom: 'md' }} fullWidth>
                        <Input
                          value={campaignParameters.reply_to_email}
                          onChange={(evt: Event) => updateCustomizableContent(evt, 'reply_to_email')}
                        />
                      </FormLabel>
                      <FormLabel labelPosition="top" label="Subject" margin={{ bottom: 'md' }} fullWidth>
                        <Input
                          value={campaignParameters.subject}
                          onChange={(evt: Event) => updateCustomizableContent(evt, 'subject')}
                        />
                      </FormLabel>
                      <FormLabel labelPosition="top" label="Pre-Header Text" margin={{ bottom: 'md' }} fullWidth>
                        <Input
                          value={campaignParameters.pre_header_text}
                          onChange={(evt: Event) => updateCustomizableContent(evt, 'pre_header_text')}
                        />
                      </FormLabel>
                    </Box.Content>
                  </Box>
                </Wrapper>
              </Wrapper>
              <div className={styles.pageDivider} />
              <Wrapper className={styles.multitabWrapper} margin={{ left: 'md' }} padding={{ top: 'mlg' }}>
                <NewsletterMultitabSection
                  disableAltSumm
                  newsletterName={newsletterItem.name}
                  campaign={newsletter}
                  handleAddContentToNewsletter={handleAddContentToNewsletter}
                />
              </Wrapper>
            </div>
          </DragDropContext>

          {sendNowOpen && (
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            <Dialog status={sendNowOpen ? 'open' : 'closed'} onDismiss={() => setSendNowOpen(false)}>
              <Dialog.Title id="dialogTitle">Send Campaign</Dialog.Title>
              <Dialog.Content id="dialogDesc">
                <Typography variant="header4">{campaignParameters.subject}</Typography>
                <Typography>Are you sure you want to send this immediately?</Typography>
              </Dialog.Content>
              <Dialog.Actions>
                <Button secondary onClick={() => setSendNowOpen(false)}>
                  Cancel
                </Button>
                <Button primary onClick={sendNow}>
                  Send
                </Button>
              </Dialog.Actions>
            </Dialog>
          )}
        </>
      )}
    </WidePageWrapper>
  );
};
