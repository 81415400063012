/* eslint-disable import/no-cycle */
import { ReactElement } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  Group,
  IconPlusCircle,
  Tooltip,
  Typography,
  useDropdownState,
  Wrapper
} from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { AvailableLayoutModule, ModuleContainer } from 'data/generated/graphql';
import { getUiModuleTypeLabel } from 'utils/modules';
import styles from './AddModuleButton.module.scss';

interface AddModuleButtonProps {
  addableUiModules?: AvailableLayoutModule[];
  nextModuleIndex: number;
  isBlockDivider?: boolean;
}

export const AddModuleButton = ({ addableUiModules, nextModuleIndex, isBlockDivider }: AddModuleButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isAddModuleDropdownOpen,
    setOpen: setIsAddModuleDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();
  const { insertEntity } = useDataModelContext();

  const handleAddModule = (index: number) => {
    if (!addableUiModules || addableUiModules.length === 0) {
      return;
    }

    const defaultModule = clonedeep(addableUiModules[index].defaultModule);

    const newModule: ModuleContainer = {
      type: 'Module',
      attributes: { pageModule: defaultModule },
      collection: []
    };
    insertEntity(`0-0-${nextModuleIndex}`, newModule);

    setIsAddModuleDropdownOpen(false);
  };

  const handleAddModuleDropdownOpenToggle = () => {
    setIsAddModuleDropdownOpen(!isAddModuleDropdownOpen);
  };

  const blockDividerAction = () => {
    setIsAddModuleDropdownOpen(!isAddModuleDropdownOpen);
  };

  if (!addableUiModules || addableUiModules.length === 0) {
    return null;
  }

  const addableUiModulesJSX = addableUiModules.map((addableUiModules, index) => (
    <Wrapper
      padding={{ all: 'md' }}
      key={addableUiModules.uiModuleType}
      className={styles.addableUiModuleOption}
      onClick={() => handleAddModule(index)}
      data-testid="page-add-module-title-button"
    >
      <Typography>{getUiModuleTypeLabel(addableUiModules.uiModuleType)}</Typography>
    </Wrapper>
  ));

  const blockDividerButton = (
    <div className={styles.blockDivider}>
      <div className={styles.blockDividerContent}>
        <Divider className={styles.blockDividerDivider} />
        <Dropdown
          arrow="top"
          position="center"
          margin={{ horizontal: 'xs' }}
          componentRef={dropdownRef}
          open={isAddModuleDropdownOpen}
          onToggle={handleAddModuleDropdownOpenToggle}
        >
          <Dropdown.Trigger>
            <Tooltip>
              <Tooltip.Content position={nextModuleIndex === 0 ? 'bottom' : 'top'}>Add a Module Here</Tooltip.Content>
              <Tooltip.Trigger>
                <Button
                  tertiary
                  icon={IconPlusCircle as SvgComponent}
                  onClick={blockDividerAction}
                  data-testid="add-module-button"
                />
              </Tooltip.Trigger>
            </Tooltip>
          </Dropdown.Trigger>
          <Dropdown.Content
            className={styles.blockDividerDropdownContent}
            arrow="top"
            margin={{ top: 'sm' }}
            padding={{ all: 'md' }}
          >
            {addableUiModulesJSX}
          </Dropdown.Content>
        </Dropdown>
        <Divider className={styles.blockDividerDivider} />
      </div>
    </div>
  );

  const regularButton = (
    <Dropdown
      componentRef={dropdownRef}
      trigger={
        <Group gap="xs" data-testid="add-module-button">
          <IconPlusCircle />
          Add a module
        </Group>
      }
      open={isAddModuleDropdownOpen || false}
      onToggle={handleAddModuleDropdownOpenToggle}
      padding={{ all: 'none' }}
    >
      {addableUiModulesJSX}
    </Dropdown>
  );

  return isBlockDivider ? blockDividerButton : regularButton;
};
