// utils in this file are temporary fixes to bugs that require more time/effort that we have at the moment

/**
 * Sometimes values we expect to be stringified objects are not and calling `JSON.parse` on them
 * will result in an error. If the validity of these values is out of our control, we should
 * prevent the app from crashing and return a default value.
 */
export function safelyParseContent<T = any, U = T>(potentiallyUnsafeToParseString: any, defaultValue: any = {}): T | U {
  try {
    if (typeof potentiallyUnsafeToParseString === 'string') return JSON.parse(potentiallyUnsafeToParseString) as T;
    throw new Error();
  } catch (_) {
    /* eslint-disable-next-line no-console */
    console.log('Error: Upstream data malformatted. Unable to stringify JSON -', potentiallyUnsafeToParseString);
    return defaultValue as U;
  }
}
