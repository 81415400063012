import { Loader, Wrapper } from '@screentone/core';

import styles from './ResponsiveLoader.module.scss';

interface ResponsiveLoaderProps {
  size?: 'md' | 'lg' | 'xl';
}

export const ResponsiveLoader = ({ size }: ResponsiveLoaderProps) => (
  <Wrapper data-testid="responsive-loader-container" className={styles.wrapper}>
    <Loader data-testid="loader" size={size} />
  </Wrapper>
);
