import { Button, IconExternalLink } from '@screentone/core';

import { useIsAdmin } from 'hooks/roles';
import env from 'utils/env';

export enum ExternalLinkContentType {
  COLLECTION = 'collection',
  ARTICLE = 'article'
}

interface ExternalLinkProps {
  contentId: string;
  isPublished: boolean;
  type: ExternalLinkContentType;
  embargo?: boolean;
}

const ExternalLink = ({ contentId, isPublished, type, embargo = false }: ExternalLinkProps) => {
  const isAdmin = useIsAdmin();

  if (!isAdmin || !isPublished || contentId === '') return null;
  const EMBARGO_PATH = 'preview/content';
  const LIVE_PATH = `${type === ExternalLinkContentType.ARTICLE ? 'history' : 'collections'}/byoriginid`;
  const PATH = embargo ? EMBARGO_PATH : LIVE_PATH;

  const allessehUrl = `${env.ALLESSEH_ADMIN_TOOL}${PATH}/${contentId}`;

  return (
    <Button
      data-testid="external-link-open-allesseh-button"
      tertiary
      icon={IconExternalLink as SvgComponent}
      iconPosition="right"
      onClick={() => window.open(allessehUrl, '_blank')}
    >
      Open in Allesseh Admin Tool
    </Button>
  );
};

export default ExternalLink;
