import { PageModule, UiModuleType } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';

export const getUiModuleTypeLabel = (uiModuleType: UiModuleType) =>
  `${uiModuleType
    .replaceAll('ModuleType', '')
    .replaceAll('Ui', '')
    .replace(/([A-Z])/g, ' $1')
    .trim()}`;

export const getModuleFromModuleHierarchyId = (
  pageModules: PageModule[],
  moduleHierarchyId: string
): PageModule[] | PageModule | null => {
  const splitHierarchyIds = moduleHierarchyId.split('-').filter((x) => x.length > 0);
  let curItem: PageModule[] | PageModule | null = pageModules;
  // eslint-disable-next-line no-restricted-syntax
  for (const currentHierarchyId of splitHierarchyIds) {
    const index = parseInt(currentHierarchyId, 10);
    // try number index
    if (!Number.isNaN(index)) {
      if (curItem && 'length' in curItem) {
        curItem = curItem[index];
      } else {
        return null;
      }
      // try twoColumnModule field
    } else if (curItem && 'uiModuleFields' in curItem && curItem.uiModuleFields.twoColumnModule) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Not sure why TypeScript evaluates the type of `curItem.uiModuleFields` as `unknown`.
      curItem = curItem.uiModuleFields.twoColumnModule[currentHierarchyId as 'leftModules' | 'rightModules'];
    } else {
      return null;
    }
  }

  return curItem;
};

export const getModuleListAndIndexFromModuleHierarchyId = (
  pageModules: PageModule[],
  moduleHierarchyId: string
): { moduleList: PageModule[]; index: number } | null => {
  const splitHierarchyIds = moduleHierarchyId.split('-').filter((x) => x.length > 0);
  let curItem: PageModule[] | PageModule | null = pageModules;
  let parentItem: PageModule[] | PageModule | null = null;
  let lastIndex: number | null = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const currentHierarchyId of splitHierarchyIds) {
    const index = parseInt(currentHierarchyId, 10);
    lastIndex = index;
    // try number index
    if (!Number.isNaN(index)) {
      if (curItem && 'length' in curItem) {
        parentItem = curItem;
        curItem = curItem[index];
      } else {
        return null;
      }
      // try twoColumnModule field
    } else if (curItem && 'uiModuleFields' in curItem && curItem.uiModuleFields.twoColumnModule) {
      parentItem = curItem;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Not sure why TypeScript evaluates the type of `curItem.uiModuleFields` as `unknown`.
      curItem = curItem.uiModuleFields.twoColumnModule[currentHierarchyId as 'leftModules' | 'rightModules'];
    } else {
      return null;
    }
  }

  if (!parentItem || lastIndex == null || !('length' in parentItem)) {
    return null;
  }

  return { moduleList: parentItem, index: lastIndex };
};

export interface PageModuleForDisplay {
  module: PageModule;
  moduleHierarchyId: string;
  parentModuleIndex: number;
  isChildModule: boolean;
  hasValidationError: boolean;
}

interface HierarchyData {
  moduleHierarchyId: string;
  isChildModule: boolean;
  parentIndex?: number;
  index?: number;
}

function getFlattenedModuleDataFromPageModules<T>(
  hierarchyData: HierarchyData,
  pageModuleFn: (pageModule: PageModule, hierarchyData: HierarchyData) => T,
  pageModules?: PageModule[]
): T[] {
  return (pageModules ?? []).reduce<T[]>((acc, curr, index) => {
    if (curr.uiModuleType === UiModuleType.UiTwoColumnModuleType) {
      const prefix = `${hierarchyData.moduleHierarchyId}${hierarchyData.moduleHierarchyId.length > 0 ? '-' : ''}`;
      return acc
        .concat(
          getFlattenedModuleDataFromPageModules(
            { moduleHierarchyId: `${prefix}${index}-leftModules`, isChildModule: true, parentIndex: index },
            pageModuleFn,
            curr.uiModuleFields.twoColumnModule?.leftModules
          )
        )
        .concat(
          getFlattenedModuleDataFromPageModules(
            { moduleHierarchyId: `${prefix}${index}-rightModules`, isChildModule: true, parentIndex: index },
            pageModuleFn,
            curr.uiModuleFields.twoColumnModule?.rightModules
          )
        );
    }
    const newHierarchyData = { ...hierarchyData, index };
    if (newHierarchyData.parentIndex == null) {
      newHierarchyData.parentIndex = index;
    }
    return acc.concat(pageModuleFn(curr, newHierarchyData));
  }, []);
}

export const getAllFlattenedModulesForDisplay = (pageModules?: PageModule[]) =>
  getFlattenedModuleDataFromPageModules<PageModuleForDisplay>(
    { moduleHierarchyId: '', isChildModule: false },
    (pageModule: PageModule, hierarchyData: HierarchyData) => ({
      module: pageModule,
      moduleHierarchyId: `${hierarchyData.moduleHierarchyId}${hierarchyData.moduleHierarchyId.length > 0 ? '-' : ''}${
        hierarchyData.index ?? ''
      }`,
      isChildModule: hierarchyData.isChildModule,
      parentModuleIndex: hierarchyData.parentIndex ?? 0,
      hasValidationError: !!pageModule.hasValidationError
    }),
    pageModules
  ).flat();

export const getAllContentIds = (pageModules?: PageModule[]) =>
  getFlattenedModuleDataFromPageModules<string[]>(
    { moduleHierarchyId: '', isChildModule: false },
    (pageModule: PageModule) =>
      pageModule.moduleItems
        .map((moduleItem) => moduleItem.itemFields.contentItem?.originId)
        .filter((x) => !!x) as string[],
    pageModules
  ).flat();

export const getAllContentIdsInCollection = (pageModules?: PageModule[]) =>
  getFlattenedModuleDataFromPageModules<string[]>(
    { moduleHierarchyId: '', isChildModule: false },
    (pageModule: PageModule) =>
      pageModule.moduleItems
        .map((moduleItem) =>
          moduleItem.itemFields.collectionItem?.collection?.contentItems.map((item) => item.originId)
        )
        .flat()
        .filter((x) => !!x) as string[],
    pageModules
  ).flat();

export const getAllCollectionIds = (pageModules?: PageModule[]) =>
  getFlattenedModuleDataFromPageModules<string[]>(
    { moduleHierarchyId: '', isChildModule: false },
    (pageModule: PageModule) =>
      pageModule.moduleItems
        .map((moduleItem) => moduleItem.itemFields.collectionItem?.idCreatedUtc)
        .filter((x) => !!x) as string[],
    pageModules
  ).flat();

export const getAllEmbargoContent = (pageModules?: PageModule[]) => {
  const flattenedModules = getAllFlattenedModulesForDisplay(pageModules);

  return (
    flattenedModules
      .flatMap<AllessehContent>((pageModule) => {
        const embargoItems = pageModule.module.moduleItems
          // Get Allesseh content in module item and parse as JSON
          .map<AllessehContent | null>((pageModuleItem) => {
            const allessehContentStr = pageModuleItem.itemFields.contentItem?.content;
            if (!allessehContentStr) return null;

            const allessehContent = JSON.parse(allessehContentStr) as AllessehContent;
            return allessehContent;
          })
          // Filter embargo content
          .filter((content): content is AllessehContent => content?.data.attributes.content_status === 'embargo');

        return embargoItems;
      })
      // Sort descending by `embargo_datetime_utc`
      .sort(
        (a, b) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          new Date(b.data.attributes.embargo_datetime_utc!).getTime() -
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          new Date(a.data.attributes.embargo_datetime_utc!).getTime()
      )
  );
};

export const clearValidationError = (pageModules: PageModule[] | undefined): PageModule[] => {
  if (!pageModules) {
    return [];
  }

  return pageModules.reduce<PageModule[]>((acc, currModule) => {
    if (currModule.uiModuleType === UiModuleType.UiTwoColumnModuleType) {
      return acc
        .concat(clearValidationError(currModule.uiModuleFields.twoColumnModule!.leftModules))
        .concat(clearValidationError(currModule.uiModuleFields.twoColumnModule!.rightModules));
    }

    return acc.concat({ ...currModule, hasValidationError: false });
  }, []);
};

export const mergeModulesWithValidationError = (
  pageModules: PageModule[],
  modulesWithValidationError: PageModule[]
): PageModule[] =>
  pageModules.map((module, idx) => ({
    ...module,
    hasValidationError: modulesWithValidationError[idx].hasValidationError
  }));
