/* eslint-disable import/no-cycle */
import { useCallback, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Dropdown, Group, IconThreeDotsVer, Typography, Wrapper } from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useAlert } from 'contexts/alert/useAlert';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { ArticleItem, ExternalCollectionItem, ModuleContainer, PageModule } from 'data/generated/graphql';
import { AddModuleButton } from 'features/section-page-edit/components/add-module-button/AddModuleButton';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { getUiModuleTypeLabel } from 'utils/modules';
import { safelyParseContent } from 'utils/temp';
import { ModuleItemCount } from './components/module-item-count/ModuleItemCount';
import { UiModuleFieldsHeader } from './components/ui-module-fields-header/UiModuleFieldsHeader';
import styles from './Module.module.scss';

interface ModuleProps extends CommonEntityProps {
  data: ModuleContainer;
}

export const Module = ({ data: module, parentHierarchyId, index, isHistory }: ModuleProps) => {
  const { generateHierarchyId, renderEntity } = useDataModelContext();
  const { renderActions: renderMenuActions } = useContextMenuActions();
  const hierarchyId = generateHierarchyId(module, parentHierarchyId, index);

  const { alertState } = useAlert();
  const moduleHasValidationError =
    !isHistory && alertState.pageValidationError?.moduleErrors.some((error) => error.pathToModule === hierarchyId);

  const { getTreatmentTypeSetting, currentLayout, addableUiModules } = usePageTypeSettingsContext();
  const treatmentTypeSetting = getTreatmentTypeSetting(module.attributes.pageModule as PageModule);

  const [articlesPerContentItem, setArticlesPerContentItem] = useState<number[]>(
    module.collection.map((contentItem) => {
      if (contentItem.type === 'Article') {
        return 1;
      }
      if (contentItem.type === 'Collection') {
        const item = contentItem as ExternalCollectionItem;
        return item.contentItems.length;
      }
      if (contentItem.type === 'Query') {
        return 0;
      }

      return 0;
    })
  );

  const handleContentLoad = useCallback((index: number, content: AllessehContent[]) => {
    setArticlesPerContentItem((prev) => {
      const newLength = [...prev];
      newLength[index] = content.length;
      return newLength;
    });
  }, []);

  const getNumTotalItemsBeforeIndex = (index: number) =>
    articlesPerContentItem.reduce((acc: number, curr: number, numContentItemsIndex: number) => {
      if (numContentItemsIndex < index) {
        const item = module.collection[numContentItemsIndex];
        if (item.type === 'Article') {
          const articleItem = item as ArticleItem;
          const parsedContent = safelyParseContent<AllessehContent>(articleItem.content);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!parsedContent.data?.attributes?.content_status) {
            return acc + curr;
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const itemStatus = parsedContent.data?.attributes?.content_status;
          return itemStatus === 'embargo' ? acc : acc + curr;
        }
      }
      return numContentItemsIndex < index ? acc + curr : acc;
    }, 0);

  const numTotalItemsUsed = getNumTotalItemsBeforeIndex(module.collection.length);
  const droppablePrefix = isHistory ? DROPPABLE_ID_PREFIXES.HISTORY : DROPPABLE_ID_PREFIXES.MODULE_ITEMS;
  const actionsType = isHistory ? 'historyModule' : 'draftModule';

  const isAvailableLayoutModuleType =
    currentLayout?.availableLayoutModules
      .map((m) => m.uiModuleType)
      .includes((module.attributes.pageModule as PageModule).uiModuleType) ?? false;

  return (
    <Wrapper
      margin={{ bottom: !isAvailableLayoutModuleType ? 'mlg' : 'none' }}
      data-model-hierarchy-id={hierarchyId}
      className={styles.moduleWrapper}
    >
      <Group margin={{ bottom: 'xs' }} align="space-between">
        <Group gap="none">
          <Typography size="sm" weight="bold" margin={{ right: 'sm' }} data-testid="page-treatment-type">
            {getUiModuleTypeLabel((module.attributes.pageModule as PageModule).uiModuleType)}
          </Typography>
          <Typography variant="note" data-testid="page-treatment-type-value">
            {treatmentTypeSetting?.label}
          </Typography>
        </Group>
        <Dropdown padding={{ all: 'none' }} position="right" trigger={<IconThreeDotsVer color="asphalt" />}>
          {renderMenuActions(actionsType, {
            entity: module,
            hierarchyId,
            index,
            extraProperties: { numTotalItemsUsed }
          })}
        </Dropdown>
      </Group>
      <UiModuleFieldsHeader pageModule={module.attributes.pageModule as PageModule} />
      <Wrapper
        padding={{ all: 'xs' }}
        className={styles.moduleDashedArea}
        data-error={moduleHasValidationError ? 'true' : 'false'}
      >
        <Droppable droppableId={droppablePrefix + hierarchyId} isDropDisabled={isHistory}>
          {(droppableProvided) => (
            <div
              data-testid="page-module-item"
              className={styles.moduleDroppableArea}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {module.collection.map((item, i) => {
                const availableContentSlots = treatmentTypeSetting?.maxRequiredContentItems
                  ? treatmentTypeSetting.maxRequiredContentItems - getNumTotalItemsBeforeIndex(i)
                  : treatmentTypeSetting?.minRequiredContentItems
                  ? treatmentTypeSetting.minRequiredContentItems - getNumTotalItemsBeforeIndex(i)
                  : undefined;
                const articleHierarchyId = generateHierarchyId(item, hierarchyId, i);
                const articleActionsType = isHistory ? 'historyArticle' : 'draftArticle';

                return (
                  <div key={i}>
                    {renderEntity(item, {
                      hierarchyId,
                      index: i,
                      isHistory,
                      extraProps: {
                        content: { availableContentSlots, onContentLoaded: handleContentLoad, isDraft: !isHistory },
                        article: {
                          renderActions: () =>
                            renderMenuActions(articleActionsType, {
                              hierarchyId: articleHierarchyId,
                              entity: item,
                              index: i
                            })
                        }
                      }
                    })}
                  </div>
                );
              })}
              {droppableProvided.placeholder}
              <ModuleItemCount
                min={treatmentTypeSetting?.minRequiredContentItems}
                max={treatmentTypeSetting?.maxRequiredContentItems}
                showMaxInDenominator
                count={numTotalItemsUsed}
                contentTypes={treatmentTypeSetting?.contentTypes}
                data-testid="page-module-item-count"
              />
            </div>
          )}
        </Droppable>
      </Wrapper>
      {!!index && isAvailableLayoutModuleType && (
        <AddModuleButton addableUiModules={addableUiModules} nextModuleIndex={index + 1} isBlockDivider />
      )}
    </Wrapper>
  );
};
