/*
Newsletter          Default send time (ET)
Weekend Reads       Fridays, noon
Mutual Funds        Thursdays, 9 am
ETF Wrap            Thursdays, 5 pm
Need to Know        Daily, 7:30 am
Distributed Ledger  Daily, 4 pm ET

From here: https://dowjones.atlassian.net/browse/ET-7315
*/

import { PublicationSetting } from 'data/generated/graphql';

export const getTimeZoneOffsetByName = (timeZone: string | undefined) => {
  if (!timeZone) return null;
  const str = new Date().toLocaleString('en', { timeZone, timeZoneName: 'longOffset' });
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [_, h, m] = str.match(/([+-]\d+):(\d+)$/) ?? ['', '+00', '00'];
  return (Number(h) * 60 + (Number(h) > 0 ? +m : -m)) * -1;
};

interface NewsletterDays {
  [currentProperty: string]: {
    [newsletterName: string]: number;
  };
}

const NEWSLETTER_DAYS: NewsletterDays = {
  marketwatch: {
    // This refers to days of the week
    // Mon = 1, Tues = 2, etc.
    'Mutual Funds Weekly': 4,
    'ETF Wrap': 4,
    'Weekend Reads': 5
  }
};

export const getNewsletterDefaultDate = (
  currentProperty: string | null,
  newsletterName?: string,
  initialTime?: string
) => {
  if (!currentProperty || !newsletterName) return;

  const now = new Date();
  const newDate = new Date();

  const hour = initialTime?.split(':')[0];
  const minute = initialTime?.split(':')[1];

  if (hour && minute) {
    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));
  }

  if (newDate < now) {
    // if our default datetime is in the past
    // set it to be one day from now
    newDate.setDate(newDate.getDate() + 1);
  }

  // // Now, set the day of the week (ie. Mon, Tues etc) to the correct value
  const dayOfWeek = NEWSLETTER_DAYS[currentProperty][newsletterName];

  // If there's no default day of the week, the newsletter is assumed to be daily
  // and the current datetime is thus correct
  // eslint-disable-next-line consistent-return
  if (!dayOfWeek) return newDate;

  // eslint-disable-next-line consistent-return
  return new Date(newDate.setDate(newDate.getDate() + ((dayOfWeek + (7 - newDate.getDay())) % 7)));
};

interface NewsletterTimes {
  [currentProperty: string]: {
    [newsletterName: string]: string;
  };
}

const NEWSLETTER_TIMES: NewsletterTimes = {
  // times are in Eastern Time
  marketwatch: {
    'Mutual Funds Weekly': '09:00',
    'ETF Wrap': '17:00',
    'Need to Know': '07:30',
    'Distributed Ledger': '16:00',
    'Weekend Reads': '12:00'
  }
};

export const getNewsletterDefaultTime = (currentProperty: string | null, newsletterName?: string) => {
  if (!currentProperty || !newsletterName) return;
  // eslint-disable-next-line consistent-return
  return NEWSLETTER_TIMES[currentProperty][newsletterName];
};

export const getNewsletterScheduleMinDate = ({
  initialDate,
  publicationSetting
}: {
  initialDate?: Date;
  publicationSetting?: PublicationSetting;
}) => {
  if (!publicationSetting || !initialDate) return;
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const desiredOffset = getTimeZoneOffsetByName(publicationSetting.preferredTimezone.timezone);
  if (!desiredOffset) return;
  const offsetDifference = offset < 0 ? desiredOffset - offset : offset - desiredOffset;
  const adjustedTime = now.getTime() + offsetDifference * 60 * 1000;
  // eslint-disable-next-line consistent-return
  return new Date(adjustedTime);
};

interface NewsletterFilters {
  [currentProperty: string]: {
    [newsletterName: string]: {
      sectionType: string;
    };
  };
}

export const NEWSLETTER_FILTERS: NewsletterFilters = {
  marketwatch: {
    'Distributed Ledger': {
      sectionType: 'Distributed Ledger'
    },
    'Mutual Funds Weekly': {
      sectionType: 'Mutual Funds Weekly'
    },
    'ETF Wrap': {
      sectionType: 'ETF Wrap'
    },
    'Need to Know': {
      sectionType: 'Need to Know'
    }
  }
};
