/*
 * This file includes helper functions that manage
 * the UI configuration of the query form. It helps determine
 * which operators or form components can be shown.
 */
import { BUYSIDE_CONFIG } from './query-rule-configs/buyside';
import { CWSJ_CONFIG } from './query-rule-configs/cwsj';
import { DEFAULT_CONFIG } from './query-rule-configs/default';
import { JWSJ_CONFIG } from './query-rule-configs/jwsj';
import { QueryRuleFormKeyConfig, QueryRuleFormType } from './query-rule-configs/types';
import { QueryRuleArrayNameType, QueryRuleOperatorType } from '../../queryRulesFormUtils';
import { DateForm } from '../date-form/DateForm';
import { SelectForm } from '../select-form/SelectForm';
import { TextForm } from '../text-form/TextForm';

// return key rule config for a particular publication
export const getQueryRuleConfig = (publicationKey: string | null) => {
  switch (publicationKey) {
    case 'buyside':
      return BUYSIDE_CONFIG;
    case 'cwsj':
      return CWSJ_CONFIG;
    case 'jwsj':
      return JWSJ_CONFIG;
    default:
      return DEFAULT_CONFIG;
  }
};

// returns the array of query rule keys that are allowed to be shown as options
// for a specific QueryRuleArrayNameType (one of the boxed sections in the query builder)
export const getKeysByQueryRuleArrayNameType = (
  queryRuleArrayNameType: QueryRuleArrayNameType,
  config: Record<string, QueryRuleFormKeyConfig>
) => {
  if (queryRuleArrayNameType === QueryRuleArrayNameType.NOT) {
    return Object.keys(config)
      .filter((k) => config[k].queryRuleFormType !== QueryRuleFormType.DATE)
      .sort();
  }
  return Object.keys(config).sort();
};

// returns an array of operators that are allowed to be shown as options
// for a specific QueryRuleArrayNameType (one of the boxed sections in the query builder)
export const getOperatorsByType = (
  queryRuleArrayNameType: QueryRuleArrayNameType,
  queryRuleFormType: QueryRuleFormType
) => {
  switch (queryRuleFormType) {
    case QueryRuleFormType.DATE:
      return queryRuleArrayNameType === QueryRuleArrayNameType.NOT
        ? []
        : [
            QueryRuleOperatorType.LESS_THAN,
            QueryRuleOperatorType.LESS_THAN_EQUAL,
            QueryRuleOperatorType.EQUAL,
            QueryRuleOperatorType.GREATER_THAN_EQUAL,
            QueryRuleOperatorType.GREATER_THAN
          ];
    case QueryRuleFormType.SELECT:
      return queryRuleArrayNameType === QueryRuleArrayNameType.NOT
        ? [QueryRuleOperatorType.NOT_EQUAL]
        : [QueryRuleOperatorType.EQUAL];
    case QueryRuleFormType.TEXT:
    default:
      return queryRuleArrayNameType === QueryRuleArrayNameType.NOT
        ? [QueryRuleOperatorType.NOT_EQUAL]
        : [QueryRuleOperatorType.EQUAL];
  }
};

// returns the form component that should be shown
// for a specific QueryRuleFormType (based on the form key being used)
export const getFormComponentByQueryRuleFormType = (queryRuleFormType: QueryRuleFormType) => {
  switch (queryRuleFormType) {
    case QueryRuleFormType.DATE:
      return DateForm;
    case QueryRuleFormType.SELECT:
      return SelectForm;
    case QueryRuleFormType.TEXT:
    default:
      return TextForm;
  }
};
