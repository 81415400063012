import { useState } from 'react';
import { Tabs, Typography, Wrapper } from '@screentone/core';

import { ShowFilterOptions } from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { PageCollectionSearch } from './page-collection-search/PageCollectionSearch';
import { PageContentSearch } from './page-content-search/PageContentSearch';
import pageEditStyles from '../../SectionPageEdit.module.scss';

export const PageSearchSection = ({
  showCollections = true,
  showFilters,
  newsletterName
}: {
  showCollections?: boolean;
  showFilters?: ShowFilterOptions;
  newsletterName?: string;
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!showCollections) {
    return (
      <PageContentSearch
        resultListClassName={pageEditStyles.searchResultScrollableWithoutCollections}
        showFilters={showFilters}
        newsletterName={newsletterName}
      />
    );
  }

  return (
    <Wrapper>
      <Typography margin={{ bottom: 'md' }} variant="header3" data-testid="page-content-search-title">
        Content
      </Typography>
      <Tabs role="tablist" onChange={setTabIndex} value={tabIndex}>
        <Tabs.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === 0 ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="page-content-items-tab"
        >
          Items
        </Tabs.Item>
        <Tabs.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === 1 ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          data-testid="page-content-collections-tab"
        >
          Collections
        </Tabs.Item>
      </Tabs>
      {tabIndex === 0 && (
        <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
          <PageContentSearch resultListClassName={pageEditStyles.searchResultScrollable} showFilters={showFilters} />
        </Wrapper>
      )}
      {tabIndex === 1 && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <PageCollectionSearch resultListClassName={pageEditStyles.searchResultScrollable} />
        </Wrapper>
      )}
    </Wrapper>
  );
};
