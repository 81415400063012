import { Button, Dialog, Loader } from '@screentone/core';

type ConfirmationDialogProps = {
  title: string;
  isLoading: boolean;
  description: string;
  onClickFunc: () => void;
  showDialog: (show: boolean) => void;
};

export const ConfirmationDialog = ({
  title,
  isLoading,
  showDialog,
  onClickFunc,
  description
}: ConfirmationDialogProps) => (
  <Dialog status="open" aria-labelledby="dialogTitle" aria-describedby="dialogDesc" onDismiss={() => showDialog(false)}>
    <Dialog.Title id="dialogTitle">{title}</Dialog.Title>
    <Dialog.Content id="dialogDesc">{description}</Dialog.Content>
    <Dialog.Actions>
      <Button secondary onClick={() => showDialog(false)}>
        Cancel
      </Button>
      <Button primary onClick={onClickFunc} data-testid="itp-delete-confirm-button">
        {isLoading ? <Loader size="md" /> : 'Confirm'}
      </Button>
    </Dialog.Actions>
  </Dialog>
);
