import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Typography } from '@screentone/core';

import { useTrash } from 'contexts/trash/useTrash';
import { DROPPABLE_IDS } from 'utils/collectionDragUtils';
import styles from './PageDraftTrash.module.scss';

const PageDraftTrash = ({ messageLabel = 'new page' }: { messageLabel?: string }) => {
  const { isDragging } = useTrash();

  return (
    <Droppable droppableId={DROPPABLE_IDS.TRASH}>
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          className={isDragging ? styles.droppable : [styles.droppable, styles.droppableInactive].join(' ')}
        >
          <div className={styles.placeholder}>{droppableProvided.placeholder}</div>
          {isDragging && (
            <Typography color="asphalt" data-testid="page-content-remove-label">
              drag here to remove
              <br />
              from the {messageLabel}
            </Typography>
          )}
        </div>
      )}
    </Droppable>
  );
};

export default PageDraftTrash;
