import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, Divider, Overlay, Typography, useModalPortal, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';

import { ResponsiveLoader } from 'components';
import { Article } from 'components/datamodel/content/Article/Article';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { QueryRulesForm } from 'components/query-rules-form/QueryRulesForm';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useAllessehContentQuery } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { getExcludedIdsQuery, mergeAllessehQueryBodies, validateValue } from 'utils/queryUtils';
import styles from './QueryModal.module.scss';

interface QueryModalProps {
  queryItemHierarchyId: string;
  onDismiss: () => void;
  allessehJsonQuery: string;
  onChangeJsonQueryStr: (newJsonQueryStr: string) => void;
  excludePageContentIdsQuery: string;
  count: number | null;
  baseQuery: string;
  viewOnly?: boolean;
}

export const QueryModal = ({
  queryItemHierarchyId,
  allessehJsonQuery,
  excludePageContentIdsQuery,
  onDismiss,
  onChangeJsonQueryStr,
  count,
  baseQuery,
  viewOnly
}: QueryModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const [jsonQueryStr, setJsonQueryStr] = useState(allessehJsonQuery);
  const [allessehQueryBody, setAllessehQueryBody] = useState<AllessehContentQueryBody | null>(null);
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { currentProperty } = useAuth();
  const { fromAllessehContent } = useDataModelContext();
  const { renderActions: renderMenuActions } = useContextMenuActions();

  const updateAllessehQueryBody = (allessehJsonQuery?: string) => {
    const fullAllessehQuery = mergeAllessehQueryBodies(baseQuery, excludePageContentIdsQuery, allessehJsonQuery);
    setAllessehQueryBody(fullAllessehQuery);
  };
  const debouncedUpdate = debounce(updateAllessehQueryBody, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDebouncedQueryBody = useCallback(debouncedUpdate, [publicationSettingsResp]);

  useEffect(() => {
    setJsonQueryStr(allessehJsonQuery);
  }, [allessehJsonQuery]);

  useEffect(() => {
    if (!allessehQueryBody) {
      updateAllessehQueryBody(jsonQueryStr);
    } else {
      updateDebouncedQueryBody(jsonQueryStr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonQueryStr]);

  const {
    data,
    isLoading: isLoad,
    fetchStatus
  } = useAllessehContentQuery(allessehQueryBody ?? {}, { enabled: !!allessehQueryBody });

  // fetch the data needed to display the headlines of excluded articles
  const excludedAllessehContentQuery = getExcludedIdsQuery(allessehQueryBody);
  const fullExcludedContentAllessehQuery = mergeAllessehQueryBodies(
    baseQuery,
    excludePageContentIdsQuery,
    JSON.stringify(excludedAllessehContentQuery)
  );
  const { data: excludedData } = useAllessehContentQuery(fullExcludedContentAllessehQuery, {
    enabled: !!fullExcludedContentAllessehQuery
  });
  const excludedArticles = excludedData?.pages[0]?.data?.attributes;

  const isLoading = isLoad && fetchStatus !== 'idle';
  const contents = data
    ? data.pages
        .map((page) => page.data?.attributes)
        .flat()
        .filter((x): x is AllessehContent => !!x)
    : [];

  // const { duplicateIds, allContentIds } = usePagePublish();
  // const [initialContentIds, setInitialContentIds] = useState({});

  // const { state } = useHeaderData();

  // const getClassName = getDuplicateClassName(duplicateIds, isDraft, state.headerConfig.currentTheme ?? 'light');

  // useEffect(() => {
  //   setInitialContentIds(allContentIds);
  //   // just need to run this once on load
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const noResultsMessage = validateValue(
    currentProperty,
    allessehQueryBody?.query as { [key: string]: AllessehQueryRule[] }
  );

  const handleAllessehJsonQueryChange = (newAllessehJsonQuery: string) => {
    setJsonQueryStr(newAllessehJsonQuery);
  };

  const handleCancel = () => {
    onDismiss();
  };

  const handleSave = () => {
    const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
    onChangeJsonQueryStr(JSON.stringify({ ...parsedJsonQuery, count }));
    onDismiss();
  };

  const moreItemsCount = (data?.pages[0].links?.total ?? 0) - contents.length;
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Overlay onDismiss={onDismiss} status="open" renderNode={renderNode} className={styles.overlay}>
      <Box>
        <Box.Title>QUERY {viewOnly ? 'VIEWER' : 'BUILDER'}</Box.Title>
        <Box.Content padding={{ all: 'none' }}>
          <Wrapper className={styles.content} padding={{ top: 'sm', right: 'md', bottom: 'md', left: 'md' }}>
            <Wrapper variant="header3" className={styles.contentLeft} padding={{ right: 'md' }}>
              <QueryRulesForm
                allessehJsonQuery={jsonQueryStr}
                excludedData={excludedArticles}
                fullAllessehJsonQueryOverride={allessehQueryBody}
                onAllessehJsonQueryChange={!viewOnly ? handleAllessehJsonQueryChange : null}
              />
            </Wrapper>
            <div className={styles.contentRight}>
              <Typography size="lg" margin={{ bottom: 'sm', top: 'sm' }}>
                Query Results
              </Typography>
              <Wrapper>
                {isLoading && <ResponsiveLoader />}
                {!isLoading && contents.length === 0 && (
                  <>
                    <NoSearchResults title="Results" bodyText={noResultsMessage} />
                  </>
                )}
                {!isLoading && contents.length > 0 && (
                  <div className={styles.contentList}>
                    {contents.map((content, i) => {
                      const articleItem = fromAllessehContent(content);
                      return (
                        <Article
                          key={i}
                          data={articleItem}
                          renderActions={() =>
                            renderMenuActions('articleQueryModal', {
                              hierarchyId: queryItemHierarchyId,
                              entity: articleItem,
                              isHistory: viewOnly,
                              extraProperties: { allessehContent: content, jsonQueryStr, setJsonQueryStr }
                            })
                          }
                          isDraft={!viewOnly}
                          parentQueryHierarchyId={queryItemHierarchyId}
                        />
                      );
                    })}
                    {moreItemsCount > 0 && (
                      <Typography variant="note" margin={{ top: 'sm', left: 'sm' }}>
                        + {moreItemsCount} more
                      </Typography>
                    )}
                  </div>
                )}
              </Wrapper>
            </div>
          </Wrapper>
          <Divider />
          {!viewOnly && (
            <Wrapper padding={{ all: 'md' }} className={styles.bottomBar}>
              <Button secondary onClick={handleCancel}>
                Cancel
              </Button>
              <Button primary margin={{ left: 'sm' }} onClick={handleSave}>
                Save
              </Button>
            </Wrapper>
          )}
        </Box.Content>
      </Box>
    </Overlay>
  );
};
