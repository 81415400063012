import { useCallback, useState } from 'react';
import { Box, Button, IconSort, Input, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';

import { NewslettersInput } from 'data/generated/graphql';
import { DEBOUNCE_TIMEOUT } from 'features/pages/components/pages-search-bar/PagesSearchBar';

import styles from 'features/pages/components/pages-search-bar/PagesSearchBar.module.scss';

export const NewsletterSearchBar = ({
  newslettersInput,
  onPartialNewslettersInputChange
}: {
  newslettersInput: NewslettersInput;
  onPartialNewslettersInputChange: (s: Partial<NewslettersInput>) => void;
}) => {
  const [searchInput, setSearchInput] = useState(newslettersInput.searchText);

  const debouncedSearch = debounce((newSearchText: string) => {
    onPartialNewslettersInputChange({ searchText: newSearchText });
  }, DEBOUNCE_TIMEOUT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchTextChange = useCallback(debouncedSearch, []);

  const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
    handleSearchTextChange(e.target.value);
  };

  return (
    <Wrapper padding={{ top: 'sm', bottom: 'md' }}>
      <Box padding={{ all: 'sm' }} className={styles.pagesSearchBar}>
        <Input
          type="text"
          placeholder="Search by newsletter name"
          value={searchInput ?? ''}
          onChange={handleSearchInputChange}
          margin={{ right: 'sm' }}
        />
        <Button disabled tertiary icon={IconSort as SvgComponent}>
          Sort: Create Date
        </Button>
      </Box>
    </Wrapper>
  );
};
