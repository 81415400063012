import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs as TabsComponent, Typography, Wrapper } from '@screentone/core';

import { PublishHistory } from 'components/publish-history/PublishHistory';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { PageDto } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import pageEditStyles from '../../SectionPageEdit.module.scss';
import { PageContentSearch } from '../page-search-section/page-content-search/PageContentSearch';
import { PageSearchSection } from '../page-search-section/PageSearchSection';

enum Tabs {
  ContentSearch,
  Embargoed,
  PublishHistory
}

export const PageMultitabSection = () => {
  const { publishUtc } = useParams();
  const [tabIndex, setTabIndex] = useState(Tabs.ContentSearch);
  const publishUtcParam = publishUtc ? parseInt(publishUtc, 10) : 0;
  const { data: publicationSettings } = usePublicationSettings();
  const { getDTO } = useDataModelContext();
  const { hasRecentlyPublished, hasRecentlyScheduled, originalPageWhenInHistoryEditMode, handleShowHistoryEditMode } =
    usePagePublish();
  const disableEmbargo = publicationSettings?.publicationSetting.disableEmbargo ?? false;
  const pageDTO = getDTO() as PageDto;
  const [liveVersion, setLiveVersion] = useState(pageDTO);

  useEffect(() => {
    // Select Publish History tab when loading a shared page version
    if (publishUtc) {
      setTabIndex(Tabs.PublishHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLiveVersion(pageDTO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRecentlyPublished]);

  return (
    <Wrapper>
      <TabsComponent
        role="tablist"
        onChange={setTabIndex}
        value={tabIndex}
        margin={{ bottom: 'md' }}
        data-testid="page-multi-tab-list"
      >
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="page-content-search-tab"
        >
          Content Search
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.Embargoed ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          disabled={disableEmbargo}
          data-testid="page-embargoed-tab"
        >
          Embargoed
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.PublishHistory ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
          data-testid="page-publish-history-tab"
        >
          Publish History
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.ContentSearch && (
        <Wrapper
          id="tabpanel-id0"
          role="tabpanel"
          aria-labelledby="tab-id0"
          padding={{ top: 'sm' }}
          data-testid="page-multi-tab-section-content-search-tab-content"
        >
          <PageSearchSection showFilters={{ status: false }} />
        </Wrapper>
      )}
      {tabIndex === Tabs.Embargoed && (
        <Wrapper
          id="tabpanel-id1"
          role="tabpanel"
          aria-labelledby="tab-id1"
          padding={{ top: 'sm' }}
          data-testid="page-multi-tab-section-embargoed-tab-content"
        >
          <Typography
            variant="header3"
            margin={{ bottom: 'none' }}
            data-testid="page-multi-tab-section-embargoed-tab-title"
          >
            Embargoed
          </Typography>
          <PageContentSearch
            embargo
            resultListClassName={pageEditStyles.searchResultScrollableEmbargo}
            data-testid="page-multi-tab-section-embargoed-tab-list"
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.PublishHistory && (
        <Wrapper id="tabpanel-id2" role="tabpanel" aria-labelledby="tab-id2" padding={{ top: 'sm' }}>
          <PublishHistory<PageDto>
            liveVersion={liveVersion}
            publishUtc={publishUtcParam}
            hasRecentlyPublished={hasRecentlyPublished || hasRecentlyScheduled}
            originalVersionWhenInHistoryEditMode={originalPageWhenInHistoryEditMode}
            currentlyEditedVersion={originalPageWhenInHistoryEditMode ? pageDTO : undefined}
            handleShowHistoryEditMode={handleShowHistoryEditMode}
            scrollableSectionClassName={pageEditStyles.historyScrollable}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};
