/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ChangeEventHandler, useEffect } from 'react';
import { Select } from '@screentone/core';

interface SelectFormProps {
  value: string;
  onChange: (newValue: string) => void;
  ruleConfig: any;
  disabled: boolean;
}

export const SelectForm = ({ value, onChange, ruleConfig, disabled }: SelectFormProps) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    onChange(ruleConfig.formComponentProps.options[0].value as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Select data-testid="select-form-option" value={value} onChange={handleChange} disabled={disabled}>
        {ruleConfig.formComponentProps.options.map((option: { label: string; value: string }) => (
          <option data-testid="select-form-option-label" key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </>
  );
};
