import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { Issues } from 'features/issues/Issues';

const IssuesPage = () => (
  <PageRootContainer>
    <NarrowPageWrapper>
      <Issues />
    </NarrowPageWrapper>
  </PageRootContainer>
);

export default IssuesPage;
