import { ReactElement } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Wrapper } from '@screentone/core';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import { ResponsiveLoader } from 'components';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { AllessehContent, AllessehContentQueryResponse } from 'hooks/useAllessehContentQuery';
import { DROPPABLE_IDS } from 'utils/collectionDragUtils';
import styles from './SearchContentList.module.scss';
import collectionEditStyles from '../../../../features/collection-edit/CollectionEdit.module.scss';

interface ContentsListProps {
  contentItems: AllessehContent[];
  className?: string;
  renderContentCard: (content: AllessehContent, index: number) => ReactElement;
  nextPageProps?: {
    fetchNextPage: (
      options?: FetchNextPageOptions | undefined
    ) => Promise<InfiniteQueryObserverResult<AllessehContentQueryResponse>>;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
  isLoading: boolean;
  customMessage?: string;
}

const SearchContentList = ({
  contentItems,
  className,
  nextPageProps,
  isLoading,
  customMessage,
  renderContentCard
}: ContentsListProps) => (
  <div
    data-testid="search-content-list-container"
    className={`${className ? `${className} ` : ''}${collectionEditStyles.curateScrollable}`}
  >
    <Wrapper data-testid="search-content-list-container-list" margin={{ top: 'sm' }}>
      {isLoading && <ResponsiveLoader />}
      {!isLoading &&
        (!contentItems[0] || contentItems[0]?.response?.error ? (
          <NoSearchResults
            data-testid="search-content-list-no-results"
            title="Content"
            bodyText={customMessage ?? 'No matching content found'}
          />
        ) : (
          <>
            <Droppable droppableId={DROPPABLE_IDS.RESULTS} isDropDisabled>
              {(droppableProvided) => (
                <div
                  data-testid="search-content-list-content"
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  className={styles.itemsList}
                >
                  {contentItems.map((content, index: number) => (
                    <div data-testid="search-content-list-item" key={content.data.id}>
                      {renderContentCard(content, index)}
                    </div>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
            {nextPageProps?.fetchNextPage && <InfiniteScrollWaypoint nextPageProps={nextPageProps} />}
          </>
        ))}
    </Wrapper>
  </div>
);

export default SearchContentList;
