import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Group,
  IconArrowLeft,
  IconCopy,
  IconEdit,
  IconEye,
  IconLink,
  Loader,
  Token,
  Tooltip,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { PageDto } from 'data/generated/graphql';
import { usePagePreview } from 'features/section-page-edit/hooks/usePagePreview';
import { useAuraQuery } from 'hooks/useAuraQuery';
import styles from './HistoryItem.module.scss';

interface HistoryItemProps<T extends PageDto> {
  selectedVersion: T;
  isLive: boolean;
  currentlyEditedVersion?: T;
  handleShowHistoryEditMode: (historyItem: PageDto) => void;
  scrollableSectionClassName?: string;
}

export const HistoryItem = <T extends PageDto>({
  selectedVersion,
  isLive,
  currentlyEditedVersion,
  handleShowHistoryEditMode,
  scrollableSectionClassName
}: HistoryItemProps<T>) => {
  const { setNewRoot } = useDataModelContext();
  const { handlePreviewPage, isLoading: isPreviewPageLoading } = usePagePreview();
  const { renderEntity } = useDataModelContext();
  const { data: revisorUserData, isLoading: isFetchingUserData } = useAuraQuery(
    selectedVersion.metadata.revisorUser.djUserId
  );
  const revisorUserText = revisorUserData
    ? `${revisorUserData.first_name ?? ''} ${revisorUserData.last_name ?? ''}`
    : selectedVersion.metadata.revisorUser.djUserId;
  const { publishUtc } = selectedVersion.metadata;

  const now = new Date().getTime();
  const headerText = isLive ? 'Published' : 'Scheduled';
  const publishDateText = publishUtc ? format(publishUtc, 'MMM d, yyyy h:mm aaa z') : '';
  const [justCopiedToClipboard, setJustCopiedToClipboard] = useState(false);
  const [versionURL, setVersionURL] = useState('');

  useEffect(() => {
    if (justCopiedToClipboard) {
      setTimeout(() => setJustCopiedToClipboard(false), 1000);
    }
  }, [justCopiedToClipboard]);

  const handleCopyToNew = () => {
    setNewRoot(selectedVersion.root);
  };

  const copyVersionURLToClipboard = async () => {
    const {
      location: { origin, pathname }
    } = window;
    // Take the path of the current URL up to the revision group key or slug.
    const path = pathname.slice(1).split('/').slice(0, 3).join('/');

    const url = `${origin}/${path}/version/${publishUtc!}`;
    await navigator.clipboard.writeText(url);
    setVersionURL(url);
    setJustCopiedToClipboard(true);
  };

  const handleShowEditMode = () => handleShowHistoryEditMode(selectedVersion);

  const isEditingVersion = currentlyEditedVersion && !isLive;

  const historySection = renderEntity(selectedVersion.root, { hierarchyId: '', isHistory: true });

  return (
    <Box>
      <Wrapper padding={{ all: 'md' }}>
        <Wrapper className={styles.publishState}>
          <Typography variant="header3" margin={{ right: 'sm' }} data-testid="page-published-title">
            {headerText}
          </Typography>
          {isLive && (
            <Token color="emerald" margin={{ right: 'md' }} data-testid="page-published-live-label">
              Live
            </Token>
          )}
          <Typography variant="note" data-testid="page-published-date-label">
            {publishDateText}
          </Typography>
        </Wrapper>
        <Wrapper className={styles.actions}>
          <Button
            tertiary
            margin={{ right: 'md' }}
            icon={IconEye as SvgComponent}
            onClick={() => handlePreviewPage(selectedVersion)}
            disabled={isPreviewPageLoading || isEditingVersion}
            data-testid="page-published-preview-button"
          >
            Preview
          </Button>

          <Tooltip>
            {justCopiedToClipboard && (
              <Tooltip.Content data-testid="page-copy-clipboard-alert">Copied to clipboard</Tooltip.Content>
            )}
            <Tooltip.Trigger>
              <Button
                tertiary
                disabled={isEditingVersion}
                margin={{ right: 'md' }}
                icon={IconLink as SvgComponent}
                onClick={copyVersionURLToClipboard}
                data-testid="page-published-url-button"
                data-version-url={versionURL}
              >
                URL
              </Button>
            </Tooltip.Trigger>
          </Tooltip>

          <Tooltip>
            <Tooltip.Trigger>
              <Button
                tertiary
                disabled={isEditingVersion}
                onClick={handleCopyToNew}
                margin={{ right: 'sm' }}
                icon={IconCopy as SvgComponent}
                data-testid="page-published-copy-new-button"
              >
                Copy to new
              </Button>
            </Tooltip.Trigger>
          </Tooltip>

          {/* only show if we're not in edit mode and if the page will publish >= 1 minute from now */}
          {!isEditingVersion &&
            selectedVersion.metadata.publishUtc &&
            selectedVersion.metadata.publishUtc > now + 60 * 1000 && (
              <Button
                tertiary
                onClick={handleShowEditMode}
                icon={IconEdit as SvgComponent}
                data-testid="page-scheduled-edit-button"
              >
                Edit
              </Button>
            )}
        </Wrapper>
      </Wrapper>
      <Divider />
      <Wrapper
        className={classnames(scrollableSectionClassName, isEditingVersion ? styles.historyScrollable : '')}
        data-testid="page-published-content"
      >
        {historySection}
        {isEditingVersion && (
          <Group
            align="center"
            valign="center"
            gap="sm"
            fullWidth
            className={styles.editNotice}
            data-testid="page-editing-status-label"
          >
            <IconArrowLeft />
            <Typography>In edit</Typography>
          </Group>
        )}
      </Wrapper>
      <Divider />
      <Wrapper padding={{ all: 'md' }}>
        <Typography variant="note" className={styles.lastEditedByText} data-testid="page-last-editor-label">
          Last edited by: {isFetchingUserData ? <Loader size="md" /> : revisorUserText}
        </Typography>
      </Wrapper>
    </Box>
  );
};
