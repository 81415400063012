import { Button, IconEye, IconRefresh, Typography, Wrapper } from '@screentone/core';
import { format } from 'date-fns-tz';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { ModuleContainer, SectionContainer } from 'data/generated/graphql';
import { usePagePreview } from 'features/section-page-edit/hooks/usePagePreview';
import styles from './PageDraftSection.module.scss';
import { AddModuleButton } from '../add-module-button/AddModuleButton';

export const PageDraftSection = () => {
  const { root, metadata, renderEntity, dedupe, insertEntity, removeAllByType } =
    useDataModelContext<SectionContainer>();
  const { originalPageWhenInHistoryEditMode } = usePagePublish();
  const { currentLayout, addableUiModules } = usePageTypeSettingsContext();
  const { handlePreviewPage, isLoading: isPreviewPageLoading } = usePagePreview();

  const handleResetToDefault = () => {
    removeAllByType('Module');

    currentLayout?.requiredLayoutModules.forEach((module, i) => {
      const newModule: ModuleContainer = {
        type: 'Module',
        attributes: { pageModule: module },
        collection: []
      };

      insertEntity(`0-0-${i}`, newModule);
    });
  };

  const draftSection = renderEntity(root, { hierarchyId: '' });

  return (
    <>
      <Wrapper margin={{ bottom: 'sm' }} className={styles.topHeaderWrapper}>
        <Wrapper className={styles.topLeftHeader} data-testid="page-draft-title">
          <Typography variant="header3" margin={{ bottom: 'none' }}>
            {originalPageWhenInHistoryEditMode
              ? `Editing: ${format(metadata.publishUtc!, 'MMM d, yyyy h:mm aaa z')}`
              : 'New Page'}
          </Typography>
        </Wrapper>
        <Wrapper>
          <Button
            tertiary
            className={styles.dedupeButton}
            icon={IconRefresh as SvgComponent}
            onClick={dedupe}
            data-testid="page-dedupe-button"
          >
            Dedupe
          </Button>
          <Button
            tertiary
            icon={IconEye as SvgComponent}
            onClick={() => handlePreviewPage()}
            disabled={isPreviewPageLoading}
            data-testid="page-preview-button"
          >
            Preview
          </Button>
        </Wrapper>
      </Wrapper>
      <Wrapper className={styles.draftContainer}>{draftSection}</Wrapper>
      <div className={styles.buttonsSection}>
        <AddModuleButton addableUiModules={addableUiModules} nextModuleIndex={root.collection[0].collection.length} />
        <Button
          tertiary
          icon={IconRefresh as SvgComponent}
          color="lava"
          onClick={handleResetToDefault}
          data-testid="page-reset-default-button"
        >
          Reset to default
        </Button>
      </div>
    </>
  );
};
