import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Typography } from '@screentone/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { Article } from 'components/datamodel/content/Article/Article';
import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import { ShowFilterOptions } from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { SearchContent, SearchParams } from 'components/search-content/SearchContent';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { useDragAndDrop } from 'contexts/drag-and-drop/useDragAndDrop';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes, AllessehQuerySortOrderType } from 'hooks/useAllessehContentQuery';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import { determineDateDisplay, formatInTimeZone } from 'utils/dates';
import { NEWSLETTER_FILTERS } from 'utils/newsletters';

export const PageContentSearch = ({
  resultListClassName,
  embargo,
  showFilters,
  newsletterName
}: {
  resultListClassName?: string;
  embargo?: boolean;
  showFilters?: ShowFilterOptions;
  newsletterName?: string;
}) => {
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { getAllArticleIds, fromAllessehContent } = useDataModelContext();
  const { renderActions: renderMenuActions } = useContextMenuActions();
  const { generateDraggableId } = useDragAndDrop();
  const currentProperty = useConvertedProperty();

  const excludedArticleIds = getAllArticleIds();

  // using useMemo here to prevent linting errors
  let additionalFilters = useMemo(() => ({}), []);

  if (newsletterName) {
    additionalFilters = NEWSLETTER_FILTERS[currentProperty as string][newsletterName];
  }

  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: excludedArticleIds,
      embargo,
      ...additionalFilters
    }),
    [publicationSettingsResp?.publicationSetting.defaultContentTypes, excludedArticleIds, embargo, additionalFilters]
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: embargo ? AllessehQuerySortKeyTypes.EmbargoDate : AllessehQuerySortKeyTypes.LiveDate,
    sortOrder: embargo ? AllessehQuerySortOrderType.ASC : AllessehQuerySortOrderType.DESC
  });

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (
      newSearchParams.filters.contentTypes.length > 0 &&
      publicationSettingsResp?.publicationSetting.defaultContentTypes && // ensure contentTypes list has loaded and has at least 1 item
      !isEqual(newSearchParams.filters.excludedContentIds, excludedArticleIds)
    ) {
      newSearchParams.filters.excludedContentIds = excludedArticleIds;
      setSearchParams(newSearchParams);
    }
  }, [excludedArticleIds, publicationSettingsResp?.publicationSetting.defaultContentTypes, searchParams]);

  const handleSearchParamsChange = (changedParams: SearchParams) => {
    setSearchParams(changedParams);
  };

  let embargoDate: string;
  let embargoTime: string;
  let formattedDate: string;
  let formattedTime: string;
  let showDate: boolean;
  let showTime: boolean;

  return (
    <div>
      <SearchContent
        beforeContent={<PageDraftTrash />}
        embargo={embargo}
        searchProps={{
          searchParams,
          defaultFilterState,
          onSearchParamsChange: handleSearchParamsChange,
          resultListClassName,
          showFilters,
          renderContentCard: (content: AllessehContent, index: number) => (
            <Draggable draggableId={generateDraggableId(DRAGGABLE_PREFIXES.MODULE_ITEM, { content })} index={index}>
              {(draggableProvided) => {
                const articleItem = fromAllessehContent(content);
                if (embargo) {
                  const dateObject = new Date(content.data.attributes.embargo_datetime_utc as string);
                  formattedDate = formatInTimeZone(
                    dateObject,
                    publicationSettingsResp?.publicationSetting.preferredTimezone,
                    'LLL d, yyyy'
                  );
                  formattedTime = formatInTimeZone(
                    dateObject,
                    publicationSettingsResp?.publicationSetting.preferredTimezone,
                    'h:mm aaaa',
                    { showTimezoneAbbreviation: true }
                  );
                  ({ showTime, showDate, embargoTime, embargoDate } = determineDateDisplay(
                    embargoDate,
                    formattedDate,
                    embargoTime,
                    formattedTime
                  ));
                }
                return (
                  <>
                    {embargo && (index < 1 || showDate) && (
                      <Typography margin={{ top: 'sm', bottom: 'md' }} data-testid="page-content-search-date-label">
                        {formattedDate}
                      </Typography>
                    )}
                    {embargo && (index < 1 || showTime) && (
                      <Typography
                        variant="label3"
                        margin={{ bottom: 'sm' }}
                        data-testid="page-content-search-time-label"
                      >
                        {formattedTime}
                      </Typography>
                    )}
                    <div
                      data-testid="page-content-search-draggable-items"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <Article
                        data={articleItem}
                        renderActions={() => renderMenuActions('articleSearch', { entity: articleItem })}
                      />
                    </div>
                  </>
                );
              }}
            </Draggable>
          )
        }}
      />
    </div>
  );
};
