import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useHeaderData } from '@screentone/addon-auth-wrapper';

import LinkItemCard from 'components/link-item/LinkItemCard';
import LinkItemForm from 'components/link-item/LinkItemForm';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import {
  Collection,
  PageModuleItem,
  PageModuleItemCollection,
  PageModuleItemQuery,
  TreatmentTypeSetting
} from 'data/generated/graphql';
import { useHighlight } from 'features/issues/hooks/useNewHighlight';
import { PageContentCard } from 'features/page-edit/components/page-content-card/PageContentCard';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import { AllessehCollectionModuleItem } from './components/allesseh-collection-module-item/AllessehCollectionModuleItem';
import { CollectionModuleItem } from './components/collection-module-item/CollectionModuleItem';
import { QueryModuleItem } from './components/query-module-item/QueryModuleItem';
import { getDuplicateClassName, ItemType } from './PageModuleItemCardUtils';

interface PageModuleItemCardInterface {
  draggableModuleItemPrefix: string;
  disableAltSumm?: boolean;
  getNumTotalItemsBeforeIndex: (index: number) => number;
  index: number;
  moduleItem: PageModuleItem;
  onChangeQueryItem: ((index: number, newQueryItem: PageModuleItemQuery) => void) | null;
  onNumItemsLoaded: (index: number, numItems: number) => void;
  onRemoveModuleItem: ((index: number, newModuleItem?: PageModuleItem) => void) | null;
  onDetachFromCollection: ((index: number, collection: Collection) => void) | null;
  treatmentTypeSetting?: TreatmentTypeSetting;
  moduleHierarchyId: string;
  handleCollectionQueryChange: (index: number, collectionItem: PageModuleItemCollection) => void;
  canHighlight?: boolean;
  highlightExtra?: boolean;
  isPageHistorySection?: boolean;
  isITP?: boolean;
}

export const PageModuleItemCard = ({
  draggableModuleItemPrefix,
  getNumTotalItemsBeforeIndex,
  index,
  disableAltSumm,
  moduleItem,
  onChangeQueryItem,
  onDetachFromCollection,
  onNumItemsLoaded,
  onRemoveModuleItem,
  treatmentTypeSetting,
  moduleHierarchyId,
  handleCollectionQueryChange,
  canHighlight,
  highlightExtra,
  isPageHistorySection,
  isITP
}: PageModuleItemCardInterface) => {
  const { duplicateIds, isEditingLinkedItem } = usePagePublish();

  const { state } = useHeaderData();
  const { shouldHighlight } = useHighlight();

  const { allessehCollectionItem, collectionItem, contentItem, linkedItem, queryItem } = moduleItem.itemFields;

  if (!contentItem && !queryItem && !collectionItem && !allessehCollectionItem && !linkedItem) {
    return null;
  }
  const availableModuleItemSlots = treatmentTypeSetting?.maxRequiredContentItems
    ? treatmentTypeSetting.maxRequiredContentItems - getNumTotalItemsBeforeIndex(index)
    : treatmentTypeSetting?.minRequiredContentItems
    ? treatmentTypeSetting.minRequiredContentItems - getNumTotalItemsBeforeIndex(index)
    : null;
  const isDraft = !!onRemoveModuleItem;
  const onRemove = onRemoveModuleItem ? () => onRemoveModuleItem(index) : null;
  const getClassName = getDuplicateClassName(duplicateIds, isDraft, state.headerConfig.currentTheme ?? 'light');

  return (
    <div data-testid="page-module-item-card-container">
      <Draggable draggableId={`${draggableModuleItemPrefix}${JSON.stringify(moduleItem)}`} index={index}>
        {(draggableProvided) => (
          <div
            data-testid="page-module-item-card-content"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            {contentItem && (
              <PageContentCard
                content={safelyParseContent<AllessehContent>(contentItem.content, { data: { attributes: {} } })}
                onRemove={onRemove}
                disableAltSumm={disableAltSumm}
                dragHandleProps={draggableProvided.dragHandleProps}
                getDuplicateClassName={getClassName(ItemType.CARD)}
                moduleHierarchyId={moduleHierarchyId}
                index={index}
                isDraft={isDraft}
                onNumItemsLoaded={() => onNumItemsLoaded(index, 1)}
                isHighlighted={canHighlight && shouldHighlight(contentItem.originId)}
                isHighlightedExtra={canHighlight && highlightExtra}
              />
            )}
            {queryItem && (
              <QueryModuleItem
                queryItem={queryItem}
                onRemove={onRemove}
                onChangeQueryItem={
                  onChangeQueryItem
                    ? (newQueryItem: PageModuleItemQuery) => onChangeQueryItem(index, newQueryItem)
                    : null
                }
                availableModuleItemSlots={availableModuleItemSlots}
                onNumItemsLoaded={(numItems: number) => onNumItemsLoaded(index, numItems)}
                dragHandleProps={draggableProvided.dragHandleProps}
                moduleHierarchyId={moduleHierarchyId}
                getDuplicateClassName={getClassName(ItemType.QUERY)}
                isDraft={isDraft}
                index={index}
                treatmentType={treatmentTypeSetting?.treatmentType}
                contentTypes={treatmentTypeSetting?.contentTypes}
              />
            )}
            {collectionItem && (
              <CollectionModuleItem
                collectionItem={collectionItem}
                onRemove={onRemove}
                availableModuleItemSlots={availableModuleItemSlots}
                onNumItemsLoaded={(numItems: number) => onNumItemsLoaded(index, numItems)}
                dragHandleProps={draggableProvided.dragHandleProps}
                onDetachFromCollection={
                  onDetachFromCollection
                    ? () => onDetachFromCollection(index, collectionItem.collection as Collection)
                    : null
                }
                getDuplicateClassName={getClassName(ItemType.CARD)}
                isDraft={isDraft}
                moduleHierarchyId={moduleHierarchyId}
                index={index}
                handleCollectionQueryChange={handleCollectionQueryChange}
                treatmentType={treatmentTypeSetting?.treatmentType}
                isITP={isITP}
              />
            )}
            {allessehCollectionItem && (
              <AllessehCollectionModuleItem
                allessehCollectionItem={allessehCollectionItem}
                onRemove={onRemove}
                availableModuleItemSlots={availableModuleItemSlots}
                onNumItemsLoaded={(numItems: number) => onNumItemsLoaded(index, numItems)}
                dragHandleProps={draggableProvided.dragHandleProps}
                getDuplicateClassName={getClassName(ItemType.QUERY)}
                isDraft={isDraft}
                moduleHierarchyId={moduleHierarchyId}
                index={index}
              />
            )}
            {linkedItem && isEditingLinkedItem === linkedItem.url && (
              <LinkItemForm
                defaultHeadline={linkedItem.headline}
                defaultMedia={linkedItem.media}
                defaultSummary={linkedItem.summary}
                defaultUrl={linkedItem.url}
                defaultValidationBypassed={!!linkedItem.validationBypassed}
                dragHandleProps={draggableProvided.dragHandleProps}
                handleCancel={() => onRemoveModuleItem?.(index)}
                handleSubmit={(newModuleItem: PageModuleItem) => onRemoveModuleItem?.(index, newModuleItem)}
              />
            )}
            {linkedItem && isEditingLinkedItem !== linkedItem.url && (
              <LinkItemCard
                canEditLinkedItem={!isPageHistorySection}
                dragHandleProps={draggableProvided.dragHandleProps}
                headline={linkedItem.headline}
                media={linkedItem.media}
                summary={linkedItem.summary}
                url={linkedItem.url}
                onRemove={() => onRemoveModuleItem?.(index)}
                getDuplicateClassName={getClassName(ItemType.CARD)}
                onNumItemsLoaded={() => onNumItemsLoaded(index, 1)}
                isHighlighted={canHighlight && shouldHighlight(linkedItem.url)}
              />
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};
