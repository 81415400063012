import { useEffect, useState } from 'react';
import { Typography, Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { PagesInput, useInfinitePagesDtoQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { PagesList, PagesSearchBar } from './components';

const PER_PAGE = 20;

export const Pages = () => {
  const currentProperty = useConvertedProperty();
  const [pagesInput, setPagesInput] = useState<PagesInput>({
    publicationKey: ''
  });

  const {
    data,
    isLoading: isLoad,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    fetchStatus
  } = useInfinitePagesDtoQuery(
    {
      pagesInput,
      paginationParams: { first: PER_PAGE, after: null }
    },
    {
      enabled: !!pagesInput.publicationKey && pagesInput.publicationKey.length > 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.pagesDTO.pageInfo.hasNextPage) return false;
        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.pagesDTO.pageInfo.endCursor
          }
        };
      }
    }
  );
  const isLoading = isLoad && fetchStatus !== 'idle';

  useEffect(() => {
    if (!currentProperty) {
      return;
    }

    setPagesInput((pagesInput) => ({ ...pagesInput, publicationKey: currentProperty }));
  }, [currentProperty]);

  const handlePartialPagesInputChange = (newSearchParams: Partial<PagesInput>) => {
    setPagesInput((pagesInput) => ({ ...pagesInput, ...newSearchParams }));
  };

  const nextPageProps = { fetchNextPage, hasNextPage, isFetchingNextPage };

  return (
    <Wrapper margin={{ top: 'lg' }}>
      <Typography variant="header2" data-testid="pages-heading">
        All Pages
      </Typography>
      <PagesSearchBar pagesInput={pagesInput} onPartialPagesInputChange={handlePartialPagesInputChange} />
      {isLoading || !data ? <ResponsiveLoader /> : <PagesList pagePages={data.pages} />}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
    </Wrapper>
  );
};
