import { useEffect, useRef, useState } from 'react';

type Dimensions = {
  clientWidth: number;
  scrollWidth: number;
  clientHeight: number;
  scrollHeight: number;
};

export const useElementDimensions = <T extends HTMLElement>() => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    clientWidth: 0,
    scrollWidth: 0,
    clientHeight: 0,
    scrollHeight: 0
  });
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    if (!elementRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      setDimensions({
        clientWidth: entries[0].target.clientWidth,
        scrollWidth: entries[0].target.scrollWidth,
        clientHeight: entries[0].target.clientHeight,
        scrollHeight: entries[0].target.scrollHeight
      });
    });

    resizeObserver.observe(elementRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return {
    elementRef,
    dimensions
  };
};
