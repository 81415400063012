import Calendar, { calendarHooks } from '@screentone/addon-calendar';
import { format } from 'date-fns';

interface DateFormProps {
  onChange: (newValue: string) => void;
  disabled: boolean;
}

export const DateForm = ({ onChange, disabled }: DateFormProps) => {
  const { selectedDate, onSelect } = calendarHooks.useDate();
  return (
    <>
      <div />
      <Calendar
        align="right"
        onSelect={(day: Date) => {
          onSelect(day);
          onChange(format(day, 'MM-dd-yyyy'));
        }}
        dateFormat="MM-dd-yyyy"
        placeholder="Select a date"
        selectedDate={selectedDate}
        disabled={disabled}
      />
    </>
  );
};
