import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Box, Input, Typography } from '@screentone/core';

import { useAllessehContentQuery } from 'hooks';
import { AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import styles from './TextForm.module.scss';
import { getAllessehQueryTermsFromQueryRules, getQueryRulesFromAllessehQueryTerms } from '../../queryRulesFormUtils';

interface TextFormProps {
  allessehKey: string;
  value: string;
  onChange: (newValue: string) => void;
  // the current state of the full Allesseh query used by the Query form
  // (includes base publication settings query and the key + value for this TextForm)
  fullAllessehQuery: AllessehContentQueryBody;
  disabled: boolean;
  placeholder?: string;
  setOpen?: (o: boolean) => void;
}

const getAllessehQueryWithoutKey = (allessehKey: string, fullAllessehQuery: AllessehContentQueryBody) => {
  const queryRules = getQueryRulesFromAllessehQueryTerms(JSON.stringify(fullAllessehQuery));
  queryRules.all = queryRules.all.filter((rule) => rule.key !== allessehKey);
  queryRules.any = queryRules.any.filter((rule) => rule.key !== allessehKey);
  queryRules.not = queryRules.not.filter((rule) => rule.key !== allessehKey);
  const allessehQuery = getAllessehQueryTermsFromQueryRules(queryRules);
  return JSON.parse(allessehQuery) as AllessehContentQueryBody;
};

export const TextForm = ({
  allessehKey,
  value,
  onChange,
  fullAllessehQuery,
  disabled,
  placeholder,
  setOpen
}: TextFormProps) => {
  const [isAutocompleteShown, setIsAutocompleteShown] = useState(false);
  const textFormRef = useRef<HTMLDivElement>(null);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChange(e.target.value);
  };

  const allessehQueryWithoutCurrentKey = getAllessehQueryWithoutKey(allessehKey, fullAllessehQuery);

  const { data } = useAllessehContentQuery(
    {
      ...allessehQueryWithoutCurrentKey,
      count: 1,
      aggregations: [allessehKey]
    },
    { enabled: isAutocompleteShown }
  );

  const handleShowAutocomplete = () => setIsAutocompleteShown(true);
  const handleClickOption = (option: string) => {
    onChange(option);
    setIsAutocompleteShown(false);
    setOpen?.(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (textFormRef.current && !textFormRef.current.contains(event.target as Node)) {
        setIsAutocompleteShown(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [textFormRef]);

  const dataOptions = data?.pages[0]?.meta?.aggregations[0].values;
  const mappedOptions = dataOptions?.map((v: { value: string }) => v.value) ?? [];
  const options = mappedOptions.filter((option: string) => option.includes(value.toLowerCase()));

  return (
    <div
      data-testid="text-form-container"
      ref={textFormRef}
      className={styles.textForm}
      data-container={placeholder ? 'custom' : 'default'}
    >
      <Input
        data-testid="text-form-input"
        placeholder={placeholder ?? 'Enter value or comma separated values'}
        onChange={handleChange}
        value={value}
        onClick={handleShowAutocomplete}
        disabled={disabled}
      />
      {isAutocompleteShown && options.length > 0 && (
        <Box data-testid="text-form-list" className={styles.autocompleteBox}>
          {options.map((option: string) => (
            <Box
              data-testid="text-form-option"
              key={option}
              padding={{ all: 'sm' }}
              className={styles.option}
              onClick={() => handleClickOption(option)}
            >
              <Typography data-testid="text-form-option-label">{option}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};
