import { useMemo } from 'react';
import { Button, IconExternalLink, Wrapper } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { buildNewsgridUrl } from 'utils/cms';

interface OpenInNewsGridProps {
  content: AllessehContent;
}

export const OpenInNewsGrid = ({ content }: OpenInNewsGridProps) => {
  const newsgridUrl = useMemo(() => buildNewsgridUrl(content.data.id), [content.data.id]);

  const handleViewNewsgridUrl = () => {
    if (!newsgridUrl) return;
    window.open(newsgridUrl, '_blank');
  };
  return newsgridUrl ? (
    <Wrapper>
      <Button
        data-testid="newsgrid-button-open-newsgrid-button"
        tertiary
        icon={IconExternalLink as SvgComponent}
        iconPosition="right"
        onClick={handleViewNewsgridUrl}
      >
        Open in Newsgrid
      </Button>
    </Wrapper>
  ) : null;
};
