import { Button, IconPlusCircle, Wrapper } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { LinkedItem, ModuleContainer } from 'data/generated/graphql';

interface AddLinkedItemProps {
  hierarchyId: string;
  module: ModuleContainer;
}

export const AddLinkedItem = ({ hierarchyId, module }: AddLinkedItemProps) => {
  const { insertEntity, isEditingLinkedItem } = useDataModelContext();

  const handleAddLinkedItem = () => {
    const DEFAULT_LINKED_ITEM_URL = '';
    const linkedItem: LinkedItem = {
      type: 'LinkedItem',
      attributes: {
        id: '',
        hosted_url: DEFAULT_LINKED_ITEM_URL,
        product: '',
        title: '',
        description: '',
        image: ''
      }
    };
    insertEntity(`${hierarchyId}-${module.collection.length}`, linkedItem);
  };

  return (
    <Wrapper>
      <Button
        disabled={isEditingLinkedItem !== ''}
        icon={IconPlusCircle as SvgComponent}
        onClick={handleAddLinkedItem}
        tertiary
      >
        Add linked item
      </Button>
    </Wrapper>
  );
};
