/* eslint-disable no-use-before-define */
/*
 * This file includes helper functions that translates
 * an Allesseh JSON query string <=> QueryRules format
 * in order to more easily manage the UI display.
 */

import {
  AllessehContentQueryBody,
  AllessehQueryRule,
  AllessehQuerySortKeyTypes,
  AllessehQuerySortOrderType,
  AllessehQuerySortRule
} from 'hooks/useAllessehContentQuery';

export enum QueryRuleArrayNameType {
  ALL = 'all',
  ANY = 'any',
  NOT = 'not'
}

export enum QueryRuleOperatorType {
  EQUAL = '=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  GREATER_THAN_EQUAL = '≥',
  LESS_THAN_EQUAL = '≤',
  NOT_EQUAL = '≠'
}

export interface QueryRule {
  key: string;
  operator: QueryRuleOperatorType;
  value: string;
}

export interface QueryRules {
  all: QueryRule[];
  any: QueryRule[];
  not: QueryRule[];
  sort: AllessehQuerySortRule[];
}

export const DEFAULT_QUERY_RULES: QueryRules = {
  all: [],
  any: [],
  not: [],
  sort: []
};

export const DEFAULT_QUERY_RULE = { key: 'SectionType', value: '', operator: QueryRuleOperatorType.EQUAL };
export const DEFAULT_SORT_RULE: AllessehQuerySortRule = {
  key: AllessehQuerySortKeyTypes.LiveDate,
  order: AllessehQuerySortOrderType.DESC
};

export const getQueryRulesFromAllessehQueryTerms = (jsonQuery?: string | null): QueryRules => {
  const all: QueryRule[] = [];
  const any: QueryRule[] = [];
  const not: QueryRule[] = [];

  const parsedJson = jsonQuery ? (JSON.parse(jsonQuery) as AllessehContentQueryBody) : {};
  if (parsedJson.query) {
    if (parsedJson.query.and) {
      parsedJson.query.and.forEach((andRule: AllessehQueryRule) => {
        const queryRule = getQueryRuleFromAllessehQueryTerm(andRule, QueryRuleOperatorType.EQUAL);
        if (queryRule) {
          all.push(queryRule);
        }
      });
    }
    if (parsedJson.query.or) {
      parsedJson.query.or.forEach((orRule: AllessehQueryRule) => {
        const queryRule = getQueryRuleFromAllessehQueryTerm(orRule, QueryRuleOperatorType.EQUAL);
        if (queryRule) {
          any.push(queryRule);
        }
      });
    }
    if (parsedJson.query.not) {
      parsedJson.query.not.forEach((notRule: AllessehQueryRule) => {
        const queryRule = getQueryRuleFromAllessehQueryTerm(notRule, QueryRuleOperatorType.NOT_EQUAL);
        if (queryRule) {
          not.push(queryRule);
        }
      });
    }
  }

  return { all, any, not, sort: parsedJson.sort ?? [] };
};

export const getAllessehQueryTermsFromQueryRules = (queryRules: QueryRules) => {
  const and: AllessehQueryRule[] = [];
  const or: AllessehQueryRule[] = [];
  const not: AllessehQueryRule[] = [];

  queryRules.all.forEach((allRule) => {
    const allessehTerm = getAllessehQueryTermsFromQueryRule(allRule);
    if (allessehTerm) {
      and.push(allessehTerm);
    }
  });

  queryRules.any.forEach((anyRule) => {
    const allessehTerm = getAllessehQueryTermsFromQueryRule(anyRule);
    if (allessehTerm) {
      or.push(allessehTerm);
    }
  });

  queryRules.not.forEach((anyRule) => {
    const allessehTerm = getAllessehQueryTermsFromQueryRule(anyRule);
    if (allessehTerm) {
      not.push(allessehTerm);
    }
  });

  return JSON.stringify({
    query: { and, or, not },
    sort: queryRules.sort
  });
};

const getQueryRuleFromAllessehQueryTerm = (
  queryRule: AllessehQueryRule,
  defaultOperator: QueryRuleOperatorType
): QueryRule | null => {
  // TODO: support query, numeric_term, prefix, ticker, code_with_significance
  if ('date' in queryRule) {
    return {
      key: queryRule.date.key,
      value: queryRule.date.value,
      operator: getDateQueryRuleOperatorTypeFromAllessehOperand(queryRule.date.operand)
    };
  }
  if ('group' in queryRule) {
    return {
      key: 'Group',
      value: queryRule.group.name,
      operator: defaultOperator
    };
  }
  if ('term' in queryRule) {
    return {
      key: queryRule.term.key,
      value: queryRule.term.value,
      operator: defaultOperator
    };
  }
  if ('terms' in queryRule) {
    return {
      key: queryRule.terms.key,
      value: queryRule.terms.value.join(','),
      operator: defaultOperator
    };
  }
  console.warn('Could not convert query term for ', queryRule);
  return null;
};

const getDateQueryRuleOperatorTypeFromAllessehOperand = (operand: string) => {
  switch (operand) {
    case 'Greater':
      return QueryRuleOperatorType.GREATER_THAN;
    case 'Less':
      return QueryRuleOperatorType.LESS_THAN;
    case 'GreaterEquals':
      return QueryRuleOperatorType.GREATER_THAN_EQUAL;
    case 'LessEquals':
      return QueryRuleOperatorType.LESS_THAN_EQUAL;
    case 'Equals':
    default:
      return QueryRuleOperatorType.EQUAL;
  }
};

const getAllessehOperandFromDateQueryRuleOperatorType = (operand: string) => {
  switch (operand) {
    case QueryRuleOperatorType.GREATER_THAN:
      return 'Greater';
    case QueryRuleOperatorType.LESS_THAN:
      return 'Less';
    case QueryRuleOperatorType.GREATER_THAN_EQUAL:
      return 'GreaterEquals';
    case QueryRuleOperatorType.LESS_THAN_EQUAL:
      return 'LessEquals';
    case QueryRuleOperatorType.EQUAL:
    default:
      return 'Equals';
  }
};

const getAllessehQueryTermsFromQueryRule = (rule: QueryRule): AllessehQueryRule | null => {
  if (
    [
      'AFPNewsCode',
      'Author',
      'AuthorId',
      'AvailabilityFlags',
      'BarronsDisplayBrand',
      'ContentStatus',
      'ContentType',
      'ColumnName',
      'DJNCode',
      'DocType',
      'DoNotArchive',
      'DraftStatus',
      'Editor',
      'FCode',
      'IndustryCode',
      'IndustryCodeName',
      'IsEvergreen',
      'Keyword',
      'LanguageCode',
      'Page',
      'PersonCodeName',
      'PrintHeadline',
      'PrintPublicationDate',
      'Product',
      'Publisher',
      'RegionCode',
      'RelayCode',
      'RootId',
      'SectionName',
      'SectionType',
      'SectionsExtra',
      'ShowId',
      'SubsectionsExtra',
      'SubjectCode',
      'SubjectCodeName',
      'Template',
      'TopicCode',
      'UpstreamOrigin',
      'UpstreamOriginId',
      'WsjSection',
      'WsjSubsection',
      'WsjdnPackage'
    ].includes(rule.key)
  ) {
    return rule.value.length > 0 && rule.value.includes(',')
      ? {
          terms: {
            key: rule.key,
            value: rule.value.split(',')
          }
        }
      : {
          term: {
            key: rule.key,
            value: rule.value
          }
        };
  }
  if (
    [
      'LiveDate',
      'LiveDateUtc',
      'PrintPublicationDate',
      'PublishedDate',
      'PublishedDateUtc',
      'UpdatedDate',
      'UpdatedDateUtc'
    ].includes(rule.key)
  ) {
    return {
      date: {
        key: rule.key,
        value: rule.value,
        operand: getAllessehOperandFromDateQueryRuleOperatorType(rule.operator)
      }
    };
  }
  if (rule.key === 'Group') {
    return {
      group: {
        name: rule.value
      }
    };
  }

  console.warn('Could not convert rule for ', rule);
  return null;
};
