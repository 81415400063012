import { useMemo, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Dropdown,
  Group,
  IconError,
  IconEye,
  IconTrash,
  List,
  Loader,
  Token,
  Typography,
  Wrapper
} from '@screentone/core';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';

import { ConfirmationDialog } from 'components';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { useIssue } from 'contexts/issue/useIssue';
import { ItpIssueStatus } from 'data/generated/graphql';
import { useIsAdmin } from 'hooks/roles';
import { ITP_STATUS_COLOR } from 'utils/constants';
import { itpIssueDateToJSDate } from 'utils/dates';
import env from 'utils/env';
import { getItpCurrentStatus } from 'utils/itp';

import styles from 'features/page-edit/components/page-title-header/PageTitleHeader.module.scss';

export const PageTitleHeader = () => {
  const {
    issue,
    saveIssue,
    deleteIssue,
    publishIssue,
    isSaveLoading,
    isDeleteLoading,
    isPublishLoading,
    isPreviewLoading,
    previewIds,
    handlePreviewPage,
    hasITPPageChanged,
    setPreviewIds,
    itpAltSummUpdated
  } = useIssue();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const { issueDate, status, publishUtc } = issue ?? {};
  const isPublished = !!publishUtc;
  const isAdmin = useIsAdmin();

  let reformattedIssueDate = '';

  if (issueDate) {
    const issueDateAsDateObj = itpIssueDateToJSDate(issueDate);
    reformattedIssueDate = format(issueDateAsDateObj, 'MMMM dd, yyyy');
  }

  const isFirstRender = useRef(true);

  const currentIssueStatus = useMemo(() => getItpCurrentStatus(status, publishUtc), [status, publishUtc]);

  const handlePublishIssue = async () => {
    setShowPublishModal(false);
    await publishIssue();
  };

  const handleDeleteIssue = async () => {
    await deleteIssue();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.titleHeader}>
        <div className={styles.row}>
          <Typography variant="header2" margin={{ bottom: 'none' }} data-testid="itp-issue-date-label">
            {reformattedIssueDate}
          </Typography>
          <Token color={ITP_STATUS_COLOR[currentIssueStatus]}>{capitalize(currentIssueStatus)}</Token>
        </div>
        <div className={styles.row}>
          <ExternalLink
            contentId={`${issue?.issueId}_`}
            isPublished={isPublished}
            type={ExternalLinkContentType.COLLECTION}
          />
          {status === ItpIssueStatus.Changed && (
            <Alert type="warning" icon={IconError as SvgComponent} inline>
              Unpublished {itpAltSummUpdated && 'Alt Summ'} changes
            </Alert>
          )}
          <Dropdown
            trigger={
              <Group gap="sm">
                <IconEye />
                Preview
              </Group>
            }
            position="right"
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('Clicked on preview!');
              if (isFirstRender.current) {
                handlePreviewPage();
                isFirstRender.current = false;
              } else {
                setPreviewIds([]);
                handlePreviewPage();
              }
            }}
          >
            {previewIds.length ? (
              isAdmin ? (
                <>
                  <Typography variant="header4" margin={{ left: 'sm' }}>
                    Preview collection IDs:
                  </Typography>
                  {previewIds.map((previewId) => (
                    <List.Item key={previewId} padding={{ all: 'sm' }}>
                      <Typography variant="link" href={`${env.ALLESSEH_PREVIEW_URL}${previewId}`} target="_blank">
                        {previewId}
                      </Typography>
                    </List.Item>
                  ))}
                </>
              ) : (
                <Typography style={{ width: '150px' }}>Please check the Proofing App to see the preview.</Typography>
              )
            ) : isPreviewLoading ? (
              <Loader size="md" />
            ) : (
              <Typography>No preview available</Typography>
            )}
          </Dropdown>
          {!isPublished && (
            <Button
              tertiary
              icon={IconTrash as SvgComponent}
              color="lava"
              onClick={() => setShowDeleteModal(true)}
              data-testid="itp-delete-button"
            >
              Delete
            </Button>
          )}
          <Button
            disabled={!issue || isSaveLoading || isPublishLoading || !hasITPPageChanged}
            onClick={saveIssue}
            secondary
          >
            {isSaveLoading ? <Loader size="md" /> : 'Save Draft'}
          </Button>
          <Button
            disabled={!issue || isPublishLoading || isSaveLoading}
            onClick={() => setShowPublishModal(true)}
            primary
            data-testid="itp-publish-button"
          >
            {isPublishLoading ? <Loader size="md" /> : 'Publish'}
          </Button>
        </div>
      </Wrapper>
      {showDeleteModal && (
        <ConfirmationDialog
          title="Delete issue"
          description="Are you sure you want to delete this issue?"
          showDialog={setShowDeleteModal}
          onClickFunc={handleDeleteIssue}
          isLoading={isDeleteLoading}
        />
      )}
      {showPublishModal && (
        <ConfirmationDialog
          title="Publish issue"
          description="Are you sure you're ready to publish to the platform?"
          showDialog={setShowPublishModal}
          onClickFunc={handlePublishIssue}
          isLoading={isPublishLoading}
        />
      )}
    </>
  );
};
