/**
 * Used to keep track of articles/items to highlight in an ITP issue
 * Creates a React context that providest the list of IDs
 * Has no logic beyond "add/remove/clear list of article IDs to highlight"
 */
import { createContext, useCallback, useContext, useState } from 'react';

export type HighlightState = string[];

type noop = () => void;
type noopHighlight = (id: string) => void;

interface HighlightContextType {
  highlightState: string[]; // list of IDs of articles to highlight
  removeItem: noopHighlight; // remove single item by id
  addItem: noopHighlight; // add single item by id
  clearItems: noop; // remove all items
  addItems: (ids: string[]) => string[];
  shouldHighlight: (id: string) => boolean; // whether this id should be highlighted
}

const DEFAULT_HIGHLIGHT_STATE: HighlightState = [];

const DEFAULT_HIGHLIGHT_CONTEXT: HighlightContextType = {
  highlightState: DEFAULT_HIGHLIGHT_STATE,
  addItem: () => [],
  addItems: () => [],
  removeItem: () => [],
  clearItems: () => [],
  shouldHighlight: () => false
};

export const HighlightContext = createContext<HighlightContextType>(DEFAULT_HIGHLIGHT_CONTEXT);

export const HighlightProvider: FCC = ({ children }) => {
  const [highlightState, setHighlightState] = useState(new Set<string>());

  const handleRemoveItem = useCallback(
    (id: string) => {
      setHighlightState((prevHighlightState) => {
        const newState = new Set(prevHighlightState);
        newState.delete(id);
        return newState;
      });
    },
    [setHighlightState]
  );

  const handleAddItem = useCallback(
    (id: string) => {
      setHighlightState((prevHighlightState) => {
        const newState = new Set(prevHighlightState);
        newState.add(id);
        return newState;
      });
    },
    [setHighlightState]
  );

  const handleAddItems = useCallback(
    (ids: string[]) => {
      setHighlightState((prevHighlightState) => {
        const newState = new Set([...Array.from(prevHighlightState), ...ids]);
        return newState;
      });
    },
    [setHighlightState]
  );

  const handleShouldHightlight = useCallback((id: string) => highlightState.has(id), [highlightState]);

  const handleClearItems = useCallback(() => {
    setHighlightState(new Set<string>());
  }, [setHighlightState]);

  const value = {
    highlightState: Array.from(highlightState), // Convert Set to Array when providing value
    removeItem: handleRemoveItem,
    addItem: handleAddItem,
    clearItems: handleClearItems,
    addItems: handleAddItems,
    shouldHighlight: handleShouldHightlight
  };

  return <HighlightContext.Provider value={value as HighlightContextType}>{children}</HighlightContext.Provider>;
};

export const useHighlight = (): HighlightContextType => {
  const context = useContext(HighlightContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (context === undefined) {
    return {
      highlightState: [],
      removeItem: () => null,
      addItem: () => null,
      addItems: () => [],
      clearItems: () => null,
      shouldHighlight: () => false
    };
  }
  return context;
};
