type ITPPageObject = {
  label: string;
  order: number;
};

interface DisplayKeyObject {
  [key: string]: ITPPageObject;
}

export const DISPLAY_KEY: DisplayKeyObject = Object.freeze({
  FRONT_SECTION: {
    label: 'Page One',
    order: 1
  },
  US: {
    label: 'U.S. News',
    order: 2
  },
  WORLD: {
    label: 'World',
    order: 3
  },
  LIFE_AND_ARTS: {
    label: 'Life & Arts',
    order: 4
  },
  OPINION: {
    label: 'Opinion',
    order: 5
  },
  BUSINESS_AND_FINANCE: {
    label: 'Business & Finance',
    order: 6
  },
  REVIEW: {
    label: 'Review',
    order: 7
  },
  OFF_DUTY: {
    label: 'Off Duty',
    order: 8
  },
  JOURNAL_REPORT: {
    label: 'Journal Report',
    order: 9
  },
  SPECIAL_SECTION: {
    label: 'Special Section',
    order: 10
  },
  MANSION: {
    label: 'Mansion',
    order: 11
  },
  WSJ_MAGAZINE: {
    label: 'WSJ. Magazine',
    order: 12
  }
});
