import { Typography, Wrapper } from '@screentone/core';

import styles from './RuleBox.module.scss';

interface RuleBoxProps {
  title: string;
}

const RuleBox: FCC<RuleBoxProps> = ({ title, children }) => (
  <Wrapper data-testid="rule-box-container" className={styles.wrapper}>
    <Typography data-testid="rule-box-title" color="asphalt">
      {title}
    </Typography>
    <Wrapper data-testid="rule-box-query-rules" className={styles.dashedBox} padding={{ all: 'md' }}>
      {children}
    </Wrapper>
  </Wrapper>
);

export default RuleBox;
