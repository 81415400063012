import { useState } from 'react';

import { usePublicationSettings } from './publication-settings';

export const useAltSummModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: publicationSettingsResp } = usePublicationSettings();
  const publicationSettings = publicationSettingsResp?.publicationSetting;

  return { isModalOpen, setIsModalOpen, publicationSettings };
};
