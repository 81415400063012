import cloneDeep from 'lodash/cloneDeep';

import {
  CollectionContentItem,
  CollectionContentItemInput,
  PageModule,
  PageModuleInput,
  PageModuleItem,
  PageModuleItemContent,
  PageModuleItemContentInput,
  PageModuleItemInput,
  UiModuleType
} from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';

export const prepContent = (content: AllessehContent, originContentType: string, originId: string) => {
  const {
    attributes: { product, seo_id: seoId, canonical_url: canonicalUrl, source_url: sourceUrl, content_status: status }
  } = content.data;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const normalizedSeoId = seoId ?? '';

  return {
    product,
    seo_id: originContentType === 'commerceproduct' ? '' : normalizedSeoId,
    hosted_url: canonicalUrl || sourceUrl,
    status,
    base_doc_id: originId,
    originContentType,
    originId,
    id: originId,
    link: originContentType === 'commerceproduct' ? '' : `api/articles/v1/originid/${originId}`
  };
};

export const prepContentItem = (
  contentItem: PageModuleItemContent | CollectionContentItem
): PageModuleItemContentInput | CollectionContentItemInput => {
  const clonedContentItem = cloneDeep(contentItem);
  const { originContentType, originId, content = '{}' } = clonedContentItem;
  const typeSafeContent = typeof content === 'string' ? content : JSON.stringify(content);
  const parsedContent = JSON.parse(typeSafeContent) as unknown as AllessehContent;
  return prepContent(parsedContent, originContentType, originId);
};

export const prepModuleItems = (moduleItems: PageModuleItem[]): PageModuleItemInput[] =>
  moduleItems.map((moduleItem) => {
    const newModuleItem = cloneDeep(moduleItem);
    if (newModuleItem.itemFields.collectionItem) {
      delete newModuleItem.itemFields.collectionItem.collection;
    } else if (newModuleItem.itemFields.contentItem) {
      newModuleItem.itemFields.contentItem = prepContentItem(newModuleItem.itemFields.contentItem);
    }

    return newModuleItem;
  });

const prepModules = ({ moduleItems, uiModuleFields, uiModuleType }: PageModule): PageModuleInput => {
  if (uiModuleType === UiModuleType.UiTwoColumnModuleType && uiModuleFields.twoColumnModule) {
    return {
      moduleItems: prepModuleItems(moduleItems),
      uiModuleType,
      uiModuleFields: {
        ...uiModuleFields,
        twoColumnModule: {
          ...uiModuleFields.twoColumnModule,
          leftModules: uiModuleFields.twoColumnModule.leftModules.map(prepModules),
          rightModules: uiModuleFields.twoColumnModule.rightModules.map(prepModules)
        }
      }
    };
  }
  return {
    moduleItems: prepModuleItems(moduleItems),
    uiModuleFields,
    uiModuleType
  };
};

export const prepPageModulesForPublish = (pageModules: PageModule[]): PageModuleInput[] => pageModules.map(prepModules);
