import { useState } from 'react';
import { Box, Button, Divider, FormHelperText, IconCode, Input, Overlay, Typography, Wrapper } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { PageModule, PageModuleItem, PageModuleItemType } from 'data/generated/graphql';
import { useAllessehCollectionQuery } from 'hooks/useAllessehCollectionQuery';
import styles from './AddAllessehCollection.module.scss';

interface AddAllessehCollectionProps {
  pageModule: PageModule;
  onPageModuleChange: (newPageModule: PageModule) => void;
  setIsDropdownOpen: (boolean: boolean) => void;
}

export const AddAllessehCollection = ({
  pageModule,
  onPageModuleChange,
  setIsDropdownOpen
}: AddAllessehCollectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allessehCollectionId, setAllessehCollectionId] = useState<string | null>(null);
  const [allessehError, setAllessehError] = useState<string>('');
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setAllessehCollectionId(null);
  };
  const handleDismissModal = () => {
    setIsModalOpen(false);
    setAllessehError('');
  };

  const { data } = useAllessehCollectionQuery(allessehCollectionId ?? '', {
    enabled: !!allessehCollectionId
  });

  const handleAdd = () => {
    const newPageModule = clonedeep(pageModule);
    if (data?.response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setAllessehError(data.response.message);
    } else {
      newPageModule.moduleItems.push({
        itemType: PageModuleItemType.AllessehCollection,
        itemFields: {
          allessehCollectionItem: {
            allessehCollectionId
          }
        }
      } as PageModuleItem);
      onPageModuleChange(newPageModule);
      handleDismissModal();
      setIsDropdownOpen(false);
      setAllessehError('');
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAllessehCollectionId(e.target.value);
    if (allessehError) setAllessehError('');
  };

  return (
    <>
      <Button tertiary icon={IconCode as SvgComponent} onClick={handleOpenModal}>
        Add collection ID
      </Button>

      <Overlay onDismiss={handleDismissModal} status={isModalOpen ? 'open' : 'closed'} className={styles.overlay}>
        <Box>
          <Box.Title>ADD ALLESSEH COLLECTION</Box.Title>
          <Box.Content padding={{ all: 'none' }}>
            <Wrapper padding={{ all: 'md' }}>
              <Input
                type="text"
                placeholder="Enter Allesseh collection ID"
                value={allessehCollectionId ?? ''}
                onChange={handleInputChange}
                margin={{ right: 'sm' }}
                error={!!allessehError}
                data-testid="allesseh-collection-id-input"
              />
              <Typography variant="note" margin={{ top: 'sm' }}>
                {/* TODO: Potentially eventually migrate collection data so that we can update
                  search to search Allesseh collections and use Allesseh as the source of truth
                  for collections. This feature could be used to import any Allesseh collectionID,
                  but this was not the intended purpose here. */}
                Use this only to import collections from Allesseh powered by Cxense.
              </Typography>
              {!!allessehError && <FormHelperText error>{allessehError}</FormHelperText>}
            </Wrapper>
            <Divider />
            <Wrapper padding={{ all: 'md' }} className={styles.bottomBar}>
              <Button secondary onClick={handleDismissModal} data-testid="cancel-allesseh-id">
                Cancel
              </Button>
              <Button primary margin={{ left: 'sm' }} onClick={handleAdd} data-testid="add-allesseh-id">
                Add
              </Button>
            </Wrapper>
          </Box.Content>
        </Box>
      </Overlay>
    </>
  );
};
