/* eslint-disable import/no-cycle */
import { useEffect, useMemo } from 'react';
import { useHeaderData } from '@screentone/addon-auth-wrapper';
import { Box, Dropdown, Group, IconDisabled, IconThreeDotsVer, Token, Typography } from '@screentone/core';
import classNames from 'classnames';

import ContentTypeIcon from 'components/content-type-icon/ContentTypeIcon';
import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { DuplicateItemType } from 'contexts/content-id-tracking/ContentIdTrackingContext';
import { useContentIdTracking } from 'contexts/content-id-tracking/useContentIdTracking';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ArticleItem, PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useImage } from 'hooks/useImage';
import { safelyParseContent } from 'utils/temp';
import { extractSizeFromUrl, resizeIMImage } from 'utils/url';
import styles from './Article.module.scss';
import { CommonContentProps } from '../commonContentProps';

const IM_WIDTH = {
  THUMBNAIL: 40,
  ENLARGED: 180
};

const getType = (content: AllessehContent) => {
  if (content.data.attributes.product.includes('Live Coverage'))
    return PublicationSettingSearchableContentType.LiveCoverage.toLowerCase();
  if (content.data.attributes.section_name === 'Opinion')
    return PublicationSettingSearchableContentType.Opinion.toLowerCase();
  switch (content.data.attributes.product) {
    case 'Interactive Media':
      return PublicationSettingSearchableContentType.Tappable.toLowerCase();
    default:
      return content.data.type;
  }
};

export interface ArticleProps extends CommonEntityProps, CommonContentProps {
  data: ArticleItem;
  noBorder?: boolean;
  boxPadding?: 'xs' | 'sm' | 'smd' | 'md' | 'mlg' | 'lg' | 'xl';
  renderActions?: () => React.ReactNode;
  parentQueryHierarchyId?: string;
}

export const Article = ({
  data: article,
  onContentLoaded,
  isDraft,
  noBorder = false,
  boxPadding = 'sm',
  renderActions,
  parentQueryHierarchyId,
  parentHierarchyId,
  index,
  // eslint-disable-next-line unused-imports/no-unused-vars
  isHistory
}: ArticleProps) => {
  const { state } = useHeaderData();
  const { generateHierarchyId } = useDataModelContext();
  const { trackContentIds, ignoreContentIds, getDuplicateClassName } = useContentIdTracking();
  const { trackContent, getAltSummFields } = useSummarianContext();
  const hierarchyId = generateHierarchyId(article, parentHierarchyId, index);
  const ignoreHierarchyIds = parentQueryHierarchyId ? [parentQueryHierarchyId] : [];

  const duplicateClassName = isDraft
    ? getDuplicateClassName(
        state.headerConfig.currentTheme ?? 'light',
        DuplicateItemType.CARD,
        article.attributes.id,
        ignoreHierarchyIds
      )
    : '';

  const allessehContent = useMemo(() => safelyParseContent<AllessehContent>(article.content), [article.content]);
  const actionButtons = renderActions?.();

  const { headline, image } = getAltSummFields(allessehContent);

  useEffect(() => {
    trackContent(allessehContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  useEffect(() => {
    if (isDraft) {
      trackContentIds(hierarchyId, article.attributes.id);

      return () => {
        ignoreContentIds(hierarchyId, article.attributes.id);
      };
    }

    return () => {};
  }, [article.attributes.id, hierarchyId, ignoreContentIds, isDraft, trackContentIds]);

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(index!, [allessehContent]);
    }
  }, [allessehContent, index, onContentLoaded]);

  const { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage } = useImage();
  const imageUrlThumbnail = useMemo(() => resizeIMImage(image, { width: IM_WIDTH.THUMBNAIL, size: 1 }), [image]) ?? '';
  const imageUrlEnlarged = useMemo(() => resizeIMImage(image, { width: IM_WIDTH.ENLARGED }), [image]) ?? '';
  const enlargedHeight = IM_WIDTH.ENLARGED / extractSizeFromUrl(imageUrlEnlarged);

  return (
    <Box
      padding={{ all: boxPadding }}
      className={classNames(noBorder ? styles.noBorder : '', duplicateClassName)}
      data-allesseh-id={allessehContent.data.id}
      data-testid="page-content-card-container"
      data-model-hierarchy-id={hierarchyId}
    >
      <Group gap="none">
        <div
          onMouseEnter={debouncedHandleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.imageContainer}
          data-testid="content-card-image-container"
        >
          {showLargerImage && imageUrlThumbnail && (
            <div
              className={classNames(styles.imageEnlarged, isDraft ? styles.left : '')}
              style={{
                backgroundImage: `url('${imageUrlEnlarged}')`,
                height: enlargedHeight
              }}
              data-testid="larger-image"
            />
          )}
          <div
            style={{ backgroundImage: `url('${imageUrlThumbnail}')` }}
            className={classNames(styles.image, image ? '' : styles.noImage)}
            data-testid="thumbnail"
          />
        </div>

        <Typography
          variant="bodytext"
          margin={{ left: 'sm' }}
          className={styles.headline}
          data-testid="content-card-headline-label"
        >
          <ContentTypeIcon
            type={getType(allessehContent)}
            size="mlg"
            margin={{ right: 'sm' }}
            className={styles.icon}
          />
          {headline}
        </Typography>
        {allessehContent.data.attributes.content_status === 'embargo' && (
          <Token icon={IconDisabled as SvgComponent} margin={{ left: 'sm' }} color="lava" />
        )}
        {actionButtons && (
          <Dropdown
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
            data-testid="page-content-ellipsis-icon"
          >
            {actionButtons}
          </Dropdown>
        )}
      </Group>
    </Box>
  );
};
