import { createContext, useCallback, useState } from 'react';

import duplicateContentStyles from './DuplicateContent.module.scss';

export enum DuplicateItemType {
  CARD = 'card',
  QUERY = 'query'
}

interface ContentIdTrackingContext {
  trackContentIds: (source: string, contentId: string | string[]) => void;
  ignoreContentIds: (source: string, contentId: string | string[]) => void;
  isDuplicateId: (contentId: string, ignoreHierarchyIds?: string[]) => boolean;
  getDuplicateClassName: (
    modeDisplayed: string,
    itemType: DuplicateItemType,
    contentId: string,
    ignoreHierarchyIds?: string[]
  ) => string;
}

const DEFAULT_STATE: ContentIdTrackingContext = {
  trackContentIds: () => {},
  ignoreContentIds: () => {},
  isDuplicateId: () => false,
  getDuplicateClassName: () => ''
};

interface ContentIdTrackingProviderProps {
  children: React.ReactNode;
}

export const ContentIdTrackingContext = createContext(DEFAULT_STATE);

export const ContentIdTrackingProvider = ({ children }: ContentIdTrackingProviderProps) => {
  const [contentIdSources, setContentIdSources] = useState<Map<string, string[]>>(new Map());

  const trackContentIds = useCallback((source: string, contentId: string | string[]) => {
    if (Array.isArray(contentId)) {
      setContentIdSources((prev) => {
        const newMap = new Map(prev);

        contentId.forEach((id) => {
          const sources = newMap.get(id) ?? [];
          if (sources.includes(source)) {
            return;
          }

          newMap.set(id, sources.concat(source));
        });
        return newMap;
      });
      return;
    }

    setContentIdSources((prev) => {
      const newMap = new Map(prev);

      const sources = newMap.get(contentId) ?? [];
      if (sources.includes(source)) {
        return newMap;
      }

      newMap.set(contentId, sources.concat(source));
      return newMap;
    });
  }, []);

  const ignoreContentIds = useCallback((source: string, contentId: string | string[]) => {
    if (Array.isArray(contentId)) {
      contentId.forEach((id) => {
        setContentIdSources((prev) => {
          const newMap = new Map(prev);

          const sources = newMap.get(id);
          if (sources) {
            const sourceIdx = sources.indexOf(source);
            if (sourceIdx > -1) {
              sources.splice(sourceIdx, 1);
            }
          }

          return newMap;
        });
      });
      return;
    }

    setContentIdSources((prev) => {
      const newMap = new Map(prev);

      const sources = newMap.get(contentId);
      if (sources) {
        const sourceIdx = sources.indexOf(source);
        if (sourceIdx > -1) {
          sources.splice(sourceIdx, 1);
        }
      }

      return newMap;
    });
  }, []);

  const isDuplicateId = useCallback(
    (contentId: string, ignoreHierarchyIds: string[] = []) => {
      if (contentIdSources.has(contentId)) {
        let sources = contentIdSources.get(contentId)!;

        if (ignoreHierarchyIds.length > 0) {
          sources = sources.filter((source) => !ignoreHierarchyIds.includes(source));
        }

        return sources.length > 1;
      }

      return false;
    },
    [contentIdSources]
  );

  const getDuplicateClassName = (
    modeDisplayed: string,
    itemType: DuplicateItemType,
    contentId: string,
    ignoreHierarchyIds: string[] = []
  ) => {
    const theme =
      (modeDisplayed === 'auto' &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: light)').matches) ||
      modeDisplayed === 'light'
        ? 'Light'
        : 'Dark';

    const className =
      itemType === DuplicateItemType.CARD
        ? duplicateContentStyles[`duplicateItemCard${theme}`]
        : duplicateContentStyles.duplicateItemQuery;

    return isDuplicateId(contentId, ignoreHierarchyIds) ? className : '';
  };

  const value = { trackContentIds, ignoreContentIds, isDuplicateId, getDuplicateClassName };
  return <ContentIdTrackingContext.Provider value={value}>{children}</ContentIdTrackingContext.Provider>;
};
