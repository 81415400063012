import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Group,
  IconArrowLeft,
  IconArrowRight,
  IconCopy,
  IconEdit,
  Token,
  Typography,
  Wrapper
} from '@screentone/core';
import { isFuture } from 'date-fns';
import { format } from 'date-fns-tz';

import { ResponsiveLoader } from 'components';
import { RevisionSelector } from 'components/revision-selector/RevisionSelector';
import {
  Collection,
  CollectionContentItem,
  ScheduledCollectionsQuery,
  useCollectionsPublishedOnDateQuery,
  useInfiniteScheduledCollectionsQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { DRAGGABLE_PREFIXES, DROPPABLE_IDS } from 'utils/collectionDragUtils';
import styles from './OffPlatformCollectionPublishedRevision.module.scss';
import { CuratedList } from '../off-platform-collection-items-section/curated-list/CuratedList';

interface OffPlatformCollectionPublishedRevisionProps {
  collection: Collection;
  currentlyEditedCollection: Collection | null;
  editingScheduledRevision: boolean;
  hasCollectionRecentlyUpdated: boolean;
  onCopyToNew: (contentItems: CollectionContentItem[]) => void;
  onEdit: (historyCollection: Collection) => void;
}

const PER_PAGE = 5;

export const OffPlatformCollectionPublishedRevision = ({
  collection,
  currentlyEditedCollection,
  editingScheduledRevision,
  hasCollectionRecentlyUpdated,
  onCopyToNew,
  onEdit
}: OffPlatformCollectionPublishedRevisionProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [history, setHistory] = useState<Collection[]>([collection]);
  const [selectedDate, setSelectedDate] = useState(Date.now());
  const [isUserCheckingSpecificDate, setIsUserCheckingSpecificDate] = useState(false);
  const currentProperty = useConvertedProperty();

  const {
    data: scheduledCollections,
    isLoading,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
    refetch
  } = useInfiniteScheduledCollectionsQuery(
    {
      allessehCollectionId: collection.allessehCollectionId,
      publicationKey: currentProperty ?? '',
      paginationParams: {
        first: PER_PAGE,
        before: null
      }
    },
    {
      enabled: !isUserCheckingSpecificDate,
      getNextPageParam: (firstPage) => {
        if (!firstPage.scheduledCollections.pageInfo.hasNextPage) return false;
        return {
          paginationParams: {
            first: PER_PAGE,
            before: firstPage.scheduledCollections.pageInfo.endCursor
          }
        };
      }
    }
  );

  const {
    data: collectionHistoryForSelectedDate,
    isLoading: isLoadingCollectionHistory,
    fetchStatus: collectionHistoryFetchStatus
  } = useCollectionsPublishedOnDateQuery(
    {
      allessehCollectionId: collection.allessehCollectionId,
      publishUtc: selectedDate,
      paginationParams: { first: 50 },
      publicationKey: currentProperty ?? ''
    },
    { enabled: !!selectedDate }
  );

  const handleClickPrev = () => {
    setCurrentIndex((curr) => curr - 1);
  };

  const handleClickNext = async () => {
    setCurrentIndex((curr) => curr + 1);
    const hasReachedEndOfPage = currentIndex === history.length - 2;
    if (history.length > 0 && hasNextPage && hasReachedEndOfPage && !isUserCheckingSpecificDate) {
      await fetchNextPage();
    }
  };

  const handleReset = () => {
    setIsUserCheckingSpecificDate(false);
    setCurrentIndex(0);
    setSelectedDate(Date.now());
  };

  const handleSelectDate = (dateTimestamp: number) => {
    setSelectedDate(dateTimestamp);
  };

  const handleSelectRevision = useCallback(
    (publishUtc?: number) => {
      if (!collectionHistoryForSelectedDate) return;
      const collectionRevisions = collectionHistoryForSelectedDate.collectionsPublishedOnDate.edges.map(
        (pageEdge) => pageEdge.node as Collection
      );
      if (!collectionRevisions.length) return;
      setHistory(collectionRevisions);
      setCurrentIndex(collectionRevisions.findIndex((collection) => collection.publishUtc === publishUtc));
      setIsUserCheckingSpecificDate(true);
    },
    [collectionHistoryForSelectedDate]
  );

  useEffect(() => {
    async function reset() {
      if (hasCollectionRecentlyUpdated) {
        handleReset();
        await refetch();
      }
    }
    void reset();
  }, [hasCollectionRecentlyUpdated, refetch]);

  useLayoutEffect(() => {
    if (!scheduledCollections || isUserCheckingSpecificDate) return;
    const collectionHistory: Collection[] = scheduledCollections.pages.reduce(
      (acc: Collection[], curr: ScheduledCollectionsQuery) => {
        const collections = curr.scheduledCollections.edges.map((collection) => collection.node);
        return acc.concat(collections as Collection[]);
      },
      []
    );
    setHistory([collection, ...collectionHistory]);
  }, [collection, isUserCheckingSpecificDate, scheduledCollections]);

  useLayoutEffect(() => {
    // If editing a module and changing tabs, select the module currently being edited once returning to the Publish History tab
    if (isUserCheckingSpecificDate || history.length === 0) return;
    if (editingScheduledRevision && currentlyEditedCollection) {
      // Select edit item
      const editItemIndex = history.findIndex((item) => item.publishUtc === currentlyEditedCollection.publishUtc);
      if (editItemIndex >= 0) {
        setCurrentIndex(editItemIndex);
        return;
      }

      // If editing revision is not found in scheduled collections history,
      // look for it in history for selected date
      const timestamp = new Date(currentlyEditedCollection.publishUtc!).setHours(0, 0, 0, 0);
      setSelectedDate(timestamp);
      handleSelectRevision(currentlyEditedCollection.publishUtc!);
    }
  }, [currentlyEditedCollection, editingScheduledRevision, handleSelectRevision, history, isUserCheckingSpecificDate]);

  const historyHasItems = useMemo(() => history.some((historyItem) => historyItem.contentItems.length), [history]);

  const isQueryLoading = (isLoading && fetchStatus !== 'idle') || history.length === 0 || currentIndex < 0;
  if (isQueryLoading) {
    return (
      <div>
        <Typography margin={{ bottom: 'md' }} variant="header3" data-testid="off-platform-publish-history-title">
          Publish History
        </Typography>
        <ResponsiveLoader />
      </div>
    );
  }

  const oneMinuteFromNow = Date.now() + 60000;
  const selectedCollection = history[currentIndex];
  const isLiveCollection =
    selectedCollection.updatedUtc === collection.updatedUtc || selectedCollection.publishUtc === collection.publishUtc;
  const hasPrev = currentIndex > 0;
  const hasNext = currentIndex < history.length - 1;
  const updatedDate = selectedCollection.updatedUtc
    ? format(selectedCollection.updatedUtc, 'MMM d, yyyy h:mm aaa z')
    : null;
  const publishDate = selectedCollection.publishUtc
    ? format(selectedCollection.publishUtc, 'MMM d, yyyy h:mm aaa z')
    : null;
  const moduleDisplayName = selectedCollection.parameters.offPlatform?.moduleDisplayName ?? 'Curated List';

  const scheduledCollectionsOnDate = collectionHistoryForSelectedDate?.collectionsPublishedOnDate.edges
    .filter((collection) => !!collection.node.publishUtc && isFuture(collection.node.publishUtc))
    .map((collection) => collection.node.publishUtc as number);

  return (
    <>
      <Typography variant="header3" margin={{ bottom: 'md' }}>
        Publish History
      </Typography>
      <Group fullWidth align="space-between" margin={{ bottom: 'sm' }}>
        <Button tertiary onClick={handleClickPrev} disabled={!hasPrev || editingScheduledRevision}>
          <IconArrowLeft margin={{ right: 'sm' }} />
          Previous
        </Button>
        <RevisionSelector
          selectedDate={selectedDate}
          onSelectDate={handleSelectDate}
          onSelectRevision={handleSelectRevision}
          onReset={handleReset}
          dateGroups={[{ title: 'Scheduled modules', type: 'scheduled', dates: scheduledCollectionsOnDate }]}
          areRevisionsLoading={isLoadingCollectionHistory && collectionHistoryFetchStatus !== 'idle'}
          disabled={editingScheduledRevision || !historyHasItems}
        />
        <Button
          tertiary
          onClick={handleClickNext}
          disabled={!hasNext || editingScheduledRevision}
          data-testid="off-platform-next-button"
        >
          Next
          <IconArrowRight margin={{ left: 'sm' }} />
        </Button>
      </Group>
      {selectedCollection.contentItems.length === 0 ? (
        <Group align="center">
          <Typography variant="bodytext" color="asphalt">
            Nothing published yet
          </Typography>
        </Group>
      ) : (
        <Box>
          <Wrapper padding={{ top: 'md', right: 'mlg', bottom: 'md', left: 'mlg' }}>
            <Group gap="sm" margin={{ bottom: 'sm' }}>
              <Typography
                variant="header3"
                margin={{ right: 'sm' }}
                data-testid="off-platform-scheduled-published-label"
              >
                {isLiveCollection ? 'Published' : 'Scheduled'}
              </Typography>
              {isLiveCollection && (
                <Token color="emerald" margin={{ right: 'md' }}>
                  Live
                </Token>
              )}
              {isLiveCollection ? (
                <Typography variant="note">Since: {updatedDate}</Typography>
              ) : (
                <Typography variant="note" data-testid="off-platform-date-published-label">
                  {' '}
                  {publishDate}
                </Typography>
              )}
            </Group>
            <Wrapper>
              <Button
                tertiary
                onClick={() => onCopyToNew(selectedCollection.contentItems)}
                margin={{ right: 'sm' }}
                icon={IconCopy as SvgComponent}
                disabled={editingScheduledRevision}
                data-testid="off-platform-copy-button"
              >
                Copy to new
              </Button>
              {/* only show if we're not in edit mode and if the page will publish >= 1 minute from now */}
              {!editingScheduledRevision &&
                selectedCollection.publishUtc &&
                selectedCollection.publishUtc > oneMinuteFromNow && (
                  <Button
                    tertiary
                    onClick={() => onEdit(selectedCollection)}
                    icon={IconEdit as SvgComponent}
                    data-testid="off-platform-edit-scheduled"
                  >
                    Edit
                  </Button>
                )}
            </Wrapper>
          </Wrapper>
          <Divider />
          <Wrapper padding={{ all: 'mlg' }} className={styles.curatedListWrapper}>
            <Typography
              weight="bold"
              size="sm"
              margin={{ bottom: 'sm' }}
              data-testid="off-platform-publish-history-display-name"
            >
              {moduleDisplayName}
            </Typography>
            <CuratedList
              readOnly
              collection={selectedCollection}
              droppableId={DROPPABLE_IDS.PUBLISHED_REVISION}
              draggablePrefix={DRAGGABLE_PREFIXES.PUBLISHED_REVISION}
            />
            {editingScheduledRevision && (
              <Group
                align="center"
                valign="center"
                gap="sm"
                fullWidth
                className={styles.editNotice}
                data-testid="off-platform-in-edit"
              >
                <IconArrowLeft />
                <Typography>In edit</Typography>
              </Group>
            )}
          </Wrapper>
        </Box>
      )}
    </>
  );
};
