import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FabButton,
  IconPlus,
  Tabs as TabsComponent,
  Typography,
  useAnimatedModalState,
  useModalPortal,
  Wrapper
} from '@screentone/core';
import { cloneDeep } from 'lodash';

import { useAlert } from 'contexts/alert/useAlert';
import { useNewsletter } from 'contexts/newsletter/useNewsletter';
import {
  AllessehContentType,
  Newsletter,
  NewslettersInput,
  PublicationSetting,
  ScheduledContentEdge,
  ScheduledContentStatus,
  useInfiniteGetScheduledContentByPublicationKeyQuery,
  useInfiniteNewslettersQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { NewsletterSearchBar } from './components/newsletter-search-bar/NewsletterSearchBar';
import { NewsletterSelectDialog } from './components/newsletter-select-dialog/NewsletterSelectDialog';
import { NewslettersList } from './components/newsletters-list/NewslettersList';

const PER_PAGE = 10;

enum Tabs {
  Sent,
  Scheduled
}

export const Newsletters = ({ publicationSetting }: { publicationSetting: PublicationSetting | undefined }) => {
  const [selectedNewsletterId, setSelectedNewsletterId] = useState<string>('');
  const { newsletterItems } = useNewsletter();
  const navigate = useNavigate();
  const currentProperty = useConvertedProperty();
  const { alertWarning } = useAlert();

  const [tabIndex, setTabIndex] = useState(Tabs.Sent);

  const [newslettersInput, setNewslettersInput] = useState<NewslettersInput>({
    publicationKey: ''
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteNewslettersQuery(
    {
      newslettersInput,
      paginationParams: { first: PER_PAGE, after: null }
    },
    {
      enabled: !!newslettersInput.publicationKey && newslettersInput.publicationKey.length > 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.newsletters.pageInfo.hasNextPage) return false;
        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.newsletters.pageInfo.endCursor
          }
        };
      }
    }
  );

  const {
    data: rawScheduledData,
    fetchNextPage: scheduledFetchNextPage,
    hasNextPage: scheduledHasNextPage,
    isFetchingNextPage: scheduledIsFetchingNextPage,
    isLoading: scheduledDataIsLoading
  } = useInfiniteGetScheduledContentByPublicationKeyQuery(
    {
      publicationKey: currentProperty ?? '',
      contentType: AllessehContentType.Newsletter,
      paginationParams: { first: PER_PAGE, after: null },
      status: ScheduledContentStatus.Scheduled
    },
    {
      enabled: !!newslettersInput.publicationKey && newslettersInput.publicationKey.length > 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.scheduledContentByPublicationKey.pageInfo.hasNextPage) return false;
        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.scheduledContentByPublicationKey.pageInfo.endCursor
          }
        };
      }
    }
  );

  const [scheduledData, setScheduledData] = useState({ ...rawScheduledData });

  // frontend filtering of scheduled campaigns by name
  useEffect(() => {
    if (newslettersInput.searchText) {
      const newScheduledData = cloneDeep(rawScheduledData);
      newScheduledData?.pages.forEach((pageData) => {
        const newEdges: ScheduledContentEdge[] = [];
        pageData.scheduledContentByPublicationKey.edges.forEach((edgeData) => {
          if (
            (edgeData.node.body as Newsletter).name
              .toLowerCase()
              .includes(newslettersInput.searchText?.toLowerCase() as string)
          ) {
            newEdges.push(edgeData as ScheduledContentEdge);
          }
        });
        // eslint-disable-next-line no-param-reassign
        pageData.scheduledContentByPublicationKey.edges = newEdges;
      });
      // eslint-disable-next-line
      // @ts-ignore
      setScheduledData(newScheduledData);
    } else {
      const newScheduledData = { ...rawScheduledData };
      setScheduledData(newScheduledData);
    }
  }, [newslettersInput.searchText, rawScheduledData, scheduledDataIsLoading]);

  useEffect(() => {
    if (!currentProperty) {
      return;
    }

    setNewslettersInput((newslettersInput) => ({ ...newslettersInput, publicationKey: currentProperty }));
  }, [currentProperty]);

  const handlePartialNewslettersInputChange = (newSearchParams: Partial<NewslettersInput>) => {
    setNewslettersInput((newslettersInput) => ({ ...newslettersInput, ...newSearchParams }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { open, status, componentRef, openModal, closeModal } = useAnimatedModalState();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();

  const handleCancelClick = () => {
    setSelectedNewsletterId('');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    closeModal();
  };

  const handleBeginCampaignClick = () => {
    if (!selectedNewsletterId) {
      alertWarning('Please select a newsletter to begin campaign', { autoDismiss: true });
      return;
    }
    navigate(`/${currentProperty}/newsletters/new?newsletter=${selectedNewsletterId}`);
  };

  const nextPageProps = { fetchNextPage, hasNextPage, isFetchingNextPage };

  const nextPagePropsScheduled = {
    fetchNextPage: scheduledFetchNextPage,
    hasNextPage: scheduledHasNextPage,
    isFetchingNextPage: scheduledIsFetchingNextPage
  };

  return (
    <Wrapper margin={{ top: 'lg' }}>
      <Typography variant="header2">Campaigns</Typography>

      <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.Sent ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
        >
          Sent
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.Scheduled ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
        >
          Scheduled
        </TabsComponent.Item>
      </TabsComponent>

      <NewsletterSearchBar
        newslettersInput={newslettersInput}
        onPartialNewslettersInputChange={handlePartialNewslettersInputChange}
      />

      {tabIndex === Tabs.Scheduled && scheduledData.pages && (
        <NewslettersList
          scheduledCampaigns={scheduledData.pages}
          publicationSetting={publicationSetting}
          nextPageProps={nextPagePropsScheduled}
        />
      )}

      {/* RENDER LIST OF PUBLISHED NEWSLETTERS */}
      {tabIndex === Tabs.Sent && data?.pages && (
        <NewslettersList
          newsletters={data.pages}
          publicationSetting={publicationSetting}
          nextPageProps={nextPageProps}
        />
      )}
      <FabButton icon={IconPlus as SVGAElement} onClick={openModal as () => void} />
      <NewsletterSelectDialog
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        open={open}
        handleBeginCampaignClick={handleBeginCampaignClick}
        handleCancelClick={handleCancelClick}
        selectedNewsletterId={selectedNewsletterId}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        componentRef={componentRef}
        newsletterItems={newsletterItems}
        setSelectedNewsletterId={setSelectedNewsletterId}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        renderNode={renderNode}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        status={status}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        closeModal={closeModal}
      />
    </Wrapper>
  );
};

export default Newsletters;
