import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { OffPlatformCollections } from 'features/off-platform/OffPlatformCollections';

const OffPlatformPage = () => (
  <>
    <PageRootContainer>
      <NarrowPageWrapper>
        <OffPlatformCollections />
      </NarrowPageWrapper>
    </PageRootContainer>
  </>
);

export default OffPlatformPage;
