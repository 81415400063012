/* eslint-disable import/no-cycle */
import React, { createContext, useCallback, useState } from 'react';
import { Divider } from '@screentone/core';

import { EntityComponent } from 'contexts/datamodel/DataModelContext';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from './contextActions.module.scss';

type ComponentFnParams = {
  hierarchyId?: string;
  entity?: EntityComponent;
  index?: number;
  isHistory?: boolean;
  extraProperties?: {
    numTotalItemsUsed?: number;
    numItemsToShow?: number;
    baseQuery?: string;
    allessehContent?: AllessehContent;
    isExternalCollection?: boolean;
    jsonQueryStr?: string;
    setJsonQueryStr?: React.Dispatch<React.SetStateAction<string>>;
  };
};

type ActionDefinition = {
  component: (params: ComponentFnParams) => JSX.Element;
  showIf?: (params: ComponentFnParams) => boolean;
  withDivider?: 'top' | 'bottom';
};

export type MenuActions = {
  draftArticle?: ActionDefinition[];
  draftExternalCollection?: ActionDefinition[];
  draftQuery?: ActionDefinition[];
  draftLinkedItem?: ActionDefinition[];
  draftModule?: ActionDefinition[];
  historyArticle?: ActionDefinition[];
  historyExternalCollection?: ActionDefinition[];
  historyQuery?: ActionDefinition[];
  historyLinkedItem?: ActionDefinition[];
  historyModule?: ActionDefinition[];
  articleSearch?: ActionDefinition[];
  articleQueryModal?: ActionDefinition[];
  collectionSearch?: ActionDefinition[];
};

interface ContextMenuActionsContext {
  renderActions: (actionType: keyof MenuActions, params: ComponentFnParams) => (JSX.Element | null)[] | null;
  setActionModalComponent: (modalComponent: JSX.Element | null) => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DEFAULT_STATE: ContextMenuActionsContext = {
  renderActions: () => [],
  setActionModalComponent: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {}
};

interface ContextMenuActionsProviderProps {
  actions: MenuActions;
  children: React.ReactNode;
}

export const ContextMenuActionsContext = createContext(DEFAULT_STATE);

export const ContextMenuActionsProvider = ({ actions, children }: ContextMenuActionsProviderProps) => {
  const [actionModal, setActionModal] = useState<JSX.Element | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderActions = (actionType: keyof MenuActions, params: ComponentFnParams) => {
    const selectedActions = actions[actionType];

    if (!selectedActions || selectedActions.length === 0) {
      return null;
    }

    // Split actions into groups based on `withDivider` property
    // Actions that have the `withDivider` property set, are displayed in their own group
    // Consecutive actions that don't have the `withDivider` property set, are displayed in the same group, wrapped in a flex container
    const splitActions = selectedActions.reduce<ActionDefinition[][]>((acc, actionDefinition, i) => {
      const hasDivider = actionDefinition.withDivider === 'top' || actionDefinition.withDivider === 'bottom';
      const previousHasDivider =
        i > 0 && (selectedActions[i - 1].withDivider === 'top' || selectedActions[i - 1].withDivider === 'bottom');

      if (hasDivider || previousHasDivider) {
        acc.push([actionDefinition]);
      } else if (Array.isArray(acc[acc.length - 1])) {
        acc[acc.length - 1].push(actionDefinition);
      } else {
        acc.push([actionDefinition]);
      }

      return acc;
    }, []);

    // Render the split actions
    return splitActions.map((group, i) => {
      if (group.length === 1) {
        const action = group[0];

        const component = action.component(params);

        if (action.showIf && !action.showIf(params)) {
          return null;
        }

        return (
          <div key={i}>
            {action.withDivider === 'top' && <Divider />}
            <div key={i} className={styles.dividerButton}>
              {component}
            </div>
            {action.withDivider === 'bottom' && <Divider />}
          </div>
        );
      }

      const willRenderAtLeastOneAction = group.some(
        (actionDefinition) => !actionDefinition.showIf || actionDefinition.showIf(params)
      );
      return (
        <div key={i} className={willRenderAtLeastOneAction ? styles.actionGroup : ''}>
          {group.map((actionDefinition, j) => {
            const component = actionDefinition.component(params);

            if (actionDefinition.showIf && !actionDefinition.showIf(params)) {
              return null;
            }

            return <div key={j}>{component}</div>;
          })}
        </div>
      );
    });
  };

  const setActionModalComponent = useCallback((modalComponent: JSX.Element | null) => {
    setActionModal(modalComponent);
  }, []);

  const value = { renderActions, setActionModalComponent, isModalOpen, setIsModalOpen };
  return (
    <ContextMenuActionsContext.Provider value={value}>
      {children}
      {isModalOpen && actionModal}
    </ContextMenuActionsContext.Provider>
  );
};
