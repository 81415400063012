import { useState } from 'react';
import { Button, Typography } from '@screentone/core';

import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { PublicationSetting } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { getNewsletterDefaultDate, getNewsletterDefaultTime, getNewsletterScheduleMinDate } from 'utils/newsletters';

export const NewsletterScheduleDialog = ({
  handleSchedule,
  newsletterName,
  reschedule = false,
  publicationSetting
}: {
  handleSchedule?: (publishUtc: number) => void;
  newsletterName?: string;
  reschedule?: boolean;
  publicationSetting?: PublicationSetting;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const currentProperty = useConvertedProperty();

  const initialTimeFromEditors = getNewsletterDefaultTime(currentProperty, newsletterName);
  const initialDate = getNewsletterDefaultDate(currentProperty, newsletterName, initialTimeFromEditors);
  const initialTime = `${
    initialDate && initialDate.getHours() < 10 ? `0${initialDate.getHours()}` : initialDate?.getHours()
  }:${initialDate && initialDate.getMinutes() < 10 ? `0${initialDate.getMinutes()}` : initialDate?.getMinutes()}`;
  const minDate = getNewsletterScheduleMinDate({ initialDate, publicationSetting });

  const afterCalendarContent = publicationSetting ? (
    <Typography variant="note">
      All times are in {publicationSetting.preferredTimezone.timezoneAbbreviation}.
    </Typography>
  ) : null;

  return (
    <>
      <Button primary onClick={() => setModalOpen(true)}>
        {reschedule ? 'Reschedule' : 'Schedule'}
      </Button>
      {modalOpen && handleSchedule && (
        <ScheduleDialog
          dialogTitle="Schedule Newsletter"
          onSchedule={handleSchedule}
          initialDate={initialDate}
          initialTime={initialTime}
          onCancel={() => setModalOpen(false)}
          afterCalendarContent={afterCalendarContent}
          minDate={minDate}
        />
      )}
    </>
  );
};
