import { ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { CollectionSearch } from 'components/collection-search/CollectionSearch';
import { FILTER_TYPES } from 'components/collection-search/components/collections-search-bar/CollectionsSearchBar';
import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { useDragAndDrop } from 'contexts/drag-and-drop/useDragAndDrop';
import { CollectionDto, CollectionDtoStatusType } from 'data/generated/graphql';

interface PageCollectionSearchProps {
  resultListClassName?: string;
}

export const PageCollectionSearch = ({ resultListClassName }: PageCollectionSearchProps) => {
  const { getAllExternalCollectionIds } = useDataModelContext();
  const { generateDraggableId } = useDragAndDrop();
  const excludedCollectionIds = getAllExternalCollectionIds();

  return (
    <CollectionSearch
      filters={[{ filterType: FILTER_TYPES.SORT, label: 'Sort' }]}
      initialCollectionsInput={{
        status: CollectionDtoStatusType.Live
      }}
      droppableId={DROPPABLE_ID_PREFIXES.COLLECTION}
      excludedCollectionIdCreatedUtcs={excludedCollectionIds}
      emptyListMessage="Try a different filter or add a new collection by going to the Collections subnav."
      resultListClassName={resultListClassName}
      beforeContent={<PageDraftTrash />}
      renderCollectionCardWrapper={(collectionCard: ReactElement, collection: CollectionDto, index: number) => (
        <Draggable draggableId={generateDraggableId(DRAGGABLE_PREFIXES.MODULE_ITEM, { collection })} index={index}>
          {(draggableProvided) => (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
            >
              {collectionCard}
            </div>
          )}
        </Draggable>
      )}
    />
  );
};
