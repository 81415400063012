import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Button, IconArticle, IconMinusCircle, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { Collection, CollectionContentItem } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from './CuratedList.module.scss';
import OffPlatformCollectionContentCard from '../../off-platform-collection-content-card/OffPlatformCollectionContentCard';

interface CuratedListProps {
  collection: Collection;
  droppableId: string;
  draggablePrefix: string;
  readOnly?: boolean;
  showBorder?: boolean;
  showItemCount?: boolean;
  onRemoveContent?: (index: number) => void;
}
export const CuratedList = ({
  collection,
  droppableId,
  draggablePrefix,
  readOnly,
  showBorder,
  showItemCount,
  onRemoveContent
}: CuratedListProps) => {
  const { contentItems } = collection;

  const contentItemsLength = contentItems.length;
  const maxItems = collection.parameters.offPlatform?.maxItems;

  return (
    <Droppable droppableId={droppableId} isDropDisabled={readOnly}>
      {(droppableProvided) => (
        <div
          data-testid="off-platform-content-list"
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          className={classnames(styles.itemsList, showBorder ? styles.showItemsListBorder : '')}
        >
          {contentItems.map((contentItem, index) => {
            const parsedContent = contentItem.content ? (JSON.parse(contentItem.content) as AllessehContent) : null;
            return (
              <Draggable
                key={contentItem.originId}
                draggableId={`${draggablePrefix}${JSON.stringify({
                  originId: contentItem.originId,
                  originContentType: contentItem.originContentType,
                  content: contentItem.content
                } as CollectionContentItem)}`}
                index={index}
              >
                {(draggableProvided, snapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    {parsedContent ? (
                      <OffPlatformCollectionContentCard
                        content={parsedContent}
                        isDragging={snapshot.isDragging}
                        isDraft={!readOnly}
                        {...(onRemoveContent && {
                          renderActions: () => (
                            <Button
                              data-testid="off-platform-remove-collection-button"
                              tertiary
                              color="lava"
                              icon={IconMinusCircle as SvgComponent}
                              onClick={() => onRemoveContent(index)}
                            >
                              Remove from Collection
                            </Button>
                          )
                        })}
                      />
                    ) : (
                      <Box padding={{ all: 'sm' }}>
                        <Typography>{contentItem.originId}</Typography>
                        <Typography variant="note">Unable to retrieve metadata from Allesseh</Typography>
                      </Box>
                    )}
                  </div>
                )}
              </Draggable>
            );
          })}
          {droppableProvided.placeholder}
          {showItemCount && contentItemsLength !== maxItems && (
            <Wrapper
              className={classnames(styles.itemsNumber, contentItemsLength > 0 ? styles.itemsNumberWithContent : '')}
              padding={{ all: 'md' }}
              data-testid="off-platform-collection-items-count"
            >
              <IconArticle color="asphalt" margin={{ right: 'xs' }} />
              <Typography variant="note">
                {contentItemsLength}/{maxItems} articles
              </Typography>
            </Wrapper>
          )}
        </div>
      )}
    </Droppable>
  );
};
