import { ReactElement, useCallback, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import {
  Button,
  Dropdown,
  Group,
  IconThreeDotsVer,
  Typography,
  useDropdownState,
  useModalPortal,
  useModalState,
  Wrapper
} from '@screentone/core';

import { useAlert } from 'contexts/alert/useAlert';
import { useIssue } from 'contexts/issue/useIssue';
import { ItpIssueWithPages, ItpPage, ItpSaveInput, useSaveItpMutation } from 'data/generated/graphql';
import { prepPageModulesForPublish } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { ChangeNameDialog } from './components/ChangeNameDialog';
import styles from './ITPPageNameChange.module.scss';

interface ITPPageNameChangeProps {
  pageType: string;
  itpPage: ItpPage;
}

export const ITPPageNameChange = ({ itpPage, pageType }: ITPPageNameChangeProps) => {
  const { currentProperty } = useAuth();
  const displayName = itpPage.label;
  const { alertSuccess, alertError } = useAlert();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isDropdownOpen,
    setOpen: setIsDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();

  const handleDropdownOpenToggle = () => setIsDropdownOpen(!isDropdownOpen);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open,
    openModal,
    closeModal
  }: {
    open: boolean;
    openModal: () => void;
    closeModal: () => void;
  } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useModalState();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { renderNode }: { renderNode: () => void } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useModalPortal();

  const { issue, handlePartialIssueChange, refreshedUtc } = useIssue();
  const currentPage = issue?.pages.find((page) => page.pageType === pageType);

  const [itpPageLabel, setItpPageLabel] = useState(currentPage?.label);

  const validateLabel = (potentialLabel: string | undefined): boolean => !!potentialLabel;

  const { mutateAsync: issueSaveAsync, isLoading: isSaveLoading } = useSaveItpMutation();

  const formatRequestBody = useCallback(
    (issue: ItpIssueWithPages, pageTypeToChange: string, newLabel: string | undefined) => {
      const unsavedUpdatesToPages = issue.pages.map((page) => ({
        adType: page.adType,
        adZones: page.adZones,
        isHidden: page.isHidden,
        issueId: page.issueId,
        label: page.pageType === pageTypeToChange ? newLabel : page.label,
        pageModules: prepPageModulesForPublish(page.pageModules),
        pageType: page.pageType
      }));

      const unsavedUpdatesToIssue: ItpSaveInput = {
        issueId: issue.issueId,
        refreshedUtc,
        label: issue.label,
        issueDate: issue.issueDate,
        pages: unsavedUpdatesToPages
      };

      return {
        itpSaveInput: unsavedUpdatesToIssue,
        publicationKey: currentProperty ?? 'wsj'
      };
    },
    [currentProperty, refreshedUtc]
  );

  const handleCancel = () => {
    setItpPageLabel(currentPage?.label);
    closeModal();
  };

  const handleSave = () => {
    if (!issue) {
      closeModal();
      return;
    }

    if (!itpPageLabel || !validateLabel(itpPageLabel)) {
      alertError('Page Label cannot be blank');
      return;
    }

    const requestBody = formatRequestBody(issue, pageType, itpPageLabel);

    issueSaveAsync(requestBody)
      .then(({ saveITP }) => {
        alertSuccess('Update successful');
        const mergedResponse = {
          ...saveITP,
          // We merge the page modules we already have in our issue with the response we get from the mutation
          pages: saveITP.pages.map((page) => ({
            ...page,
            pageModules: issue.pages.find((issuePage) => page.pageType === issuePage.pageType)!.pageModules
          }))
        };

        handlePartialIssueChange(mergedResponse);
      })
      .catch((err) => {
        console.error(err);
        alertError('Something went wrong. Name not updated');
      })
      .finally(closeModal);
  };

  return (
    <Wrapper className={styles.wrapper} margin={{ bottom: 'xs' }}>
      <Typography variant="header3" margin={{ bottom: 'none' }} className={styles.displayName}>
        {displayName}
      </Typography>
      <Dropdown
        open={isDropdownOpen}
        onToggle={handleDropdownOpenToggle}
        componentRef={dropdownRef}
        padding={{ all: 'none' }}
        position="right"
        trigger={<IconThreeDotsVer color="asphalt" />}
        margin={{ right: 'sm' }}
      >
        <Group margin={{ all: 'md' }} gap="xs">
          <Button tertiary onClick={openModal}>
            Change Display Name
          </Button>
        </Group>
      </Dropdown>
      {open && (
        <ChangeNameDialog
          setItpPageLabel={setItpPageLabel}
          itpPageLabel={itpPageLabel}
          isSaveLoading={isSaveLoading}
          handleSave={handleSave}
          handleCancel={handleCancel}
          open={open}
          closeModal={closeModal}
          renderNode={renderNode}
          validateLabel={validateLabel}
        />
      )}
    </Wrapper>
  );
};
