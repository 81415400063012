import { Button, List, Typography, Wrapper } from '@screentone/core';
import { format } from 'date-fns-tz';

interface RevisionListProps {
  selectedDate?: number | null;
  onSelectRevision: (publishUtc?: number) => void;
  title: string;
  type: string;
  dates: number[];
}

export const RevisionList = ({ selectedDate, onSelectRevision, title, type, dates }: RevisionListProps) => {
  if (!selectedDate) {
    return (
      <Wrapper margin={{ horizontal: 'md' }}>
        <Typography size="sm" margin={{ top: 'sm' }}>
          Select a date to see revisions.
        </Typography>
      </Wrapper>
    );
  }

  if (dates.length === 0) {
    return (
      <Wrapper margin={{ horizontal: 'md' }}>
        <Typography size="sm" margin={{ top: 'sm' }}>
          This date has no {type} revisions.
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper margin={{ horizontal: 'md' }}>
      <Typography size="sm" weight="bold">
        {title}
      </Typography>
      <List>
        {dates.map((date) => (
          <List.Item key={date}>
            <Button tertiary componentEl="a" onClick={() => onSelectRevision(date)}>
              {format(date, 'MMM d, yyyy h:mm aaa z')}
            </Button>
          </List.Item>
        ))}
      </List>
    </Wrapper>
  );
};
