import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { NewsletterProvider } from 'contexts/newsletter/NewsletterContext';
import { Newsletters } from 'features/newsletters/Newsletters';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';

const NewsletterPage = () => {
  const { data: publicationSettingsResp } = usePublicationSettings();
  const currentProperty = useConvertedProperty();
  if (!currentProperty || currentProperty !== 'marketwatch') return <ErrorPage type="404" />;

  return (
    <PageRootContainer>
      <NarrowPageWrapper>
        <NewsletterProvider currentProperty={currentProperty}>
          <Newsletters publicationSetting={publicationSettingsResp?.publicationSetting} />
        </NewsletterProvider>
      </NarrowPageWrapper>
    </PageRootContainer>
  );
};

export default NewsletterPage;
