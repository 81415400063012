import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth, useHeaderData } from '@screentone/addon-auth-wrapper';
import { Box, Button, Divider, Overlay, Typography, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';

import { ResponsiveLoader } from 'components';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { QueryRulesForm } from 'components/query-rules-form/QueryRulesForm';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { PageContentCard } from 'features/page-edit/components/page-content-card/PageContentCard';
import { addModuleItem, AllContentIds } from 'features/page-edit/components/page-draft-section/PageDraftSectionUtils';
import { useAllessehContentQuery } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { getExcludedIdsQuery, mergeAllessehQueryBodies, QUERY_CONTENT_ID_KEY, validateValue } from 'utils/queryUtils';
import styles from './QueryModal.module.scss';
import { getDuplicateClassName, ItemType } from '../../../../PageModuleItemCardUtils';

interface QueryModalProps {
  onDismiss: () => void;
  allessehJsonQuery: string;
  onChangeJsonQueryStr: ((newJsonQueryStr: string) => void) | null;
  excludePageContentIdsQuery: string;
  count: number | null;
  baseQuery: string;
  moduleHierarchyId: string;
  index: number;
  isDraft: boolean;
}

export const QueryModal = ({
  allessehJsonQuery,
  excludePageContentIdsQuery,
  onDismiss,
  onChangeJsonQueryStr,
  count,
  baseQuery,
  moduleHierarchyId,
  index,
  isDraft
}: QueryModalProps) => {
  const canEdit = !!onChangeJsonQueryStr;
  const [jsonQueryStr, setJsonQueryStr] = useState(allessehJsonQuery);
  const [allessehQueryBody, setAllessehQueryBody] = useState<AllessehContentQueryBody | null>(null);
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { currentProperty } = useAuth();
  const { setAllContentIds } = usePagePublish();

  const updateAllessehQueryBody = (allessehJsonQuery?: string) => {
    const fullAllessehQuery = mergeAllessehQueryBodies(baseQuery, excludePageContentIdsQuery, allessehJsonQuery);
    setAllessehQueryBody(fullAllessehQuery);
  };
  const debouncedUpdate = debounce(updateAllessehQueryBody, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDebouncedQueryBody = useCallback(debouncedUpdate, [publicationSettingsResp]);

  useEffect(() => {
    if (!allessehQueryBody) {
      updateAllessehQueryBody(jsonQueryStr);
    } else {
      updateDebouncedQueryBody(jsonQueryStr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonQueryStr]);

  const {
    data,
    isLoading: isLoad,
    fetchStatus
  } = useAllessehContentQuery(allessehQueryBody ?? {}, { enabled: !!allessehQueryBody });

  // fetch the data needed to display the headlines of excluded articles
  const excludedAllessehContentQuery = getExcludedIdsQuery(allessehQueryBody);
  const fullExcludedContentAllessehQuery = mergeAllessehQueryBodies(
    baseQuery,
    excludePageContentIdsQuery,
    JSON.stringify(excludedAllessehContentQuery)
  );
  const { data: excludedData } = useAllessehContentQuery(fullExcludedContentAllessehQuery, {
    enabled: !!fullExcludedContentAllessehQuery
  });
  const excludedArticles = excludedData?.pages[0]?.data?.attributes;

  const isLoading = isLoad && fetchStatus !== 'idle';
  const initContents = useMemo(
    () =>
      data
        ? (data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x) => !!x) as AllessehContent[])
        : [],
    [data]
  );

  const { duplicateIds, allContentIds } = usePagePublish();
  const [initialContentIds, setInitialContentIds] = useState({});

  const { state } = useHeaderData();

  const getClassName = getDuplicateClassName(duplicateIds, isDraft, state.headerConfig.currentTheme ?? 'light');

  useEffect(() => {
    setInitialContentIds(allContentIds);
    // just need to run this once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDraft) {
      setAllContentIds((prevState: AllContentIds) =>
        addModuleItem(
          prevState,
          { moduleHierarchyId, index },
          initContents.map((content) => content.data.id)
        )
      );
    }
  }, [index, initContents, isDraft, moduleHierarchyId, setAllContentIds]);

  const noResultsMessage = validateValue(
    currentProperty,
    allessehQueryBody?.query as { [key: string]: AllessehQueryRule[] }
  );

  const handleAllessehJsonQueryChange = (newAllessehJsonQuery: string) => {
    setJsonQueryStr(newAllessehJsonQuery);
  };

  const handleExclude = (content: AllessehContent) => {
    if (!jsonQueryStr) {
      return; // should not get here since there needs to be existing jsonQueryStr to move an item from it
    }
    const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
    parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
    parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
    const newTerm = {
      term: {
        key: QUERY_CONTENT_ID_KEY,
        value: content.data.id
      }
    };
    if (parsedJsonQuery.query.not.findIndex((rule: AllessehQueryRule) => isEqual(rule, newTerm)) < 0) {
      parsedJsonQuery.query.not.push(newTerm);
    }
    setJsonQueryStr(JSON.stringify(parsedJsonQuery));
  };

  const handleCancel = () => {
    onDismiss();
    if (isDraft) {
      setAllContentIds(() => initialContentIds);
    }
  };

  const handleSave = () => {
    const parsedJsonQuery = JSON.parse(jsonQueryStr) as AllessehContentQueryBody;
    onChangeJsonQueryStr?.(JSON.stringify({ ...parsedJsonQuery, count }));
  };

  const moreItemsCount = (data?.pages[0].links?.total ?? 0) - initContents.length;
  return (
    <Overlay onDismiss={onDismiss} status="open" className={styles.overlay}>
      <Box>
        <Box.Title>QUERY {canEdit ? 'BUILDER' : 'VIEWER'}</Box.Title>
        <Box.Content padding={{ all: 'none' }}>
          <Wrapper className={styles.content} padding={{ top: 'sm', right: 'md', bottom: 'md', left: 'md' }}>
            <Wrapper variant="header3" className={styles.contentLeft} padding={{ right: 'md' }}>
              <QueryRulesForm
                allessehJsonQuery={jsonQueryStr}
                excludedData={excludedArticles}
                fullAllessehJsonQueryOverride={allessehQueryBody}
                onAllessehJsonQueryChange={canEdit ? handleAllessehJsonQueryChange : null}
              />
            </Wrapper>
            <div className={styles.contentRight}>
              <Typography size="lg" margin={{ bottom: 'sm', top: 'sm' }}>
                Query Results
              </Typography>
              <Wrapper>
                {isLoading && <ResponsiveLoader />}
                {!isLoading && initContents.length === 0 && (
                  <>
                    <NoSearchResults title="Results" bodyText={noResultsMessage} />
                  </>
                )}
                {!isLoading && initContents.length > 0 && (
                  <div className={styles.contentList}>
                    {initContents.map((content, index) => (
                      <PageContentCard
                        key={index}
                        content={content}
                        onExcludeFromQuery={onChangeJsonQueryStr ? () => handleExclude(content) : null}
                        getDuplicateClassName={getClassName(ItemType.QUERY)}
                      />
                    ))}
                    {moreItemsCount > 0 && (
                      <Typography variant="note" margin={{ top: 'sm', left: 'sm' }}>
                        + {moreItemsCount} more
                      </Typography>
                    )}
                  </div>
                )}
              </Wrapper>
            </div>
          </Wrapper>
          <Divider />
          {canEdit && (
            <Wrapper data-testid="query-modal-footer" padding={{ all: 'md' }} className={styles.bottomBar}>
              <Button data-testid="query-modal-cancel-button" secondary onClick={handleCancel}>
                Cancel
              </Button>
              <Button data-testid="query-modal-save-button" primary margin={{ left: 'sm' }} onClick={handleSave}>
                Save
              </Button>
            </Wrapper>
          )}
        </Box.Content>
      </Box>
    </Overlay>
  );
};
