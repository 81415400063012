import { OnDragEndResponder, OnDragStartResponder } from 'react-beautiful-dnd';
import isEqual from 'lodash.isequal';

import { useAlert } from 'contexts/alert/useAlert';
import { useTrash } from 'contexts/trash/useTrash';
import { Collection, CollectionContentItem } from 'data/generated/graphql';
import { prepContent } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { QUERY_CONTENT_ID_KEY } from 'utils/queryUtils';
import { DRAGGABLE_PREFIXES, DROPPABLE_IDS } from '../../utils/collectionDragUtils';

interface UseManageCollectionItemsProps {
  collection: Collection | null;
  onPartialCollectionChange: (newCollection: Partial<Collection>) => void;
}

export const useManageCollectionItems = ({ collection, onPartialCollectionChange }: UseManageCollectionItemsProps) => {
  const { alertError } = useAlert();
  const { setIsDragging } = useTrash();

  // helper function to add a piece of content (sourceContent) to a copied curated list of content (newContentItems)
  // at a specific index (destIndex) if it doesn't already exist
  const addToCurated = (
    newContentItems: CollectionContentItem[],
    sourceContent: CollectionContentItem | null,
    destIndex: number
  ) => {
    if (!sourceContent || !collection) {
      return;
    }

    if (newContentItems.some((content) => content.originId === sourceContent.originId)) {
      alertError('The content was not added to the collection. It already exists in this collection.');
      return;
    }
    newContentItems.splice(destIndex, 0, sourceContent);
    onPartialCollectionChange({ contentItems: newContentItems });
  };

  // adds a specific piece of content to the curated list
  const handleAddContent = (content: AllessehContent) => {
    if (!collection) {
      return;
    }
    addToCurated(
      [...collection.contentItems],
      { ...prepContent(content, content.data.type, content.data.id), content: JSON.stringify(content) },
      collection.contentItems.length
    );
  };

  // removes a specific piece of content from the curated list based off its current index
  const handleRemoveContent = (index: number) => {
    if (!collection) {
      return;
    }

    const newContentItems = [...collection.contentItems];
    newContentItems.splice(index, 1);
    onPartialCollectionChange({ contentItems: newContentItems });
  };

  // remove all content items from the collection
  const handleClearAllContent = () => {
    if (!collection) {
      return;
    }

    onPartialCollectionChange({ contentItems: [] });
  };

  // update page context when a trashable item is being dragged
  const handleDragStart: OnDragStartResponder = (dragEvent) => {
    const regexDroppable = new RegExp(`^${DROPPABLE_IDS.MANUAL}`);
    const regexDraggable = new RegExp(`^${DRAGGABLE_PREFIXES.MANUAL}`);
    if (dragEvent.draggableId.match(regexDraggable) && dragEvent.source.droppableId.match(regexDroppable)) {
      setIsDragging(true);
    }
  };

  // handles react-beautiful-dnd's drag end function to add or reorder content to the curated list
  const handleDragEnd: OnDragEndResponder = (dragEvent) => {
    setIsDragging(false);

    if (!collection) {
      return;
    }

    const {
      destination,
      source: { droppableId: sourceDroppableId, index: sourceIndex },
      draggableId
    } = dragEvent;

    if (!destination) {
      return;
    }

    const { droppableId: destDroppableId, index: destIndex } = destination;

    // handle removing if dragged to trash and item comes from the manually-curated section
    if (destDroppableId === DROPPABLE_IDS.TRASH && sourceDroppableId === DROPPABLE_IDS.MANUAL) {
      handleRemoveContent(sourceIndex);
      return;
    }

    if (destDroppableId !== DROPPABLE_IDS.MANUAL) {
      // only allowed to drop into manually curated section
      return;
    }

    const newContentItems = [...collection.contentItems];
    const regex = new RegExp(
      `${DRAGGABLE_PREFIXES.PUBLISHED_REVISION}|${DRAGGABLE_PREFIXES.MANUAL}|${DRAGGABLE_PREFIXES.RESULTS}(\\d+-)*`
    );
    const sourceContent = JSON.parse(draggableId.replace(regex, '')) as CollectionContentItem;

    if (sourceDroppableId === DROPPABLE_IDS.MANUAL) {
      newContentItems.splice(sourceIndex, 1);
    }
    addToCurated(newContentItems, sourceContent, destIndex);
  };

  // handles moving content from the curated list to the queried list
  const handleMoveContentFromCuratedToQueried = (index: number, content: AllessehContent) => {
    // remove from curated
    handleRemoveContent(index);

    // remove exclusion from query
    if (!collection?.allessehJsonQuery) {
      return;
    }
    const parsedJsonQuery = JSON.parse(collection.allessehJsonQuery) as AllessehContentQueryBody;
    parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
    parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
    const newTerm = {
      term: {
        key: QUERY_CONTENT_ID_KEY,
        value: content.data.id
      }
    };
    parsedJsonQuery.query.not = [...parsedJsonQuery.query.not].filter((rule) => !isEqual(rule, newTerm));
    onPartialCollectionChange({ allessehJsonQuery: JSON.stringify(parsedJsonQuery) });
  };

  // handles excluding content from the query
  const handleExcludeQueryItem = (content: AllessehContent) => {
    if (!collection?.allessehJsonQuery) {
      return;
    }
    const parsedJsonQuery = JSON.parse(collection.allessehJsonQuery) as AllessehContentQueryBody;
    parsedJsonQuery.query = parsedJsonQuery.query ?? { and: [], or: [], not: [] };
    parsedJsonQuery.query.not = parsedJsonQuery.query.not ?? [];
    const newTerm = {
      term: {
        key: QUERY_CONTENT_ID_KEY,
        value: content.data.id
      }
    };
    if (parsedJsonQuery.query.not.findIndex((rule: AllessehQueryRule) => isEqual(rule, newTerm)) < 0) {
      parsedJsonQuery.query.not.push(newTerm);
    }
    onPartialCollectionChange({ allessehJsonQuery: JSON.stringify(parsedJsonQuery) });
  };

  // handles moving content from the queried list to the curated list
  const handleMoveContentFromQueriedToCurated = (content: AllessehContent) => {
    // add to curate
    handleAddContent(content);

    // add exclusion to query
    handleExcludeQueryItem(content);
  };

  return {
    // curated items
    handleAddContent,
    handleRemoveContent,
    handleDragEnd,
    handleDragStart,
    handleClearAllContent,

    // query items
    handleMoveContentFromQueriedToCurated,
    handleMoveContentFromCuratedToQueried,
    handleExcludeQueryItem
  };
};
