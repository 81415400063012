import { useEffect, useState } from 'react';
import { Button, IconArrowLeft, IconArrowRight, Illustration, Typography, Wrapper } from '@screentone/core';
import { isSameDay } from 'date-fns';

import { ResponsiveLoader } from 'components';
import { RevisionSelector } from 'components/revision-selector/RevisionSelector';
import { PageDto, useGetScheduledContentByAllessehIdQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { HistoryItem } from './HistoryItem';
import styles from './PublishHistory.module.scss';

interface PageHistorySectionProps<T extends PageDto> {
  liveVersion: T;
  publishUtc: number;
  hasRecentlyPublished: boolean;
  originalVersionWhenInHistoryEditMode?: T | null;
  currentlyEditedVersion?: T;
  handleShowHistoryEditMode: (historyItem: PageDto) => void;
  scrollableSectionClassName?: string;
}

export const PublishHistory = <T extends PageDto>({
  liveVersion,
  publishUtc,
  hasRecentlyPublished,
  originalVersionWhenInHistoryEditMode,
  currentlyEditedVersion,
  handleShowHistoryEditMode,
  scrollableSectionClassName
}: PageHistorySectionProps<T>) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [history, setHistory] = useState<T[]>([liveVersion]);
  const [historyForDate, setHistoryForDate] = useState<T[]>([]);
  const [userPaginated, setUserPaginated] = useState(false);
  const [selectedDate, setSelectedDate] = useState<number | null>(Date.now());
  const currentProperty = useConvertedProperty();

  const {
    data: scheduledContents,
    isLoading: isLoad,
    refetch,
    fetchStatus
  } = useGetScheduledContentByAllessehIdQuery({
    allessehId: liveVersion.metadata.allessehId,
    publicationKey: currentProperty ?? ''
  });

  const isLoading = isLoad && fetchStatus !== 'idle';

  const handleReset = () => {
    setUserPaginated(false);
    setHistoryForDate([]);
    setCurrentIndex(0);
    setSelectedDate(Date.now());
  };

  useEffect(() => {
    async function reset() {
      if (hasRecentlyPublished) {
        handleReset();
        await refetch();
      }
    }
    void reset();
  }, [hasRecentlyPublished, refetch]);

  useEffect(() => {
    if (!scheduledContents) return;
    const contentHistory = scheduledContents.scheduledContentByAllessehId.map((content) => content.body as T);
    setHistory([liveVersion, ...contentHistory]);
  }, [liveVersion, scheduledContents]);

  // Unless we are looking at a specific date, automatically switch to a specific page in the following order:
  // 1. the page that is currently being edited,
  // 2. the page that matches the `publishUtc` timestamp in the url params,
  // 3. the page that is currently live in history
  useEffect(() => {
    if (historyForDate.length) return;
    if (!userPaginated) {
      if (originalVersionWhenInHistoryEditMode && currentlyEditedVersion) {
        const indexOfEditingItem = history.findIndex(
          (item) => item.metadata.publishUtc === currentlyEditedVersion.metadata.publishUtc
        );
        if (indexOfEditingItem >= 0) {
          setCurrentIndex(indexOfEditingItem);
        }
      } else if (history.length > 0 && publishUtc > 0) {
        const indexOfSelectedVersion = history.findIndex((item) => item.metadata.publishUtc === publishUtc);
        if (indexOfSelectedVersion >= 0) {
          setCurrentIndex(indexOfSelectedVersion);
        }
      } else {
        setCurrentIndex(0);
      }
    }
  }, [
    currentlyEditedVersion,
    history,
    historyForDate.length,
    originalVersionWhenInHistoryEditMode,
    publishUtc,
    userPaginated
  ]);

  const handleClickPrev = () => {
    if (currentIndex === 0) return;

    setUserPaginated(true);
    setCurrentIndex((curr) => curr - 1);
  };

  const handleClickNext = () => {
    if (currentIndex === history.length - 1) return;

    setUserPaginated(true);
    setCurrentIndex((curr) => curr + 1);
  };

  const handleSelectDate = (dateNum: number) => {
    setSelectedDate(dateNum);
  };

  const handleSelectRevision = (publishUtc?: number) => {
    if (!history.length) return;
    const revisions = history.filter((item) => {
      if (!item.metadata.publishUtc) {
        console.warn('Missing `publishUtc` for item when trying to select specific revision in history', item);
        return false;
      }

      return isSameDay(item.metadata.publishUtc, publishUtc!);
    });
    setHistoryForDate(revisions);
    setCurrentIndex(revisions.findIndex((item) => item.metadata.publishUtc === publishUtc));
  };

  if (isLoading) {
    return (
      <div>
        <Typography margin={{ bottom: 'md' }} variant="header3">
          Publish History
        </Typography>
        <ResponsiveLoader />
      </div>
    );
  }

  if (!history.length)
    return (
      <>
        <Typography variant="header3" margin={{ bottom: 'md' }}>
          Publish History
        </Typography>
        <Illustration name="no-data" className={styles.illustration} />
        <Typography componentEl="p" size="sm" align="center">
          This content has never been published!
        </Typography>
      </>
    );

  const revisions = historyForDate.length > 0 ? historyForDate : history;
  const currentItem = revisions[currentIndex];
  const hasNext = currentIndex < revisions.length - 1;
  const hasPrev = currentIndex > 0;
  const disabledNavigationInEditMode = !!originalVersionWhenInHistoryEditMode;

  return (
    <div>
      <Typography margin={{ bottom: 'md' }} variant="header3" data-testid="page-publish-history-title">
        Publish History
      </Typography>
      <Wrapper className={styles.navigator}>
        <Button
          tertiary
          onClick={handleClickPrev}
          disabled={!hasPrev || disabledNavigationInEditMode}
          data-testid="page-publish-history-previous-button"
        >
          <IconArrowLeft margin={{ right: 'sm' }} />
          Previous
        </Button>
        <RevisionSelector
          selectedDate={selectedDate}
          onSelectRevision={handleSelectRevision}
          onSelectDate={handleSelectDate}
          onReset={handleReset}
          dateGroups={[
            {
              title: 'Scheduled pages',
              type: 'scheduled',
              dates: history
                .slice(1)
                .filter((item) => isSameDay(item.metadata.publishUtc!, selectedDate!))
                .map((item) => item.metadata.publishUtc!)
            }
          ]}
          disabled={disabledNavigationInEditMode}
        />
        <Button
          tertiary
          onClick={handleClickNext}
          disabled={!hasNext || disabledNavigationInEditMode}
          data-testid="page-publish-history-next-button"
        >
          Next
          <IconArrowRight margin={{ left: 'sm' }} />
        </Button>
      </Wrapper>
      <HistoryItem
        selectedVersion={currentItem}
        isLive={currentItem.metadata.publishUtc === liveVersion.metadata.publishUtc}
        currentlyEditedVersion={currentlyEditedVersion}
        handleShowHistoryEditMode={handleShowHistoryEditMode}
        scrollableSectionClassName={scrollableSectionClassName}
      />
    </div>
  );
};
