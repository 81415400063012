import { FormLabel, Select, Typography, Wrapper } from '@screentone/core';

import { HelpIcon } from 'components';
import { PageModule, TreatmentTypeSetting, UiModule } from 'data/generated/graphql';
import { useModuleTreatmentTypes } from 'features/page-edit/components/page-draft-section/components/page-module-list/components/page-module-item-list/PageModuleUtils';
import styles from './PageModuleShared.module.scss';

interface PageModuleSharedProps {
  children: React.ReactNode;
  moduleFieldKey: Exclude<keyof UiModule, '__typename'>;
  onChange: ((newPageModule: PageModule) => void) | null;
  pageModule: PageModule;
  subsetTreatmentTypeSettings?: TreatmentTypeSetting[];
  showCurrentTreatmentTypeAtTheTop?: boolean;
  viewOnly?: boolean;
}

export const PageModuleShared = ({
  children,
  moduleFieldKey,
  onChange,
  pageModule,
  subsetTreatmentTypeSettings,
  showCurrentTreatmentTypeAtTheTop,
  viewOnly
}: PageModuleSharedProps) => {
  const { handleChangeTreatment, allowedTreatmentTypeSettings } = useModuleTreatmentTypes({
    moduleFieldKey,
    onChange,
    pageModule,
    subsetTreatmentTypeSettings
  });

  if (showCurrentTreatmentTypeAtTheTop) {
    const currentTreatmentType = pageModule.uiModuleFields[moduleFieldKey]?.treatmentType;

    const currTreatmentTypeIndex = allowedTreatmentTypeSettings?.findIndex(
      (treatmentTypeSetting) =>
        (treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType) ===
        (currentTreatmentType as string)
    );

    allowedTreatmentTypeSettings?.unshift(allowedTreatmentTypeSettings[currTreatmentTypeIndex!]);
    allowedTreatmentTypeSettings?.splice(currTreatmentTypeIndex! + 1, 1);
  }

  return (
    <>
      {children}
      <FormLabel
        key="treatment"
        label={
          <Wrapper className={styles.labelHeader}>
            <Typography margin={{ bottom: 'none' }} variant="label1" weight="bold" className={styles.labelText}>
              Treatment Type
            </Typography>
            <HelpIcon
              text="The treatment type defines the module's layout that is presented to the user. Some treatment types are not editable based on the page's design."
              isLeftAnchored
            />
          </Wrapper>
        }
        fullWidth
        margin={{ top: 'sm' }}
      >
        <Select
          type="text"
          value={pageModule.uiModuleFields[moduleFieldKey]?.treatmentType}
          onChange={handleChangeTreatment}
          disabled={allowedTreatmentTypeSettings?.length === 1 || viewOnly}
          data-testid="page-treatment-type-select"
        >
          {allowedTreatmentTypeSettings?.map((treatmentTypeSetting) => (
            <option
              key={treatmentTypeSetting.treatmentType}
              value={treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType}
            >
              {treatmentTypeSetting.label}
            </option>
          ))}
        </Select>
      </FormLabel>
    </>
  );
};
