import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Button, IconArrowDown, IconMinusCircle, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { Collection, CollectionContentItem } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { DRAGGABLE_PREFIXES, DROPPABLE_IDS } from 'utils/collectionDragUtils';
import { isContentIdExcludedInQueryRules } from 'utils/queryUtils';
import styles from './CuratedList.module.scss';
import CollectionContentCard from '../../../collection-content-card/CollectionContentCard';

interface CuratedListProps {
  collection: Collection;
  onRemoveContent: (index: number) => void;
  onMoveContentFromCuratedToQueried: (index: number, content: AllessehContent) => void;
}
export const CuratedList = ({ collection, onRemoveContent, onMoveContentFromCuratedToQueried }: CuratedListProps) => {
  const { allessehJsonQuery, contentItems } = collection;

  return (
    <Droppable droppableId={DROPPABLE_IDS.MANUAL}>
      {(droppableProvided, snapshot) => (
        <div
          data-testid="curated-list-container"
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          className={classnames(styles.itemsList, contentItems.length === 0 ? styles.itemsListEmpty : '')}
        >
          {contentItems.length === 0 ? (
            <Wrapper
              data-testid="curated-list-empty-list"
              className={classnames(
                styles.emptyCuratedList,
                snapshot.isDraggingOver ? styles.emptyCuratedListDragging : ''
              )}
              padding={{ all: 'md' }}
            >
              <Typography data-testid="curated-list-empty-label" variant="note">
                Drop story here
              </Typography>
            </Wrapper>
          ) : (
            contentItems.map((contentItem, index) => {
              const parsedContent = contentItem.content ? (JSON.parse(contentItem.content) as AllessehContent) : null;
              return (
                <Draggable
                  key={contentItem.originId}
                  draggableId={`${DRAGGABLE_PREFIXES.MANUAL}${JSON.stringify({
                    originId: contentItem.originId,
                    originContentType: contentItem.originContentType,
                    content: contentItem.content
                  } as CollectionContentItem)}`}
                  index={index}
                >
                  {(draggableProvided, snapshot) => (
                    <div
                      data-testid="curated-list-item"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {parsedContent ? (
                        <CollectionContentCard
                          content={parsedContent}
                          isDragging={snapshot.isDragging}
                          renderActions={() =>
                            isContentIdExcludedInQueryRules(parsedContent.data.id, allessehJsonQuery) ? (
                              <Button
                                data-testid="curated-list-remove-top-button"
                                tertiary
                                icon={IconArrowDown as SvgComponent}
                                onClick={() => onMoveContentFromCuratedToQueried(index, parsedContent)}
                              >
                                Remove from Top
                              </Button>
                            ) : (
                              <Button
                                data-testid="curated-list-remove-collection-button"
                                tertiary
                                color="lava"
                                icon={IconMinusCircle as SvgComponent}
                                onClick={() => onRemoveContent(index)}
                              >
                                Remove from Collection
                              </Button>
                            )
                          }
                        />
                      ) : (
                        <Box padding={{ all: 'sm' }}>
                          <Typography>{contentItem.originId}</Typography>
                          <Typography variant="note">Unable to retrieve metadata from Allesseh</Typography>
                        </Box>
                      )}
                    </div>
                  )}
                </Draggable>
              );
            })
          )}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
