import { Box, Button, IconTrash, Typography } from '@screentone/core';

import { Newsletter, PublicationSetting } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { formatInTimeZone } from 'utils/dates';
import { safelyParseContent } from 'utils/temp';

export const NewsletterSettings = ({
  campaign,
  readOnly,
  isScheduled,
  cancelCampaign,
  publicationSetting
}: {
  readOnly: boolean | undefined;
  campaign?: Newsletter;
  isScheduled: boolean;
  cancelCampaign?: () => void;
  publicationSetting?: PublicationSetting;
}) => {
  const contentJson = campaign?.pageModules[0].moduleItems[0]?.itemFields.contentItem?.content;
  const content: AllessehContent = safelyParseContent(contentJson);

  return (
    <>
      {readOnly && campaign && (
        <>
          <Box margin={{ bottom: 'md' }}>
            <Box.Title>Distribution Schedule</Box.Title>
            <Box.Content>
              {campaign.updatedUtc && (
                <Typography variant="header4" margin={{ bottom: 'sm' }}>
                  {formatInTimeZone(campaign.updatedUtc, publicationSetting?.preferredTimezone, 'MMMM dd, yyyy', {
                    showTimezoneAbbreviation: false
                  })}{' '}
                  at{' '}
                  {formatInTimeZone(campaign.updatedUtc, publicationSetting?.preferredTimezone, 'h:mm aaaa', {
                    showTimezoneAbbreviation: true
                  })}
                </Typography>
              )}
              {campaign.publishUtc && (
                <Typography variant="header4" margin={{ bottom: 'sm' }}>
                  {formatInTimeZone(campaign.publishUtc, publicationSetting?.preferredTimezone, 'MMMM dd, yyyy', {
                    showTimezoneAbbreviation: false
                  })}{' '}
                  at{' '}
                  {formatInTimeZone(campaign.publishUtc, publicationSetting?.preferredTimezone, 'h:mm aaaa', {
                    showTimezoneAbbreviation: true
                  })}
                </Typography>
              )}
              <Typography>{content.data.attributes.headline?.text}</Typography>
              {isScheduled && cancelCampaign && (
                <Button
                  tertiary
                  margin={{ top: 'sm' }}
                  icon={IconTrash as SvgComponent}
                  color="lava"
                  onClick={cancelCampaign}
                >
                  Cancel Campaign
                </Button>
              )}
            </Box.Content>
          </Box>
        </>
      )}
    </>
  );
};
