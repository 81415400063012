import { ReactElement } from 'react';
import { Dropdown, Group, IconUser, useDropdownState, Wrapper } from '@screentone/core';

import { TextForm } from 'components/query-rules-form/components/text-form/TextForm';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import styles from './AuthorFilter.module.scss';

interface AuthorFilterProps {
  author: string;
  onChange: (value: string) => void;
}

export const AuthorFilter = ({ author, onChange }: AuthorFilterProps) => {
  const { data: publicationSettingsResp } = usePublicationSettings();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { open, setOpen, componentRef }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();

  const fullAllessehQuery: AllessehContentQueryBody = mergeAllessehQueryBodies(
    publicationSettingsResp?.publicationSetting.baseAllessehQuery,
    ''
  );

  return (
    <Dropdown
      data-testid="author-filter-button"
      open={open}
      onToggle={() => setOpen(!open)}
      componentRef={componentRef}
      trigger={
        <Group
          data-testid="author-filter-button-label"
          gap="xs"
          margin={{ right: 'sm' }}
          className={author ? styles.active : ''}
        >
          <IconUser data-testid="author-filter-button-icon" /> {author || 'Author'}
        </Group>
      }
    >
      <Wrapper data-testid="author-filter-input" margin={{ top: 'sm', bottom: 'sm' }} className={styles.buttonGroup}>
        <TextForm
          allessehKey="Author"
          value={author}
          placeholder="Author's name"
          onChange={onChange}
          disabled={false}
          fullAllessehQuery={fullAllessehQuery}
          setOpen={setOpen}
        />
      </Wrapper>
    </Dropdown>
  );
};
