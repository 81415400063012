import { useCallback, useState } from 'react';

export const useElementWidth = () => {
  const [elementWidth, setElementWidth] = useState(0);

  const elementRef = useCallback((node: HTMLElement | null) => {
    function handleWindowResize() {
      if (!node) return;
      setElementWidth(node.offsetWidth);
    }

    if (node) {
      setElementWidth(node.offsetWidth);
      window.addEventListener('resize', handleWindowResize);
    } else {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return {
    elementWidth,
    elementRef
  };
};
