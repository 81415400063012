import { useCallback } from 'react';

import useLatest from 'utils/hooks/useLatest';

interface ShowNotificationProps {
  message?: string;
  title?: string;
}

const useNotifications = () => {
  // TODO: notifications, NOTE: use useAlert instead
  // eslint-disable-next-line unused-imports/no-unused-vars
  const showNotification = ({ title, message, color }: any) => {
    /* placeholder */
  };

  const showNotificationRef = useLatest(showNotification);

  return {
    showSuccessNotification: useCallback(
      ({ title = 'Success!', message = 'Action completed successfully' }: ShowNotificationProps = {}) =>
        showNotificationRef.current({ title, message, color: 'green' }),
      [showNotificationRef]
    ),
    showErrorNotification: useCallback(
      ({ title = 'Oops!', message = 'Something went wrong' }: ShowNotificationProps = {}) =>
        showNotificationRef.current({ title, message, color: 'red' }),
      [showNotificationRef]
    )
  };
};

export default useNotifications;
