import React from 'react';
import { Box, Divider, FormLabel, Group, Input, Select, Typography, Wrapper } from '@screentone/core';

import { AltSummVariant } from 'contexts/summarian/SummarianContext';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { Collection } from 'data/generated/graphql';
import styles from './OffPlatformCollectionSetingsSection.module.scss';

interface OffPlatformCollectionSettingsProps {
  collection: Collection | null;
  handlePartialCollectionChange: (newCollection: Partial<Collection>) => void;
  editingScheduledRevision: boolean;
}

export const OffPlatformCollectionSettingsSection = ({
  collection,
  handlePartialCollectionChange,
  editingScheduledRevision
}: OffPlatformCollectionSettingsProps) => {
  const { selectedAltSummVariant, setSelectedAltSummVariant } = useSummarianContext();

  return (
    <>
      <Typography variant="header3" margin={{ bottom: 'md' }}>
        Settings
      </Typography>
      <Box>
        <Wrapper padding={{ top: 'md', right: 'mlg', bottom: 'md', left: 'mlg' }}>
          <Typography variant="label2" margin={{ all: 'none' }}>
            Info
          </Typography>
        </Wrapper>
        <Divider />
        <Wrapper padding={{ top: 'md', right: 'md', bottom: 'lg', left: 'md' }}>
          <Group>
            <FormLabel label="Name" labelPosition="top" fullWidth>
              <Input
                placeholder="Enter the name of this Off-Platform module"
                value={collection?.name ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePartialCollectionChange({ name: e.target.value })
                }
                disabled={editingScheduledRevision}
              />
            </FormLabel>
            <FormLabel label="Alt-Summ" labelPosition="top" fullWidth>
              <Select
                defaultValue={selectedAltSummVariant}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectedAltSummVariant(e.target.value as AltSummVariant)
                }
                // Disabled for now, as we will only support 'U.S. Home' variant at the moment
                disabled
              >
                <option value="U.S. Home">Homepage</option>
                <option value="NewsPlus">News Plus</option>
                <option value="APIImage">API Image</option>
                <option value="Mobile">Mobile</option>
                <option value="SEO">SEO</option>
                <option value="Social Open Graph">Social Card</option>
              </Select>
            </FormLabel>
            <FormLabel label="URL" labelPosition="top" fullWidth className={styles.infoLabels}>
              <Typography margin={{ all: 'none' }}>{collection?.parameters.offPlatform?.hostingPageURL}</Typography>
            </FormLabel>
            <FormLabel label="Page Type" labelPosition="top" fullWidth className={styles.infoLabels}>
              <Typography margin={{ all: 'none' }}>Off-Platform</Typography>
            </FormLabel>
          </Group>
        </Wrapper>
      </Box>
    </>
  );
};
