import { useLocation } from 'react-router-dom';

import { useIssue } from 'contexts/issue/useIssue';
import { useNewsletter } from 'contexts/newsletter/useNewsletter';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { ContentTypeFilter, ContentTypeFilterType } from '../content-type-filter/ContentTypeFilter';

interface ContentTypeFilterContainer {
  values: ContentTypeFilterType[];
  onChange: (value: ContentTypeFilterType[]) => void;
  setFiltersSet: (filtersSet: boolean) => void;
}

const ROUTE = {
  PAGES: 'pages',
  ISSUES: 'issues',
  COLLECTIONS: 'collections',
  OFF_PLATFORM: 'off-platform',
  NEWSLETTERS: 'newsletters'
};

export const ContentTypeFilterContainer = ({ values, setFiltersSet, onChange }: ContentTypeFilterContainer) => {
  const { data: publicationSettings, isLoading: isLoad, fetchStatus } = usePublicationSettings();
  const isLoading = isLoad && fetchStatus !== 'idle';

  const enabledContentTypes = publicationSettings?.publicationSetting.searchableContentTypes ?? [];
  const defaultContentTypes = publicationSettings?.publicationSetting.defaultContentTypes ?? [];

  const { pathname } = useLocation();

  const route = pathname.split('/')[2];
  const issueHookResult = useIssue();
  const pagePublishHookResult = usePagePublish();
  const newsletterHookResult = useNewsletter();
  const { pageTypeSetting: pageTypeSettingContext } = usePageTypeSettingsContext();

  const handleChange = (e: { target: { value: PublicationSettingSearchableContentType; checked: boolean } }) => {
    const newContentTypes = [...values];

    if (!e.target.checked) {
      onChange(newContentTypes.filter((value) => value !== e.target.value));
    } else {
      onChange([...newContentTypes, e.target.value]);
    }
    setFiltersSet(true);
  };

  let pageTypeSetting =
    route === ROUTE.ISSUES
      ? issueHookResult.pageTypeSetting
      : route === ROUTE.NEWSLETTERS
      ? newsletterHookResult.pageTypeSetting
      : pagePublishHookResult.pageTypeSetting;

  if (Object.keys(pageTypeSetting ?? {}).length === 0) {
    pageTypeSetting = pageTypeSettingContext;
  }

  const displayContentTypeFilter = enabledContentTypes.length > 1;

  const publishStatusValues =
    route === ROUTE.COLLECTIONS || route === ROUTE.OFF_PLATFORM
      ? enabledContentTypes
      : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        pageTypeSetting?.contentTypes?.filter((publishStatusValue) =>
          enabledContentTypes.includes(publishStatusValue)
        ) ?? [PublicationSettingSearchableContentType.Article];

  const isActive =
    defaultContentTypes.length !== values.length || !defaultContentTypes.every((value) => values.includes(value));
  return displayContentTypeFilter ? (
    <ContentTypeFilter
      values={values}
      handleChange={handleChange}
      isActive={isActive}
      publishStatusValues={publishStatusValues}
      isLoading={isLoading}
    />
  ) : (
    <></>
  );
};
