import { useEffect } from 'react';
import { Button, IconCode } from '@screentone/core';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { ModuleContainer } from 'data/generated/graphql';
import { AddAllessehCollectionModal } from './AddAllessehCollectionModal';

interface AddAllessehCollectionProps {
  hierarchyId: string;
  module: ModuleContainer;
}

export const AddAllessehCollection = ({ hierarchyId, module }: AddAllessehCollectionProps) => {
  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();

  const handleAddCollectionId = () => {
    setIsModalOpen(true);

    setActionModalComponent(<AddAllessehCollectionModal hierarchyId={hierarchyId} module={module} />);
  };

  useEffect(() => {
    setActionModalComponent(<AddAllessehCollectionModal hierarchyId={hierarchyId} module={module} />);
  }, [hierarchyId, module, setActionModalComponent]);

  return (
    <Button tertiary icon={IconCode as SvgComponent} onClick={handleAddCollectionId}>
      Add collection ID
    </Button>
  );
};
