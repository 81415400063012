import { OnDragEndResponder, OnDragStartResponder } from 'react-beautiful-dnd';

import { useTrash } from 'contexts/trash/useTrash';
import { Newsletter, PageModuleItem } from 'data/generated/graphql';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES } from 'features/page-edit/dragUtils';
import { DROPPABLE_IDS } from 'utils/collectionDragUtils';

interface UseDragEditNewsletterProps {
  newsletter: Newsletter | null;
  onPartialNewsletterChange?: (issue: Partial<Newsletter>) => void;
}

const handleDragItemToModuleFromResults = ({
  newsletter,
  draggableId,
  onPartialNewsletterChange
}: {
  newsletter: Newsletter;
  draggableId: string;
  onPartialNewsletterChange?: (issue: Partial<Newsletter>) => void;
}) => {
  if (!onPartialNewsletterChange) return;

  const itemJson: string = draggableId.replace(DRAGGABLE_PREFIXES.MODULE_ITEM, '');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newItem: PageModuleItem = JSON.parse(itemJson);

  const newPageModules = [...newsletter.pageModules];
  newPageModules[0].moduleItems = [newItem];
  onPartialNewsletterChange({ pageModules: newPageModules });
};

const handleDragModuleItemInModule = ({
  newsletter,
  draggableId,
  sourceDroppableId,
  onPartialNewsletterChange
}: {
  newsletter: Newsletter;
  draggableId: string;
  sourceDroppableId: string;
  onPartialNewsletterChange: (issue: Partial<Newsletter>) => void;
}) => {
  if (sourceDroppableId === 'RESULTS') {
    handleDragItemToModuleFromResults({
      newsletter,
      draggableId,
      onPartialNewsletterChange
    });
  }
};

const handleDragModuleItemToTrash = ({
  newsletter,
  onPartialNewsletterChange
}: {
  newsletter: Newsletter;
  onPartialNewsletterChange: (issue: Partial<Newsletter>) => void;
}) => {
  //   if (!onPartialNewsletterChange) return;

  const newPageModules = [...newsletter.pageModules];
  newPageModules[0].moduleItems = [];
  onPartialNewsletterChange({ pageModules: newPageModules });
};

export const useDragEditNewsletter = ({ newsletter, onPartialNewsletterChange }: UseDragEditNewsletterProps) => {
  const { setIsDragging } = useTrash();
  if (!onPartialNewsletterChange) return {};

  const handleDragStart: OnDragStartResponder = (dragEvent) => {
    // when an item currently in a module is being dragged
    // update the isDragging context so that the droppable trash area becomes active

    const regexDroppable = new RegExp(`^${DROPPABLE_ID_PREFIXES.MODULE_ITEMS}`);
    const regexDraggable = new RegExp(`^${DRAGGABLE_PREFIXES.MODULE_ITEM}`);
    if (dragEvent.draggableId.match(regexDraggable) && dragEvent.source.droppableId.match(regexDroppable)) {
      setIsDragging(true);
    }
  };

  const handleDragEnd: OnDragEndResponder = (dragEvent) => {
    setIsDragging(false);

    if (!newsletter) return;

    const {
      destination,
      source: { droppableId: sourceDroppableId },
      draggableId
    } = dragEvent;

    if (!destination) {
      return;
    }

    const { droppableId: destDroppableId } = destination;

    if (destDroppableId.includes(DROPPABLE_ID_PREFIXES.MODULE_ITEMS)) {
      handleDragModuleItemInModule({
        newsletter,
        draggableId,
        sourceDroppableId,
        onPartialNewsletterChange
      });
    } else if (destDroppableId === DROPPABLE_IDS.TRASH) {
      handleDragModuleItemToTrash({
        newsletter,
        onPartialNewsletterChange
      });
    }
  };

  return {
    handleDragStart,
    handleDragEnd
  };
};
