/* eslint-disable import/no-cycle */
import { ChangeEventHandler } from 'react';
import { cloneDeep } from 'lodash';

import { AlertOptions } from 'contexts/alert/AlertContext';
import { ContentItem } from 'contexts/datamodel/DataModelContext';
import {
  ArticleItem,
  ContentUnion,
  ExternalCollectionItem,
  ModuleContainer,
  PageModule,
  QueryItem,
  TreatmentTypeSetting,
  UiBasicOptionalTreatmentType,
  UiBasicTreatmentType,
  UiModule,
  UiModuleType,
  UiRankedTreatmentType,
  UiTextTreatmentType,
  UiTitleTreatmentType,
  UiTwoColumnTreatmentType
} from 'data/generated/graphql';
import { AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';

export const getUiModuleTypeLabel = (uiModuleType: UiModuleType) =>
  `${uiModuleType
    .replaceAll('ModuleType', '')
    .replaceAll('Ui', '')
    .replace(/([A-Z])/g, ' $1')
    .trim()}`;

export function getModuleFieldKey(uiModuleType: UiModuleType) {
  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
      return 'basicModule';
    case UiModuleType.UiRankedModuleType:
      return 'rankedModule';
    case UiModuleType.UiTitleModuleType:
      return 'titleModule';
    case UiModuleType.UiTextModuleType:
      return 'textModule';
    case UiModuleType.UiBasicOptionalModuleType:
      return 'basicOptionalModule';
    case UiModuleType.UiTwoColumnModuleType:
      return 'twoColumnModule';
    default:
      return 'twoColumnModule';
  }
}

export const getTreatmentType = (pageModule: PageModule): string => {
  let treatmentType = null;
  const keys = Object.keys(pageModule.uiModuleFields);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const uiModuleFieldKey = key as keyof UiModule;
    const uiModule = pageModule.uiModuleFields[uiModuleFieldKey] as { treatmentType: string } | null;
    if (uiModule) {
      treatmentType = uiModule.treatmentType;
    }
  }
  return treatmentType as string;
};

export function getSubsetTreatmentTypeSettings(
  uiModuleType: UiModuleType,
  treatmentTypeSettings: TreatmentTypeSetting[] = []
) {
  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiBasicTreatmentType).includes(s.treatmentTypeKey as UiBasicTreatmentType) ||
          Object.values(UiBasicTreatmentType).includes(s.treatmentType as UiBasicTreatmentType)
      );
    case UiModuleType.UiRankedModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiRankedTreatmentType).includes(s.treatmentTypeKey as UiRankedTreatmentType) ||
          Object.values(UiRankedTreatmentType).includes(s.treatmentType as UiRankedTreatmentType)
      );
    case UiModuleType.UiTitleModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiTitleTreatmentType).includes(s.treatmentTypeKey as UiTitleTreatmentType) ||
          Object.values(UiTitleTreatmentType).includes(s.treatmentType as UiTitleTreatmentType)
      );
    case UiModuleType.UiTextModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiTextTreatmentType).includes(s.treatmentTypeKey as UiTextTreatmentType) ||
          Object.values(UiTextTreatmentType).includes(s.treatmentType as UiTextTreatmentType)
      );
    case UiModuleType.UiBasicOptionalModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiBasicOptionalTreatmentType).includes(s.treatmentTypeKey as UiBasicOptionalTreatmentType) ||
          Object.values(UiBasicOptionalTreatmentType).includes(s.treatmentType as UiBasicOptionalTreatmentType)
      );
    case UiModuleType.UiTwoColumnModuleType:
      return treatmentTypeSettings.filter(
        (s) =>
          Object.values(UiTwoColumnTreatmentType).includes(s.treatmentTypeKey as UiTwoColumnTreatmentType) ||
          Object.values(UiTwoColumnTreatmentType).includes(s.treatmentType as UiTwoColumnTreatmentType)
      );
    default:
      return treatmentTypeSettings;
  }
}

export function useModuleTreatmentTypes({
  moduleFieldKey,
  onChange,
  pageModule,
  subsetTreatmentTypeSettings
}: {
  moduleFieldKey: Exclude<keyof UiModule, '__typename'>;
  onChange?: ((newPageModule: PageModule) => void) | null;
  pageModule: PageModule;
  subsetTreatmentTypeSettings?: TreatmentTypeSetting[];
}) {
  const handleChangeTreatmentByValue = (value: string) => {
    const newPageModule = cloneDeep(pageModule);
    if (newPageModule.uiModuleFields[moduleFieldKey]) {
      const currentTreatmentType = newPageModule.uiModuleFields[moduleFieldKey]!.treatmentType;
      newPageModule.uiModuleFields[moduleFieldKey]!.treatmentType = value as typeof currentTreatmentType;
    }
    onChange?.(newPageModule);
  };

  const handleChangeTreatment: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleChangeTreatmentByValue(e.target.value);
  };

  const allowedTreatmentTypes =
    pageModule.uiModuleFields[moduleFieldKey]?.allowedTreatmentTypes ??
    ([pageModule.uiModuleFields[moduleFieldKey]?.treatmentType] as string[]);

  const allowedTreatmentTypeSettings = subsetTreatmentTypeSettings?.filter(
    (treatmentTypeSetting) =>
      allowedTreatmentTypes.includes(treatmentTypeSetting.treatmentType) ||
      allowedTreatmentTypes.includes(treatmentTypeSetting.treatmentTypeKey ?? '')
  );

  return {
    handleChangeTreatment,
    handleChangeTreatmentByValue,
    allowedTreatmentTypes,
    allowedTreatmentTypeSettings
  };
}

export const getContentTypes = (pageModule: PageModule): string[] => {
  let contentTypes = null;
  const keys = Object.keys(pageModule.uiModuleFields);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const uiModuleFieldKey = key as keyof UiModule;
    const uiModule = pageModule.uiModuleFields[uiModuleFieldKey] as { contentTypes: string[] } | null;
    if (uiModule) {
      contentTypes = uiModule.contentTypes.map((contentType) => contentType.toLowerCase());
    }
  }
  return contentTypes as string[];
};

const isQueryContentTypeAllowed = (allessehJsonQuery: string, allowedContentTypes: string[]): boolean => {
  const parsedQuery = JSON.parse(allessehJsonQuery) as AllessehContentQueryBody;
  if (parsedQuery.query) {
    const CONTENT_TYPE = 'ContentType';
    const contentTypeTerm = parsedQuery.query.and?.find((andRule: AllessehQueryRule) => {
      if ('term' in andRule) {
        return andRule.term.key === CONTENT_TYPE;
      }
      if ('terms' in andRule) {
        return andRule.terms.key === CONTENT_TYPE;
      }
      return false;
    });
    if (contentTypeTerm) {
      if ('terms' in contentTypeTerm) {
        return contentTypeTerm.terms.value.some((value: string) => allowedContentTypes.includes(value));
      }
      if ('term' in contentTypeTerm) {
        return allowedContentTypes.includes(contentTypeTerm.term.value);
      }
    }
    return allowedContentTypes.includes('article');
  }
  return false;
};

export const isContentTypeAllowedInModule = (module: ModuleContainer, contentItem: ContentItem): boolean => {
  const allowedContentTypes = getContentTypes(module.attributes.pageModule as PageModule);

  if (contentItem.type === 'Collection') {
    const collection = contentItem as ExternalCollectionItem;
    const { contentItems } = collection;
    const articles = contentItems.filter((item: ContentUnion): item is ArticleItem => item.type === 'Article');
    const query = contentItems.find((item: ContentUnion) => item.type === 'Query') as QueryItem | undefined;
    const hasArticles = articles.length > 0;
    const hasQuery = !!query;
    const isMixedCollection = hasArticles && hasQuery;
    const allessehJsonQuery = JSON.stringify(query?.attributes.query ?? '');

    if (isMixedCollection) {
      const isContentAllowed = articles.every((article) =>
        allowedContentTypes.includes(article.attributes.originContentType!)
      );
      if (!isContentAllowed) return false;

      return isQueryContentTypeAllowed(allessehJsonQuery, allowedContentTypes);
    }

    if (hasArticles) {
      return articles.every((article) => allowedContentTypes.includes(article.attributes.originContentType!));
    }

    if (hasQuery) {
      return isQueryContentTypeAllowed(allessehJsonQuery, allowedContentTypes);
    }
  }

  if (contentItem.type === 'Article') {
    const article = contentItem as ArticleItem;
    return allowedContentTypes.includes(article.attributes.originContentType!);
  }

  if (contentItem.type === 'Query') {
    const query = contentItem as QueryItem;
    const allessehJsonQuery = JSON.stringify(query.attributes.query);

    return isQueryContentTypeAllowed(allessehJsonQuery, allowedContentTypes);
  }

  // allow manually pasted allesseh collection ids (added through Add collection ID button)
  return true;
};

export const isItemAllowedInModule = (
  module: ModuleContainer,
  contentItem: ContentItem,
  alertError: (message: string, options?: AlertOptions) => void
): boolean => {
  const isContentTypeAllowed = isContentTypeAllowedInModule(module, contentItem);
  if (!isContentTypeAllowed) {
    alertError('Content type is not allowed in the module.', { autoDismiss: true });
    return false;
  }

  return true;
};
