import { useMemo } from 'react';
import { Button, IconExternalLink } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { buildNewspressUrl } from 'utils/cms';

interface NewspressButtonProps {
  content: AllessehContent;
}

export const NewspressButton = ({ content }: NewspressButtonProps) => {
  const newspressUrl = useMemo(
    () => buildNewspressUrl(content.data.id, content.data.attributes.type),
    [content.data.id, content.data.attributes.type]
  );

  const handleViewNewsPressUrl = () => {
    if (!newspressUrl) return;
    window.open(newspressUrl, '_blank');
  };
  return newspressUrl ? (
    <Button
      data-testid="newspress-button-open-cms-button"
      tertiary
      icon={IconExternalLink as SvgComponent}
      iconPosition="right"
      onClick={handleViewNewsPressUrl}
    >
      Open in CMS
    </Button>
  ) : null;
};
