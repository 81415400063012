import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Group, Overlay, Typography, useModalPortal, Wrapper } from '@screentone/core';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useIssue } from 'contexts/issue/useIssue';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ItpIssueStatus, PublicationSetting } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { SnippetyImageData, SnippetyResponseWithImage } from 'hooks/useSnippetyQuery';
import styles from './AltSummModal.module.scss';
import { ImageInput } from './components/image-input/ImageInput';

interface AltSumModalProps {
  content: AllessehContent;
  // TODO: remove these props once all artifacts use the EditAltSumm action, and the modal is controlled by the Context Menu Actions context
  // eslint-disable-next-line react/no-unused-prop-types
  setIsModalOpen?: (isModalOpen: boolean) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  isModalOpen?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  publicationSettings?: PublicationSetting | undefined;
}

interface ControlledAltSumm {
  headline: string;
  body: string;
  image: SnippetyImageData[];
  imageUrl: string; // we don't send this property to Summarian, we only use it to display the image in the modal
}

export const AltSummModal = ({ content }: AltSumModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { isModalOpen, setIsModalOpen } = useContextMenuActions();
  const [controlledAltSum, setControlledAltSum] = useState<ControlledAltSumm>({
    headline: '',
    body: '',
    image: [],
    imageUrl: ''
  });
  const headlineRef = useRef<HTMLTextAreaElement>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const publicationSettings = publicationSettingsResp?.publicationSetting;

  const {
    selectedAltSummVariant: altSummVariant,
    trackContent,
    getAltSummFields,
    setAltSummFields
  } = useSummarianContext();
  const { headline, body, image, proxy } = getAltSummFields(content);
  const { issue, handlePartialIssueChange, setItpAltSummUpdated } = useIssue();

  useEffect(() => {
    trackContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  useEffect(() => {
    if (headlineRef.current) {
      headlineRef.current.style.height = '0';
      headlineRef.current.style.height = `${headlineRef.current.scrollHeight}px`;
    }

    if (bodyRef.current) {
      bodyRef.current.style.height = '0';
      bodyRef.current.style.height = `${bodyRef.current.scrollHeight}px`;
    }
  }, [controlledAltSum.headline, controlledAltSum.body]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, headline }));
  }, [headline]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, body }));
  }, [body]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, imageUrl: image }));
  }, [image]);

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCancel = () => handleToggleModal();

  const handleChangeForTextFields =
    (field: keyof typeof controlledAltSum) => (inputEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
      setControlledAltSum((prev) => ({ ...prev, [field]: inputEvent.target.value }));
    };

  const handleChangeForImage = useCallback((imageData: SnippetyResponseWithImage | null) => {
    setControlledAltSum((prev) => ({
      ...prev,
      image: imageData ? [imageData.tools.image] : [],
      imageUrl: imageData ? imageData.tools.image.href ?? imageData.url : ''
    }));
  }, []);

  const updateAltSumThroughSummarian = async () => {
    if (!proxy) return;
    const headlinePersistedValue = await proxy.headline;
    if (controlledAltSum.headline !== headlinePersistedValue) {
      proxy.headline = controlledAltSum.headline;
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await proxy.headline;
    }

    const bodyPersistedValue = await proxy.body;
    if (controlledAltSum.body !== bodyPersistedValue) {
      proxy.body = controlledAltSum.body;
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await proxy.body;
    }

    const imagePersistedValue = await proxy.images;
    if (controlledAltSum.imageUrl !== imagePersistedValue?.[0]?.href) {
      proxy.images = controlledAltSum.image;
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await proxy.images;
    }

    const version = await window.summarianShareDbAPI.currentTemplateVersion(content.data.id, altSummVariant);
    await window.summarianShareDbAPI.publishTemplateAndContent(content.data.id, altSummVariant, version);
    setControlledAltSum(controlledAltSum);
    setAltSummFields(content, {
      headline: controlledAltSum.headline,
      body: controlledAltSum.body,
      image: controlledAltSum.imageUrl
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Overlay data-testid="alt-summ-modal-view" onDismiss={handleToggleModal} status="open" renderNode={renderNode}>
      <Box data-testid="alt-summ-modal-container" className={styles.modalBox}>
        <Box.Title data-testid="alt-summ-modal-title">Edit Alt-Summ</Box.Title>
        <Box.Content data-testid="alt-summ-modal-content" padding={{ all: 'none' }}>
          <Wrapper data-testid="alt-summ-modal-body" padding={{ top: 'sm', right: 'mlg', bottom: 'md', left: 'mlg' }}>
            <Typography data-testid="alt-summ-modal-type" variant="header4">
              {altSummVariant}
            </Typography>
            <ImageInput
              imageUrl={controlledAltSum.imageUrl}
              altText={controlledAltSum.image[0]?.caption}
              onAdd={handleChangeForImage}
              imDomain={publicationSettings?.snippetyIMDomain ?? ''}
            />
            <Wrapper data-testid="alt-summ-modal-form" className={styles.inputsWrapper} margin={{ top: 'md' }}>
              <textarea
                data-testid="alt-summ-modal-headline-input"
                className={styles.headlineTextarea}
                onChange={handleChangeForTextFields('headline')}
                value={controlledAltSum.headline}
                ref={headlineRef}
                placeholder="Alt-summ headline"
              />
              <span data-testid="alt-summ-modal-headline-counter" className={styles.headlineCharCount}>
                {controlledAltSum.headline.length}
              </span>
              <textarea
                data-testid="alt-summ-modal-body-input"
                className={styles.bodyTextarea}
                onChange={handleChangeForTextFields('body')}
                value={controlledAltSum.body}
                ref={bodyRef}
                placeholder="Alt-summ body"
              />
            </Wrapper>
          </Wrapper>
          <Wrapper data-testid="alt-summ-modal-footer" padding={{ top: 'sm', right: 'mlg', bottom: 'md', left: 'mlg' }}>
            <Group data-testid="alt-summ-modal-buttons" gap="sm" align="stretch">
              <Group.Item data-testid="alt-summ-modal-cancel" flex>
                <Button data-testid="alt-summ-modal-cancel-button" secondary fullWidth onClick={handleCancel}>
                  Cancel
                </Button>
              </Group.Item>
              <Group.Item data-testid="alt-summ-modal-update" flex>
                <Button
                  data-testid="alt-summ-modal-update-button"
                  primary
                  fullWidth
                  onClick={async () => {
                    await updateAltSumThroughSummarian();
                    if (issue) {
                      handlePartialIssueChange({ status: ItpIssueStatus.Changed });
                      setItpAltSummUpdated(true);
                    }
                    setIsModalOpen(!isModalOpen);
                  }}
                >
                  Update
                </Button>
              </Group.Item>
            </Group>
          </Wrapper>
        </Box.Content>
      </Box>
    </Overlay>
  );
};
