import { useEffect } from 'react';
import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAlert } from 'contexts/alert/useAlert';
import useNotifications from 'utils/notifications';

type APIErrorResponse = {
  error: string;
  message: string;
  statusCode: number;
};

const useAppQuery = <TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError & AxiosError<APIErrorResponse>, TData, TQueryKey> & {
    shouldShowErrorAlert?: boolean;
  }
): UseQueryResult<TData, TError> => {
  const queryResult = useQuery(queryKey, queryFn, options);

  const { showErrorNotification } = useNotifications();
  const { alertError } = useAlert();

  useEffect(() => {
    if (options?.shouldShowErrorAlert && queryResult.isError) {
      const { error: responseErrorName, message: responseErrorMessage } = queryResult.error.response?.data ?? {};
      const { name: axiosErrorName, message: axiosErrorMessage } = queryResult.error;

      // Show error from response if available, or default to axios error
      const title = responseErrorName ?? axiosErrorName;
      const message = responseErrorMessage ?? axiosErrorMessage;
      alertError(`${title} ${message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrorNotification, queryResult.isError, options?.shouldShowErrorAlert, queryResult.error]);

  return queryResult;
};

export default useAppQuery;
