import { usePublicationSettingsQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks/useConvertedProperty';

type Variables = {
  publicationKey: string;
};

const usePublicationSettings = (variables: Variables | null = null) => {
  const currentProperty = useConvertedProperty();
  const publicationSettingsQueryVariables = variables ?? { publicationKey: currentProperty ?? '' };

  return usePublicationSettingsQuery(publicationSettingsQueryVariables, {
    enabled: !!currentProperty,
    staleTime: 60000
  });
};

export default usePublicationSettings;
