import { useState } from 'react';
import { Box, Button, Divider, Input, Overlay, Typography, useModalPortal, Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { ContentItem } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import {
  ArticleItem,
  CollectionContentItemInput,
  CollectionDtoUpdateInput,
  ExternalCollectionItem,
  ModuleContainer,
  QueryItem,
  useCollectionDtoCreateMutation,
  useCollectionDtoUpdateMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import styles from './ConvertToCollectionModal.module.scss';

interface ConvertToCollectionModalProps {
  hierarchyId: string;
  module: ModuleContainer;
}

export function getCollectionIdAndUtcNum(idCreatedUtc?: string) {
  if (!idCreatedUtc) {
    return {
      id: '',
      createdUtcNum: 0
    };
  }
  const regex = /(\d{13})$/;
  const timeStamp = idCreatedUtc.match(regex);
  if (!timeStamp) {
    return {
      id: idCreatedUtc,
      createdUtcNum: 0
    };
  }
  const createdUtcNum = parseInt(timeStamp[0], 10);
  const id = idCreatedUtc.split('_').slice(0, -1).join('_');

  return { id, createdUtcNum };
}

export const ConvertToCollectionModal = ({ hierarchyId, module }: ConvertToCollectionModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const { generateHierarchyId, insertEntity, removeEntity } = useDataModelContext();
  const { isModalOpen, setIsModalOpen } = useContextMenuActions();
  const currentProperty = useConvertedProperty();
  const [collectionName, setCollectionName] = useState<string | null>(null);

  const { data: publicationSettingsResp } = usePublicationSettings();
  const { setHasRecentlyCreatedCollection } = usePagePublish();
  const { mutateAsync: collectionCreateMutationAsync, isLoading: collectionCreateLoading } =
    useCollectionDtoCreateMutation();
  const { mutateAsync: collectionUpdateMutateAsync, isLoading: collectionUpdateLoading } =
    useCollectionDtoUpdateMutation();

  const isLoading = collectionCreateLoading || collectionUpdateLoading;

  const handleDismissModal = () => setIsModalOpen(false);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCollectionName(e.target.value);
  };

  const handleAdd = async () => {
    if (!currentProperty) {
      return;
    }
    const {
      collectionDTOCreate: { idCreatedUtc }
    } = await collectionCreateMutationAsync({
      collectionDTOCreateInput: {
        name: collectionName ?? 'Unnamed Page Section Collection',
        publicationKey: currentProperty
      }
    });

    const { id, createdUtcNum } = getCollectionIdAndUtcNum(idCreatedUtc || '');

    const articleItems = module.collection.filter((item): item is ArticleItem => item.type === 'Article');
    const queryItem = module.collection.find((item) => item.type === 'Query') as QueryItem | undefined;

    const allessehJsonQuery = JSON.stringify(queryItem?.attributes.query);
    const fullAllessehQuery: AllessehContentQueryBody | false =
      !!allessehJsonQuery &&
      allessehJsonQuery !== '' &&
      mergeAllessehQueryBodies(publicationSettingsResp?.publicationSetting.baseAllessehQuery, allessehJsonQuery);

    const stringifiedJsonQuery = fullAllessehQuery ? JSON.stringify(fullAllessehQuery) : null;

    const collectionDTOUpdateInput: CollectionDtoUpdateInput = {
      allessehCollectionId: id,
      createdUtc: createdUtcNum,
      name: collectionName ?? 'Unnamed Page Section Collection',
      publicationKey: currentProperty,
      notes: null,
      contentItems: articleItems.map<CollectionContentItemInput>((article) => ({
        base_doc_id: article.attributes.base_doc_id,
        hosted_url: article.attributes.hosted_url,
        id: article.attributes.id,
        link: article.attributes.link,
        originContentType: article.attributes.originContentType!,
        originId: article.attributes.originId!,
        product: article.attributes.product,
        seo_id: article.attributes.seo_id,
        status: article.attributes.status
      })),
      allessehJsonQuery: stringifiedJsonQuery
    };

    let collectionUpdate;
    try {
      const response = await collectionUpdateMutateAsync({ collectionDTOUpdateInput });
      collectionUpdate = response.collectionDTOUpdate;
    } catch (err: unknown) {
      console.error('Error creating collection', err);
      throw new Error('Error creating collection');
    }

    const externalCollectionItem: ExternalCollectionItem = {
      type: 'Collection',
      attributes: {
        id: collectionUpdate.metadata.allessehId,
        base_doc_id: collectionUpdate.metadata.allessehId,
        repo: 'Allesseh'
      },
      metadata: {
        id: collectionUpdate.metadata.allessehId,
        name: collectionUpdate.metadata.name,
        createdUtc: collectionUpdate.metadata.createdUtc,
        creatorUser: collectionUpdate.metadata.creatorUser,
        isArchived: collectionUpdate.metadata.isArchived,
        notes: collectionUpdate.metadata.notes ?? '',
        publicationKey: collectionUpdate.metadata.publicationKey,
        revisorUser: collectionUpdate.metadata.revisorUser,
        updatedUtc: collectionUpdate.metadata.updatedUtc ?? 0
      },
      contentItems: collectionUpdate.root.collection as ContentItem[]
    };

    const childItemsHierarchyIds = module.collection.map((item, i) => generateHierarchyId(item, hierarchyId, i));
    childItemsHierarchyIds.toReversed().forEach((childHierarchyId) => removeEntity(childHierarchyId));
    insertEntity(`${hierarchyId}-0`, externalCollectionItem);

    handleDismissModal();
    setHasRecentlyCreatedCollection(true);
  };

  return (
    <Overlay
      onDismiss={handleDismissModal}
      status={isModalOpen ? 'open' : 'closed'}
      className={styles.overlay}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      renderNode={renderNode}
    >
      <Box>
        <Box.Title>CONVERT TO COLLECTION</Box.Title>
        <Box.Content padding={{ all: 'none' }}>
          <Wrapper padding={{ all: 'md' }}>
            <Input
              type="text"
              placeholder="Enter Collection Name"
              value={collectionName ?? 'Unnamed Page Section Collection'}
              onChange={handleInputChange}
              margin={{ right: 'sm' }}
            />
            <Typography variant="note" margin={{ top: 'sm' }}>
              This will create a new collection with the items added to this page module.
            </Typography>
          </Wrapper>
          <Divider />
          <Wrapper padding={{ all: 'md' }} className={styles.bottomBar}>
            <Button secondary onClick={handleDismissModal} disabled={isLoading}>
              Cancel
            </Button>
            {!isLoading ? (
              <Button primary margin={{ left: 'sm' }} onClick={handleAdd}>
                Create
              </Button>
            ) : (
              <ResponsiveLoader size="md" />
            )}
          </Wrapper>
        </Box.Content>
      </Box>
    </Overlay>
  );
};
