import { useEffect, useState } from 'react';
import { Button, Group, IconRefresh, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { Collection } from 'data/generated/graphql';
import { useElementDimensions } from 'hooks/dom/useElementDimensions';
import { DRAGGABLE_PREFIXES, DROPPABLE_IDS } from 'utils/collectionDragUtils';
import { CuratedList } from './curated-list/CuratedList';
import styles from './OffPlatformCollectionItemsSection.module.scss';
import collectionEditStyles from '../../OffPlatformEdit.module.scss';

interface CollectionItemsSectionProps {
  collection: Collection;
  editingScheduledRevision: boolean;
  onRemoveContent: (index: number) => void;
  onClearAllContent: () => void;
}

export const OffPlatformCollectionItemsSection = ({
  collection,
  editingScheduledRevision,
  onRemoveContent,
  onClearAllContent
}: CollectionItemsSectionProps) => {
  const moduleDisplayName = collection.parameters.offPlatform?.moduleDisplayName ?? 'Curated List';
  const [isWrapperHeightGreaterThanMaxHeight, setIsWrapperHeightGreaterThanMaxHeight] = useState(false);
  const { elementRef, dimensions } = useElementDimensions<HTMLDivElement>();

  useEffect(() => {
    if (!elementRef.current) return;
    const maxHeightStr = getComputedStyle(elementRef.current).maxHeight.replace('px', '');
    const maxHeight = Number(maxHeightStr);
    if (dimensions.scrollHeight > maxHeight) {
      setIsWrapperHeightGreaterThanMaxHeight(true);
    } else {
      setIsWrapperHeightGreaterThanMaxHeight(false);
    }
  }, [dimensions.scrollHeight, elementRef]);

  return (
    <>
      <Typography variant="header3" margin={{ bottom: 'md' }} data-testid="off-platform-new-header">
        {editingScheduledRevision ? `Editing: ${format(collection.publishUtc!, 'MMM d, yyyy h:mm aaa z')}` : 'New'}
      </Typography>
      <Wrapper margin={{ bottom: 'md' }}>
        <div
          className={classnames(
            styles.collectionItemsWrapper,
            collectionEditStyles.itemsScrollable,
            isWrapperHeightGreaterThanMaxHeight ? styles.wrapperOverflow : ''
          )}
          ref={elementRef}
          data-testid="off-platform-collection-Items"
        >
          <Typography weight="bold" size="sm" margin={{ bottom: 'sm' }} data-testid="off-platform-display-name">
            {moduleDisplayName}
          </Typography>
          <CuratedList
            collection={collection}
            droppableId={DROPPABLE_IDS.MANUAL}
            draggablePrefix={DRAGGABLE_PREFIXES.MANUAL}
            showBorder
            showItemCount
            onRemoveContent={onRemoveContent}
          />
        </div>
      </Wrapper>
      <Group align="end">
        <Button
          tertiary
          icon={IconRefresh as SvgComponent}
          color="lava"
          onClick={onClearAllContent}
          data-testid="off-platform-reset-to-default"
        >
          Reset to default
        </Button>
      </Group>
    </>
  );
};
