import { Draggable } from 'react-beautiful-dnd';
import { IconArrowDown, IconArrowUp, IconHandle, Wrapper } from '@screentone/core';
import cloneDeep from 'lodash.clonedeep';

import { useAlert } from 'contexts/alert/useAlert';
import {
  AvailableLayoutModule,
  ItpIssuePageType,
  Layout,
  PageModule,
  TreatmentTypeSetting,
  UiModuleType
} from 'data/generated/graphql';
import { NumberOrNothing } from 'features/issues/types';
import { DRAGGABLE_PREFIXES } from 'features/page-edit/dragUtils';
import {
  getAllFlattenedModulesForDisplay,
  getModuleListAndIndexFromModuleHierarchyId,
  PageModuleForDisplay
} from 'features/page-edit/pageEditUtils';
import { PageModuleItemList } from './components/page-module-item-list/PageModuleItemList';
import styles from './PageModuleList.module.scss';
import { AddModuleButton } from '../add-module-button/AddModuleButton';

interface PageModuleListProps {
  isITP?: boolean;
  isNewsletter?: boolean;
  isMobileAppScreen?: boolean;
  isPageHistorySection?: boolean;
  itpPageType?: ItpIssuePageType;
  disableAltSumm?: boolean;
  pageIndex?: number;
  pageModules: PageModule[];
  removableUiModuleTypes?: UiModuleType[];
  onPageModuleListChange?: (newPageModules: PageModule[]) => void;
  layout?: Layout;
  treatmentTypeSettings?: TreatmentTypeSetting[];
  droppableModuleItemListPrefix: string;
  draggableModuleItemPrefix: string;
  addableUiModules?: AvailableLayoutModule[];
  removeITPModuleItem?: ({
    moduleListIndex,
    pageIndex,
    newPageModule
  }: {
    moduleListIndex: NumberOrNothing;
    pageIndex: NumberOrNothing;
    newPageModule: PageModule;
  }) => void;
}

export const PageModuleList = ({
  isITP,
  isNewsletter,
  isMobileAppScreen,
  isPageHistorySection,
  itpPageType,
  pageModules,
  removableUiModuleTypes,
  onPageModuleListChange,
  disableAltSumm,
  pageIndex,
  layout,
  treatmentTypeSettings,
  droppableModuleItemListPrefix,
  draggableModuleItemPrefix,
  addableUiModules,
  removeITPModuleItem
}: PageModuleListProps) => {
  const { alertError } = useAlert();
  const flattenedModulesForDisplay = getAllFlattenedModulesForDisplay(pageModules);

  const handleRemoveModule = (moduleHierarchyId: string) => {
    const newPageModules = cloneDeep(pageModules);
    const moduleListIndex = getModuleListAndIndexFromModuleHierarchyId(newPageModules, moduleHierarchyId);
    if (!moduleListIndex) {
      alertError('Could not remove module. Please report this bug.');
      return;
    }

    moduleListIndex.moduleList.splice(moduleListIndex.index, 1);
    onPageModuleListChange?.(newPageModules);
  };

  const handlePageModuleChange = (moduleHierarchyId: string, newPageModule: PageModule) => {
    const newPageModules = cloneDeep(pageModules);
    const moduleListIndex = getModuleListAndIndexFromModuleHierarchyId(newPageModules, moduleHierarchyId);
    if (!moduleListIndex) {
      alertError('Could not edit module. Please report this bug.');
      return;
    }

    moduleListIndex.moduleList[moduleListIndex.index] = newPageModule;
    onPageModuleListChange?.(newPageModules);
  };

  const getRemoveModuleItemFunc = ({ isITP, moduleHierarchyId }: { isITP?: boolean; moduleHierarchyId: string }) => {
    const newPageModules = cloneDeep(pageModules);
    const moduleListIndex = getModuleListAndIndexFromModuleHierarchyId(newPageModules, moduleHierarchyId);

    if (isITP && removeITPModuleItem) {
      return (newPageModule: PageModule) =>
        removeITPModuleItem({ moduleListIndex: moduleListIndex?.index, pageIndex, newPageModule });
    }
    const onRemoveFunc = onPageModuleListChange
      ? (newPageModule: PageModule) => handlePageModuleChange(moduleHierarchyId, newPageModule)
      : null;
    return onRemoveFunc;
  };

  return (
    <div data-testid="page-modules-list-container">
      {flattenedModulesForDisplay.map(
        ({ module, moduleHierarchyId, isChildModule, parentModuleIndex, hasValidationError }, index) => {
          const isAvailableLayoutModuleType = (currentPageModule: PageModule) =>
            layout?.availableLayoutModules.map((m) => m.uiModuleType).includes(currentPageModule.uiModuleType) ?? false;
          const isDraggable = (pageModuleForDisplay: PageModuleForDisplay) =>
            onPageModuleListChange != null &&
            !pageModuleForDisplay.isChildModule &&
            !!isAvailableLayoutModuleType(pageModuleForDisplay.module);
          const showAddModuleButton = (bool: boolean, newModuleIndex: number) =>
            bool && (
              <AddModuleButton
                addableUiModules={addableUiModules}
                pageModules={pageModules}
                newModuleIndex={newModuleIndex}
                itpPageType={itpPageType}
                isITP={isITP}
                isBlockDivider
              />
            );

          const currentModule: PageModuleForDisplay = flattenedModulesForDisplay[index];
          const previousModule: PageModuleForDisplay | undefined =
            index - 1 < 0 ? undefined : flattenedModulesForDisplay[index - 1];
          const nextModule: PageModuleForDisplay | undefined =
            index + 1 > flattenedModulesForDisplay.length ? undefined : flattenedModulesForDisplay[index + 1];

          const isCurrentDraggable: boolean = isDraggable(currentModule);
          const isPreviousDraggable: boolean = !!previousModule && isDraggable(previousModule);
          const isNextDraggable: boolean = !!nextModule && isDraggable(nextModule);

          const showAddModuleButtonAbove = !isITP && isCurrentDraggable && !isPreviousDraggable;
          const showAddModuleButtonBelow = isCurrentDraggable;
          const padding =
            (!isCurrentDraggable && !isNextDraggable) || !addableUiModules?.length
              ? { bottom: 'mlg' }
              : { bottom: 'xs' };
          const item = (
            <div data-testid="page-module-list-static" key={moduleHierarchyId} className={styles.moduleItemInfo}>
              {showAddModuleButton(showAddModuleButtonAbove, parentModuleIndex)}
              <PageModuleItemList
                isITP={isITP}
                isNewsletter={isNewsletter}
                isMobileAppScreen={isMobileAppScreen}
                isPageHistorySection={isPageHistorySection}
                disableAltSumm={disableAltSumm}
                numberOfModulesInPage={flattenedModulesForDisplay.length}
                draggableModuleItemPrefix={`${draggableModuleItemPrefix}${index}-`}
                moduleItemsDroppableId={`${droppableModuleItemListPrefix}${moduleHierarchyId}`}
                onPageModuleChange={getRemoveModuleItemFunc({
                  isITP,
                  moduleHierarchyId
                })}
                onRemoveModule={
                  removableUiModuleTypes?.includes(module.uiModuleType)
                    ? () => handleRemoveModule(moduleHierarchyId)
                    : null
                }
                pageModule={module}
                treatmentTypeSettings={treatmentTypeSettings}
                padding={padding}
                moduleHierarchyId={moduleHierarchyId}
                moduleError={hasValidationError}
              />
              {showAddModuleButton(showAddModuleButtonBelow, parentModuleIndex + 1)}
            </div>
          );

          // only allow reordering for top level modules and module types that can be added/removed
          if (
            isITP ||
            onPageModuleListChange == null || // don't allow reordering if page module list can't be changed
            isChildModule ||
            !isAvailableLayoutModuleType(module)
          ) {
            return item;
          }

          return (
            <Draggable
              key={moduleHierarchyId}
              draggableId={`${DRAGGABLE_PREFIXES.MODULE}${moduleHierarchyId}`}
              index={parentModuleIndex}
            >
              {(draggableProvided) => (
                <div
                  data-testid="page-module-list-draggable"
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  className={styles.moduleContainer}
                >
                  {item}
                  <Wrapper
                    padding={{ all: 'sm' }}
                    {...draggableProvided.dragHandleProps}
                    data-testid="page-module-list-drag"
                  >
                    <div className={styles.moduleIconContainer}>
                      <IconArrowUp color="asphalt" />
                      <IconHandle color="asphalt" />
                      <IconArrowDown color="asphalt" />
                    </div>
                  </Wrapper>
                </div>
              )}
            </Draggable>
          );
        }
      )}
    </div>
  );
};
