import { useState } from 'react';
import { Button, Group, IconTrash } from '@screentone/core';

import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { Collection } from 'data/generated/graphql';

interface CollectionPublishingButtonsProps {
  hasCollectionChanged: boolean;
  collection: Collection | null;
  originalCollectionWhenInEditMode: Collection | null;
  isUpdating: boolean;
  isScheduling: boolean;
  isDeletingScheduled: boolean;
  onPublish: () => Promise<void>;
  onSchedule: (publishUtc: number) => Promise<void>;
  onDeleteScheduled: () => Promise<void>;
  onHideHistoryEditMode: () => void;
}

export const OffPlatformCollectionPublishingButtons = ({
  hasCollectionChanged,
  collection,
  originalCollectionWhenInEditMode,
  isUpdating,
  isScheduling,
  isDeletingScheduled,
  onPublish,
  onSchedule,
  onDeleteScheduled,
  onHideHistoryEditMode
}: CollectionPublishingButtonsProps) => {
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const toggleScheduleDialog = () => setShowScheduleModal((prev) => !prev);

  const handleSchedule = async (publishUtc: number) => {
    await onSchedule(publishUtc);
    setShowScheduleModal(false);
  };

  const handleDeleteScheduled = async () => {
    await onDeleteScheduled();
  };

  const handlePublish = async () => {
    if (originalCollectionWhenInEditMode) {
      await onSchedule(collection!.publishUtc!);
    } else {
      await onPublish();
    }
  };

  const isPublishing = isUpdating || isScheduling;
  const isCollectionChangedOrPublishing = isPublishing || !hasCollectionChanged;

  return (
    <>
      {showScheduleModal && (
        <ScheduleDialog dialogTitle="Schedule module" onSchedule={handleSchedule} onCancel={toggleScheduleDialog} />
      )}
      <Group align="center" gap="md">
        <ExternalLink
          contentId={collection?.allessehCollectionId ?? ''}
          type={ExternalLinkContentType.COLLECTION}
          isPublished={!!collection?.updatedUtc}
        />
        {originalCollectionWhenInEditMode && (
          <Button
            tertiary
            disabled={isDeletingScheduled || isPublishing}
            onClick={handleDeleteScheduled}
            margin={{ right: 'sm' }}
            color="lava"
            icon={IconTrash as SvgComponent}
            data-testid="off-platform-delete-scheduled-button"
          >
            Delete Scheduled publish
          </Button>
        )}
        {originalCollectionWhenInEditMode && (
          <Button
            secondary
            onClick={onHideHistoryEditMode}
            disabled={isPublishing}
            margin={{ right: 'sm' }}
            data-testid="off-platform-cancel-edit-button"
          >
            Cancel Edit
          </Button>
        )}
        <Button
          secondary
          onClick={toggleScheduleDialog}
          disabled={isCollectionChangedOrPublishing}
          data-testid="off-platform-schedule-button"
        >
          {originalCollectionWhenInEditMode ? 'Reschedule' : 'Schedule'}
        </Button>
        <Button
          primary
          onClick={handlePublish}
          disabled={isCollectionChangedOrPublishing}
          data-testid="off-platform-publish-button"
        >
          {originalCollectionWhenInEditMode ? 'Save' : 'Publish'}
        </Button>
      </Group>
    </>
  );
};
