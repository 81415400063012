import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Group, Token, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { CollectionDto } from 'data/generated/graphql';
import styles from './CollectionCard.module.scss';

const SMALL_WIDTH_THRESHOLD = 420;

interface CollectionCardProps {
  collection: CollectionDto;
  containerWidth: number;
  index: number;
  renderCollectionCardWrapper?: (
    collectionCard: ReactElement,
    collection: CollectionDto,
    index: number
  ) => ReactElement;
}

export const CollectionCard = ({
  collection,
  containerWidth,
  index,
  renderCollectionCardWrapper
}: CollectionCardProps) => {
  const { currentProperty } = useAuth();
  const navigate = useNavigate();

  const isSmall = containerWidth <= SMALL_WIDTH_THRESHOLD;
  const itemsLength = collection.root.collection.filter((item) => item.type === 'Article').length;

  const handleCollectionClick = (idCreatedUtc: string) => () => {
    navigate(`/${currentProperty ?? ''}/collections/${idCreatedUtc}`);
  };

  const getCollectionTypeLabel = (collection: CollectionDto) => {
    const hasArticles = !!collection.root.collection.filter((item) => item.type === 'Article').length;
    const hasQuery = !!collection.root.collection.filter((item) => item.type === 'Query').length;

    if (hasArticles && hasQuery) {
      return 'Curated + Query';
    }

    if (hasArticles) {
      return 'Curated';
    }

    if (hasQuery) {
      return 'Query';
    }

    return 'No Items';
  };

  const collectionCard = (
    <Box
      padding={{ all: 'sm' }}
      onClick={handleCollectionClick(collection.idCreatedUtc)}
      className={classnames(styles.collectionItem, styles.collectionItemClickable)}
      data-testid="collection-card-container"
    >
      {!isSmall ? (
        <>
          <Group align="space-between" gap="none" data-testid="collection-card-info">
            <Typography data-testid="collection-card-title">{collection.metadata.name}</Typography>
            <Wrapper data-testid="collection-card-status">
              {collection.metadata.updatedUtc && (
                <Typography variant="note" inline data-testid="collection-card-status-date">
                  Last published {`${format(collection.metadata.updatedUtc, 'MMM dd, yyyy')}`}
                </Typography>
              )}
              {collection.metadata.isArchived && (
                <Token color="gray" margin={{ left: 'sm' }} data-testid="collection-card-status-archived">
                  Archived
                </Token>
              )}
            </Wrapper>
          </Group>
          <Group align="space-between" gap="none" margin={{ top: 'sm' }} data-testid="collection-card-details">
            <Typography variant="note" data-testid="collection-card-allesseh-id">
              {collection.metadata.allessehId || <div>&nbsp;</div>}
            </Typography>
            <Wrapper>
              {itemsLength > 0 && (
                <Typography variant="note" margin={{ right: 'sm' }} inline data-testid="collection-card-item-count">
                  {itemsLength} {itemsLength > 1 ? 'items' : 'item'}
                </Typography>
              )}
              <Token color="blurple" data-testid="collection-card-content-type">
                {getCollectionTypeLabel(collection)}
              </Token>
            </Wrapper>
          </Group>
        </>
      ) : (
        <>
          <Typography data-testid="collection-card-title-sm">{collection.metadata.name}</Typography>
          <Typography variant="note" data-testid="collection-card-allesseh-id-sm">
            {collection.metadata.allessehId || ''}
          </Typography>
          <Group align="space-between" data-testid="collection-card-info-sm">
            <Wrapper>
              {collection.metadata.updatedUtc && (
                <Typography variant="note" inline margin={{ right: 'sm' }} data-testid="collection-card-status-date-sm">
                  {`${format(collection.metadata.updatedUtc, 'MMM dd, yyyy')}`}
                </Typography>
              )}
              <Token color="blurple" data-testid="collection-card-content-type-sm">
                {getCollectionTypeLabel(collection)}
              </Token>
            </Wrapper>
          </Group>
          <Group align="space-between" data-testid="collection-card-details-sm">
            <Wrapper>
              {itemsLength > 0 && (
                <Typography variant="note" margin={{ right: 'sm' }} inline data-testid="collection-card-item-count-sm">
                  {itemsLength} {itemsLength > 1 ? 'items' : 'item'}
                </Typography>
              )}
              {collection.metadata.isArchived && (
                <Token color="gray" margin={{ left: 'sm' }} data-testid="collection-card-status-archived-sm">
                  Archived
                </Token>
              )}
            </Wrapper>
          </Group>
        </>
      )}
    </Box>
  );

  if (renderCollectionCardWrapper) {
    return renderCollectionCardWrapper(collectionCard, collection, index);
  }
  return collectionCard;
};
