import { useCallback, useEffect } from 'react';
import { Button, IconCode2, Wrapper } from '@screentone/core';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { QueryItem } from 'data/generated/graphql';
import { QueryModal } from 'features/section-page-edit/components/page-draft-section/components/query-modal/QueryModal';

interface EditQueryProps {
  hierarchyId: string;
  query: QueryItem;
  numItemsToShow: number;
  baseQuery: string;
  viewOnly?: boolean;
}

export const EditQuery = ({ hierarchyId, query, numItemsToShow, baseQuery, viewOnly }: EditQueryProps) => {
  const { modifyEntity } = useDataModelContext();
  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();

  const allessehJsonQuery = JSON.stringify(query.attributes.query);

  const handleChangeJsonQueryStr = useCallback(
    (newJsonQueryStr: string) => {
      modifyEntity(hierarchyId, (entity) => {
        const query = entity as QueryItem;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query.attributes.query = JSON.parse(newJsonQueryStr);
      });
    },
    [hierarchyId, modifyEntity]
  );

  const handleEditQuery = () => {
    setIsModalOpen(true);

    setActionModalComponent(
      <QueryModal
        queryItemHierarchyId={hierarchyId}
        allessehJsonQuery={allessehJsonQuery}
        excludePageContentIdsQuery=""
        onDismiss={() => setIsModalOpen(false)}
        onChangeJsonQueryStr={handleChangeJsonQueryStr}
        count={numItemsToShow}
        baseQuery={baseQuery}
        viewOnly={viewOnly}
      />
    );
  };

  useEffect(() => {
    setActionModalComponent(
      <QueryModal
        queryItemHierarchyId={hierarchyId}
        allessehJsonQuery={allessehJsonQuery}
        excludePageContentIdsQuery=""
        onDismiss={() => setIsModalOpen(false)}
        onChangeJsonQueryStr={handleChangeJsonQueryStr}
        count={numItemsToShow}
        baseQuery={baseQuery}
        viewOnly={viewOnly}
      />
    );
  }, [
    allessehJsonQuery,
    baseQuery,
    handleChangeJsonQueryStr,
    hierarchyId,
    numItemsToShow,
    setActionModalComponent,
    setIsModalOpen,
    viewOnly
  ]);

  return (
    <Wrapper>
      <Button tertiary icon={IconCode2 as SvgComponent} onClick={handleEditQuery}>
        {viewOnly ? 'View' : 'Edit'} query
      </Button>
    </Wrapper>
  );
};
