import { useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { NewsletterProvider } from 'contexts/newsletter/NewsletterContext';
import { useNewsletterByIdQuery } from 'data/generated/graphql';
import { ReadOnlyNewsletter } from 'features/newsletters/ReadOnlyNewsletter';
import { useConvertedProperty } from 'hooks';

const SavedNewsletterPage = () => {
  const { campaignId } = useParams();
  const currentProperty = useConvertedProperty();

  const { data, isLoading, isError } = useNewsletterByIdQuery({
    id: campaignId ?? '',
    publicationKey: currentProperty ?? ''
  });

  if (!(campaignId && currentProperty)) return <></>;
  if (isError) return <ErrorPage type="500" />;

  const campaign = { status: 'Sent', ...data?.newsletterById };

  return (
    <PageRootContainer>
      <NewsletterProvider currentProperty={currentProperty}>
        {isLoading && <ResponsiveLoader />}
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        {!isLoading && <ReadOnlyNewsletter campaign={campaign} />}
      </NewsletterProvider>
    </PageRootContainer>
  );
};

export default SavedNewsletterPage;
