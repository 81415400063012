import { PageWrapper } from '@screentone/core';
import classnames from 'classnames';

import styles from './WidePageWrapper.module.scss';

export const WidePageWrapper = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
  margin?: any;
  padding?: any;
}) => (
  <PageWrapper {...props} className={classnames(styles.widePageWrapper, className)}>
    {children}
  </PageWrapper>
);
