import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button, IconPlus, Tabs as TabsComponent, Wrapper } from '@screentone/core';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import { SearchParams } from 'components/search-content/SearchContent';
import { Collection, CollectionContentItem, PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes } from 'hooks/useAllessehContentQuery';
import { DRAGGABLE_PREFIXES } from 'utils/collectionDragUtils';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import styles from '../../OffPlatformEdit.module.scss';
import OffPlatformCollectionContentCard from '../off-platform-collection-content-card/OffPlatformCollectionContentCard';
import { OffPlatformCollectionCurateSection } from '../off-platform-collection-curate-section/OffPlatformCollectionCurateSection';
import { OffPlatformCollectionPublishedRevision } from '../off-platform-collection-published-revision/OffPlatformCollectionPublishedRevision';
import { OffPlatformCollectionSettingsSection } from '../off-platform-collection-settings-section/OffPlatformCollectionSettingsSection';

interface OffPlatformCollectionMultitabProps {
  collection: Collection | null;
  originalCollection: Collection | null;
  originalCollectionWhenInEditMode: Collection | null;
  hasCollectionRecentlyUpdated: boolean;
  handleCopyToCollection: (contentItems: CollectionContentItem[]) => void;
  handleAddContent: (content: AllessehContent) => void;
  handlePartialCollectionChange: (newCollection: Partial<Collection>) => void;
  handleShowHistoryEditMode: (historyCollection: Collection) => void;
}

enum Tabs {
  ContentSearch,
  PublishHistory,
  Settings
}

export const OffPlatformCollectionMultitabSection = ({
  collection,
  originalCollection,
  originalCollectionWhenInEditMode,
  hasCollectionRecentlyUpdated,
  handleCopyToCollection,
  handleAddContent,
  handlePartialCollectionChange,
  handleShowHistoryEditMode
}: OffPlatformCollectionMultitabProps) => {
  const [tabIndex, setTabIndex] = useState(Tabs.ContentSearch);
  const { data: publicationSettingsResp } = usePublicationSettings();
  // search
  const collectionContentIds = useMemo(() => collection?.contentItems.map((c) => c.originId) ?? [], [collection]);
  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: collectionContentIds
    }),
    [collectionContentIds, publicationSettingsResp]
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: AllessehQuerySortKeyTypes.LiveDate
  });

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (publicationSettingsResp) {
      newSearchParams.filters.contentTypes = publicationSettingsResp.publicationSetting.defaultContentTypes;
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationSettingsResp]);

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (
      newSearchParams.filters.contentTypes.length > 0 && // ensure contentTypes list has loaded and has at least 1 item
      !isEqual(newSearchParams.filters.excludedContentIds, collectionContentIds)
    ) {
      newSearchParams.filters.excludedContentIds = collectionContentIds;
      setSearchParams(newSearchParams);
    }
  }, [collectionContentIds, searchParams]);

  const handleSearchParamsChange = (newSearchParams: SearchParams) => {
    setSearchParams(newSearchParams);
  };

  return (
    <Wrapper>
      <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="off-platform-content-search-tab"
        >
          Content Search
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === Tabs.PublishHistory ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          data-testid="off-platform-publish-history"
        >
          Publish History
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
        >
          Settings
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.ContentSearch && (
        <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
          <OffPlatformCollectionCurateSection
            searchProps={{
              defaultFilterState,
              searchParams,
              resultListClassName: styles.curateScrollable,
              onSearchParamsChange: handleSearchParamsChange,
              renderContentCard: (content: AllessehContent, index: number) => (
                <Draggable
                  draggableId={`${DRAGGABLE_PREFIXES.RESULTS}${JSON.stringify({
                    originId: content.data.id,
                    originContentType: content.data.type,
                    content: JSON.stringify(content)
                  } as CollectionContentItem)}`}
                  index={index}
                >
                  {(draggableProvided, snapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <OffPlatformCollectionContentCard
                        content={content}
                        isDragging={snapshot.isDragging}
                        isDraft
                        isSearch
                        renderActions={() => (
                          <Button
                            tertiary
                            icon={IconPlus as SvgComponent}
                            onClick={() => handleAddContent(content)}
                            data-testid="off-platform-add-to-collection"
                          >
                            Add to Collection
                          </Button>
                        )}
                      />
                    </div>
                  )}
                </Draggable>
              )
            }}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.PublishHistory && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <OffPlatformCollectionPublishedRevision
            collection={originalCollection!}
            currentlyEditedCollection={collection}
            editingScheduledRevision={!!originalCollectionWhenInEditMode}
            hasCollectionRecentlyUpdated={hasCollectionRecentlyUpdated}
            onCopyToNew={handleCopyToCollection}
            onEdit={handleShowHistoryEditMode}
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.Settings && (
        <Wrapper id="tabpanel-id2" role="tabpanel" aria-labelledby="tab-id2" padding={{ top: 'sm' }}>
          <OffPlatformCollectionSettingsSection
            collection={collection}
            handlePartialCollectionChange={handlePartialCollectionChange}
            editingScheduledRevision={!!originalCollectionWhenInEditMode}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};
