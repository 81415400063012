import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { Collections } from 'features/collections/Collections';

const CollectionsPage = () => (
  <>
    <PageRootContainer>
      <NarrowPageWrapper>
        <Collections />
      </NarrowPageWrapper>
    </PageRootContainer>
  </>
);

export default CollectionsPage;
