import { Checkbox, Dropdown, FormLabel, Group, IconSliders } from '@screentone/core';

import ContentTypeIcon from 'components/content-type-icon/ContentTypeIcon';
import { PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { getContentLabel } from 'utils/contentType';
import styles from './ContentTypeFilter.module.scss';

export type ContentTypeFilterType = PublicationSettingSearchableContentType;

interface ContentTypeFilterProps {
  values: ContentTypeFilterType[];
  isLoading: boolean;
  isActive: boolean;
  publishStatusValues: ContentTypeFilterType[];
  handleChange: (e: { target: { value: ContentTypeFilterType; checked: boolean } }) => void;
}

function getIconType(contentType: PublicationSettingSearchableContentType) {
  const ICON_TYPES: Record<string, string> = {
    [PublicationSettingSearchableContentType.LiveCoverageCard]: 'livecoverage',
    [PublicationSettingSearchableContentType.LiveCoverageStack]: 'livecoverage'
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return ICON_TYPES[contentType] ?? contentType;
}

export const ContentTypeFilter = ({
  values,
  isLoading,
  isActive,
  publishStatusValues,
  handleChange
}: ContentTypeFilterProps) => (
  <Dropdown
    data-testid="content-type-filter-button"
    trigger={
      <Group
        data-testid="content-type-filter-button-label"
        gap="xs"
        margin={{ right: 'sm' }}
        className={isActive ? styles.active : ''}
      >
        <IconSliders data-testid="content-type-filter-button-icon" /> Type
      </Group>
    }
    disabled={isLoading}
  >
    {publishStatusValues.map((publishStatusValue) => (
      <FormLabel
        data-testid="content-type-filter-item"
        className={styles.dropDown}
        key={publishStatusValue}
        label={
          <div data-testid="content-type-filter-label" className={styles.label}>
            <ContentTypeIcon
              data-testid="content-type-filter-label-icon"
              type={getIconType(publishStatusValue)}
              margin={{ right: 'sm' }}
            />
            {getContentLabel(publishStatusValue)}
          </div>
        }
        labelPosition="right"
        wrap={false}
        fullWidth
      >
        <Checkbox
          data-testid="content-type-filter-item-checkbox"
          name="contentTypes"
          value={publishStatusValue}
          checked={values.includes(publishStatusValue)}
          onChange={handleChange}
          disabled={values.length === 1 && values.includes(publishStatusValue)}
        />
      </FormLabel>
    ))}
  </Dropdown>
);
