import { useState } from 'react';
import { Tabs as TabsComponent, Wrapper } from '@screentone/core';

import { ItpIssueWithPages, PageModule } from 'data/generated/graphql';
import { PageSearchSection } from 'features/page-edit/components/page-search-section/PageSearchSection';
import IssuePagesList from './components/IssuePagesList';
import IssueSettings from './components/IssueSettings';

enum Tabs {
  Sections,
  Settings,
  ContentSearch
}

interface IssueMultiTabSectionProps {
  issue?: ItpIssueWithPages;
  handlePartialIssueChange?: (issue: Partial<ItpIssueWithPages>) => void;
}

const IssueMultiTabSection = ({ issue, handlePartialIssueChange }: IssueMultiTabSectionProps) => {
  const [tabIndex, setTabIndex] = useState(Tabs.Sections);
  if (!handlePartialIssueChange) return <></>;

  const pageModules: PageModule[] = issue?.pages ? issue.pages.map((page) => page.pageModules).flat() : [];

  return (
    <>
      <Wrapper>
        <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
          <TabsComponent.Item
            role="tab"
            id="tab-id0"
            aria-selected={tabIndex === Tabs.Sections ? 'true' : 'false'}
            aria-controls="tabpanel-id0"
          >
            Sections
          </TabsComponent.Item>
          <TabsComponent.Item
            role="tab"
            id="tab-id1"
            aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
            aria-controls="tabpanel-id1"
            data-testid="itp-settings-tab"
          >
            Settings
          </TabsComponent.Item>
          <TabsComponent.Item
            role="tab"
            id="tab-id2"
            aria-selected={tabIndex === Tabs.ContentSearch ? 'true' : 'false'}
            aria-controls="tabpanel-id2"
          >
            Content Search
          </TabsComponent.Item>
        </TabsComponent>
        {tabIndex === Tabs.Sections && (
          <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
            <IssuePagesList issue={issue} handlePartialIssueChange={handlePartialIssueChange} />
          </Wrapper>
        )}
        {tabIndex === Tabs.Settings && (
          <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
            <IssueSettings />
          </Wrapper>
        )}
        {tabIndex === Tabs.ContentSearch && (
          <Wrapper id="tabpanel-id2" role="tabpanel" aria-labelledby="tab-id2" padding={{ top: 'sm' }}>
            <PageSearchSection pageModules={pageModules} showCollections={false} showFilters={{ status: false }} />
          </Wrapper>
        )}
      </Wrapper>
    </>
  );
};

export default IssueMultiTabSection;
