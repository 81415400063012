export const DROPPABLE_IDS = {
  MANUAL: 'MANUAL',
  PUBLISHED_REVISION: 'PUBLISHED_REVISION',
  TRASH: 'TRASH',
  RESULTS: 'RESULTS',
  QUERY_SORTS: 'QUERY_SORTS'
};

// these are used to differentiate card items in different lists
export const DRAGGABLE_PREFIXES = {
  MANUAL: 'MANUAL_',
  PUBLISHED_REVISION: 'PUBLISHED_REVISION_',
  TRASH: 'TRASH_',
  RESULTS: 'RESULTS_',
  QUERY_SORTS: 'QUERY_SORTS_'
};
