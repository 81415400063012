import { useEffect, useState } from 'react';
import { Button, Dialog, Divider, Wrapper } from '@screentone/core';

import { PageModule, TreatmentTypeSetting, UiModuleType } from 'data/generated/graphql';
import { PageModuleBasic } from './components/page-module-basic/PageModuleBasic';
import { PageModuleRanked } from './components/page-module-ranked/PageModuleRanked';
import { PageModuleShared } from './components/page-module-shared/PageModuleShared';
import { PageModuleText } from './components/page-module-text/PageModuleText';
import { PageModuleTitle } from './components/page-module-title/PageModuleTitle';
import { PageModuleTwoColumn } from './components/page-module-two-column/PageModuleTwoColumn';
import styles from './PageModuleModal.module.scss';
import { getModuleFieldKey, getSubsetTreatmentTypeSettings } from '../../PageModuleUtils';

interface PageModuleModalProps {
  onChange: ((newPageModule: PageModule) => void) | null;
  onDismiss: () => void;
  pageModule: PageModule;
  treatmentTypeSettings?: TreatmentTypeSetting[];
  isITP?: boolean;
  isMobileAppScreen?: boolean;
}

interface PageModuleModalFormProps {
  pageModule: PageModule;
  onChange: ((newPageModule: PageModule) => void) | null;
  treatmentTypeSettings?: TreatmentTypeSetting[];
  isITP?: boolean;
  isMobileAppScreen?: boolean;
}

const PageModuleModalForm = ({
  pageModule,
  onChange,
  treatmentTypeSettings,
  isITP,
  isMobileAppScreen
}: PageModuleModalFormProps) => {
  const { uiModuleType } = pageModule;
  let childModule = null;

  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
      childModule = <PageModuleBasic />;
      break;
    case UiModuleType.UiRankedModuleType:
      childModule = <PageModuleRanked pageModule={pageModule} onChange={onChange} />;
      break;
    case UiModuleType.UiTitleModuleType:
      childModule = (
        <PageModuleTitle pageModule={pageModule} onChange={onChange} isMobileAppScreen={isMobileAppScreen} />
      );
      break;
    case UiModuleType.UiTextModuleType:
      childModule = <PageModuleText pageModule={pageModule} onChange={onChange} />;
      break;
    case UiModuleType.UiBasicOptionalModuleType:
      childModule = <PageModuleBasic />;
      break;
    default:
      childModule = <PageModuleTwoColumn />;
  }

  return (
    <PageModuleShared
      pageModule={pageModule}
      moduleFieldKey={getModuleFieldKey(uiModuleType)}
      subsetTreatmentTypeSettings={getSubsetTreatmentTypeSettings(uiModuleType, treatmentTypeSettings)}
      onChange={onChange}
      showCurrentTreatmentTypeAtTheTop={isITP}
    >
      {childModule}
      <PageModuleBasic />
    </PageModuleShared>
  );
};

export const PageModuleModal = ({
  pageModule,
  onChange,
  onDismiss,
  treatmentTypeSettings,
  isITP,
  isMobileAppScreen
}: PageModuleModalProps) => {
  const canEdit = !!onChange;
  const [curPageModule, setCurPageModule] = useState(pageModule);

  useEffect(() => {
    setCurPageModule(pageModule);
  }, [pageModule]);

  const handleChange = (newModule: PageModule) => {
    setCurPageModule(newModule);
  };

  const handleSave = () => {
    onChange?.(curPageModule);
    onDismiss();
  };

  return (
    <Dialog data-testid="page-module-modal-container" status="open" onDismiss={onDismiss} focusTrap={false}>
      <Dialog.Title data-testid="page-module-modal-title">{canEdit ? 'Edit' : 'View'} Module</Dialog.Title>
      <Dialog.Content data-testid="page-module-modal-content">
        <PageModuleModalForm
          pageModule={curPageModule}
          onChange={canEdit ? handleChange : null}
          treatmentTypeSettings={treatmentTypeSettings}
          isITP={isITP}
          isMobileAppScreen={isMobileAppScreen}
        />
        <Divider className={styles.divider} />
        {canEdit && (
          <Wrapper data-testid="page-module-modal-buttons" className={styles.buttons}>
            <Button secondary onClick={onDismiss} margin={{ right: 'sm' }} data-testid="page-treatment-type-cancel">
              Cancel
            </Button>
            <Button primary onClick={handleSave} data-testid="page-treatment-type-save">
              Save
            </Button>
          </Wrapper>
        )}
      </Dialog.Content>
    </Dialog>
  );
};
