import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { Pages } from 'features/pages/Pages';
import { useRequirePublisher } from 'hooks/roles';

const PagesPage = () => {
  useRequirePublisher();

  return (
    <>
      <PageRootContainer>
        <NarrowPageWrapper>
          <Pages />
        </NarrowPageWrapper>
      </PageRootContainer>
    </>
  );
};

export default PagesPage;
