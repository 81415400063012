import { useState } from 'react';
import { Tabs, Typography, Wrapper } from '@screentone/core';

import { ShowFilterOptions } from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { PageModule } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { PageCollectionSearch } from './components/page-collection-search/PageCollectionSearch';
import { PageContentSearch } from './components/page-content-search/PageContentSearch';
import pageEditStyles from '../../PageEdit.module.scss';

export const PageSearchSection = ({
  pageModules,
  showCollections = true,
  disableAltSumm,
  renderActions,
  showFilters,
  newsletterName
}: {
  pageModules: PageModule[];
  showCollections?: boolean;
  disableAltSumm?: boolean;
  renderActions?: (content: AllessehContent) => void;
  showFilters?: ShowFilterOptions;
  newsletterName?: string;
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!showCollections) {
    return (
      <PageContentSearch
        disableAltSumm={disableAltSumm}
        pageModules={pageModules}
        resultListClassName={pageEditStyles.searchResultScrollableWithoutCollections}
        renderActions={renderActions}
        showFilters={showFilters}
        newsletterName={newsletterName}
      />
    );
  }

  return (
    <Wrapper>
      <Typography data-testid="page-search-section-title" margin={{ bottom: 'md' }} variant="header3">
        Content
      </Typography>
      <Tabs role="tablist" onChange={setTabIndex} value={tabIndex}>
        <Tabs.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === 0 ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          data-testid="page-content-items-tab"
        >
          Items
        </Tabs.Item>
        <Tabs.Item
          role="tab"
          id="tab-id1"
          aria-selected={tabIndex === 1 ? 'true' : 'false'}
          aria-controls="tabpanel-id1"
          data-testid="page-content-collections-tab"
        >
          Collections
        </Tabs.Item>
      </Tabs>
      {tabIndex === 0 && (
        <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
          <PageContentSearch
            disableAltSumm={disableAltSumm}
            pageModules={pageModules}
            resultListClassName={pageEditStyles.searchResultScrollable}
            renderActions={renderActions}
            showFilters={showFilters}
          />
        </Wrapper>
      )}
      {tabIndex === 1 && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <PageCollectionSearch pageModules={pageModules} resultListClassName={pageEditStyles.searchResultScrollable} />
        </Wrapper>
      )}
    </Wrapper>
  );
};
