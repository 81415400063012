import { AppConfig, AuthRouter, OktaAuthService } from '@screentone/addon-auth-wrapper';

import env from 'utils/env';
import HeaderBarWrapper from './HeaderBar';
import packageJson from '../../../../package.json';

export const enum OKTA_ENV {
  LOCAL = 'local',
  DEV = 'dev',
  INT = 'int',
  PRD = 'prd'
}

const appConfig: AppConfig = {
  name: 'Curation',
  key: 'curation',
  env: env.OKTA_ENV as OKTA_ENV,
  requestAccess: {
    defaultProperties: ['wsj'],
    url: 'https://hooks.slack.com/services/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',
    options: {
      mode: 'no-cors'
    }
  },
  // TODO: access the API version as well (needs to be sent from backend)
  versions: [{ name: 'Tool', version: packageJson.version }]
};

const authConfig: OktaAuthService = {
  issuer: env.OKTA_ISSUER,
  clientId: env.OKTA_CLIENT_ID,
  redirectUri: env.OKTA_REDIRECT_URI
};

const OktaRouter: FCC = ({ children }) => (
  <AuthRouter app={appConfig} authConfig={authConfig} headerBarWrapper={HeaderBarWrapper}>
    {children}
  </AuthRouter>
);

export default OktaRouter;
