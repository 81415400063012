import { useEffect, useMemo } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  IconMinusCircle,
  IconThreeDotsVer,
  List,
  Typography,
  Wrapper
} from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { PageModuleItemAllessehCollection } from 'data/generated/graphql';
import { addModuleItem } from 'features/page-edit/components/page-draft-section/PageDraftSectionUtils';
import { useAllessehCollectionQuery } from 'hooks/useAllessehCollectionQuery';
import styles from './AllessehCollectionModuleItem.module.scss';

interface AllessehCollectionModuleItemProps {
  allessehCollectionItem: PageModuleItemAllessehCollection;
  onRemove: (() => void) | null;
  availableModuleItemSlots: number | null;
  onNumItemsLoaded: (numItems: number) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  moduleHierarchyId: string;
  index: number;
  getDuplicateClassName?: (id: string) => string;
  isDraft?: boolean;
}

export const AllessehCollectionModuleItem = ({
  allessehCollectionItem,
  onRemove,
  availableModuleItemSlots,
  onNumItemsLoaded,
  dragHandleProps,
  moduleHierarchyId,
  index,
  getDuplicateClassName,
  isDraft
}: AllessehCollectionModuleItemProps) => {
  const {
    data,
    isLoading: isLoad,
    fetchStatus
  } = useAllessehCollectionQuery(allessehCollectionItem.allessehCollectionId, {
    enabled: !!allessehCollectionItem.allessehCollectionId
  });
  const isLoading = isLoad && fetchStatus !== 'idle';
  const { setAllContentIds, hasRecentlyPublished, page } = usePagePublish();
  const contentItems = useMemo(
    () => data?.data?.attributes.collection.slice(0, availableModuleItemSlots ?? undefined) ?? [],
    [data, availableModuleItemSlots]
  );

  useEffect(() => {
    onNumItemsLoaded(contentItems.length);
  }, [contentItems, onNumItemsLoaded]);

  useEffect(() => {
    if (isDraft) {
      setAllContentIds((prevState) =>
        addModuleItem(
          prevState,
          { moduleHierarchyId, index },
          contentItems.map((item) => item.id)
        )
      );
    }
  }, [
    contentItems,
    data,
    index,
    isDraft,
    moduleHierarchyId,
    setAllContentIds,
    hasRecentlyPublished,
    page?.pageModules
  ]);

  return (
    <Box className={styles.cardContainer} {...dragHandleProps}>
      <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader}>
        <Typography size="md" weight="bold" data-testid="page-allesseh-id-style">
          Allesseh Collection {allessehCollectionItem.allessehCollectionId}
        </Typography>
        <Dropdown
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
          data-testid="allesseh-ellipsis-icon"
        >
          {onRemove && (
            <>
              <Wrapper padding={{ all: 'md' }}>
                <Button
                  tertiary
                  icon={IconMinusCircle as SvgComponent}
                  color="lava"
                  onClick={onRemove}
                  data-testid="allesseh-remove-from-module"
                >
                  Remove from module
                </Button>
              </Wrapper>
              <Divider />
            </>
          )}
        </Dropdown>
      </Wrapper>
      {isLoading && <ResponsiveLoader />}
      {!isLoading && (
        <Wrapper padding={{ right: 'sm', left: 'xs', bottom: 'sm' }} data-testid="allesseh-id-list">
          {contentItems.length === 0 && <Typography size="sm">No items</Typography>}
          {contentItems.length > 0 && (
            <List listStyle="bullet">
              {contentItems.map((content, index) => (
                <List.Item
                  key={index}
                  className={getDuplicateClassName?.(content.id)}
                  data-testid="allesseh-id-bullet-style"
                >
                  <Typography variant="bodytext" className={styles.headline}>
                    {content.id}
                  </Typography>
                </List.Item>
              ))}
            </List>
          )}
        </Wrapper>
      )}
    </Box>
  );
};
