import { ReactElement } from 'react';
import '@screentone/addon-calendar/css/index.min.css';
import Calendar from '@screentone/addon-calendar';
import { Button, Dropdown, Group, IconCalendar, useDropdownState, Wrapper } from '@screentone/core';
import { format } from 'date-fns';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';

import styles from './FilterDate.module.scss';

export interface FilterDateProps {
  startDate: Date | null;
  endDate: Date | null;
  label: string;
  onChange: (a?: number | null, b?: number | null) => void;
  onSelect: ({ startDate, endDate }: { endDate?: Date | null; startDate?: Date | null }) => void;
  handleDateFilterClear: () => void;
}

export const FilterDate = ({
  startDate,
  endDate,
  label,
  onSelect,
  onChange,
  handleDateFilterClear
}: FilterDateProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isDropdownOpen,
    setOpen: setIsDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();

  const handleDropdownOpenToggle = () => setIsDropdownOpen(!isDropdownOpen);

  const handleDateFilterClick = (offsetDaysToStart: number, offsetDaysToEnd: number) => () => {
    const startOfDayDate = startOfDay(new Date());
    const start = addDays(startOfDayDate, offsetDaysToStart);
    const end = addDays(start, offsetDaysToEnd);
    onChange(start.getTime(), end.getTime());
    onSelect({ startDate: start, endDate: end });
    setIsDropdownOpen(false);
  };

  const handleSelect = () => {
    onChange(startDate?.getTime(), endDate?.getTime());
    setIsDropdownOpen(false);
  };

  const handleClear = () => {
    setIsDropdownOpen(false);
    handleDateFilterClear();
  };

  const isDisabledSelectBtn = !(startDate && endDate);

  return (
    <Dropdown
      data-testid="filter-date-button"
      open={isDropdownOpen || false}
      componentRef={dropdownRef}
      onToggle={handleDropdownOpenToggle}
      position="right"
      trigger={
        startDate ?? endDate ? (
          <Group data-testid="filter-date-label-range" gap="xs" className={styles.active}>
            <IconCalendar data-testid="filter-date-label-range-icon" /> {startDate ? format(startDate, 'MM/dd') : ''} -{' '}
            {endDate ? format(endDate, 'MM/dd') : ''}
          </Group>
        ) : (
          <Group data-testid="filter-date-label" gap="xs">
            <IconCalendar data-testid="filter-date-label-icon" /> {label}
          </Group>
        )
      }
      margin={{ right: 'sm' }}
    >
      <div data-testid="filter-date-container" className={styles.dateFilterContent}>
        <Wrapper data-testid="filter-date-ranges" margin={{ bottom: 'sm' }} className={styles.dateButtons}>
          <Button
            data-testid="filter-date-thirty-days"
            tertiary
            margin={{ top: 'sm' }}
            onClick={handleDateFilterClick(-30, 30)}
          >
            Last 30 Days
          </Button>
          <Button
            data-testid="filter-date-last-week"
            tertiary
            margin={{ top: 'sm', left: 'sm' }}
            onClick={handleDateFilterClick(-7, 7)}
          >
            Last Week
          </Button>
          <Button
            data-testid="filter-date-today"
            tertiary
            margin={{ top: 'sm', left: 'sm' }}
            onClick={handleDateFilterClick(0, 1)}
          >
            Today
          </Button>
        </Wrapper>
        <Calendar
          startDate={startDate}
          endDate={endDate}
          dateRangeStart
          dateRangeEnd
          onSelect={onSelect}
          numberOfMonths={2}
          showCalendarOnly
          dateFormat="MM/dd/yyyy"
        />
        <Wrapper
          data-testid="filter-date-buttons"
          className={styles.dateFilterActions}
          margin={{ top: 'sm', bottom: 'sm' }}
        >
          <Button data-testid="filter-date-clear-button" secondary onClick={handleClear}>
            Clear
          </Button>
          <Button
            data-testid="filter-date-select-button"
            primary
            margin={{ left: 'sm' }}
            onClick={handleSelect}
            disabled={isDisabledSelectBtn}
          >
            Select
          </Button>
        </Wrapper>
      </div>
    </Dropdown>
  );
};
