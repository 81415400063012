import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Button, IconCode2, Wrapper } from '@screentone/core';

import { ExternalCollectionItem } from 'data/generated/graphql';
import { usePrompt } from 'hooks/useBeforeLeavePage';

interface EditCollectionProps {
  collection: ExternalCollectionItem;
}

export const EditCollection = ({ collection }: EditCollectionProps) => {
  const { currentProperty } = useAuth();
  const navigate = useNavigate();

  usePrompt('You are navigating away from the page. Are you sure you want to leave?');

  const handleGoToCollection = () => {
    const idCreatedUtc = `${collection.metadata.id}_${collection.metadata.createdUtc}`;
    navigate(`/${currentProperty ?? ''}/collections/${idCreatedUtc}`);
  };

  return (
    <Wrapper>
      <Button tertiary icon={IconCode2 as SvgComponent} onClick={handleGoToCollection}>
        Edit collection
      </Button>
    </Wrapper>
  );
};
