import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { OffPlatformEdit } from 'features/off-platform-edit/OffPlatformEdit';

const OffPlatformEditPage = () => (
  <SummarianProvider>
    <TrashProvider>
      <OffPlatformEdit />
    </TrashProvider>
  </SummarianProvider>
);

export default OffPlatformEditPage;
