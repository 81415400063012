import { useNavigate } from 'react-router-dom';
import { Box, Token, Typography } from '@screentone/core';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

import { ResponsiveLoader } from 'components';
import { InfiniteScrollWaypoint } from 'components/infinite-scroll-waypoint/InfiniteScrollWaypoint';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import {
  GetScheduledContentByPublicationKeyQuery,
  Newsletter,
  NewslettersQuery,
  PublicationSetting,
  ScheduledContent
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { formatInTimeZone } from 'utils/dates';
import styles from '../../NewsletterEdit.module.scss';

export const NewslettersList = ({
  newsletters,
  publicationSetting,
  nextPageProps,
  scheduledCampaigns
}: {
  newsletters?: NewslettersQuery[];
  scheduledCampaigns?: GetScheduledContentByPublicationKeyQuery[];
  publicationSetting: PublicationSetting | undefined;
  nextPageProps: {
    fetchNextPage: (
      options?: FetchNextPageOptions | undefined
    ) =>
      | Promise<InfiniteQueryObserverResult<NewslettersQuery>>
      | Promise<InfiniteQueryObserverResult<GetScheduledContentByPublicationKeyQuery>>;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
}) => {
  const campaigns: Newsletter[] | ScheduledContent[] = newsletters
    ? newsletters.reduce((acc: Newsletter[], curr: NewslettersQuery) => {
        const campaignsToAdd = curr.newsletters.edges.map((newsletterEdge) => {
          const { node: newsletter } = newsletterEdge;
          return newsletter;
        });
        return acc.concat(campaignsToAdd as Newsletter[]);
      }, [])
    : (scheduledCampaigns ?? []).reduce((acc: ScheduledContent[], curr: GetScheduledContentByPublicationKeyQuery) => {
        const campaignsToAdd = curr.scheduledContentByPublicationKey.edges.map((newsletterEdge) => {
          const { node } = newsletterEdge;
          return node;
        });
        return acc.concat(campaignsToAdd as ScheduledContent[]);
      }, []);

  const navigate = useNavigate();
  const currentProperty = useConvertedProperty();

  const handleCampaignClick = (campaignId: string) => {
    navigate(`/${currentProperty}/newsletters/${campaignId}`);
  };

  const handleScheduledCampaignClick = (campaignId: string, publishUtc: string) =>
    navigate(`/${currentProperty}/newsletters/scheduled/${campaignId}/${publishUtc}`);

  if (campaigns.length < 1) {
    return <NoSearchResults title="Campaigns" bodyText="Try creating a new one." />;
  }

  return (
    <div>
      {campaigns.map((campaign: Newsletter | ScheduledContent) => (
        <Box
          className={styles.newsletterListBox}
          padding={{ all: 'md' }}
          margin={{ top: 'sm', bottom: 'sm' }}
          key={
            newsletters
              ? (campaign as Newsletter).id
              : (campaign as ScheduledContent).allessehId + (campaign as ScheduledContent).publishUtc
          }
          onClick={() =>
            newsletters
              ? handleCampaignClick((campaign as Newsletter).id)
              : handleScheduledCampaignClick(
                  (campaign as ScheduledContent).allessehId,
                  `${(campaign as ScheduledContent).publishUtc}`
                )
          }
        >
          <div className={styles.newsletterListText}>
            <Typography inline weight="med">
              {newsletters ? (campaign as Newsletter).name : ((campaign as ScheduledContent).body as Newsletter).name}
            </Typography>
            <div>
              {campaign.updatedUtc && (
                <>
                  <Typography inline variant="note">
                    {formatInTimeZone(campaign.updatedUtc, publicationSetting?.preferredTimezone, 'MMMM dd, yyyy', {
                      showTimezoneAbbreviation: false
                    })}{' '}
                    at{' '}
                    {formatInTimeZone(campaign.updatedUtc, publicationSetting?.preferredTimezone, 'h:mm aaaa', {
                      showTimezoneAbbreviation: true
                    })}
                  </Typography>
                  <Token color="emerald" margin={{ left: 'sm' }}>
                    Sent
                  </Token>
                </>
              )}

              {campaign.publishUtc && (
                <>
                  <Typography inline variant="note">
                    {formatInTimeZone(campaign.publishUtc, publicationSetting?.preferredTimezone, 'MMMM dd, yyyy', {
                      showTimezoneAbbreviation: false
                    })}{' '}
                    at{' '}
                    {formatInTimeZone(campaign.publishUtc, publicationSetting?.preferredTimezone, 'h:mm aaaa', {
                      showTimezoneAbbreviation: true
                    })}
                  </Typography>
                  <Token color="blurple" margin={{ left: 'sm' }}>
                    Scheduled
                  </Token>
                </>
              )}
            </div>
          </div>
          <Typography size="md" margin={{ top: 'sm' }}>
            {newsletters
              ? (campaign as Newsletter).parameters.subject
              : ((campaign as ScheduledContent).body as Newsletter).parameters.subject}
          </Typography>
        </Box>
      ))}
      <InfiniteScrollWaypoint nextPageProps={nextPageProps} />
      {nextPageProps.isFetchingNextPage && <ResponsiveLoader />}
    </div>
  );
};
