import { ReactElement } from 'react';
import Calendar, { calendarHooks } from '@screentone/addon-calendar';
import {
  Button,
  Divider,
  Dropdown,
  Group,
  IconCalendar,
  Typography,
  useDropdownState,
  Wrapper
} from '@screentone/core';

import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { RevisionList } from './components/revision-list/RevisionList';
import styles from './RevisionSelector.module.scss';

interface DateGroup {
  title: string;
  type: 'published' | 'scheduled';
  dates: number[] | undefined;
}

interface RevisionSelectorProps {
  onReset: () => void;
  onSelectDate: (date: number) => void;
  onSelectRevision: (publishUtc?: number) => void;
  dateGroups: DateGroup[];
  selectedDate?: number | null;
  disabled?: boolean;
  areRevisionsLoading?: boolean;
}

export const RevisionSelector = ({
  onReset,
  onSelectDate,
  onSelectRevision,
  dateGroups,
  selectedDate,
  disabled,
  areRevisionsLoading
}: RevisionSelectorProps) => {
  const { selectedDate: selectedDateFromCalendarHook, onSelect } = calendarHooks.useDate();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    open: isDropdownOpen,
    setOpen: setIsDropdownOpen,
    componentRef: dropdownRef
  }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();

  const handleDropdownOpenToggle = () => setIsDropdownOpen(!isDropdownOpen);
  const handleDateChange = (date: Date) => {
    onSelect(date);
    onSelectDate(date.getTime());
  };

  const handleGoToToday = () => {
    onSelect(new Date());
    onReset();
  };

  const handleSelectRevision = (publishUtc?: number) => {
    onSelectRevision(publishUtc);
    setIsDropdownOpen(false);
  };

  const hasGroupsWithDate = dateGroups.some((group) => group.dates && group.dates.length > 0);

  return (
    <Dropdown
      open={isDropdownOpen || false}
      componentRef={dropdownRef}
      onToggle={handleDropdownOpenToggle}
      position="right"
      disabled={disabled}
      trigger={
        <Group gap="xs">
          <IconCalendar /> Go To Date
        </Group>
      }
      data-testid="page-history-date-button"
    >
      <Wrapper className={styles.dateFilterWrapper}>
        <Wrapper>
          <Calendar
            onSelect={handleDateChange}
            numberOfMonths={1}
            showCalendarOnly
            dateFormat="MM/dd/yyyy"
            selectedDate={selectedDateFromCalendarHook}
          />
          <Wrapper className={styles.dateFilterActions} margin={{ top: 'sm', bottom: 'sm' }}>
            <Button secondary margin={{ left: 'sm' }} onClick={handleGoToToday}>
              Go to Today
            </Button>
          </Wrapper>
        </Wrapper>
        <Wrapper margin={{ vertical: 'md' }} className={styles.publishedPages}>
          {hasGroupsWithDate ? (
            dateGroups.map((dateGroup, idx) => (
              <Wrapper key={dateGroup.title}>
                {dateGroup.dates && (
                  <>
                    <RevisionList
                      title={dateGroup.title}
                      type={dateGroup.type}
                      onSelectRevision={handleSelectRevision}
                      selectedDate={selectedDate}
                      dates={dateGroup.dates}
                    />
                    {idx < dateGroups.length - 1 && <Divider margin={{ all: 'md' }} />}
                  </>
                )}
              </Wrapper>
            ))
          ) : (
            <>
              {areRevisionsLoading ? (
                <ResponsiveLoader />
              ) : (
                <Wrapper margin={{ horizontal: 'md' }}>
                  <Typography size="sm" data-testid="page-no-history-alert">
                    This date has no published or scheduled revisions.
                  </Typography>
                </Wrapper>
              )}
            </>
          )}
        </Wrapper>
      </Wrapper>
    </Dropdown>
  );
};
