import { useState } from 'react';
import { Button, IconPlus, Tabs as TabsComponent, Wrapper } from '@screentone/core';

import { Newsletter, PublicationSetting } from 'data/generated/graphql';
import { PageSearchSection } from 'features/page-edit/components/page-search-section/PageSearchSection';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import styles from '../../NewsletterEdit.module.scss';
import { NewsletterSettings } from '../newsletter-settings/NewsletterSettings';

enum Tabs {
  Articles,
  Settings
}

interface NewsletterMultitabSectionProps {
  campaign: Newsletter;
  readOnly?: boolean;
  disableAltSumm?: boolean;
  newsletterName?: string;
  isScheduled?: boolean;
  handleAddContentToNewsletter?: (content: AllessehContent) => void;
  cancelCampaign?: () => void;
  publicationSetting?: PublicationSetting;
}

export const NewsletterMultitabSection = ({
  campaign,
  disableAltSumm,
  readOnly,
  isScheduled = false,
  newsletterName,
  handleAddContentToNewsletter,
  cancelCampaign,
  publicationSetting
}: NewsletterMultitabSectionProps) => {
  const [tabIndex, setTabIndex] = useState(readOnly ? Tabs.Settings : Tabs.Articles);

  return (
    <Wrapper>
      <TabsComponent role="tablist" onChange={setTabIndex} value={tabIndex} margin={{ bottom: 'md' }}>
        <TabsComponent.Item
          role="tab"
          id="tab-id0"
          aria-selected={tabIndex === Tabs.Articles ? 'true' : 'false'}
          aria-controls="tabpanel-id0"
          disabled={readOnly}
          className={readOnly ? styles.disabledTab : ''}
        >
          Articles
        </TabsComponent.Item>
        <TabsComponent.Item
          role="tab"
          id="tab-id2"
          aria-selected={tabIndex === Tabs.Settings ? 'true' : 'false'}
          aria-controls="tabpanel-id2"
          disabled={!(readOnly && campaign)}
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          className={!(readOnly && campaign) ? styles.disabledTab : ''}
        >
          Settings
        </TabsComponent.Item>
      </TabsComponent>
      {tabIndex === Tabs.Articles && !readOnly && (
        <Wrapper id="tabpanel-id0" role="tabpanel" aria-labelledby="tab-id0" padding={{ top: 'sm' }}>
          <PageSearchSection
            pageModules={campaign.pageModules}
            showCollections={false}
            disableAltSumm={disableAltSumm}
            newsletterName={newsletterName}
            renderActions={
              handleAddContentToNewsletter
                ? (content: AllessehContent) => (
                    <Button
                      tertiary
                      icon={IconPlus as SvgComponent}
                      onClick={() => handleAddContentToNewsletter(content)}
                    >
                      Add to Campaign
                    </Button>
                  )
                : () => {}
            }
          />
        </Wrapper>
      )}
      {tabIndex === Tabs.Settings && (
        <Wrapper id="tabpanel-id1" role="tabpanel" aria-labelledby="tab-id1" padding={{ top: 'sm' }}>
          <NewsletterSettings
            readOnly={readOnly}
            isScheduled={isScheduled}
            campaign={campaign}
            cancelCampaign={cancelCampaign}
            publicationSetting={publicationSetting}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
};
