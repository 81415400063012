import { useCallback, useState } from 'react';
import { Box, Group, Input, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';

import { SortDropdown } from 'components';
import { CollectionDtoSortType, CollectionDtoStatusType, CollectionsDtoInput } from 'data/generated/graphql';
import styles from './CollectionsSearchBar.module.scss';
import { FilterStatus } from '../filter-status/FilterStatus';

export enum FILTER_TYPES {
  SORT = 'SORT',
  STATUS = 'STATUS'
}

export interface Filter {
  filterType: FILTER_TYPES;
  label: string;
}

export const DEBOUNCE_TIMEOUT = 500;

const getFilter = (filters: Filter[], type: FILTER_TYPES) => filters.find(({ filterType }) => filterType === type);

interface CollectionsSearchBarProps {
  collectionsInput: CollectionsDtoInput;
  filters: Filter[];
  onPartialCollectionsInputChange: (s: Partial<CollectionsDtoInput>) => void;
}

export const CollectionsSearchBar = ({
  collectionsInput,
  filters,
  onPartialCollectionsInputChange
}: CollectionsSearchBarProps) => {
  const [searchInput, setSearchInput] = useState(collectionsInput.searchText);

  const debouncedSearch = debounce((newSearchText: string) => {
    onPartialCollectionsInputChange({ searchText: newSearchText });
  }, DEBOUNCE_TIMEOUT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchTextChange = useCallback(debouncedSearch, []);

  const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
    handleSearchTextChange(e.target.value);
  };

  const handlePublishStatusChange = (status: CollectionDtoStatusType | null) => {
    onPartialCollectionsInputChange({ status });
  };
  const handleSortChange = (newSort: CollectionDtoSortType) => {
    const newCollectionInputPartial: Partial<CollectionsDtoInput> = { sortType: newSort };
    onPartialCollectionsInputChange(newCollectionInputPartial);
  };

  const currentSortType = collectionsInput.sortType ?? CollectionDtoSortType.CreateDate;

  const createFilter = (filter: Filter) => {
    switch (filter.filterType) {
      case FILTER_TYPES.SORT:
        return (
          <SortDropdown
            key={filter.filterType}
            sortEnum={CollectionDtoSortType}
            value={currentSortType}
            onChange={handleSortChange}
          />
        );
      case FILTER_TYPES.STATUS:
        return (
          <FilterStatus
            key={filter.filterType}
            label={filter.label}
            value={collectionsInput.status}
            onChange={handlePublishStatusChange}
          />
        );

      default:
        return null;
    }
  };

  const statusFilter = getFilter(filters, FILTER_TYPES.STATUS);
  const sortFilter = getFilter(filters, FILTER_TYPES.SORT);

  const statusFilterJsx = statusFilter ? createFilter(statusFilter) : null;
  const sortFilterJsx = sortFilter ? createFilter(sortFilter) : null;

  return (
    <Wrapper padding={{ top: 'sm', bottom: 'md' }} data-testid="collections-search-bar-container">
      <Box padding={{ all: 'sm' }} className={styles.collectionsSearchBar} data-testid="collections-search-bar-filter">
        <div className={styles.collectionsSearchInput}>
          <Input
            type="text"
            placeholder="Search by name or collection ID"
            value={searchInput ?? ''}
            onChange={handleSearchInputChange}
            margin={{ right: 'sm' }}
            data-testid="collections-search-bar-input"
          />
        </div>
        {sortFilterJsx}
      </Box>
      {statusFilterJsx && (
        <Group margin={{ top: 'sm' }} gap="md" data-testid="collections-search-bar-status">
          {statusFilterJsx}
        </Group>
      )}
    </Wrapper>
  );
};
