import {
  IconArticle,
  IconInteractive,
  IconMic,
  IconPackage,
  IconShoppingCart,
  IconVideo,
  IconWsjopinion,
  IconZap
} from '@screentone/core';

import { PublicationSettingSearchableContentType } from 'data/generated/graphql';

const ContentTypeIcon = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (props.type as PublicationSettingSearchableContentType | string) {
    case PublicationSettingSearchableContentType.CommerceProduct.toLowerCase():
    case PublicationSettingSearchableContentType.CommerceProduct:
      return <IconShoppingCart {...props} />;
    case PublicationSettingSearchableContentType.Article.toLowerCase():
    case PublicationSettingSearchableContentType.Article:
      return <IconArticle {...props} />;
    case PublicationSettingSearchableContentType.Audio.toLowerCase():
    case PublicationSettingSearchableContentType.Audio:
      return <IconMic {...props} />;
    case PublicationSettingSearchableContentType.Video.toLowerCase():
    case PublicationSettingSearchableContentType.Video:
      return <IconVideo {...props} />;
    case PublicationSettingSearchableContentType.LiveCoverage.toLowerCase():
    case PublicationSettingSearchableContentType.LiveCoverage:
      return <IconZap {...props} />;
    case PublicationSettingSearchableContentType.Tappable.toLowerCase():
    case PublicationSettingSearchableContentType.Tappable:
    case PublicationSettingSearchableContentType.Webstory.toLowerCase():
    case PublicationSettingSearchableContentType.Webstory:
      return <IconInteractive {...props} />;
    // linked items are not a searchable content type
    case 'linkeditem':
      return <IconPackage {...props} />;
    case PublicationSettingSearchableContentType.Opinion.toLowerCase():
    case PublicationSettingSearchableContentType.Opinion:
      return <IconWsjopinion {...props} />;
    default:
      return <IconArticle {...props} />;
  }
};

export default ContentTypeIcon;
