import { ReactElement, useEffect, useState } from 'react';
import { Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { CollectionDto, CollectionsDtoInput, useInfiniteCollectionsDtoQuery } from 'data/generated/graphql';
import styles from './CollectionSearch.module.scss';
import { CollectionsList } from './components/collections-list/CollectionsList';
import { CollectionsSearchBar, Filter } from './components/collections-search-bar/CollectionsSearchBar';
import { useConvertedProperty } from '../../hooks';

interface CollectionSearchProps {
  emptyListMessage?: string;
  filters?: Filter[];
  initialCollectionsInput?: Partial<CollectionsDtoInput>;
  renderCollectionCardWrapper?: (
    collectionCard: ReactElement,
    collection: CollectionDto,
    index: number
  ) => ReactElement;
  resultListClassName?: string;
  excludedCollectionIdCreatedUtcs?: string[];
  droppableId?: string;
  /** render this jsx before rendering list of content */
  beforeContent?: React.ReactNode;
}

const PER_PAGE = 10;

export const CollectionSearch = ({
  emptyListMessage,
  filters,
  initialCollectionsInput,
  renderCollectionCardWrapper,
  resultListClassName,
  excludedCollectionIdCreatedUtcs,
  droppableId,
  beforeContent = null
}: CollectionSearchProps) => {
  const currentProperty = useConvertedProperty();
  const [collectionsInput, setCollectionsInput] = useState<CollectionsDtoInput>({
    publicationKey: '',
    ...initialCollectionsInput
  });
  const { hasRecentlyCreatedCollection } = usePagePublish();

  useEffect(() => {
    if (!currentProperty) {
      return;
    }

    setCollectionsInput((collectionsInput) => ({ ...collectionsInput, publicationKey: currentProperty }));
  }, [currentProperty]);

  const enabled = !!collectionsInput.publicationKey && collectionsInput.publicationKey.length > 0;
  const {
    data,
    isLoading: isLoad,
    fetchStatus,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteCollectionsDtoQuery(
    {
      collectionsDTOInput: {
        ...collectionsInput,
        ...(excludedCollectionIdCreatedUtcs ? { excludedCollectionIdCreatedUtcs } : {})
      },
      paginationParams: { first: PER_PAGE, after: null }
    },
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (!lastPage.collectionsDTO.pageInfo.hasNextPage) return false;
        return {
          paginationParams: {
            first: PER_PAGE,
            after: lastPage.collectionsDTO.pageInfo.endCursor
          }
        };
      }
    }
  );
  const isLoading = isLoad && fetchStatus !== 'idle';

  useEffect(() => {
    async function refetchCollectionData() {
      if (hasRecentlyCreatedCollection) {
        await refetch();
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetchCollectionData();
  }, [hasRecentlyCreatedCollection, refetch]);

  const handlePartialCollectionsInputChange = (newSearchParams: Partial<CollectionsDtoInput>) => {
    setCollectionsInput((collectionsInput) => ({ ...collectionsInput, ...newSearchParams }));
  };

  const nextPageProps = { fetchNextPage, hasNextPage, isFetchingNextPage };

  return (
    <>
      {filters && (
        <CollectionsSearchBar
          collectionsInput={collectionsInput}
          onPartialCollectionsInputChange={handlePartialCollectionsInputChange}
          filters={filters}
        />
      )}
      <Wrapper className={styles.collectionsListWrapper} data-testid="collection-search-container">
        {beforeContent}
        {isLoading ? (
          <ResponsiveLoader />
        ) : (
          <>
            <CollectionsList
              className={resultListClassName}
              collectionPages={data?.pages ?? []}
              emptyListMessage={emptyListMessage}
              renderCollectionCardWrapper={renderCollectionCardWrapper}
              droppableId={droppableId}
              nextPageProps={nextPageProps}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};
