export const prettifyGraphQLBadRequestError = (messages: string[] | string): string => {
  if (typeof messages === 'string') {
    return messages;
  }
  const detailedErrors = messages
    .map((m: string) => {
      const matches = m.match(/[\w+\d+.](?:.*[.])/gi);
      if (matches && matches.length === 1) {
        const resultStr = m.replace(matches[0], '');
        return resultStr.charAt(0).toUpperCase() + resultStr.slice(1);
      }
      return m;
    })
    .filter((x: string) => !!x);

  return detailedErrors.join('; ');
};
