import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Alert, IconInfo, Responsive, Subnav } from '@screentone/core';
import classnames from 'classnames';

import { useAlert } from 'contexts/alert/useAlert';
import { useIssue } from 'contexts/issue/useIssue';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { useIsPublisher } from 'hooks/roles';
import styles from './UcsSubnav.module.scss';

export const UcsSubnav = () => {
  const { currentProperty } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { alertState, removeAlert } = useAlert();
  const { clearValidationErrorsInModules } = usePagePublish();
  const { clearErrorState: clearIssueErrorState } = useIssue();

  const handleSubnavClick = (path: string) => () => {
    navigate(`/${currentProperty ?? ''}${path}`);
  };

  const handleAlertDismiss = () => {
    clearValidationErrorsInModules();
    clearIssueErrorState();
    removeAlert();
  };
  const pagesAllowed = useIsPublisher();

  return (
    <>
      <Responsive sm lg className={styles.ucsSubnav}>
        <Subnav margin={{ bottom: 'none' }} data-testid="ucs-subnav-tabs">
          {pagesAllowed && (
            <Subnav.Item
              active={currentProperty ? [`/${currentProperty}/`, `/${currentProperty}`].includes(pathname) : false}
              onClick={handleSubnavClick('/')}
              data-testid="ucs-subnav-pages-tab"
            >
              Pages
            </Subnav.Item>
          )}
          <Subnav.Item
            active={currentProperty ? pathname === `/${currentProperty}/collections` : false}
            onClick={handleSubnavClick('/collections')}
            data-testid="ucs-subnav-collections-tab"
          >
            Collections
          </Subnav.Item>
          <Subnav.Item
            active={currentProperty ? pathname === `/${currentProperty}/off-platform` : false}
            onClick={handleSubnavClick('/off-platform')}
            data-testid="ucs-subnav-off-platform-tab"
          >
            Off-Platform
          </Subnav.Item>
          {pagesAllowed && currentProperty === 'wsj' && (
            <Subnav.Item
              active={pathname === `/${currentProperty}/issues`}
              onClick={handleSubnavClick('/issues')}
              data-testid="ucs-subnav-itp-issues-tab"
            >
              ITP Issues
            </Subnav.Item>
          )}
          {pagesAllowed && currentProperty === 'marketwatch' && (
            <Subnav.Item
              active={pathname === `/${currentProperty}/newsletters`}
              onClick={handleSubnavClick('/newsletters')}
              data-testid="ucs-subnav-news-letters-tab"
            >
              Newsletters
            </Subnav.Item>
          )}
        </Subnav>
      </Responsive>
      {alertState.message && (
        <Alert
          className={classnames(styles.alert, alertState.wrapNewlines ? styles.wrapText : '')}
          icon={IconInfo as SvgComponent}
          type={alertState.type}
          onDismiss={handleAlertDismiss}
          autoDismiss={alertState.autoDismiss}
          data-testid="ucs-subnav-alert"
        >
          {alertState.message}
        </Alert>
      )}
    </>
  );
};
