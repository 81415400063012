import { Dropdown, FormLabel, Group, IconSort, Radio, Wrapper } from '@screentone/core';

import styles from './SortDropdown.module.scss';

interface SortDropdownProps<T> {
  sortEnum: T;
  value: T[keyof T];
  onChange: (newSort: T[keyof T]) => void;
  className?: string;
}

const getEnumNameLabel = (enumName: string) => enumName.replace(/[A-Z]/g, ' $&').trim();

export const SortDropdown = <T extends {}>({ sortEnum, value, onChange, className }: SortDropdownProps<T>) => {
  const handleChange = (e: { target: { value: keyof T } }) => {
    onChange(sortEnum[e.target.value]);
  };

  const sortEnumKeys = Object.keys(sortEnum).sort();
  const defaultSortKey = sortEnumKeys[0];
  const valueSortKey = sortEnumKeys.find((key) => sortEnum[key as keyof T] === value);
  const currentLabel = defaultSortKey !== valueSortKey && valueSortKey && getEnumNameLabel(valueSortKey);

  return (
    <Wrapper data-testid="sort-dropdown-container" className={styles.wrapper}>
      <Dropdown
        data-testid="sort-dropdown-button"
        position="right"
        trigger={
          <Group
            data-testid="sort-dropdown-button-label"
            gap="xs"
            margin={{ horizontal: 'xs' }}
            className={styles.group}
          >
            <IconSort data-testid="sort-dropdown-button-icon" /> {currentLabel && `${currentLabel}`}
          </Group>
        }
        className={className}
      >
        <div data-testid="sort-dropdown-list" className={styles.dropdown}>
          {Object.keys(sortEnum)
            .sort()
            .map((key) => (
              <FormLabel
                data-testid="sort-dropdown-option"
                key={key}
                label={getEnumNameLabel(key)}
                labelPosition="right"
                fullWidth
                style={{ whiteSpace: 'nowrap' }}
              >
                <Radio
                  data-testid="sort-dropdown-option-radio"
                  name="sort"
                  value={key}
                  checked={value === sortEnum[key as keyof T]}
                  onChange={handleChange}
                />
              </FormLabel>
            ))}
        </div>
      </Dropdown>
    </Wrapper>
  );
};
