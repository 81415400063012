import { ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { CollectionSearch } from 'components/collection-search/CollectionSearch';
import { FILTER_TYPES } from 'components/collection-search/components/collections-search-bar/CollectionsSearchBar';
import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import {
  AllessehCollectionType,
  Collection,
  CollectionStatusType,
  PageModule,
  PageModuleItem,
  PageModuleItemType
} from 'data/generated/graphql';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES } from 'features/page-edit/dragUtils';
import { getAllCollectionIds } from 'features/page-edit/pageEditUtils';

interface PageCollectionSearchProps {
  pageModules: PageModule[];
  resultListClassName?: string;
}

export const PageCollectionSearch = ({ pageModules, resultListClassName }: PageCollectionSearchProps) => {
  const pageCollectionIds = getAllCollectionIds(pageModules);

  return (
    <CollectionSearch
      filters={[{ filterType: FILTER_TYPES.SORT, label: 'Sort' }]}
      collectionType={AllessehCollectionType.CurationCollection}
      initialCollectionsInput={{
        // @ts-expect-error - Ignoring this TS error, as this component will eventually be removed on cleanup once all artifacts are migrated to new data model
        status: CollectionStatusType.Live
      }}
      droppableId={DROPPABLE_ID_PREFIXES.COLLECTION}
      excludedCollectionIdCreatedUtcs={pageCollectionIds}
      emptyListMessage="Try a different filter or add a new collection by going to the Collections subnav."
      resultListClassName={resultListClassName}
      beforeContent={<PageDraftTrash />}
      // @ts-expect-error - Ignoring this TS error, as this component will eventually be removed on cleanup once all artifacts are migrated to new data model
      renderCollectionCardWrapper={(collectionCard: ReactElement, collection: Collection, index: number) => (
        <Draggable
          draggableId={`${DRAGGABLE_PREFIXES.MODULE_ITEM}${JSON.stringify({
            itemType: PageModuleItemType.Collection,
            itemFields: {
              collectionItem: {
                idCreatedUtc: collection.idCreatedUtc,
                collection
              }
            }
          } as PageModuleItem)}`}
          index={index}
        >
          {(draggableProvided) => (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
            >
              {collectionCard}
            </div>
          )}
        </Draggable>
      )}
    />
  );
};
