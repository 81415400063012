import { useEffect, useMemo } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  Group,
  IconEdit,
  IconExternalLink,
  IconMinusCircle,
  IconThreeDotsVer,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';

import ContentTypeIcon from 'components/content-type-icon/ContentTypeIcon';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { useImage } from 'hooks/useImage';
import { extractSizeFromUrl, resizeIMImage } from 'utils/url';
import styles from './LinkItemCard.module.scss';

const IM_WIDTH = {
  THUMB: 40,
  ENLARGED: 180
};

interface PageContentCardProps {
  canEditLinkedItem?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  headline: string;
  media?: string | null;
  summary?: string | null;
  url: string;
  onRemove?: (() => void) | null;
  getDuplicateClassName?: (id: string) => string;
  isSearch?: boolean;
  isHighlighted?: boolean;
  onNumItemsLoaded?: () => void;
}

const LinkItemCard = ({
  canEditLinkedItem,
  dragHandleProps,
  headline,
  media,
  summary, // eslint-disable-line unused-imports/no-unused-vars
  url, // eslint-disable-line unused-imports/no-unused-vars
  onRemove,
  getDuplicateClassName,
  isSearch,
  isHighlighted,
  onNumItemsLoaded
}: PageContentCardProps) => {
  const { setIsEditingLinkedItem } = usePagePublish();

  useEffect(() => {
    if (onNumItemsLoaded) onNumItemsLoaded();
  }, [onNumItemsLoaded]);

  const className = classnames(styles.card, isHighlighted && styles.highlight, getDuplicateClassName?.(url) ?? '');

  const image = media ?? '';
  const { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage } = useImage();
  const imageUrl =
    useMemo(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      () => resizeIMImage(image, { width: IM_WIDTH.THUMB, size: 1 }),
      [image]
    ) ?? '';

  const imageUrlEnlarged =
    useMemo(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      () => resizeIMImage(image, { width: IM_WIDTH.ENLARGED }),
      [image]
    ) ?? '';
  const enlargedHeight = IM_WIDTH.ENLARGED / extractSizeFromUrl(imageUrlEnlarged);

  return (
    <Box
      data-testid="link-item-card-container"
      className={className}
      padding={{ all: 'sm' }}
      data-allesseh-id={`not-in-allesseh::${url}`}
      {...dragHandleProps}
    >
      <div data-testid="link-item-card-content" className={styles.cardLeft}>
        <div
          onMouseEnter={debouncedHandleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.imageContainer}
          data-testid="link-item-card-image"
        >
          {showLargerImage && imageUrl && (
            <div
              className={classnames(styles.imageEnlarged, isSearch ? '' : `${styles.left}`)}
              style={{
                backgroundImage: `url('${imageUrlEnlarged}')`,
                height: enlargedHeight
              }}
              data-testid="larger-image"
            />
          )}
          <div
            style={{ backgroundImage: `url('${imageUrl}')` }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            className={classnames(styles.image, imageUrl ? '' : styles.noImage)}
            data-testid="thumbnail"
          />
        </div>
        <Typography
          variant="bodytext"
          margin={{ left: 'sm' }}
          className={styles.headline}
          data-testid="link-item-card-headline-label"
        >
          <ContentTypeIcon
            type="link-item-card-edit-icon"
            size="mlg"
            margin={{ right: 'sm' }}
            className={styles.icon}
          />
          {headline}
        </Typography>
      </div>

      <Dropdown
        padding={{ all: 'none' }}
        position="right"
        trigger={<IconThreeDotsVer color="asphalt" />}
        data-testid="link-item-card-dropdown"
      >
        {onRemove && (
          <>
            <Wrapper padding={{ all: 'md' }}>
              <Button
                tertiary
                icon={IconMinusCircle as SvgComponent}
                color="lava"
                onClick={onRemove}
                data-testid="link-item-card-remove-button"
              >
                Remove from module
              </Button>
            </Wrapper>
            <Divider />
          </>
        )}
        <Group margin={{ all: 'md' }} gap="xs" data-testid="link-item-card-ellipsis-options">
          <Button
            data-testid="link-item-card-edit-button"
            disabled={!canEditLinkedItem}
            tertiary
            margin={{ top: 'sm' }}
            icon={IconEdit as SvgComponent}
            iconPosition="right"
            onClick={() => setIsEditingLinkedItem(url)}
          >
            Edit linked item
          </Button>
          <Button
            data-testid="link-item-card-open-link-button"
            tertiary
            icon={IconExternalLink as SvgComponent}
            iconPosition="right"
            onClick={() => window.open(url, '_blank')}
          >
            Open external link
          </Button>
        </Group>
      </Dropdown>
    </Box>
  );
};

export default LinkItemCard;
