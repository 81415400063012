import { useMemo } from 'react';
import { Button, IconExternalLink } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { buildNewsgridUrl } from 'utils/cms';

interface NewsgridButtonProps {
  content: AllessehContent;
}

export const NewsgridButton = ({ content }: NewsgridButtonProps) => {
  const newsgridUrl = useMemo(() => buildNewsgridUrl(content.data.id), [content.data.id]);

  const handleViewNewsgridUrl = () => {
    if (!newsgridUrl) return;
    window.open(newsgridUrl, '_blank');
  };
  return newsgridUrl ? (
    <Button
      data-testid="newsgrid-button-open-newsgrid-button"
      tertiary
      icon={IconExternalLink as SvgComponent}
      iconPosition="right"
      onClick={handleViewNewsgridUrl}
    >
      Open in Newsgrid
    </Button>
  ) : null;
};
