import { Typography, Wrapper } from '@screentone/core';

import OffPlatformSearch from 'components/off-platform-search/OffPlatformSearch';

export const OffPlatformCollections = () => (
  <Wrapper margin={{ top: 'lg' }}>
    <Typography variant="header2" margin={{ bottom: 'md' }}>
      Off-Platform
    </Typography>
    <OffPlatformSearch />
  </Wrapper>
);
