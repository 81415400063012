import { ErrorPage } from '@screentone/addon-auth-wrapper';
import { Box, Divider, Typography, Wrapper } from '@screentone/core';

import { WidePageWrapper } from 'components';
import { useAlert } from 'contexts/alert/useAlert';
import { Newsletter, NewsletterCreateInput, useCreatePreviewNewsletterMutation } from 'data/generated/graphql';
import { PageContentCard } from 'features/page-edit/components/page-content-card/PageContentCard';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import { NewsletterMultitabSection } from './components/newsletter-multitab-section/NewsletterMultitabSection';
import { PageTitleHeader } from './components/page-title-header/PageTitleHeader';
import { formatNewsletterPageModulesForPublish } from './NewsletterEdit';
import styles from './NewsletterEdit.module.scss';

export const ReadOnlyNewsletter = ({
  campaign,
  cancelCampaign,
  handleSendNowClick,
  rescheduleCampaign
}: {
  handleSendNowClick?: () => void;
  campaign?: Newsletter & { status: string };
  cancelCampaign?: () => void;
  rescheduleCampaign?: (publishUtc: number) => void;
}) => {
  const { data: publicationSettingResp } = usePublicationSettings();

  const contentJson = campaign?.pageModules[0].moduleItems[0]?.itemFields.contentItem?.content;
  const { mutateAsync: createPreviewNewsletterMutateAsync } = useCreatePreviewNewsletterMutation();
  const { alertSuccess } = useAlert();

  if (!contentJson) return <ErrorPage />;

  const content: AllessehContent = safelyParseContent(contentJson);

  const isScheduled = !!campaign.publishUtc && campaign.publishUtc > Date.now();

  const handleSendTestClick = async (emails: string) => {
    const { parameters, name, pageModules, publicationKey } = campaign;
    const formattedPageModules = formatNewsletterPageModulesForPublish(pageModules);
    const createPreviewInput = {
      id: `${campaign.id}_${new Date().getTime()}`,
      name,
      publicationKey,
      parameters: {
        ...parameters,
        recipients: emails
      },
      pageModules: formattedPageModules
    };

    try {
      await createPreviewNewsletterMutateAsync({
        newsletterCreateInput: createPreviewInput as NewsletterCreateInput
      });
      alertSuccess(
        `Preview campaign ${createPreviewInput.id} successfully published to Allesseh for recipient(s) ${emails
          .split(',')
          .join(', ')}.`
      );
    } catch (e: unknown) {
      console.error(e);
    }
  };

  return (
    <WidePageWrapper>
      <PageTitleHeader
        readOnly
        isScheduled={isScheduled}
        status={campaign.status}
        newsletterName={campaign.name}
        handleScheduleClick={rescheduleCampaign}
        handleSendNowClick={handleSendNowClick}
        handleSendTestClick={handleSendTestClick}
        publicationSetting={publicationSettingResp?.publicationSetting}
      />
      <Divider />
      <div className={styles.container}>
        <Wrapper className={styles.draftWrapper} margin={{ right: 'md' }} padding={{ top: 'mlg' }}>
          <Wrapper margin={{ all: 'md' }}>
            <PageContentCard content={content} />
          </Wrapper>
          <Box margin={{ all: 'md' }}>
            <Box.Title>Custom Content</Box.Title>
            <Box.Content>
              <Typography variant="label3">Sender Name</Typography>
              <Typography margin={{ bottom: 'md' }}>{campaign.parameters.sender_name}</Typography>
              <Typography variant="label3">Reply-To Email</Typography>
              <Typography margin={{ bottom: 'md' }}>{campaign.parameters.reply_to_email}</Typography>
              <Typography variant="label3">Subject Line</Typography>
              <Typography margin={{ bottom: 'md' }}>{campaign.parameters.subject}</Typography>
              <Typography variant="label3">Pre-Header Text</Typography>
              <Typography margin={{ bottom: 'md' }}>{campaign.parameters.pre_header_text}</Typography>
            </Box.Content>
          </Box>
        </Wrapper>
        <div className={styles.pageDivider} />
        <Wrapper className={styles.multitabWrapper} margin={{ left: 'md' }} padding={{ top: 'mlg' }}>
          <NewsletterMultitabSection
            readOnly
            isScheduled={isScheduled}
            campaign={campaign}
            cancelCampaign={cancelCampaign}
            publicationSetting={publicationSettingResp?.publicationSetting}
          />
        </Wrapper>
      </div>
    </WidePageWrapper>
  );
};
