import { useAuth } from '@screentone/addon-auth-wrapper';
import { Alert, Box, Group, IconError, Token, Tooltip, Typography, Wrapper } from '@screentone/core';
import { format } from 'date-fns';

import { ItpIssue, ItpIssueStatus } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { toTitleCase } from 'utils/case';
import { ITP_STATUS_COLOR } from 'utils/constants';
import { formatInTimeZone, itpIssueDateToJSDate } from 'utils/dates';
import { getItpCurrentStatus } from 'utils/itp';
import styles from './IssueList.module.scss';

interface IssueListProps {
  issues: (ItpIssue & { isNew?: boolean })[];
}

const MAX_ISSUES_TO_DISPLAY = 7;

export const IssueList = ({ issues }: IssueListProps) => {
  const { currentProperty } = useAuth();
  const { data: publicationSettings } = usePublicationSettings();

  const formatLastUpdatedDateTime = (updatedUtc: number) =>
    formatInTimeZone(updatedUtc, publicationSettings?.publicationSetting.preferredTimezone, 'MM/dd/yyyy hh:mm aa', {
      showTimezoneAbbreviation: true
    });

  return (
    <>
      {issues.slice(0, MAX_ISSUES_TO_DISPLAY).map((issue) => {
        const issueJSDate = itpIssueDateToJSDate(issue.issueDate);
        const cssClassName = issue.isNew ? `${styles.itpNewIssue} ${styles.issueBox}` : styles.issueBox;

        return (
          <Box
            key={issue.issueId}
            padding={{ all: 'md' }}
            margin={{ top: 'sm', bottom: 'sm' }}
            className={cssClassName}
            componentEl="a"
            href={`/${currentProperty as string}/issues/${format(issueJSDate, 'yyyyMMdd')}`}
            data-testid="itp-issues-list-items"
          >
            <Group align="space-between" valign="center" gap="none" fullWidth>
              <Typography variant="bodytext" margin={{ bottom: 'none' }} data-testid="itp-issues-date">
                {format(issueJSDate, 'MMMM dd, yyyy')}
              </Typography>
              <Group gap="sm" data-testid="itp-last-update-time">
                {issue.updatedUtc && (
                  <Typography variant="note">Last updated: {formatLastUpdatedDateTime(issue.updatedUtc)}</Typography>
                )}
                <Wrapper className={styles.tokenWrapper} data-testid="itp-issues-status-text">
                  {issue.status === ItpIssueStatus.Changed && (
                    <Tooltip>
                      <Tooltip.Content id="unpublished-changes-tooltip" data-testid="itp-unpublished-changes-text">
                        Unpublished changes
                      </Tooltip.Content>
                      <Tooltip.Trigger aria-describedby="unpublished-changes-tooltip">
                        <Alert
                          data-testid="itp-unpublished-changes-icon"
                          type="warning"
                          icon={IconError as SvgComponent}
                          inline
                          margin={{ right: 'sm' }}
                          className={styles.alertIcon}
                        />
                      </Tooltip.Trigger>
                    </Tooltip>
                  )}
                  <Token
                    color={ITP_STATUS_COLOR[getItpCurrentStatus(issue.status, issue.publishUtc)]}
                    data-testid="itp-issue-status-label"
                  >
                    {toTitleCase(getItpCurrentStatus(issue.status, issue.publishUtc))}
                  </Token>
                </Wrapper>
              </Group>
            </Group>
          </Box>
        );
      })}
    </>
  );
};
