import { useMemo } from 'react';
import { Button, IconExternalLink, Wrapper } from '@screentone/core';

import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { buildNewspressUrl } from 'utils/cms';

interface OpenInNewsPressProps {
  content: AllessehContent;
}

export const OpenInNewsPress = ({ content }: OpenInNewsPressProps) => {
  const newspressUrl = useMemo(
    () => buildNewspressUrl(content.data.id, content.data.attributes.type),
    [content.data.id, content.data.attributes.type]
  );

  const handleViewNewsPressUrl = () => {
    if (!newspressUrl) return;
    window.open(newspressUrl, '_blank');
  };
  return newspressUrl ? (
    <Wrapper>
      <Button
        data-testid="newspress-button-open-cms-button"
        tertiary
        icon={IconExternalLink as SvgComponent}
        iconPosition="right"
        onClick={handleViewNewsPressUrl}
      >
        Open in CMS
      </Button>
    </Wrapper>
  ) : null;
};
