import { ReactElement } from 'react';
import { Button, Dropdown, Group, IconCaretDown, Typography, useDropdownState } from '@screentone/core';

import { PageModule, TreatmentTypeSetting } from 'data/generated/graphql';
import styles from './PageModuleTreatmentDropdown.module.scss';
import {
  getModuleFieldKey,
  getSubsetTreatmentTypeSettings,
  getTreatmentType,
  useModuleTreatmentTypes
} from '../../PageModuleUtils';

interface PageModuleModalProps {
  onChange: ((newPageModule: PageModule) => void) | null;
  pageModule: PageModule;
  treatmentTypeSettings?: TreatmentTypeSetting[];
  showCurrentTreatmentTypeAtTheTop?: boolean;
}

export const PageModuleTreatmentDropdown = ({
  pageModule,
  onChange,
  treatmentTypeSettings = [],
  showCurrentTreatmentTypeAtTheTop
}: PageModuleModalProps) => {
  const canEdit = !!onChange;
  const moduleFieldKey = getModuleFieldKey(pageModule.uiModuleType);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { open, setOpen, componentRef }: { open: boolean; setOpen: (o: boolean) => void; componentRef: ReactElement } =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useDropdownState();

  const handleDropdownOpenToggle = () => setOpen(!open);

  const handleChange = (newModule: PageModule) => {
    onChange?.(newModule);
  };

  const { handleChangeTreatmentByValue, allowedTreatmentTypeSettings } = useModuleTreatmentTypes({
    moduleFieldKey,
    onChange: canEdit ? handleChange : null,
    pageModule,
    subsetTreatmentTypeSettings: getSubsetTreatmentTypeSettings(pageModule.uiModuleType, treatmentTypeSettings)
  });
  const treatmentType = getTreatmentType(pageModule);
  const treatmentTypeSetting = treatmentTypeSettings.filter(
    (t) => t.treatmentType === treatmentType || t.treatmentTypeKey === treatmentType
  )[0];

  if (showCurrentTreatmentTypeAtTheTop) {
    const currTreatmentTypeIndex = allowedTreatmentTypeSettings?.findIndex(
      (treatmentTypeSetting) =>
        (treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType) === treatmentType
    );

    allowedTreatmentTypeSettings?.unshift(allowedTreatmentTypeSettings[currTreatmentTypeIndex!]);
    allowedTreatmentTypeSettings?.splice(currTreatmentTypeIndex! + 1, 1);
  }

  return (
    <Dropdown
      data-testid="page-module-treatment-dropdown-container"
      disabled={allowedTreatmentTypeSettings?.length === 1 || !onChange}
      open={open}
      onToggle={handleDropdownOpenToggle}
      componentRef={componentRef}
    >
      <Dropdown.Trigger>
        <Group direction="row" gap="xs" className={`st_focusable ${styles.trigger}`} componentEl="button">
          <Typography variant="note">{treatmentTypeSetting.label}</Typography>
          <IconCaretDown color="asphalt" />
        </Group>
      </Dropdown.Trigger>
      <Dropdown.Content padding={{ all: 'sm' }}>
        <Group direction="column" gap="xs">
          {allowedTreatmentTypeSettings?.map((treatmentTypeSetting) => (
            <Button
              fullWidth
              tertiary
              key={treatmentTypeSetting.treatmentType}
              active={
                treatmentTypeSetting.treatmentTypeKey === treatmentType ||
                treatmentTypeSetting.treatmentType === treatmentType
              }
              disabled={
                treatmentTypeSetting.treatmentTypeKey === treatmentType ||
                treatmentTypeSetting.treatmentType === treatmentType
              }
              onClick={() => {
                setOpen(false);
                handleChangeTreatmentByValue(
                  treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType
                );
              }}
            >
              {treatmentTypeSetting.label}
            </Button>
          ))}
        </Group>
      </Dropdown.Content>
    </Dropdown>
  );
};
