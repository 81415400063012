import { Checkbox, Dropdown, FormLabel, Group, IconToday } from '@screentone/core';

import { getStatusLabel } from 'utils/contentType';
import styles from './StatusFilter.module.scss';

export type ContentStatusFilterType = 'PUBLISHED' | 'EMAIL_ONLY' | 'EMBARGO' | 'SCHEDULED_PUBLISH';

interface StatusFilterProps {
  values: Set<ContentStatusFilterType>;
  isActive: boolean;
  handleChange: (contentStatus: ContentStatusFilterType) => void;
}

const CONTENT_STATUSES: ContentStatusFilterType[] = ['PUBLISHED' /* , 'EMAIL_ONLY', 'EMBARGO', 'SCHEDULED_PUBLISH' */];

export const StatusFilter = ({ values, isActive, handleChange }: StatusFilterProps) => (
  <Dropdown
    data-testid="status-filter-button"
    trigger={
      <Group data-testid="status-filter-button-label" gap="xs" className={isActive ? styles.active : ''}>
        <IconToday data-testid="status-filter-button-icon" /> Status
      </Group>
    }
  >
    {CONTENT_STATUSES.map((status) => (
      <FormLabel
        data-testid="status-filter-item"
        className={styles.dropDown}
        key={status}
        label={<div className={styles.label}>{getStatusLabel(status)}</div>}
        labelPosition="right"
        wrap={false}
        fullWidth
      >
        <Checkbox
          data-testid="status-filter-item-checkbox"
          name="Status"
          value={status}
          checked={values.has(status)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value as ContentStatusFilterType)}
          disabled={values.size === 1 && values.has(status)}
        />
      </FormLabel>
    ))}
  </Dropdown>
);
