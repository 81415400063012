import { Group, IconInfo, Typography } from '@screentone/core';

import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { formatInTimeZone } from 'utils/dates';
import { safelyParseContent } from 'utils/temp';
import styles from './SchedulePageDialog.module.scss';

interface SchedulePagePublishDialogProps {
  onSchedule: (publishUtc: number) => Promise<void>;
  onCancel: () => void;
}

export const SchedulePageDialog = ({ onSchedule, onCancel }: SchedulePagePublishDialogProps) => {
  const { getEmbargoContent } = useDataModelContext();
  const { data: publicationSettingsResp } = usePublicationSettings();

  const latestEmbargoArticle = getEmbargoContent()
    .sort((a, b) => {
      const aDateTime = safelyParseContent<AllessehContent>(a.content).data.attributes.embargo_datetime_utc;
      const bDateTime = safelyParseContent<AllessehContent>(b.content).data.attributes.embargo_datetime_utc;

      const aDate = new Date(aDateTime!);
      const bDate = new Date(bDateTime!);

      return aDate < bDate ? 1 : -1;
    })
    .at(0);
  const embargoContent = safelyParseContent<AllessehContent>(latestEmbargoArticle?.content);
  const EmbargoWarning = latestEmbargoArticle && (
    <Group gap="md">
      <Group gap="sm" className={styles.latestEmbargo}>
        <IconInfo className={styles.latestEmbargoIcon} size="md" />
        <Typography margin={{ right: 'xs' }} className={styles.latestEmbargoInfo}>
          Embargoed story for{' '}
          {formatInTimeZone(
            embargoContent.data.attributes.embargo_datetime_utc!,
            publicationSettingsResp?.publicationSetting.preferredTimezone,
            "M/dd/yyyy 'at' hh:mm aaaa",
            { showTimezoneAbbreviation: true }
          )}
        </Typography>
      </Group>
    </Group>
  );

  return (
    <ScheduleDialog
      dialogTitle="Schedule Page"
      onSchedule={onSchedule}
      onCancel={onCancel}
      afterCalendarContent={EmbargoWarning}
    />
  );
};
