import { useEffect, useState } from 'react';
import { format } from 'date-fns-tz';

import { useAlert } from 'contexts/alert/useAlert';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import {
  AllessehCollectionType,
  Collection,
  CollectionContentItem,
  CollectionScheduleInput,
  Maybe,
  useCollectionByIdQuery,
  useCollectionDeleteScheduledMutation,
  useCollectionScheduleMutation,
  useCollectionUpdateMutation
} from 'data/generated/graphql';
import { prepContentItem } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { useConvertedProperty } from 'hooks';
import { isNonEmptyAllessehJsonQuery } from 'utils/queryUtils';

export function getCollectionIdAndUtcNum(idCreatedUtc?: string) {
  if (!idCreatedUtc) {
    return {
      id: '',
      createdUtcNum: 0
    };
  }
  const regex = /(\d{13})$/;
  const timeStamp = idCreatedUtc.match(regex);
  if (!timeStamp) {
    return {
      id: idCreatedUtc,
      createdUtcNum: 0
    };
  }
  const createdUtcNum = parseInt(timeStamp[0], 10);
  const id = idCreatedUtc.split('_').slice(0, -1).join('_');

  return { id, createdUtcNum };
}

export type CollectionType = AllessehCollectionType.CurationCollection | AllessehCollectionType.CurationOffPlatform;

export const useSaveCollection = (collectionType: CollectionType, idCreatedUtc?: string) => {
  const { alertError, removeAlert, alertSuccess } = useAlert();
  const [originalCollection, setOriginalCollection] = useState<Collection | null>(null); // immutable state of collection as received from backend
  const [collection, setCollection] = useState<Collection | null>(null); // current form state of collection
  const [hasCollectionChanged, setHasCollectionChanged] = useState(false); // used to disable/enable publishing buttons
  const [hasAllessehCollectionId, setHasAllessehCollectionId] = useState(false);
  const [originalCollectionWhenInEditMode, setOriginalCollectionWhenInEditMode] = useState<Collection | null>(null);
  const [hasRecentlyUpdatedNotificationText, setHasRecentlyUpdatedNotificationText] = useState<string | null>(null); // used to animate in success notification
  const { id } = getCollectionIdAndUtcNum(idCreatedUtc);
  const { hasRecentlyCreatedCollection, setHasRecentlyCreatedCollection } = usePagePublish();
  const currentProperty = useConvertedProperty();

  const {
    data,
    isLoading: isLoad,
    fetchStatus,
    isRefetching,
    refetch
  } = useCollectionByIdQuery(
    {
      id,
      publicationKey: currentProperty ?? '',
      collectionType: collectionType as string
    },
    { enabled: !!id }
  );
  const isLoading = isLoad && fetchStatus !== 'idle';

  const { mutateAsync: collectionUpdateMutateAsync, isLoading: isUpdating } = useCollectionUpdateMutation();
  const { mutateAsync: collectionScheduleMutateAsync, isLoading: isScheduling } = useCollectionScheduleMutation();
  const { mutateAsync: collectionDeleteScheduledAsync, isLoading: isDeletingScheduled } =
    useCollectionDeleteScheduledMutation();

  useEffect(() => {
    if (!isLoading && !data && hasRecentlyCreatedCollection) {
      void refetch();
    }
    if (!isLoading && data) {
      setCollection(data.collectionById as Collection);
      setOriginalCollection(data.collectionById as Collection);
    }
    setHasAllessehCollectionId(!!data?.collectionById.allessehCollectionId);
  }, [isLoading, data, refetch, hasRecentlyCreatedCollection]);

  const updateCollectionName = async (allessehJsonQuery: Maybe<string> | undefined) => {
    if (!collection || !originalCollection) return;

    const updateInput = {
      allessehCollectionId: originalCollection.allessehCollectionId,
      contentItems: originalCollection.contentItems.map(prepContentItem),
      allessehJsonQuery,
      notes: originalCollection.notes,
      consumer: originalCollection.consumer,
      id: originalCollection.id,
      createdUtc: originalCollection.createdUtc,
      publicationKey: currentProperty ?? '',
      name: collection.name
    };

    await collectionUpdateMutateAsync({
      collectionUpdateInput: updateInput
    });
  };

  const handleSchedule = async (publishUtc: number) => {
    if (!collection || !originalCollection) return;

    removeAlert();
    const allessehJsonQuery = isNonEmptyAllessehJsonQuery(collection.allessehJsonQuery)
      ? collection.allessehJsonQuery
      : null;

    const scheduleInput: CollectionScheduleInput = {
      allessehCollectionId: collection.allessehCollectionId,
      contentItems: collection.contentItems.map(prepContentItem),
      createdUtc: collection.createdUtc,
      publishUtc,
      id: collection.id,
      name: collection.name,
      publicationKey: currentProperty ?? '',
      allessehJsonQuery,
      consumer: collection.consumer,
      notes: collection.notes
    };

    try {
      await collectionScheduleMutateAsync({
        collectionScheduleInput: scheduleInput,
        previousPublishUtc: collection.publishUtc
      });

      if (collection.name !== originalCollection.name) {
        // If collection name was changed, save the name change right away.
        await updateCollectionName(allessehJsonQuery);
      }

      setHasCollectionChanged(false);
      setCollection((prev) => ({ ...prev!, contentItems: [] }));
      setHasRecentlyUpdatedNotificationText('Scheduled');
      setHasAllessehCollectionId(!!collection.allessehCollectionId);
      alertSuccess(`Scheduled off-platform module for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);
      // eslint-disable-next-line no-use-before-define
      handleHideHistoryEditMode();
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not schedule the off-platform module. ${e.message}`);
      } else {
        alertError('Could not schedule the off-platform module.');
      }
    }
  };

  const handleSave = async () => {
    if (!collection) {
      return;
    }
    removeAlert();
    const allessehJsonQuery = isNonEmptyAllessehJsonQuery(collection.allessehJsonQuery)
      ? collection.allessehJsonQuery
      : null;

    if (!collection.name) {
      alertError('Could not update the collection. Name cannot be blank.');
      return;
    }

    const updateInput = {
      allessehCollectionId: collection.allessehCollectionId,
      contentItems: collection.contentItems.map(prepContentItem),
      allessehJsonQuery,
      name: collection.name,
      notes: collection.notes,
      consumer: collection.consumer,
      id: collection.id,
      createdUtc: collection.createdUtc,
      publicationKey: currentProperty ?? ''
    };

    try {
      const data = await collectionUpdateMutateAsync({
        collectionUpdateInput: updateInput
      });
      setHasCollectionChanged(false);
      setHasRecentlyCreatedCollection(true);
      setOriginalCollection(data.collectionUpdate as Collection);
      setCollection(data.collectionUpdate as Collection);
      setHasRecentlyUpdatedNotificationText('Saved');
      setHasAllessehCollectionId(!!collection.allessehCollectionId);
      alertSuccess('Collection successfully saved');
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not update the collection. ${e.message}`);
      } else {
        alertError('Could not update the collection.');
      }
    }
  };

  const handleDeleteScheduled = async () => {
    try {
      await collectionDeleteScheduledAsync({
        collectionDeleteScheduledInput: {
          allessehCollectionId: collection!.allessehCollectionId,
          publishUtc: collection!.publishUtc!,
          publicationKey: collection!.publicationKey
        }
      });
      setHasCollectionChanged(false);
      setHasRecentlyUpdatedNotificationText('Scheduled deleted');
      alertSuccess('Scheduled collection has been deleted');
      // eslint-disable-next-line no-use-before-define
      handleHideHistoryEditMode();
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not delete scheduled collection.';
      if (e instanceof Error) {
        try {
          const errorJson = JSON.parse(e.message) as { message: string };
          alertError(`${ERROR_MESSAGE} ${errorJson.message}`);
        } catch (error: unknown) {
          // likely an error coming from class-validator
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handlePartialCollectionChange = (newCollection: Partial<Collection>) => {
    setHasCollectionChanged(true);
    setCollection((collection) => {
      if (!collection) {
        return null;
      }

      return { ...collection, ...newCollection };
    });
  };

  const handleCopyToCollection = (contentItems: CollectionContentItem[]) => {
    handlePartialCollectionChange({ contentItems });
  };

  const handleShowHistoryEditMode = (historyCollection: Collection) => {
    setOriginalCollectionWhenInEditMode(collection);
    handlePartialCollectionChange(historyCollection);
  };

  const handleHideHistoryEditMode = () => {
    if (originalCollectionWhenInEditMode) {
      handlePartialCollectionChange(originalCollectionWhenInEditMode);
    }
    setOriginalCollectionWhenInEditMode(null);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (hasRecentlyUpdatedNotificationText) {
      timeout = setTimeout(() => {
        setHasRecentlyUpdatedNotificationText(null);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasRecentlyUpdatedNotificationText]);

  return {
    collection,
    originalCollection,
    originalCollectionWhenInEditMode,
    hasCollectionChanged,
    hasRecentlyUpdated: !!hasRecentlyUpdatedNotificationText,
    hasRecentlyUpdatedNotificationText,
    hasAllessehCollectionId,
    isUpdating,
    isScheduling,
    isDeletingScheduled,
    isLoadingCollection: isLoading,
    isRefetchingCollection: isRefetching,
    handlePartialCollectionChange,
    handleSave,
    handleCopyToCollection,
    handleSchedule,
    handleDeleteScheduled,
    handleShowHistoryEditMode,
    handleHideHistoryEditMode
  };
};
