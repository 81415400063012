import { memo } from 'react';
import { Box, Button, Divider, Dropdown, Group, IconExternalLink, IconThreeDotsVer, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { AltSummModal } from 'components/altsumm-modal/AltSummModal';
import { EditAltSummOption } from 'components/altsumm-modal/components/edit-alt-summ-option/EditAltSummOption';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { NewsgridButton } from 'components/newsgrid-button/NewsgridButton';
import { NewspressButton } from 'components/newspress-button/NewspressButton';
import { ContentCard } from 'features/page-edit/components/article-card/ContentCard';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useAltSummModal } from 'hooks/useAltSummModal';
import styles from './OffPlatformCollectionContentCard.module.scss';

interface CollectionContentCardProps {
  content: AllessehContent;
  isDragging: boolean;
  isDraft: boolean;
  renderActions?: React.FC;
  isSearch?: boolean;
}

const OffPlatformCollectionContentCard = ({
  content,
  isDragging,
  isDraft,
  renderActions,
  isSearch
}: CollectionContentCardProps) => {
  const { attributes, type } = content.data;
  const { isModalOpen, setIsModalOpen, publicationSettings } = useAltSummModal();

  const handleViewPublishedUrl = () => {
    window.open(attributes.source_url, '_blank');
  };

  const displayPublishedUrl = type === 'article' && attributes.content_status === 'live';

  return (
    <Box
      padding={{ all: 'sm' }}
      className={classnames(styles.wrapper, isDragging ? styles.wrapperDragging : '')}
      data-testid="off-platform-content-card"
    >
      <ContentCard content={content} isIconPresent={false} isSearch={isSearch} />
      <Wrapper>
        <Dropdown
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
          data-testid="off-platform-content-ellipsis-icon"
        >
          {renderActions && (
            <>
              <Wrapper padding={{ all: 'md' }}>{renderActions({})}</Wrapper>
              <Divider />
            </>
          )}
          <Group margin={{ all: 'md' }} gap="xs">
            <EditAltSummOption
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              shouldDisplay={isDraft && content.data.attributes.type !== 'commerceproduct'}
              disableAltSummEdit={publicationSettings?.disableAltSummEdit ?? false}
            />
            <NewspressButton content={content} />
            <NewsgridButton content={content} />
            {displayPublishedUrl && (
              <Button
                tertiary
                icon={IconExternalLink as SvgComponent}
                iconPosition="right"
                onClick={handleViewPublishedUrl}
                data-testid="off-platform-view-published-url"
              >
                View published URL
              </Button>
            )}
            <ExternalLink
              contentId={content.data.id}
              isPublished={!!content.data.attributes.published_datetime}
              type={ExternalLinkContentType.ARTICLE}
              embargo={content.data.attributes.content_status === 'embargo'}
            />
          </Group>
        </Dropdown>
      </Wrapper>
      {isDraft && isModalOpen && window.isSummarianReady && (
        <AltSummModal
          content={content}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          publicationSettings={publicationSettings}
        />
      )}
    </Box>
  );
};

export default memo(OffPlatformCollectionContentCard);
