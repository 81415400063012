import { useAuth } from '@screentone/addon-auth-wrapper';

/**
Checks to see if using correct property label - in routing referred to as BuySide but backend and domains use wsj commerce
 */
export const useConvertedProperty = (): string | null => {
  const { currentProperty } = useAuth();
  if (!currentProperty) return null;
  return currentProperty === 'wsjcommerce' ? 'buyside' : currentProperty;
};
