import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Typography } from '@screentone/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import PageDraftTrash from 'components/page-draft-trash/PageDraftTrash';
import { ShowFilterOptions } from 'components/search-content/components/search-content-filters/SearchContentFiltersContainer';
import { SearchContent, SearchParams } from 'components/search-content/SearchContent';
import {
  PageModule,
  PageModuleItem,
  PageModuleItemType,
  PublicationSettingSearchableContentType
} from 'data/generated/graphql';
import { PageContentCard } from 'features/page-edit/components/page-content-card/PageContentCard';
import { DRAGGABLE_PREFIXES } from 'features/page-edit/dragUtils';
import { getAllContentIds } from 'features/page-edit/pageEditUtils';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehQuerySortKeyTypes, AllessehQuerySortOrderType } from 'hooks/useAllessehContentQuery';
import { DEFAULT_CONTENT_SEARCH_FILTERS } from 'utils/constants';
import { determineDateDisplay, formatInTimeZone } from 'utils/dates';
import { NEWSLETTER_FILTERS } from 'utils/newsletters';

export const PageContentSearch = ({
  pageModules,
  resultListClassName,
  disableAltSumm,
  embargo,
  renderActions,
  showFilters,
  newsletterName
}: {
  pageModules: PageModule[];
  disableAltSumm?: boolean;
  resultListClassName?: string;
  embargo?: boolean;
  renderActions?: (content: AllessehContent) => void;
  showFilters?: ShowFilterOptions;
  newsletterName?: string;
}) => {
  const pageContentIds = getAllContentIds(pageModules);
  const { data: publicationSettingsResp } = usePublicationSettings();
  const currentProperty = useConvertedProperty();

  // using useMemo here to prevent linting errors
  let additionalFilters = useMemo(() => ({}), []);

  if (newsletterName) {
    additionalFilters = NEWSLETTER_FILTERS[currentProperty as string][newsletterName];
  }

  const defaultFilterState = useMemo(
    () => ({
      ...DEFAULT_CONTENT_SEARCH_FILTERS,
      contentTypes: publicationSettingsResp?.publicationSetting.defaultContentTypes ?? [
        PublicationSettingSearchableContentType.Article
      ],
      excludedContentIds: pageContentIds,
      embargo,
      ...additionalFilters
    }),
    [publicationSettingsResp?.publicationSetting.defaultContentTypes, pageContentIds, embargo, additionalFilters]
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    filters: defaultFilterState,
    sort: embargo ? AllessehQuerySortKeyTypes.EmbargoDate : AllessehQuerySortKeyTypes.LiveDate,
    sortOrder: embargo ? AllessehQuerySortOrderType.ASC : AllessehQuerySortOrderType.DESC
  });

  useEffect(() => {
    const newSearchParams = cloneDeep(searchParams);
    if (
      newSearchParams.filters.contentTypes.length > 0 &&
      publicationSettingsResp?.publicationSetting.defaultContentTypes && // ensure contentTypes list has loaded and has at least 1 item
      !isEqual(newSearchParams.filters.excludedContentIds, pageContentIds)
    ) {
      newSearchParams.filters.excludedContentIds = pageContentIds;
      setSearchParams(newSearchParams);
    }
  }, [pageContentIds, publicationSettingsResp?.publicationSetting.defaultContentTypes, searchParams]);

  const handleSearchParamsChange = (changedParams: SearchParams) => {
    setSearchParams(changedParams);
  };

  let embargoDate: string;
  let embargoTime: string;
  let formattedDate: string;
  let formattedTime: string;
  let showDate: boolean;
  let showTime: boolean;

  return (
    <div data-testid="page-content-search-container">
      <SearchContent
        beforeContent={<PageDraftTrash />}
        embargo={embargo}
        searchProps={{
          searchParams,
          defaultFilterState,
          onSearchParamsChange: handleSearchParamsChange,
          resultListClassName,
          showFilters,
          renderContentCard: (content: AllessehContent, index: number) => (
            <Draggable
              data-testid="page-content-search-draggable"
              draggableId={`${DRAGGABLE_PREFIXES.MODULE_ITEM}${JSON.stringify({
                itemType: PageModuleItemType.Content,
                itemFields: {
                  contentItem: {
                    originId: content.data.id,
                    originContentType: content.data.type,
                    content: JSON.stringify(content)
                  }
                }
              } as PageModuleItem)}`}
              index={index}
            >
              {(draggableProvided) => {
                if (embargo) {
                  const dateObject = new Date(content.data.attributes.embargo_datetime_utc as string);
                  formattedDate = formatInTimeZone(
                    dateObject,
                    publicationSettingsResp?.publicationSetting.preferredTimezone,
                    'LLL d, yyyy'
                  );
                  formattedTime = formatInTimeZone(
                    dateObject,
                    publicationSettingsResp?.publicationSetting.preferredTimezone,
                    'h:mm aaaa',
                    { showTimezoneAbbreviation: true }
                  );
                  ({ showTime, showDate, embargoTime, embargoDate } = determineDateDisplay(
                    embargoDate,
                    formattedDate,
                    embargoTime,
                    formattedTime
                  ));
                }
                return (
                  <>
                    {embargo && (index < 1 || showDate) && (
                      <Typography data-testid="page-content-search-date-label" margin={{ top: 'sm', bottom: 'md' }}>
                        {formattedDate}
                      </Typography>
                    )}
                    {embargo && (index < 1 || showTime) && (
                      <Typography
                        data-testid="page-content-search-time-label"
                        variant="label3"
                        margin={{ bottom: 'sm' }}
                      >
                        {formattedTime}
                      </Typography>
                    )}
                    <div
                      data-testid="page-content-search-draggable-items"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                    >
                      <PageContentCard
                        renderActions={renderActions}
                        disableAltSumm={disableAltSumm}
                        content={content}
                        dragHandleProps={draggableProvided.dragHandleProps}
                        isSearch
                      />
                    </div>
                  </>
                );
              }}
            </Draggable>
          )
        }}
      />
    </div>
  );
};
