import { Illustration, Typography, Wrapper } from '@screentone/core';

import styles from './NoSearchResults.module.scss';

interface NoSearchResultsProps {
  title: string;
  bodyText?: string;
}

export const NoSearchResults = ({ title, bodyText }: NoSearchResultsProps) => (
  <Wrapper padding={{ all: 'lg' }} className={styles.emptyWrapper}>
    <Illustration name="empty" className={styles.emptyImage} />
    <Typography variant="header2" margin={{ top: 'lg', bottom: 'md' }}>
      No {title} Found
    </Typography>
    <Typography align="center" variant="bodytext">
      {bodyText ?? 'Try a different filter.'}
    </Typography>
  </Wrapper>
);
