import { memo, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  Group,
  IconDisabled,
  IconExternalLink,
  IconThreeDotsVer,
  Token,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';
import { format } from 'date-fns-tz';

import { AltSummModal } from 'components/altsumm-modal/AltSummModal';
import { EditAltSummOption } from 'components/altsumm-modal/components/edit-alt-summ-option/EditAltSummOption';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { NewsgridButton } from 'components/newsgrid-button/NewsgridButton';
import { NewspressButton } from 'components/newspress-button/NewspressButton';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useAltSummModal } from 'hooks/useAltSummModal';
import { resizeIMImage } from 'utils/url';
import styles from './CollectionContentCard.module.scss';

interface CollectionContentCardProps {
  content: AllessehContent;
  isDragging: boolean;
  renderActions?: React.FC;
}

const CollectionContentCard = ({ content, isDragging, renderActions }: CollectionContentCardProps) => {
  const { attributes, type } = content.data;
  const { trackContent, getAltSummFields } = useSummarianContext();
  const { headline, image } = getAltSummFields(content);
  const { isModalOpen, setIsModalOpen, publicationSettings } = useAltSummModal();

  useEffect(() => {
    trackContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  const handleViewPublishedUrl = () => {
    window.open(attributes.source_url, '_blank');
  };

  const displayPublishedUrl =
    type === 'article' && attributes.content_status === 'live' && attributes.product !== 'YDJ';

  const summary = useMemo(() => {
    const summaries = attributes.summary?.content;
    const summaryDescriptions = summaries?.[summaries.length - 1]?.content;
    return summaryDescriptions?.[summaryDescriptions.length - 1]?.text;
  }, [attributes]);

  const updatedTime = useMemo(
    () =>
      attributes.updated_datetime_utc
        ? format(new Date(attributes.updated_datetime_utc), 'MMM d, yyyy h:mm aaa z')
        : '-',
    [attributes]
  );

  const imageUrl = useMemo(() => resizeIMImage(image, { width: 98, size: 1 }), [image]);

  return (
    <Box
      data-testid="collection-content-card-item"
      className={classnames(styles.wrapper, isDragging ? styles.wrapperDragging : '')}
    >
      <Wrapper
        data-testid="collection-content-card-content"
        padding={{ top: 'md', left: 'md', bottom: 'md' }}
        className={styles.wrapperInner}
      >
        <Wrapper data-testid="collection-content-card-info" margin={{ right: 'md' }} className={styles.left}>
          <div data-testid="collection-content-card-headline" className={styles.headline}>
            <Typography
              data-testid="collection-content-card-title"
              weight="bold"
              className={styles.header}
              title={attributes.headline?.text}
            >
              {headline}
            </Typography>
            {content.data.attributes.content_status === 'embargo' && (
              <Token
                data-testid="collection-content-card-icon-embargo"
                icon={IconDisabled as SvgComponent}
                margin={{ left: 'sm' }}
                color="lava"
              />
            )}
          </div>

          {summary && (
            <Typography className={styles.description} data-testid="collection-content-card-description">
              {summary}
            </Typography>
          )}
          <Typography variant="note" data-testid="collection-content-card-status-datetime">
            Updated {updatedTime}
          </Typography>
          {attributes.section_type && (
            <Wrapper margin={{ top: 'sm' }} data-testid="collection-content-card-type">
              <Token data-testid="collection-content-card-section-type">{attributes.section_type}</Token>
            </Wrapper>
          )}
        </Wrapper>
        {imageUrl && (
          <img
            data-testid="collection-content-card-thumbnail"
            src={imageUrl}
            alt="Content Thumbnail"
            className={styles.image}
          />
        )}
      </Wrapper>
      <Wrapper data-testid="collection-content-card-toggle" margin={{ all: 'sm' }}>
        <Dropdown
          data-testid="collection-content-card-toggle-button"
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
        >
          {renderActions && (
            <>
              <Wrapper data-testid="collection-content-card-toggle-custom-option" padding={{ all: 'md' }}>
                {renderActions({})}
              </Wrapper>
              <Divider data-testid="collection-content-card-divider" />
            </>
          )}
          <Group data-testid="collection-content-card-toggle-options-list" margin={{ all: 'md' }} gap="xs">
            <EditAltSummOption
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              shouldDisplay={content.data.attributes.type !== 'commerceproduct'}
              disableAltSummEdit={publicationSettings?.disableAltSummEdit ?? false}
            />
            <NewspressButton content={content} />
            <NewsgridButton content={content} />
            {displayPublishedUrl && (
              <Button
                data-testid="collection-content-card-toggle-published-url"
                tertiary
                icon={IconExternalLink as SvgComponent}
                iconPosition="right"
                onClick={handleViewPublishedUrl}
              >
                View published URL
              </Button>
            )}
            <ExternalLink
              embargo={content.data.attributes.content_status === 'embargo'}
              contentId={content.data.id}
              isPublished={!!content.data.attributes.published_datetime}
              type={ExternalLinkContentType.ARTICLE}
            />
          </Group>
        </Dropdown>
      </Wrapper>
      {isModalOpen && window.isSummarianReady && (
        <AltSummModal
          content={content}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          publicationSettings={publicationSettings}
        />
      )}
    </Box>
  );
};

export default memo(CollectionContentCard);
