import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Token, Typography, Wrapper } from '@screentone/core';

import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { PageDto, PagesDtoQuery, PageType } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { useIsAdmin } from 'hooks/roles';
import styles from './PagesList.module.scss';

export const PagesList = ({ pagePages }: { pagePages: PagesDtoQuery[] }) => {
  const { currentProperty } = useAuth();
  const isAdmin = useIsAdmin();
  const { data: publicationSettings } = usePublicationSettings();
  const [pages, setPages] = useState<PageDto[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const pages: PageDto[] = pagePages
      .reduce((acc: PageDto[], curr: PagesDtoQuery) => {
        const pages = curr.pagesDTO.edges.map((pageEdge) => {
          const { node: page } = pageEdge;
          return page;
        });
        return acc.concat(pages as PageDto[]);
      }, [])
      .filter((page) => {
        // TODO: remove this when section fronts be available
        if (!isAdmin) {
          const pageType = page.root.attributes.pageType as PageType;
          return pageType === PageType.Home;
        }
        return true;
      });
    setPages(pages);
  }, [isAdmin, pagePages]);

  if (pagePages.length === 0 || pagePages[0].pagesDTO.edges.length === 0) {
    return <NoSearchResults title="Pages" bodyText="Try a different filter or ask an admin to create a new page." />;
  }

  const handlePageClick = (pageAllessehId: string) => () => {
    const slugMappings = publicationSettings?.publicationSetting.slugMappings;
    const [slug] = Object.entries(slugMappings ?? {}).find(([, allessehId]) => allessehId === pageAllessehId) ?? [];
    const pageId = slug ?? pageAllessehId;

    navigate(`/${currentProperty ?? ''}/pages/${pageId}`);
  };

  return (
    <div data-testid="pages-sections-list">
      {pages.map((page) => (
        <Box
          key={page.metadata.allessehId}
          padding={{ all: 'sm' }}
          margin={{ top: 'sm', bottom: 'sm' }}
          onClick={handlePageClick(page.metadata.allessehId)}
          className={styles.pageItem}
        >
          <Wrapper className={styles.pageItemContent}>
            <div className={styles.pageItemLeft}>
              <Typography variant="bodytext" margin={{ right: 'sm' }} data-testid="pages-names-section">
                {page.metadata.name}
              </Typography>
              <Typography margin={{ left: 'sm' }} variant="note">
                {publicationSettings?.publicationSetting.baseRoute}
                {page.root.attributes.routeKey}
              </Typography>
            </div>
            <Token color="gray">{page.root.attributes.pageType}</Token>
          </Wrapper>
        </Box>
      ))}
    </div>
  );
};
