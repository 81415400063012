/* eslint-disable import/no-cycle */
import { createContext, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IconHandle, Wrapper } from '@screentone/core';
import { cloneDeep } from 'lodash';

import { Module } from 'components/datamodel/container/Module/Module';
import { Page } from 'components/datamodel/container/Page/Page';
import { Section } from 'components/datamodel/container/Section/Section';
import { Article, ArticleProps } from 'components/datamodel/content/Article/Article';
import { Collection } from 'components/datamodel/content/Collection/Collection';
import { CommonContentProps } from 'components/datamodel/content/commonContentProps';
import { LinkedItem as LinkedItemComponent } from 'components/datamodel/content/LinkedItem/LinkedItem';
import { Query } from 'components/datamodel/content/Query/Query';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import {
  ArticleItem,
  BaseMetadata,
  ExternalCollectionItem,
  Layout,
  LinkedItem,
  ModuleContainer,
  PageContainer,
  PageModule,
  QueryItem,
  SectionContainer
} from 'data/generated/graphql';
import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
// Need to use relative import for stylesheet, otherwise an error will be thrown when running tests
import moduleStyles from '../../components/datamodel/container/Module/Module.module.scss';

export type Container = SectionContainer | PageContainer | ModuleContainer;
export type ContentItem = ArticleItem | ExternalCollectionItem | QueryItem | LinkedItem;
export type EntityComponent = Container | ContentItem;

type ExtraProps = {
  module?: {
    layout: Layout | undefined;
  };
  content?: CommonContentProps;
  article?: Pick<ArticleProps, 'renderActions'>;
};

type RenderEntityOptions = {
  hierarchyId: string;
  index?: number;
  extraProps?: ExtraProps;
  isHistory?: boolean;
};

export interface IDataModelContext<T extends Container> {
  root: T;
  metadata: BaseMetadata;
  hasModelChanged: boolean;
  resetModelChanged: () => void;
  getAllArticleIds(): string[];
  getAllExternalCollectionIds(): string[];
  getDTO(deleteResolvedFields?: boolean): {
    root: Container;
    metadata: BaseMetadata;
  };
  deleteResolvedFieldsFromExternalDTO(dto: { root: Container; metadata: BaseMetadata }): {
    root: Container;
    metadata: BaseMetadata;
  };
  fromAllessehContent(content: AllessehContent): ArticleItem;
  generateHierarchyId(entity: EntityComponent, parentHierarchyId?: string, index?: number): string;
  generateHierarchyIdFromDragEvent(droppableId: string, dropIndex: number): string;
  setNewRoot(newRoot: Container): void;
  setNewMetadata(newMetadata: Partial<BaseMetadata>): void;
  getEntity<E extends EntityComponent>(hierarchyId: string): E | null;
  swapEntities(srcHierarchyId: string, destHierarchyId: string): void;
  insertEntity(destHierarchyId: string, entity: EntityComponent | EntityComponent[]): void;
  modifyEntity(hierarchyId: string, callback: (entity: EntityComponent) => void): void;
  removeEntity(hierarchyId: string): void;
  removeAllByType(type: string): void;
  getEmbargoContent(): ArticleItem[];
  dedupe(): void;
  renderEntity(entity: EntityComponent, options: RenderEntityOptions): React.ReactElement;
  isEditingLinkedItem: string;
  setIsEditingLinkedItem: React.Dispatch<React.SetStateAction<string>>;
}

const DEFAULT_STATE: IDataModelContext<Container> = {
  // @ts-expect-error - null is not assignable to type Container
  root: null,
  // @ts-expect-error - empty object is not assignable to type BaseMetadata
  metadata: {},
  hasModelChanged: false,
  resetModelChanged: () => {},
  getAllArticleIds: () => [],
  getAllExternalCollectionIds: () => [],
  // @ts-expect-error - null is not assignable to root and empty object is not assignable to metadata
  getDTO: () => ({ root: null, metadata: {} }),
  // @ts-expect-error - empty object is not assignable to return type
  deleteResolvedFieldsFromExternalDTO: () => ({}),
  // @ts-expect-error - null is not assignable to type ArticleItem
  fromAllessehContent: () => null,
  generateHierarchyId: () => '',
  generateHierarchyIdFromDragEvent: () => '',
  setNewRoot: () => {},
  setNewMetadata: () => {},
  getEntity: () => null,
  swapEntities: () => {},
  insertEntity: () => {},
  modifyEntity: () => {},
  removeEntity: () => {},
  removeAllByType: () => {},
  getEmbargoContent: () => [],
  dedupe: () => {},
  renderEntity: () => <></>
};

interface DataModelProviderProps {
  type: 'PageDTO' | 'CollectionDTO' | 'OffPlatformDTO';
  root: Container;
  metadata: BaseMetadata;
  children: React.ReactNode;
}

export const DataModelContext = createContext(DEFAULT_STATE);

export const DataModelProvider = ({
  type,
  root: initialRoot,
  metadata: initialMetadata,
  children
}: DataModelProviderProps) => {
  const [root, setRoot] = useState(initialRoot);
  const [metadata, setMetadata] = useState(initialMetadata);
  const [hasModelChanged, setHasModelChanged] = useState(false);
  const [isEditingLinkedItem, setIsEditingLinkedItem] = useState('');
  const auxRoot = useRef(initialRoot); // auxiliary root to apply successive changes to the root, bypassing React automatic state batching

  function setNewRoot(newRoot: Container) {
    auxRoot.current = newRoot;
    setHasModelChanged(true);
    setRoot(newRoot);
  }

  function setNewMetadata(newMetadata: Partial<DataModelProviderProps['metadata']>) {
    setMetadata((prev) => ({ ...prev, ...newMetadata }));
  }

  function resetModelChanged() {
    setHasModelChanged(false);
  }

  function readHierarchyId(hierarchyId: string, onlyIndices: boolean): number[];
  function readHierarchyId(hierarchyId: string): { entity: string; index: number }[];
  function readHierarchyId(hierarchyId: string, onlyIndices = false) {
    if (onlyIndices) {
      const stringIndices = hierarchyId.match(/\d+/g);
      if (!stringIndices) {
        throw new Error(`No indices found in hierarchyId: ${hierarchyId}`);
      }

      return stringIndices.map((index) => parseInt(index, 10));
    }

    const splitHierarchyId = hierarchyId.split('_').map((part) => part.split('-'));
    return splitHierarchyId.map(([entity, index]) => ({ entity, index: parseInt(index, 10) }));
  }

  function generateHierarchyId(entity: EntityComponent, parentHierarchyId?: string, index?: number) {
    let hierarchyId = entity.type.toLowerCase();

    if (parentHierarchyId) {
      hierarchyId = `${parentHierarchyId}_${hierarchyId}`;
    }

    if (index !== undefined) {
      hierarchyId = `${hierarchyId}-${index}`;
    }

    return hierarchyId;
  }

  function generateHierarchyIdFromDragEvent(droppableId: string, dropIndex: number) {
    return `${droppableId}_entity-${dropIndex}`;
  }

  function traverseEntity(
    entity: EntityComponent,
    callback: (entity: EntityComponent, hierarchyId: string) => void,
    parentHierarchyId = '',
    entityIndex = 0
  ) {
    const hierarchyId = generateHierarchyId(entity, parentHierarchyId, entityIndex);
    callback(entity, hierarchyId);

    if ('collection' in entity) {
      entity.collection.forEach((child, i) => traverseEntity(child, callback, hierarchyId, i));
    }
  }

  function getAllArticleIds(): string[] {
    const ids: string[] = [];

    traverseEntity(auxRoot.current, (entity) => {
      if (entity.type === 'Article') {
        const articleItem = entity as ArticleItem;
        ids.push(articleItem.attributes.id);
      }
    });

    return ids;
  }

  function getAllExternalCollectionIds(): string[] {
    const ids: string[] = [];

    traverseEntity(auxRoot.current, (entity) => {
      if (entity.type === 'Collection') {
        const externalCollectionItem = entity as ExternalCollectionItem;
        ids.push(externalCollectionItem.attributes.id);
      }
    });

    return ids;
  }

  function getDTO(deleteResolvedFields = false) {
    const rootCopy = cloneDeep(auxRoot.current);
    const metadataCopy = cloneDeep(metadata);

    traverseEntity(rootCopy, (entity) => {
      if (entity.type === 'Article' && deleteResolvedFields) {
        const articleItem = entity as ArticleItem;
        delete articleItem.content;
      }

      if (entity.type === 'Collection' && deleteResolvedFields) {
        const collectionItem = entity as ExternalCollectionItem;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.contentItems;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.metadata;
      }
    });

    return {
      type,
      root: rootCopy,
      metadata: metadataCopy
    };
  }

  function deleteResolvedFieldsFromExternalDTO(dto: { root: Container; metadata: BaseMetadata }): {
    root: Container;
    metadata: BaseMetadata;
  } {
    const dtoCopy = cloneDeep(dto);

    traverseEntity(dtoCopy.root, (entity) => {
      if (entity.type === 'Article') {
        const articleItem = entity as ArticleItem;
        delete articleItem.content;
      }

      if (entity.type === 'Collection') {
        const collectionItem = entity as ExternalCollectionItem;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.contentItems;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.metadata;
      }
    });

    return dtoCopy;
  }

  function fromAllessehContent(content: AllessehContent): ArticleItem {
    const {
      id: originId,
      type: originContentType,
      attributes: { product, seo_id: seoId, canonical_url: canonicalUrl, source_url: sourceUrl, content_status: status }
    } = content.data;

    if (originContentType !== 'article' && originContentType !== 'commerceproduct') {
      throw new Error(`Unsupported origin content type: ${originContentType}`);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const normalizedSeoId = seoId ?? '';

    const articleItem: ArticleItem = {
      type: 'Article',
      content: JSON.stringify(content),
      attributes: {
        base_doc_id: originId,
        hosted_url: canonicalUrl || sourceUrl,
        id: originId,
        link: originContentType === 'commerceproduct' ? '' : `api/articles/v1/originid/${originId}`,
        originContentType,
        originId,
        product,
        seo_id: originContentType === 'commerceproduct' ? '' : normalizedSeoId,
        status
      }
    };

    return articleItem;
  }

  function getEntity<E extends EntityComponent>(hierarchyId: string): E | null {
    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = readHierarchyId(hierarchyIdSanitized, true);

    let entity: EntityComponent | null = null;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < hierarchyIdIndices.length; i++) {
      const index = hierarchyIdIndices[i];
      if (entity && 'collection' in entity && index >= entity.collection.length) {
        return null;
      }

      if (!entity) {
        entity = auxRoot.current;
      } else {
        entity = entity.collection[index];
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return entity;
  }

  function swapEntities(srcHierarchyId: string, destHierarchyId: string) {
    const rootCopy = cloneDeep(auxRoot.current);

    const srcHierarchyIdSanitized = srcHierarchyId.replace(/.+?#/, '');
    const destHierarchyIdSanitized = destHierarchyId.replace(/.+?#/, '');
    const srcHierarchyIdIndices = readHierarchyId(srcHierarchyIdSanitized, true);
    const destHierarchyIdIndices = readHierarchyId(destHierarchyIdSanitized, true);

    let srcEntity: EntityComponent | null = null;
    for (let i = 0; i < srcHierarchyIdIndices.length - 1; i++) {
      const index = srcHierarchyIdIndices[i];

      if (!srcEntity) {
        srcEntity = rootCopy;
      } else {
        srcEntity = srcEntity.collection[index];
      }
    }
    const lastSrcIndex = srcHierarchyIdIndices.at(-1)!;

    if (!('collection' in srcEntity!)) {
      throw new Error('Source entity is not a container');
    }

    const [removed] = srcEntity.collection.splice(lastSrcIndex, 1);

    let destEntity: EntityComponent | null = null;
    for (let i = 0; i < destHierarchyIdIndices.length - 1; i++) {
      const index = destHierarchyIdIndices[i];

      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }
    const lastDestIndex = destHierarchyIdIndices.at(-1)!;

    if (!('collection' in destEntity!)) {
      throw new Error('Destination entity is not a container');
    }

    // @ts-expect-error - splice expect a specific entity type, not a union of entity types
    destEntity.collection.splice(lastDestIndex, 0, removed);

    setNewRoot(rootCopy);
  }

  function insertEntity(destHierarchyId: string, entity: EntityComponent | EntityComponent[]) {
    const rootCopy = cloneDeep(auxRoot.current);

    const destHierarchyIdSanitized = destHierarchyId.replace(/.+?#/, '');
    const destHierarchyIdIndices = readHierarchyId(destHierarchyIdSanitized, true);

    let destEntity: EntityComponent | null = null;
    for (let i = 0; i < destHierarchyIdIndices.length - 1; i++) {
      const index = destHierarchyIdIndices[i];

      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }
    const lastDestIndex = destHierarchyIdIndices.at(-1)!;

    if (!('collection' in destEntity!)) {
      throw new Error('Destination entity is not a container');
    }

    if (Array.isArray(entity)) {
      // @ts-expect-error - splice expect a specific entity type, not a union of entity types
      destEntity.collection.splice(lastDestIndex, 0, ...entity);
    } else {
      // @ts-expect-error - splice expect a specific entity type, not a union of entity types
      destEntity.collection.splice(lastDestIndex, 0, entity);
    }

    setNewRoot(rootCopy);
  }

  function modifyEntity(hierarchyId: string, callback: (entity: EntityComponent) => void) {
    const rootCopy = cloneDeep(auxRoot.current);

    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = readHierarchyId(hierarchyIdSanitized, true);

    let destEntity: EntityComponent | null = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const index of hierarchyIdIndices) {
      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }

    if (destEntity) {
      callback(destEntity);
    }

    setNewRoot(rootCopy);
  }

  function removeEntity(hierarchyId: string) {
    const rootCopy = cloneDeep(auxRoot.current);

    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = readHierarchyId(hierarchyIdSanitized, true);

    let destEntity: EntityComponent | null = null;
    for (let i = 0; i < hierarchyIdIndices.length - 1; i++) {
      const index = hierarchyIdIndices[i];

      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }
    const lastDestIndex = hierarchyIdIndices.at(-1)!;

    if (!('collection' in destEntity!)) {
      throw new Error('Destination entity is not a container');
    }

    destEntity.collection.splice(lastDestIndex, 1);

    setNewRoot(rootCopy);
  }

  function removeAllByType(type: string) {
    const entityHierarchyIds: string[] = [];

    traverseEntity(auxRoot.current, (entity, hierarchyId) => {
      if (entity.type === type) {
        entityHierarchyIds.push(hierarchyId);
      }
    });

    entityHierarchyIds.toReversed().forEach((hierarchyId) => removeEntity(hierarchyId));
  }

  function getEmbargoContent() {
    const embargoContent: ArticleItem[] = [];

    traverseEntity(auxRoot.current, (entity) => {
      if (entity.type === 'Article') {
        const articleItem = entity as ArticleItem;
        if (articleItem.attributes.status === 'embargo') {
          embargoContent.push(articleItem);
        }
      }
    });

    return embargoContent;
  }

  function dedupe() {
    const rootCopy = cloneDeep(auxRoot.current);
    const allArticleIds = getAllArticleIds();

    traverseEntity(rootCopy, (entity) => {
      if (entity.type === 'Query') {
        const queryItem = entity as QueryItem;
        const queryBody = queryItem.attributes.query as AllessehContentQueryBody;

        const notRules: AllessehQueryRule[] =
          queryBody.query?.not?.filter(
            (item: AllessehQueryRule) => 'term' in item && item.term.key !== 'UpstreamOriginId'
          ) ?? [];

        allArticleIds.forEach((articleId) => {
          notRules.push({
            term: {
              key: 'UpstreamOriginId',
              value: articleId
            }
          });
        });

        if (!queryBody.query) {
          queryBody.query = {};
        }

        queryBody.query.not = notRules;
      }
    });

    setNewRoot(rootCopy);
  }

  function renderEntity(entity: EntityComponent, options: RenderEntityOptions): React.ReactElement {
    const { hierarchyId, index = 0, isHistory = false } = options;

    let entityComponent: React.ReactNode | null = null;

    if (entity.type === 'Section') {
      const section = entity as SectionContainer;
      entityComponent = <Section data={section} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;
    }

    if (entity.type === 'Page') {
      const page = entity as PageContainer;
      entityComponent = <Page data={page} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;
    }

    if (entity.type === 'Module') {
      const module = entity as ModuleContainer;
      entityComponent = <Module data={module} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;
    }

    if (entity.type === 'Article') {
      const article = entity as ArticleItem;
      entityComponent = (
        <Article
          data={article}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
          {...options.extraProps?.article}
        />
      );
    }

    if (entity.type === 'Collection') {
      const collection = entity as ExternalCollectionItem;
      entityComponent = (
        <Collection
          data={collection}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
        />
      );
    }

    if (entity.type === 'Query') {
      const query = entity as QueryItem;
      entityComponent = (
        <Query
          data={query}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
        />
      );
    }

    if (entity.type === 'LinkedItem') {
      const linkedItem = entity as LinkedItem;
      entityComponent = (
        <LinkedItemComponent
          data={linkedItem}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
        />
      );
    }

    if (!entityComponent) {
      throw new Error(`Unknown entity type: ${entity.type}`);
    }

    if (entity.type === 'Module' && !isHistory) {
      const module = entity as ModuleContainer;
      const isAvailableLayoutModuleType =
        options.extraProps?.module?.layout?.availableLayoutModules
          .map((m) => m.uiModuleType)
          .includes((module.attributes.pageModule as PageModule).uiModuleType) ?? false;
      const fullHierarchyId = generateHierarchyId(entity, hierarchyId, index);

      if (isAvailableLayoutModuleType) {
        entityComponent = (
          <Draggable draggableId={DRAGGABLE_PREFIXES.MODULE + fullHierarchyId} index={index}>
            {((currComponent) =>
              // eslint-disable-next-line func-names
              function (draggableProvided) {
                return (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    className={moduleStyles.draggableModule}
                  >
                    {currComponent}
                    <Wrapper padding={{ all: 'sm' }} {...draggableProvided.dragHandleProps}>
                      <IconHandle color="asphalt" />
                    </Wrapper>
                  </div>
                );
              })(entityComponent)}
          </Draggable>
        );
      }
    }

    // Wrap Article, Collection, and Query entities in Draggable
    if (
      entity.type === 'Article' ||
      entity.type === 'Collection' ||
      entity.type === 'Query' ||
      entity.type === 'LinkedItem'
    ) {
      const fullHierarchyId = generateHierarchyId(entity, hierarchyId, index);
      const draggablePrefix = isHistory ? DRAGGABLE_PREFIXES.HISTORY_MODULE_ITEM : DRAGGABLE_PREFIXES.MODULE_ITEM;
      let draggableId = draggablePrefix + fullHierarchyId;
      if (isHistory) {
        draggableId = draggablePrefix + JSON.stringify(entity);
      }

      entityComponent = (
        <Draggable draggableId={draggableId} index={index}>
          {((currComponent) =>
            // eslint-disable-next-line func-names
            function (draggableProvided) {
              return (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                >
                  {currComponent}
                </div>
              );
            })(entityComponent)}
        </Draggable>
      );
    }

    return entityComponent;
  }

  const value = {
    root,
    metadata,
    hasModelChanged,
    resetModelChanged,
    getAllArticleIds,
    getAllExternalCollectionIds,
    getDTO,
    deleteResolvedFieldsFromExternalDTO,
    fromAllessehContent,
    generateHierarchyId,
    generateHierarchyIdFromDragEvent,
    setNewRoot,
    setNewMetadata,
    getEntity,
    swapEntities,
    insertEntity,
    modifyEntity,
    removeEntity,
    removeAllByType,
    getEmbargoContent,
    dedupe,
    renderEntity,
    isEditingLinkedItem,
    setIsEditingLinkedItem
  };

  return <DataModelContext.Provider value={value}>{children}</DataModelContext.Provider>;
};
