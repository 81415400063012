import axios, { AxiosRequestConfig, CancelToken } from 'axios';

import env from 'utils/env';

type RequestConfig = Omit<AxiosRequestConfig, 'cancelToken'>;

type HttpRequestPromise<T> = Promise<T> & { cancel: () => void };

interface RestClient {
  get<T>(url: string, config?: RequestConfig): HttpRequestPromise<T>;

  post<T>(url: string, data?: unknown, config?: RequestConfig): HttpRequestPromise<T>;

  put<T>(url: string, data?: unknown, config?: RequestConfig): HttpRequestPromise<T>;

  delete<T>(url: string, config?: RequestConfig): HttpRequestPromise<T>;
}

const cancellable = <T>(handler: (cancelToken: CancelToken) => Promise<T>): HttpRequestPromise<T> => {
  const source = axios.CancelToken.source();
  const resultPromise = handler(source.token) as HttpRequestPromise<T>;

  resultPromise.cancel = () => source.cancel();

  return resultPromise;
};

const http = axios.create({
  baseURL: env.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: env.API_TIMEOUT
});

const restClient: RestClient = {
  get: <T>(url: string, config?: RequestConfig) =>
    cancellable((cancelToken) => http.get<T>(url, { ...config, cancelToken }).then((x) => x.data)),

  post: <T>(url: string, data?: unknown, config?: RequestConfig) =>
    cancellable((cancelToken) => http.post<T>(url, data, { ...config, cancelToken }).then((x) => x.data)),

  put: <T>(url: string, data?: unknown, config?: RequestConfig) =>
    cancellable((cancelToken) => http.put<T>(url, data, { ...config, cancelToken }).then((x) => x.data)),

  delete: <T>(url: string, config?: RequestConfig) =>
    cancellable((cancelToken) => http.delete<T>(url, { ...config, cancelToken }).then((x) => x.data))
};

export default restClient;
