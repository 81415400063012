import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';

export interface AuraEmployeeResponse {
  // Success response, there is more data: email_address, job_description, is_manager, and much more
  first_name?: string;
  last_name?: string;
  // Error response
  status?: number;
  message?: string;
  name?: string;
}

const getUserData = (authToken: string, djUserId: string) =>
  restClient.get<AuraEmployeeResponse>(`aura/employee/${djUserId}`, {
    headers: { Authorization: authToken }
  });

export const useAuraQuery = (djUserId: string, options?: { enabled: boolean }) => {
  const authToken = useAuthToken();

  return useAppQuery([djUserId], () => getUserData(authToken, djUserId), options);
};
