import { Typography, Wrapper } from '@screentone/core';

import { PageModule, UiModuleType } from 'data/generated/graphql';
import styles from './UiModuleFieldsHeader.module.scss';

interface UiModuleHeaderProps {
  pageModule: PageModule;
}

export const UiModuleFieldsHeader = ({ pageModule }: UiModuleHeaderProps) => {
  const { uiModuleType, uiModuleFields } = pageModule;
  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
    case UiModuleType.UiBasicOptionalModuleType:
      return null;
    case UiModuleType.UiRankedModuleType:
      return (
        <Wrapper margin={{ bottom: 'xs' }} className={styles.header} data-testid="page-ranked-title-name">
          <Typography size="sm" weight="bold" margin={{ right: 'sm' }}>
            {uiModuleFields.rankedModule?.title}
          </Typography>
        </Wrapper>
      );
    case UiModuleType.UiTitleModuleType:
      return (
        <Wrapper margin={{ bottom: 'xs' }} className={styles.header} data-testid="page-title-name">
          <Typography size="sm" weight="bold" margin={{ right: 'sm' }}>
            {uiModuleFields.titleModule?.title}
          </Typography>
          <Typography variant="note">{uiModuleFields.titleModule?.subtitle}</Typography>
        </Wrapper>
      );
    case UiModuleType.UiTextModuleType:
      return (
        <Wrapper margin={{ bottom: 'xs' }} className={styles.header}>
          <Typography size="sm" weight="bold" margin={{ right: 'sm' }}>
            {uiModuleFields.textModule?.mainText}
          </Typography>
          <Typography variant="note" className={styles.truncatedText}>
            {uiModuleFields.textModule?.secondaryText}
          </Typography>
        </Wrapper>
      );
    default:
      return null;
  }
};
