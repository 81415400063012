import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { CollectionEdit } from 'features/collection-edit/CollectionEdit';

const CollectionEditPage = () => (
  <SummarianProvider>
    <TrashProvider>
      <CollectionEdit />
    </TrashProvider>
  </SummarianProvider>
);

export default CollectionEditPage;
