import { createContext, useState } from 'react';

interface TrashContext {
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
}

const DEFAULT_DRAG_STATE = false;

const DEFAULT_TRASH_CONTEXT: TrashContext = {
  isDragging: DEFAULT_DRAG_STATE,
  setIsDragging: () => {
    /** do nothing */
  }
};

export const TrashContext = createContext(DEFAULT_TRASH_CONTEXT);

export const TrashProvider: FCC = ({ children }) => {
  const [isDragging, setIsDragging] = useState(DEFAULT_DRAG_STATE);

  const value = {
    isDragging,
    setIsDragging
  };

  return <TrashContext.Provider value={value}>{children}</TrashContext.Provider>;
};
