import { useEffect } from 'react';
import { Button, IconCode } from '@screentone/core';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { ModuleContainer, PageModule, UiRankedTreatmentType } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { getTreatmentType } from 'utils/modules';
import { ConvertToCollectionModal } from './ConvertToCollectionModal';

const shouldConvertToCollectionByPublication = (treatmentType: string, publication: string | null) => {
  switch (publication) {
    case 'buyside':
      // eslint-disable-next-line
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      return treatmentType !== UiRankedTreatmentType.Vertical_5WithProductCards;
    default:
      return true;
  }
};

interface ConvertToCollectionProps {
  hierarchyId: string;
  module: ModuleContainer;
}

export const ConvertToCollection = ({ hierarchyId, module }: ConvertToCollectionProps) => {
  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();
  const currentProperty = useConvertedProperty();

  const handleConvertToCollection = () => {
    setIsModalOpen(true);
    setActionModalComponent(<ConvertToCollectionModal hierarchyId={hierarchyId} module={module} />);
  };

  useEffect(() => {
    setActionModalComponent(<ConvertToCollectionModal hierarchyId={hierarchyId} module={module} />);
  }, [hierarchyId, module, setActionModalComponent]);

  const contentAndQueryTypes = ['Article', 'Query'];
  const moduleTreatmentType = getTreatmentType(module.attributes.pageModule as PageModule);
  const moduleIsEmpty = module.collection.length === 0;
  const hasContentOtherThanArticleOrQuery = module.collection.some((item) => !contentAndQueryTypes.includes(item.type));
  const shouldNotShowConvertToCollection =
    moduleIsEmpty ||
    hasContentOtherThanArticleOrQuery ||
    !shouldConvertToCollectionByPublication(moduleTreatmentType, currentProperty);

  if (shouldNotShowConvertToCollection) {
    return null;
  }

  return (
    <Button tertiary icon={IconCode as SvgComponent} onClick={handleConvertToCollection}>
      Convert to Collection
    </Button>
  );
};
