import { ChangeEventHandler } from 'react';
import {
  Box,
  Button,
  Dropdown,
  Group,
  IconHandle,
  IconSort,
  IconSortReverse,
  IconThreeDotsVer,
  IconTrash,
  Select
} from '@screentone/core';

import {
  AllessehQuerySortKeyTypes,
  AllessehQuerySortOrderType,
  AllessehQuerySortRule
} from 'hooks/useAllessehContentQuery';
import { getSentence } from 'utils/text';
import styles from './SortRule.module.scss';

interface SortRuleProps {
  rule: AllessehQuerySortRule;
  onChange: ((rule: AllessehQuerySortRule) => void) | null;
  availableSortKeys: string[];
  onRemoveRule: (() => void) | null;
}

const SortRule = ({ rule, onChange, onRemoveRule, availableSortKeys }: SortRuleProps) => {
  const handleSelectRule: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChange?.({ ...rule, key: e.target.value as AllessehQuerySortKeyTypes });
  };

  const handleChangeSortOrder = (newSortOrder: AllessehQuerySortOrderType) => {
    onChange?.({ ...rule, order: newSortOrder });
  };

  const handleRemoveRule = () => {
    onRemoveRule?.();
  };

  const canEdit = !!onChange;

  return (
    <div data-testid="sort-rule-item" className={styles.wrapper}>
      <IconHandle data-testid="sort-rule-drag-icon" color="asphalt" />
      <Select
        data-testid="sort-rule-option"
        value={rule.key}
        onChange={handleSelectRule}
        className={styles.select}
        disabled={!canEdit}
      >
        {[rule.key, ...availableSortKeys].map((key) => (
          <option data-testid="sort-rule-option-label" key={key} value={key}>
            {getSentence(key)}
          </option>
        ))}
      </Select>
      <Box data-testid="sort-rule-order-buttons" padding={{ all: 'xs' }} className={styles.buttonGroup}>
        <Button
          data-testid="sort-rule-ascendant-button"
          tertiary
          icon={IconSortReverse as SvgComponent}
          active={rule.order === AllessehQuerySortOrderType.ASC}
          onClick={() => handleChangeSortOrder(AllessehQuerySortOrderType.ASC)}
          disabled={!canEdit}
        />
        <Button
          data-testid="sort-rule-descendant-button"
          tertiary
          icon={IconSort as SvgComponent}
          active={rule.order === AllessehQuerySortOrderType.DESC}
          onClick={() => handleChangeSortOrder(AllessehQuerySortOrderType.DESC)}
          disabled={!canEdit}
        />
      </Box>
      {canEdit && (
        <Dropdown
          data-testid="sort-rule-toggle-menu"
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
        >
          <Group data-testid="sort-rule-toggle-remove" margin={{ all: 'md' }} gap="xs">
            <Button
              data-testid="sort-rule-toggle-remove-button"
              tertiary
              icon={IconTrash as SvgComponent}
              color="lava"
              onClick={handleRemoveRule}
            >
              Remove
            </Button>
          </Group>
        </Dropdown>
      )}
    </div>
  );
};

export default SortRule;
