/*
 * This file includes helper functions that extracts the Allesseh JSON query
 * from the content sources array.
 */

import isEqual from 'lodash.isequal';

import { getQueryRuleConfig } from 'components/query-rules-form/components/query-rule-form/queryRuleFormUtils';
import {
  AllessehContentQueryBody,
  AllessehQueryRule,
  AllessehQueryRuleTerm,
  AllessehQueryRuleTerms
} from 'hooks/useAllessehContentQuery';
import { getSentence } from './text';

export const QUERY_CONTENT_ID_KEY = 'UpstreamOriginId';

// checks if contentId exists in Allesseh JSON query, if Allesseh JSON query exists
export const isContentIdExcludedInQueryRules = (contentId: string, allessehJsonQuery?: string | null) => {
  if (!allessehJsonQuery) {
    return false;
  }
  const parsedJsonQuery = JSON.parse(allessehJsonQuery) as AllessehContentQueryBody;
  if (!parsedJsonQuery.query?.not || parsedJsonQuery.query.not.length === 0) {
    return false;
  }
  return (
    parsedJsonQuery.query.not.findIndex(
      (rule) => 'term' in rule && rule.term.key === QUERY_CONTENT_ID_KEY && rule.term.value === contentId
    ) >= 0
  );
};

export const isNonEmptyQueryForAllessehQueryRules = (queryBody?: AllessehContentQueryBody | null) =>
  (!!queryBody?.sort && queryBody.sort.length > 0) ||
  (!!queryBody?.query?.and && queryBody.query.and.length > 0) ||
  (!!queryBody?.query?.or && queryBody.query.or.length > 0) ||
  (!!queryBody?.query?.not && queryBody.query.not.length > 0);

export const isNonEmptyAllessehJsonQuery = (allessehJsonQuery?: string | null) => {
  if (!allessehJsonQuery) {
    return false;
  }

  return isNonEmptyQueryForAllessehQueryRules(JSON.parse(allessehJsonQuery) as AllessehContentQueryBody);
};

const trimQueryValues = (query: AllessehContentQueryBody) => {
  const trimValues = (item: AllessehQueryRule) => {
    const termsItem = item as AllessehQueryRuleTerms;
    const termItem = item as AllessehQueryRuleTerm;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (termsItem.terms) {
      termsItem.terms.value = termsItem.terms.value.map((val: string) => val.trim());
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (termItem.term) {
      termItem.term.value = termItem.term.value.trim();
    }
  };
  query.query?.and?.forEach(trimValues);
  query.query?.or?.forEach(trimValues);
  query.query?.not?.forEach(trimValues);
};

const removeConflictingRules = (queryBody: AllessehContentQueryBody) => {
  const allRules = [
    ...queryBody.query!.and!.map((rule, i) => ({ ...rule, index: i, clause: 'and' })),
    ...queryBody.query!.or!.map((rule, i) => ({ ...rule, index: i, clause: 'or' })),
    ...queryBody.query!.not!.map((rule, i) => ({ ...rule, index: i, clause: 'not' }))
  ];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < allRules.length; i++) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let j = i + 1; j < allRules.length; j++) {
      // @ts-expect-error TypeScript doesn't know that .clause is a valid index value
      const ruleA = queryBody.query![allRules[i].clause]! as AllessehQueryRule[];
      // @ts-expect-error TypeScript doesn't know that .clause is a valid index value
      const ruleB = queryBody.query![allRules[j].clause]! as AllessehQueryRule[];

      if ('terms' in allRules[i] && 'terms' in allRules[j]) {
        // @ts-expect-error TypeScript doesn't narrow the type and doesn't recognize .terms as a valid field
        if (allRules[i].clause !== allRules[j].clause && isEqual(allRules[i].terms, allRules[j].terms)) {
          ruleA.splice(allRules[i].index, 1);
          ruleB.splice(allRules[j].index, 1);
        }
      }

      if ('term' in allRules[i] && 'term' in allRules[j]) {
        // @ts-expect-error TypeScript doesn't narrow the type and doesn't recognize .term as a valid field
        if (allRules[i].clause !== allRules[j].clause && isEqual(allRules[i].term, allRules[j].term)) {
          ruleA.splice(allRules[i].index, 1);
          ruleB.splice(allRules[j].index, 1);
        }
      }
    }
  }

  return queryBody;
};

export const mergeAllessehQueryBodies = (...newQueryBodyStrs: (string | undefined | null)[]) => {
  const finalQueryBody: AllessehContentQueryBody = {
    query: { and: [], or: [], not: [] },
    sort: []
  };

  newQueryBodyStrs.forEach((newQueryBodyStr) => {
    if (!newQueryBodyStr) {
      return;
    }

    const newQueryBody = JSON.parse(newQueryBodyStr) as AllessehContentQueryBody;

    if (newQueryBody.count != null) {
      finalQueryBody.count = newQueryBody.count;
    }

    if (newQueryBody.query && finalQueryBody.query) {
      finalQueryBody.query.and = finalQueryBody.query.and?.concat(newQueryBody.query.and ?? []);
      finalQueryBody.query.not = finalQueryBody.query.not?.concat(newQueryBody.query.not ?? []);
      finalQueryBody.query.or = finalQueryBody.query.or?.concat(newQueryBody.query.or ?? []);
    }

    trimQueryValues(finalQueryBody);

    if (newQueryBody.sort && finalQueryBody.sort) {
      // finalQueryBody.sort = finalQueryBody.sort ?? [];
      newQueryBody.sort.forEach((newSortRule) => {
        const existingIndex = finalQueryBody.sort?.findIndex(
          (existingSortRule) => existingSortRule.key === newSortRule.key
        );
        if (existingIndex && existingIndex >= 0) {
          finalQueryBody.sort?.splice(existingIndex, 1);
        }
        finalQueryBody.sort?.push(newSortRule);
      });
    }

    if (newQueryBody.aggregations) {
      finalQueryBody.aggregations = finalQueryBody.aggregations ?? [];
      finalQueryBody.aggregations.concat(newQueryBody.aggregations);
    }
  });

  return removeConflictingRules(finalQueryBody);
};

export const validateValue = (
  currentProperty: string | null,
  query?: { [key: string]: AllessehQueryRule[] | undefined }
) => {
  const defaultMessage = 'No items matching query rules';
  if (!query) return defaultMessage;

  const queryRuleConfig = getQueryRuleConfig(currentProperty);
  const keysForMissingValues = Object.keys(query)
    .map((rule) =>
      (query[rule] as AllessehQueryRule[]).reduce((accum: string[], ruleItem: AllessehQueryRule) => {
        if ('terms' in ruleItem) {
          if (!ruleItem.terms.value.length)
            accum.push(queryRuleConfig[ruleItem.terms.key].label ?? getSentence(ruleItem.terms.key));
        }
        if ('term' in ruleItem) {
          if (!ruleItem.term.value || ruleItem.term.value.length === 0)
            accum.push(queryRuleConfig[ruleItem.term.key].label ?? getSentence(ruleItem.term.key));
        }
        if ('date' in ruleItem) {
          if (!ruleItem.date.value || ruleItem.date.value.length === 0)
            accum.push(queryRuleConfig[ruleItem.date.key].label ?? getSentence(ruleItem.date.key));
        }
        return accum;
      }, [])
    )
    .flat();
  return keysForMissingValues.length
    ? `A value is missing from term${keysForMissingValues.length > 1 ? 's' : ''}: ${keysForMissingValues
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(', ')}. Please check your query.`
    : defaultMessage;
};

export const getExcludedIdsQuery = (allessehQueryBody: AllessehContentQueryBody | null) => {
  const excludedAllessehContent =
    allessehQueryBody?.query?.not?.filter(
      (queryItem) => 'term' in queryItem && queryItem.term.key === 'UpstreamOriginId'
    ) ?? [];
  return { query: { or: [...excludedAllessehContent] } };
};
