import { FormLabel, Input, Textarea } from '@screentone/core';
import clonedeep from 'lodash.clonedeep';

import { PageModule } from 'data/generated/graphql';

interface PageModuleTextProps {
  pageModule: PageModule;
  onChange: (newPageModule: PageModule) => void;
}

export const PageModuleText = ({ pageModule, onChange }: PageModuleTextProps) => {
  const handleChangeStringField =
    (fieldKey: 'mainText' | 'secondaryText') => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPageModule = clonedeep(pageModule);
      if (newPageModule.uiModuleFields.textModule) {
        newPageModule.uiModuleFields.textModule[fieldKey] = e.target.value;
      }
      onChange(newPageModule);
    };

  return (
    <>
      <FormLabel key="mainText" label="Main Text" fullWidth>
        <Input
          placeholder="Enter main text"
          value={pageModule.uiModuleFields.textModule?.mainText}
          margin={{ bottom: 'sm' }}
          onChange={handleChangeStringField('mainText')}
          disabled={!onChange}
        />
      </FormLabel>
      <FormLabel key="secondaryText" label="Secondary Text" fullWidth margin={{ top: 'sm' }}>
        <Textarea
          placeholder="Enter Secondary Text"
          value={pageModule.uiModuleFields.textModule?.secondaryText}
          margin={{ bottom: 'sm' }}
          onChange={handleChangeStringField('secondaryText')}
          disabled={!onChange}
        />
      </FormLabel>
    </>
  );
};
