import { PageError, PageValidationError } from 'contexts/alert/AlertContext';
import { useAlert } from 'contexts/alert/useAlert';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { Page, PagePreviewLinkInput, usePagePreviewLinkMutation } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { prepPageModulesForPublish } from './utils/prepPageModulesForPublish';
import { mergeModulesWithValidationError } from '../pageEditUtils';

const PREVIEW_ERROR_MESSAGE = 'Could not set up previewing for the page.';

export const usePagePreview = () => {
  const { alertError, alertModules } = useAlert();
  const { mutateAsync: pagePreviewLinkMutateAsync, isLoading } = usePagePreviewLinkMutation();
  const currentProperty = useConvertedProperty();
  const { handlePartialPageChange } = usePagePublish();

  const handlePreviewPage = async (page: Page) => {
    const pagePreviewLinkInput: PagePreviewLinkInput = {
      layoutId: page.layoutId,
      name: page.name,
      notes: page.notes,
      revisionGroupKey: page.revisionGroupKey,
      createdUtc: page.createdUtc,
      pageModules: prepPageModulesForPublish(page.pageModules),
      publicationKey: currentProperty ?? ''
    };
    try {
      const resp = await pagePreviewLinkMutateAsync({
        pagePreviewLinkInput
      });
      if (resp.pagePreviewLink) {
        window.open(resp.pagePreviewLink, '_blank');
      } else {
        alertError(PREVIEW_ERROR_MESSAGE);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        try {
          const errorJson = JSON.parse(e.message) as PageError;
          const modulesWithValidationError = mergeModulesWithValidationError(
            page.pageModules,
            errorJson.validationError!.modulesWithValidationError
          );
          alertError(`${PREVIEW_ERROR_MESSAGE} ${errorJson.message}`);
          alertModules(errorJson.validationError as PageValidationError);
          handlePartialPageChange({ pageModules: modulesWithValidationError });
        } catch (error: unknown) {
          // likely an error coming from class-validator
          alertError(`${PREVIEW_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(PREVIEW_ERROR_MESSAGE);
      }
    }
  };

  return {
    isLoading,
    handlePreviewPage
  };
};
