import {
  PageType,
  PublicationSettingSearchableContentType,
  RequiredUiModuleFields,
  UiBasicTreatmentType,
  UiModuleType,
  UiTextTreatmentType,
  UiTitleTreatmentType
} from 'data/generated/graphql';

export const mockCampaign = {
  isAllessehPublished: 'true',
  publicationKey: 'marketwatch',
  name: 'Some sort of Newsletter',
  notes: null,
  // revisionGroupKey: '6077ae61-b040-48fa-88b6-1d6d56e2eac2',
  createdUtc: 1711469990605,
  creatorUser: {
    djUserId: 'jaspersj'
  },
  // isMobileAppScreen: false,
  // layoutId: '89af708c-371d-4fa4-b955-db5e03875d29',
  revisorUser: {
    djUserId: 'jaspersj'
  },
  // routeKey: '/home',
  config: { summaryPriority: 'U.S. Home' },
  pageType: PageType.Section,
  publishUtc: null,
  pageModules: [
    {
      moduleItems: [],
      uiModuleType: UiModuleType.UiBasicModuleType,
      uiModuleFields: {
        basicModule: {
          treatmentType: UiBasicTreatmentType.Audience,
          contentTypes: [PublicationSettingSearchableContentType.Article.toLowerCase()],
          allowedTreatmentTypes: null
        }
      }
    }
  ],
  // allessehNewsletterId: '',
  id: '',
  parameters: {
    sender_name: '',
    reply_to_email: '',
    subject: '',
    pre_header_text: '',
    newsletter_id: 0,
    campaign_id: ''
  }
};

export const mockPageTypeSetting = {
  pageType: PageType.Section,
  publicationKey: 'marketwatch',
  layouts: [
    {
      id: '89af708c-371d-4fa4-b955-db5e03875d29',
      name: 'Layout A',
      deprecated: null,
      requiredLayoutModules: [
        {
          uiModuleType: UiModuleType.UiTextModuleType,
          uiModuleFields: {
            basicModule: null,
            titleModule: null,
            rankedModule: null,
            textModule: {
              treatmentType: UiTextTreatmentType.HeaderDescriptionLeftBar,
              mainText: '',
              secondaryText: '',
              allowedTreatmentTypes: [UiTextTreatmentType.HeaderDescriptionLeftBar]
            },
            basicOptionalModule: null,
            twoColumnModule: null
          },
          moduleItems: []
        }
      ],
      availableRequiredLayoutModules: [],
      availableLayoutModules: [
        {
          uiModuleType: UiModuleType.UiTitleModuleType,
          defaultModule: {
            uiModuleType: UiModuleType.UiTitleModuleType,
            uiModuleFields: {
              basicModule: null,
              titleModule: {
                treatmentType: UiTitleTreatmentType.Grid_2x4,
                title: '',
                subtitle: '',
                subtitleUrl: '',
                contentTypes: [PublicationSettingSearchableContentType.Article],
                allowedTreatmentTypes: [UiTitleTreatmentType.Asymmetric_1LargeLeft_2SmallRight]
              },
              rankedModule: null,
              textModule: null,
              basicOptionalModule: null,
              twoColumnModule: null
            },
            moduleItems: []
          },
          minimumAllowed: 1,
          maximumAllowed: 6,
          allowedTreatmentTypes: []
        }
      ]
    }
  ],
  treatmentTypeSettings: [
    {
      requiredProperties: [RequiredUiModuleFields.MainText],
      treatmentType: 'ASSYMETRIC_2X4', // UiTitleTreatmentType.Asymmetric_2MediumTop_2SmallBottom,
      minRequiredContentItems: 1,
      maxRequiredContentItems: 1,
      label: '',
      contentTypes: [PublicationSettingSearchableContentType.Article]
    }
  ],
  contentTypes: [PublicationSettingSearchableContentType.Article]
};
