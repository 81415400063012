/* eslint-disable import/no-cycle */
import { useContext } from 'react';

import { Container, DataModelContext, IDataModelContext } from 'contexts/datamodel/DataModelContext';

export const useDataModelContext = <T extends Container>() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dataModelState = useContext<IDataModelContext<T>>(DataModelContext);
  return dataModelState;
};
