import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { ROLE_TYPE } from './types';
import useRole from './useRole';

const useRequireRole = (requiredRole: ROLE_TYPE) => {
  const navigate = useNavigate();
  const isAllowed = useRole(requiredRole);
  const { currentProperty } = useAuth();

  useEffect(() => {
    if (!isAllowed && currentProperty) {
      const path = `/${currentProperty}${requiredRole === ROLE_TYPE.PUBLISHER ? '/collections' : ''}`;
      navigate(path);
    }
  }, [navigate, isAllowed, requiredRole, currentProperty]);
};

export default useRequireRole;
