import { useMemo } from 'react';

import useSearchContent from 'components/search-content/hooks/useSearchContent';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { SearchContentByProps } from '../search-by-url/SearchByUrl';
import SearchContentList from '../search-content-list/SearchContentList';

const SearchByQueryTerm = ({ searchParams, resultListClassName, renderContentCard }: SearchContentByProps) => {
  const {
    data: searchData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isLoad,
    fetchStatus
  } = useSearchContent(searchParams);

  const isLoading = isLoad && fetchStatus !== 'idle';

  const { pages } = searchData ?? {};
  const initContentItems = useMemo(
    () =>
      (pages
        ?.map((page) => page.data?.attributes)
        .flat()
        .filter((content) => !searchParams.filters.excludedContentIds?.includes(content?.data.id ?? '')) ??
        []) as AllessehContent[],
    [pages, searchParams.filters.excludedContentIds]
  );

  const nextPageProps = useMemo(
    () => ({
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage
    }),
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  return (
    <SearchContentList
      contentItems={initContentItems}
      className={resultListClassName}
      nextPageProps={nextPageProps}
      isLoading={isLoading}
      renderContentCard={renderContentCard}
    />
  );
};

export default SearchByQueryTerm;
