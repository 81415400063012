import { PageError } from 'contexts/alert/AlertContext';
import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { PageDto, PageDtoPreviewLinkInput, usePageDtoPreviewLinkMutation } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';

const PREVIEW_ERROR_MESSAGE = 'Could not set up previewing for the page.';

export const usePagePreview = () => {
  const { alertError, alertModules } = useAlert();
  const { getDTO, deleteResolvedFieldsFromExternalDTO } = useDataModelContext();
  const { mutateAsync: pagePreviewLinkMutateAsync, isLoading } = usePageDtoPreviewLinkMutation();
  const currentProperty = useConvertedProperty();

  const handlePreviewPage = async (page?: PageDto) => {
    let pageDTO = page ?? getDTO(true);
    pageDTO = deleteResolvedFieldsFromExternalDTO(pageDTO);

    const pagePreviewLinkInput: PageDtoPreviewLinkInput = {
      publicationKey: currentProperty ?? '',
      pageDTO
    };
    try {
      const resp = await pagePreviewLinkMutateAsync({
        pagePreviewLinkInput
      });
      if (resp.pageDTOPreviewLink) {
        window.open(resp.pageDTOPreviewLink, '_blank');
      } else {
        alertError(PREVIEW_ERROR_MESSAGE);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('PAGE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('PAGE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${PREVIEW_ERROR_MESSAGE}\n${pageError.message.replace(/; /g, '\n')}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${PREVIEW_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(PREVIEW_ERROR_MESSAGE);
      }
    }
  };

  return {
    isLoading,
    handlePreviewPage
  };
};
