/* eslint-disable import/no-cycle */
import { Wrapper } from '@screentone/core';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { SectionContainer } from 'data/generated/graphql';

interface SectionProps extends CommonEntityProps {
  data: SectionContainer;
}

export const Section = ({ data: section, parentHierarchyId, index, isHistory }: SectionProps) => {
  const { generateHierarchyId, renderEntity } = useDataModelContext();
  const hierarchyId = generateHierarchyId(section, parentHierarchyId, index);

  return (
    <Wrapper data-model-hierarchy-id={hierarchyId}>
      {section.collection.map((collection, i) => (
        <div key={i}>{renderEntity(collection, { hierarchyId, index: i, isHistory })}</div>
      ))}
    </Wrapper>
  );
};
