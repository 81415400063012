import { useContext } from 'react';

import { AlertContext, AlertOptions, PageValidationError } from 'contexts/alert/AlertContext';

export const useAlert = () => {
  const alert = useContext(AlertContext);

  const alertError = (message: string, options?: AlertOptions) => alert.alertError(message, options);
  const alertWarning = (message: string, options?: AlertOptions) => alert.alertWarning(message, options);
  const alertSuccess = (message: string, options?: AlertOptions) => alert.alertSuccess(message, options);
  const removeAlert = () => alert.removeAlert();
  const alertModules = (pageValidationError: PageValidationError) => alert.alertModules(pageValidationError);

  return {
    alertState: alert.alertState,
    alertError,
    alertWarning,
    alertSuccess,
    removeAlert,
    alertModules
  };
};
