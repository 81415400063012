import classnames from 'classnames';

import styles from './NarrowPageWrapper.module.scss';

export const NarrowPageWrapper = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div {...props} className={classnames(styles.narrowPageWrapper, className)}>
    {children}
  </div>
);
