import { useState } from 'react';
import debounce from 'lodash.debounce';

const DEBOUNCE_TIMEOUT = 1000;

export const useImage = () => {
  const [showLargerImage, setShowLargerImage] = useState<boolean>(false);

  const debouncedHandleMouseEnter = debounce(() => setShowLargerImage(true), DEBOUNCE_TIMEOUT);

  const handleOnMouseLeave = () => {
    setShowLargerImage(false);
    debouncedHandleMouseEnter.cancel();
  };

  return { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage };
};
