import { IconHelp, Typography } from '@screentone/core';
import classnames from 'classnames';

import styles from './HelpIcon.module.scss';

interface HelpIconProps {
  text: string;
  className?: string;
  isLeftAnchored?: boolean;
}

export const HelpIcon = ({ text, className, isLeftAnchored }: HelpIconProps) => (
  <div data-testid="help-icon-hover" className={classnames(styles.help, className)}>
    <IconHelp data-testid="help-icon-icon" className={styles.helpIcon} />
    <div
      data-testid="help-icon-container"
      className={classnames(styles.helpDropdown, isLeftAnchored ? styles.helpDropdownLeftAnchored : '')}
    >
      <Typography data-testid="help-icon-description" variant="note">
        {text}
      </Typography>
    </div>
  </div>
);
