import { Button } from '@screentone/core';

interface PublishButtonProps {
  variant: 'publish' | 'historyEdit' | 'embargo' | 'historyEditAndEmbargo';
  disabled: boolean;
  publishFunctions: {
    handlePublishImmediately: () => void;
    handleSave: (publishUtc: number) => Promise<void>;
    toggleEmbargoDialog: () => void;
  };
}

interface VariantMap {
  [key: string]: {
    name: 'Publish' | 'Save';
    handleClickName: 'handlePublishImmediately' | 'handleSave' | 'toggleEmbargoDialog';
  };
}

const VARIANT_MAP: VariantMap = {
  publish: {
    name: 'Publish',
    handleClickName: 'handlePublishImmediately'
  },
  historyEdit: {
    name: 'Save',
    handleClickName: 'handleSave'
  },
  embargo: {
    name: 'Publish',
    handleClickName: 'toggleEmbargoDialog'
  },
  historyEditAndEmbargo: {
    name: 'Save',
    handleClickName: 'toggleEmbargoDialog'
  }
};

interface ButtonVariantProps {
  hasEmbargoedContent: boolean;
  isHistoryEdit: boolean;
}

export function getPublishButtonVariant({ hasEmbargoedContent, isHistoryEdit }: ButtonVariantProps) {
  if (hasEmbargoedContent && isHistoryEdit) {
    return 'historyEditAndEmbargo';
  }
  if (hasEmbargoedContent) {
    return 'embargo';
  }
  if (isHistoryEdit) {
    return 'historyEdit';
  }
  return 'publish';
}

export const PublishButton = ({ variant, disabled, publishFunctions }: PublishButtonProps) => {
  const { name, handleClickName } = VARIANT_MAP[variant];
  return (
    <Button
      primary
      margin={{ left: 'sm' }}
      onClick={publishFunctions[handleClickName]}
      disabled={disabled}
      data-testid="page-publish-button"
    >
      {name}
    </Button>
  );
};
