import { useState } from 'react';
import { Box, Button, Divider, IconCode, Input, Overlay, Typography, Wrapper } from '@screentone/core';
import cloneDeep from 'lodash.clonedeep';

import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import {
  CollectionContentItemInput,
  PageModule,
  PageModuleItem,
  PageModuleItemContent,
  PageModuleItemType,
  useCollectionCreateMutation,
  useCollectionUpdateMutation
} from 'data/generated/graphql';
import { prepContentItem } from 'features/page-edit/hooks/utils/prepPageModulesForPublish';
import { useConvertedProperty } from 'hooks';
import { getCollectionIdAndUtcNum } from 'hooks/collections/useSaveCollection';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import styles from './ConvertToCollection.module.scss';

interface ConvertToCollectionProps {
  pageModule: PageModule;
  onPageModuleChange: (newPageModule: PageModule) => void;
  setIsDropdownOpen: (bool: boolean) => void;
}

export const ConvertToCollection = ({
  pageModule,
  onPageModuleChange,
  setIsDropdownOpen
}: ConvertToCollectionProps) => {
  const currentProperty = useConvertedProperty();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collectionName, setCollectionName] = useState<string | null>(null);

  const { data: publicationSettingsResp } = usePublicationSettings();
  const { setHasRecentlyCreatedCollection } = usePagePublish();
  const { mutateAsync: collectionCreateMutationAsync } = useCollectionCreateMutation();
  const { mutateAsync: collectionUpdateMutateAsync } = useCollectionUpdateMutation();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setCollectionName(null);
  };
  const handleDismissModal = () => setIsModalOpen(false);

  const handleAdd = async () => {
    if (!currentProperty) {
      return;
    }
    const {
      collectionCreate: { idCreatedUtc }
    } = await collectionCreateMutationAsync({
      collectionCreateInput: {
        name: collectionName ?? 'Unnamed Page Section Collection',
        publicationKey: currentProperty
      }
    });

    const { id, createdUtcNum } = getCollectionIdAndUtcNum(idCreatedUtc || '');

    const contentModuleItems = (
      pageModule.moduleItems
        .filter(({ itemType }) => itemType === PageModuleItemType.Content)
        .map((contentModuleItem) => contentModuleItem.itemFields.contentItem) as PageModuleItemContent[]
    ).map(prepContentItem) as CollectionContentItemInput[];

    const queryModuleItem: PageModuleItem | undefined = pageModule.moduleItems.find(
      ({ itemType }) => itemType === PageModuleItemType.Query
    );

    const allessehJsonQuery = queryModuleItem?.itemFields.queryItem?.jsonQuery ?? '';
    const fullAllessehQuery: AllessehContentQueryBody | false =
      allessehJsonQuery !== '' &&
      mergeAllessehQueryBodies(publicationSettingsResp?.publicationSetting.baseAllessehQuery, allessehJsonQuery);

    const stringifiedJsonQuery = fullAllessehQuery ? JSON.stringify(fullAllessehQuery) : null;

    const update = {
      allessehCollectionId: id,
      contentItems: contentModuleItems,
      allessehJsonQuery: stringifiedJsonQuery,
      name: collectionName ?? 'Unnamed Page Section Collection',
      notes: null,
      id,
      createdUtc: createdUtcNum,
      publicationKey: currentProperty
    };

    let collectionUpdate;
    try {
      const response = await collectionUpdateMutateAsync({
        collectionUpdateInput: update
      });
      collectionUpdate = response.collectionUpdate;
    } catch (err: unknown) {
      console.error('Error creating collection', err);
      throw new Error('Error creating collection');
    }
    const newPageModule = cloneDeep(pageModule);
    newPageModule.moduleItems = [
      {
        itemType: PageModuleItemType.Collection,
        itemFields: {
          queryItem: null,
          allessehCollectionItem: null,
          collectionItem: {
            // TODO: why is this necessary? it keeps thinking the collection does not have all the fields
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            collection: { ...collectionUpdate, publicationKey: currentProperty },
            idCreatedUtc
          },
          contentItem: null
        }
      }
    ];
    onPageModuleChange(newPageModule);
    handleDismissModal();
    setIsDropdownOpen(false);
    setHasRecentlyCreatedCollection(true);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCollectionName(e.target.value);
  };

  return (
    <>
      <Button tertiary icon={IconCode as SvgComponent} onClick={handleOpenModal}>
        Convert to Collection
      </Button>

      <Overlay onDismiss={handleDismissModal} status={isModalOpen ? 'open' : 'closed'} className={styles.overlay}>
        <Box>
          <Box.Title>CONVERT TO COLLECTION</Box.Title>
          <Box.Content padding={{ all: 'none' }}>
            <Wrapper padding={{ all: 'md' }}>
              <Input
                type="text"
                placeholder="Enter Collection Name"
                value={collectionName ?? 'Unnamed Page Section Collection'}
                onChange={handleInputChange}
                margin={{ right: 'sm' }}
              />
              <Typography variant="note" margin={{ top: 'sm' }}>
                This will create a new collection with the items added to this page module.
              </Typography>
            </Wrapper>
            <Divider />
            <Wrapper padding={{ all: 'md' }} className={styles.bottomBar}>
              <Button secondary onClick={handleDismissModal}>
                Cancel
              </Button>
              <Button primary margin={{ left: 'sm' }} onClick={handleAdd}>
                Create
              </Button>
            </Wrapper>
          </Box.Content>
        </Box>
      </Overlay>
    </>
  );
};
