export enum QueryRuleFormType {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select'
}

export interface QueryRuleFormKeyConfig {
  label?: string; // displayed label for the key
  queryRuleFormType: QueryRuleFormType; // overall type to help determine what operators or form component to display
  formComponentProps?: any; // additional props for the form component to use
}
