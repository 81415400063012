import { useCallback, useState } from 'react';
import { Box, Button, IconSort, Input, Wrapper } from '@screentone/core';
import debounce from 'lodash.debounce';

import { PagesInput } from 'data/generated/graphql';
import styles from './PagesSearchBar.module.scss';

export const DEBOUNCE_TIMEOUT = 500;

export const PagesSearchBar = ({
  pagesInput,
  onPartialPagesInputChange
}: {
  pagesInput: PagesInput;
  onPartialPagesInputChange: (s: Partial<PagesInput>) => void;
}) => {
  const [searchInput, setSearchInput] = useState(pagesInput.searchText);

  const debouncedSearch = debounce((newSearchText: string) => {
    onPartialPagesInputChange({ searchText: newSearchText });
  }, DEBOUNCE_TIMEOUT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchTextChange = useCallback(debouncedSearch, []);

  const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
    handleSearchTextChange(e.target.value);
  };

  return (
    <Wrapper padding={{ top: 'sm', bottom: 'md' }}>
      <Box padding={{ all: 'sm' }} className={styles.pagesSearchBar}>
        <Input
          type="text"
          placeholder="Search by name"
          value={searchInput ?? ''}
          onChange={handleSearchInputChange}
          margin={{ right: 'sm' }}
          data-testid="pages-search-input"
        />
        <Button tertiary icon={IconSort as SvgComponent} disabled data-testid="pages-sort-filter">
          Sort: Create Date
        </Button>
      </Box>
    </Wrapper>
  );
};
