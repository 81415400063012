import { OnDragEndResponder, OnDragStartResponder } from 'react-beautiful-dnd';

import { useTrash } from 'contexts/trash/useTrash';
import { ItpIssueWithPages, PageModuleItem } from 'data/generated/graphql';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES } from 'features/page-edit/dragUtils';
import { DROPPABLE_IDS } from 'utils/collectionDragUtils';

interface UseDragEditIssueProps {
  issue: ItpIssueWithPages | null;
  onPartialIssueChange?: (issue: Partial<ItpIssueWithPages>) => void;
}

const handleDragItemToModuleFromResults = ({
  issue,
  draggableId,
  destDroppableId,
  destIndex,
  onPartialIssueChange
}: {
  issue: ItpIssueWithPages;
  draggableId: string;
  destDroppableId: string;
  destIndex: number;
  onPartialIssueChange?: (issue: Partial<ItpIssueWithPages>) => void;
}) => {
  if (issue.pages.length === 0 || !onPartialIssueChange) return;

  const issuePageIndexRegex = new RegExp(
    `(?<=${DROPPABLE_ID_PREFIXES.ITP_SECTION})(.*)(?=-${DROPPABLE_ID_PREFIXES.MODULE_ITEMS})`
  );
  const issuePageIndexRegexMatch: RegExpMatchArray | null = destDroppableId.match(issuePageIndexRegex);
  if (!issuePageIndexRegexMatch) return;
  const issuePageIndex = parseInt(issuePageIndexRegexMatch[0], 10);

  const moduleIndexRegex = new RegExp(`(?<=${DROPPABLE_ID_PREFIXES.MODULE_ITEMS})(.*)`);
  const moduleIndexRegexMatch: RegExpMatchArray | null = destDroppableId.match(moduleIndexRegex);
  if (!moduleIndexRegexMatch) return;
  const moduleIndex = parseInt(moduleIndexRegexMatch[0], 10);

  const newPages = [...issue.pages];
  const itemJson: string = draggableId.replace(DRAGGABLE_PREFIXES.MODULE_ITEM, '');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newItem: PageModuleItem = JSON.parse(itemJson);
  newPages[issuePageIndex].pageModules[moduleIndex].moduleItems = [
    ...issue.pages[issuePageIndex].pageModules[moduleIndex].moduleItems.slice(0, destIndex),
    newItem,
    ...issue.pages[issuePageIndex].pageModules[moduleIndex].moduleItems.slice(destIndex)
  ];
  onPartialIssueChange({ pages: newPages });
};

const handleDragItemWithinModule = ({
  issue,
  draggableId,
  destDroppableId,
  sourceIndex,
  destIndex,
  sourceDroppableId,
  onPartialIssueChange
}: {
  issue: ItpIssueWithPages;
  draggableId: string;
  destDroppableId: string;
  sourceIndex: number;
  destIndex: number;
  sourceDroppableId: string;
  onPartialIssueChange: (issue: Partial<ItpIssueWithPages>) => void;
}) => {
  if (issue.pages.length === 0) return;

  const issuePageIndexRegex = new RegExp(
    `(?<=${DROPPABLE_ID_PREFIXES.ITP_SECTION})(.*)(?=-${DROPPABLE_ID_PREFIXES.MODULE_ITEMS})`
  );
  const moduleIndexRegex = new RegExp(`(?<=${DROPPABLE_ID_PREFIXES.MODULE_ITEMS})(.*)`);

  const newPages = [...issue.pages];
  const sourceIssuePageIndexRegexMatch: RegExpMatchArray | null = sourceDroppableId.match(issuePageIndexRegex);
  if (!sourceIssuePageIndexRegexMatch) return;
  const sourceIssuePageIndex = parseInt(sourceIssuePageIndexRegexMatch[0], 10);

  const sourceModuleIndexRegexMatch: RegExpMatchArray | null = sourceDroppableId.match(moduleIndexRegex);
  if (!sourceModuleIndexRegexMatch) return;
  const sourceModuleIndex = parseInt(sourceModuleIndexRegexMatch[0], 10);

  newPages[sourceIssuePageIndex].pageModules[sourceModuleIndex].moduleItems = [
    ...newPages[sourceIssuePageIndex].pageModules[sourceModuleIndex].moduleItems.slice(0, sourceIndex),
    ...newPages[sourceIssuePageIndex].pageModules[sourceModuleIndex].moduleItems.slice(sourceIndex + 1)
  ];

  const itemJson = `{"itemType${draggableId.split('{"itemType')[1]}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newItem: PageModuleItem = JSON.parse(itemJson);
  const destIssuePageIndexRegexMatch: RegExpMatchArray | null = destDroppableId.match(issuePageIndexRegex);
  if (!destIssuePageIndexRegexMatch) return;
  const destIssuePageIndex = parseInt(destIssuePageIndexRegexMatch[0], 10);

  const destModuleIndexRegexMatch: RegExpMatchArray | null = destDroppableId.match(moduleIndexRegex);
  if (!destModuleIndexRegexMatch) return;
  const destModuleIndex = parseInt(destModuleIndexRegexMatch[0], 10);

  newPages[destIssuePageIndex].pageModules[destModuleIndex].moduleItems = [
    ...newPages[destIssuePageIndex].pageModules[destModuleIndex].moduleItems.slice(0, destIndex),
    newItem,
    ...newPages[destIssuePageIndex].pageModules[destModuleIndex].moduleItems.slice(destIndex)
  ];

  onPartialIssueChange({ pages: newPages });
};

const handleDragModuleItemInModule = ({
  issue,
  draggableId,
  destDroppableId,
  sourceIndex,
  destIndex,
  sourceDroppableId,
  onPartialIssueChange
}: {
  issue: ItpIssueWithPages;
  draggableId: string;
  destDroppableId: string;
  sourceIndex: number;
  destIndex: number;
  sourceDroppableId: string;
  onPartialIssueChange: (issue: Partial<ItpIssueWithPages>) => void;
}) => {
  if (sourceDroppableId === 'RESULTS' || sourceDroppableId === 'COLLECTION-') {
    handleDragItemToModuleFromResults({
      issue,
      draggableId,
      destDroppableId,
      destIndex,
      onPartialIssueChange
    });
  } else {
    handleDragItemWithinModule({
      issue,
      draggableId,
      destDroppableId,
      sourceIndex,
      destIndex,
      sourceDroppableId,
      onPartialIssueChange
    });
  }
};

const handleDragModuleItemToTrash = ({
  issue,
  sourceIndex,
  sourceDroppableId,
  onPartialIssueChange
}: {
  issue: ItpIssueWithPages;
  sourceIndex: number;
  sourceDroppableId: string;
  onPartialIssueChange: (issue: Partial<ItpIssueWithPages>) => void;
}) => {
  if (issue.pages.length === 0) return;

  const issuePageIndexRegex = new RegExp(
    `(?<=${DROPPABLE_ID_PREFIXES.ITP_SECTION})(.*)(?=-${DROPPABLE_ID_PREFIXES.MODULE_ITEMS})`
  );
  const issuePageIndexRegexMatch: RegExpMatchArray | null = sourceDroppableId.match(issuePageIndexRegex);
  if (!issuePageIndexRegexMatch) return;
  const issuePageIndex = parseInt(issuePageIndexRegexMatch[0], 10);

  const moduleIndexRegex = new RegExp(`(?<=${DROPPABLE_ID_PREFIXES.MODULE_ITEMS})(.*)`);
  const moduleIndexRegexMatch: RegExpMatchArray | null = sourceDroppableId.match(moduleIndexRegex);
  if (!moduleIndexRegexMatch) return;
  const moduleIndex = parseInt(moduleIndexRegexMatch[0], 10);

  const newPages = [...issue.pages];
  newPages[issuePageIndex].pageModules[moduleIndex].moduleItems = [
    ...issue.pages[issuePageIndex].pageModules[moduleIndex].moduleItems.slice(0, sourceIndex),
    ...issue.pages[issuePageIndex].pageModules[moduleIndex].moduleItems.slice(sourceIndex + 1)
  ];
  onPartialIssueChange({ pages: newPages });
};

export const useDragEditIssue = ({ issue, onPartialIssueChange }: UseDragEditIssueProps) => {
  const { setIsDragging } = useTrash();
  if (!onPartialIssueChange) return {};

  const handleDragStart: OnDragStartResponder = (dragEvent) => {
    // when an item currently in a module is being dragged
    // update the isDragging context so that the droppable trash area becomes active
    const regexDroppable = new RegExp(`^${DROPPABLE_ID_PREFIXES.ITP_SECTION}`);
    const regexDraggable = new RegExp(`${DRAGGABLE_PREFIXES.MODULE_ITEM}`);
    if (dragEvent.draggableId.match(regexDraggable) && dragEvent.source.droppableId.match(regexDroppable)) {
      setIsDragging(true);
    }
  };

  const handleDragEnd: OnDragEndResponder = (dragEvent) => {
    setIsDragging(false);

    if (!issue) return;

    const {
      destination,
      source: { droppableId: sourceDroppableId, index: sourceIndex },
      draggableId
    } = dragEvent;

    if (!destination) {
      return;
    }

    const { droppableId: destDroppableId, index: destIndex } = destination;

    if (destDroppableId.includes(DROPPABLE_ID_PREFIXES.MODULE_ITEMS)) {
      handleDragModuleItemInModule({
        issue,
        draggableId,
        destDroppableId,
        sourceIndex,
        destIndex,
        sourceDroppableId,
        onPartialIssueChange
      });
    } else if (destDroppableId === DROPPABLE_IDS.TRASH) {
      handleDragModuleItemToTrash({
        issue,
        sourceIndex,
        sourceDroppableId,
        onPartialIssueChange
        // setAllContentIds
      });
    }
  };

  return {
    handleDragStart,
    handleDragEnd
  };
};
