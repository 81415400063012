import { useAppQuery } from 'data/query-client';
import restClient from 'data/restClient';
import { useAuthToken } from 'hooks/useAuthToken';

export interface SnippetySoftCropsData {
  height: number;
  width: number;
  label: string;
  location: string;
}

export interface SnippetyImageData {
  id: string;
  credit: string;
  caption: string;
  title: string;
  aspectRatio: number;
  reuseType: string;
  type: string;
  href?: string;
  src?: {
    baseUrl?: string;
    imageId?: string;
    path?: string;
    prams?: {
      size: number;
    };
  };
  sizeCode: string;
  width: number;
  height: number;
  imageDetailsHref: string;
  contentType: string;
  isResizeOnly: boolean;
  alt_sizes: {
    softcrops: SnippetySoftCropsData[];
  };
  altSrc: [];
  status: string;
  'Content-type': string;
  publication: string;
  url: string;
  application: string;
}

export interface SnippetyToolsData {
  image: SnippetyImageData;
}

export interface SnippetyResponse {
  title: string;
  desc: string;
  provider: string;
  url: string;
  type: string;
  media: string;
  publication: string;
  [key: string]: any;
}

export interface SnippetyResponseWithImage extends SnippetyResponse {
  tools: SnippetyToolsData;
}

const getSnippetyData = (authToken: string, url: string) =>
  restClient.get<SnippetyResponse | SnippetyResponseWithImage>(`snippety?url=${encodeURIComponent(url)}`, {
    headers: { Authorization: authToken }
  });

export const useSnippetyQuery = (url: string, options?: { enabled: boolean }) => {
  const authToken = useAuthToken();

  return useAppQuery([url], () => getSnippetyData(authToken, url), options);
};
