import pageModuleItemStyles from './PageModuleItemCard.module.scss';

export enum ItemType {
  CARD = 'card',
  QUERY = 'query'
}

export const getDuplicateClassName =
  (duplicateIds: string[] = [], isDraft: boolean, modeDisplayed: string) =>
  (itemType: ItemType) => {
    const theme =
      (modeDisplayed === 'auto' &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: light)').matches) ||
      modeDisplayed === 'light'
        ? 'Light'
        : 'Dark';
    const className =
      itemType === ItemType.CARD
        ? pageModuleItemStyles[`duplicateItemCard${theme}`]
        : pageModuleItemStyles.duplicateItemQuery;
    return (contentId: string) => (isDraft && duplicateIds.includes(contentId) ? className : '');
  };
