import { useEffect, useState } from 'react';
import { Wrapper } from '@screentone/core';

import { ResponsiveLoader } from 'components';
import { OffPlatformInput, useInfiniteGetAllOffPlatformQuery } from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import ContentList from './ContentList';
import styles from './OffPlatformSearch.module.scss';

const PER_PAGE = 10;

const OffPlatformSearch = () => {
  const [offPlatformInput, setOffPlatformInput] = useState<OffPlatformInput>({ publicationKey: '' });

  const currentProperty = useConvertedProperty();

  useEffect(() => {
    if (currentProperty) {
      setOffPlatformInput({ publicationKey: currentProperty });
    }
  }, [currentProperty]);

  const { data, isLoading, fetchStatus, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteGetAllOffPlatformQuery(
      {
        offPlatformInput,
        paginationParams: { first: PER_PAGE, after: null }
      },
      {
        enabled: !!offPlatformInput.publicationKey,
        getNextPageParam: (lastPage) => {
          if (!lastPage.getAllOffPlatform.pageInfo.hasNextPage) {
            return false;
          }

          return {
            paginationParams: {
              first: PER_PAGE,
              after: lastPage.getAllOffPlatform.pageInfo.endCursor
            }
          };
        }
      }
    );

  return (
    <Wrapper className={styles.offPlatformWrapper}>
      {isLoading && fetchStatus !== 'idle' ? (
        <ResponsiveLoader />
      ) : (
        <ContentList pages={data?.pages ?? []} nextPageProps={{ fetchNextPage, hasNextPage, isFetchingNextPage }} />
      )}
    </Wrapper>
  );
};

export default OffPlatformSearch;
