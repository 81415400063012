import { useAuth } from '@screentone/addon-auth-wrapper';

import { ROLE_TYPE } from './types';

const useRole = (role: ROLE_TYPE) => {
  const { userAccess, currentProperty } = useAuth();

  const fullRole = role === ROLE_TYPE.ADMIN ? ROLE_TYPE.ADMIN : `${currentProperty ?? ''}:${role}`;
  return userAccess(fullRole);
};

export default useRole;
