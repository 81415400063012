import { useCallback } from 'react';
import { DragDropContext, DropResult, OnDragEndResponder, ResponderProvided } from 'react-beautiful-dnd';
import { Divider, Wrapper } from '@screentone/core';

import { ResponsiveLoader, WidePageWrapper } from 'components';
import { NoSearchResults } from 'components/no-search-results/NoSearchResults';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { useIssue } from 'contexts/issue/useIssue';
import { ItpIssueWithPages, ItpPage, PageModule } from 'data/generated/graphql';
import { PageDraftSection } from 'features/page-edit/components/page-draft-section/PageDraftSection';
import { DROPPABLE_ID_PREFIXES } from 'features/page-edit/dragUtils';
import { usePrompt } from 'hooks/useBeforeLeavePage';
import IssueMultiTabSection from './components/issue-multitab-section/IssueMultiTabSection';
import { PageTitleHeader } from './components/page-title-header/PageTitleHeader';
import { useDragEditIssue } from './hooks/useDragEditIssue';
import { useHighlight } from './hooks/useNewHighlight';
import styles from './IssueEdit.module.scss';
import { NumberOrNothing } from './types';

import pageEditStyles from 'features/page-edit/PageEdit.module.scss';

export const IssueEdit = () => {
  const {
    issue,
    handlePartialIssueChange,
    isLoading: isIssueLoading,
    pageTypeSetting,
    hasITPPageChanged,
    itpAltSummUpdated
  } = useIssue();
  const { clearItems } = useHighlight();

  const { handleDragEnd, handleDragStart } = useDragEditIssue({
    issue,
    onPartialIssueChange: handlePartialIssueChange
  });

  const handlePartialIssueChangeWithHighlight = useCallback(
    (issue: Partial<ItpIssueWithPages>) => {
      clearItems();
      handlePartialIssueChange(issue);
    },
    [handlePartialIssueChange, clearItems]
  );

  const handleDragEndwithHighlight: OnDragEndResponder = useCallback(
    (dragEvent: DropResult, provided: ResponderProvided) => {
      clearItems();
      if (handleDragEnd) {
        handleDragEnd(dragEvent, provided);
      }
    },
    [clearItems, handleDragEnd]
  );

  usePrompt(
    `You have ${itpAltSummUpdated ? 'unpublished ITP Alt summ' : 'unsaved'} changes. Are you sure you want to leave?`,
    hasITPPageChanged
  );

  if (isIssueLoading) {
    return <ResponsiveLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!issue?.pages || !handleDragEnd || !handleDragStart) {
    return <NoSearchResults title="Issue" bodyText="No issue is available for the date requested." />;
  }

  const removeITPModuleItem = ({
    moduleListIndex,
    pageIndex,
    newPageModule
  }: {
    moduleListIndex: NumberOrNothing;
    pageIndex: NumberOrNothing;
    newPageModule: PageModule;
  }) => {
    if (moduleListIndex === undefined || pageIndex === undefined) return;

    const newPages = [...issue.pages];

    newPages[pageIndex].pageModules[moduleListIndex] = newPageModule;
    handlePartialIssueChangeWithHighlight({ pages: newPages });
  };

  const indexOfLastEnabledPage = issue.pages.findLastIndex((page: ItpPage) => !page.isHidden);

  return (
    <PageRootContainer noBottomMargin>
      <WidePageWrapper padding={{ all: 'sm' }}>
        <PageTitleHeader />
        <Divider className={pageEditStyles.horizontalDivider} />
        <DragDropContext onDragEnd={handleDragEndwithHighlight} onDragStart={handleDragStart}>
          <div className={styles.container}>
            <Wrapper className={styles.draftWrapper} margin={{ right: 'md' }} padding={{ top: 'mlg' }}>
              {issue.pages.map((page, index) => {
                if (page.isHidden) return null;

                const droppableId = `${DROPPABLE_ID_PREFIXES.ITP_SECTION}${index}`;

                return (
                  <Wrapper key={droppableId} margin={index === indexOfLastEnabledPage ? null : { bottom: 'md' }}>
                    <PageDraftSection
                      itpPage={page}
                      key={droppableId}
                      droppableId={droppableId}
                      pageIndex={index}
                      removeITPModuleItem={removeITPModuleItem}
                      pageTypeSetting={pageTypeSetting}
                    />
                  </Wrapper>
                );
              })}
            </Wrapper>
            <div className={styles.pageDivider} />
            <Wrapper className={styles.multitabWrapper} margin={{ left: 'md' }} padding={{ top: 'mlg' }}>
              <IssueMultiTabSection issue={issue} handlePartialIssueChange={handlePartialIssueChange} />
            </Wrapper>
          </div>
        </DragDropContext>
      </WidePageWrapper>
    </PageRootContainer>
  );
};
