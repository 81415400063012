import { Wrapper } from '@screentone/core';

import { UcsSubnav } from 'components';
import styles from './PageRootContainer.module.scss';

interface PageRootContainerProps {
  children: React.ReactNode;
  noBottomMargin?: boolean;
}

export const PageRootContainer = ({ children, noBottomMargin }: PageRootContainerProps) => (
  <Wrapper className={styles.pageRootContainer}>
    <UcsSubnav />
    <Wrapper className={styles.pageRootContainerContent} margin={noBottomMargin ? null : { bottom: 'mlg' }}>
      {children}
    </Wrapper>
  </Wrapper>
);
