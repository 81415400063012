import { Button, Dialog, FormLabel, Radio } from '@screentone/core';

import { NewsletterListItem } from 'data/generated/graphql';
import { ComponentRef } from 'utils/componentRef';

interface NewsletterSelectDialogProps {
  open: boolean;
  closeModal: () => void;
  renderNode: () => void;
  handleCancelClick: () => void;
  handleBeginCampaignClick: () => void;
  setSelectedNewsletterId: (id: string) => void;
  status: string;
  newsletterItems: NewsletterListItem[] | undefined;
  selectedNewsletterId: string;
  componentRef: ComponentRef;
}

export const NewsletterSelectDialog = ({
  open,
  closeModal,
  componentRef,
  renderNode,
  handleCancelClick,
  handleBeginCampaignClick,
  status,
  setSelectedNewsletterId,
  newsletterItems,
  selectedNewsletterId
}: NewsletterSelectDialogProps) => (
  <>
    {open && (
      <Dialog
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDesc"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        onDismiss={closeModal}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        renderNode={renderNode}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        status={status}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        componentRef={componentRef}
      >
        <Dialog.Title id="dialogTitle">Choose Newsletter</Dialog.Title>
        <Dialog.Content id="dialogDesc">
          {newsletterItems?.map((newsletterItem) => (
            <div key={newsletterItem.id}>
              <FormLabel label={newsletterItem.name} labelPosition="right">
                <Radio
                  checked={newsletterItem.id === selectedNewsletterId}
                  id={newsletterItem.id}
                  name={newsletterItem.id}
                  onChange={(evt: Event) => setSelectedNewsletterId((evt.target as HTMLElement).id)}
                />
              </FormLabel>
            </div>
          ))}
        </Dialog.Content>
        <Dialog.Actions>
          <Button secondary onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button primary onClick={handleBeginCampaignClick}>
            Begin Campaign
          </Button>
        </Dialog.Actions>
      </Dialog>
    )}
  </>
);
