import React from 'react';
import { Button, IconEdit, Tooltip } from '@screentone/core';

import styles from './EditAltSummOption.scss';

interface EditAltSummOptionProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shouldDisplay: boolean | undefined;
  disableAltSummEdit: boolean;
}

export const EditAltSummOption = ({
  isModalOpen,
  setIsModalOpen,
  shouldDisplay,
  disableAltSummEdit
}: EditAltSummOptionProps) => {
  const EditAltSummButton = (
    <>
      <Button
        data-testid="edit-alt-summ-option-button"
        disabled={!window.isSummarianReady}
        tertiary
        icon={IconEdit as SvgComponent}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        Edit Alt-Summ
      </Button>
    </>
  );

  return shouldDisplay && !disableAltSummEdit ? (
    <>
      {window.isSummarianReady ? (
        EditAltSummButton
      ) : (
        <Tooltip data-testid="edit-alt-summ-option-tooltip">
          <Tooltip.Content
            data-testid="edit-alt-summ-option-tooltip-content"
            id="altSumModalTooltip"
            position="bottom"
            className={styles.altSumTooltip}
            size="lg"
          >
            There was an issue loading Summarian or you do not have Summarian access. <br /> Please contact the
            #curation team on Slack.
          </Tooltip.Content>
          <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
            {EditAltSummButton}
          </Tooltip.Trigger>
        </Tooltip>
      )}
    </>
  ) : null;
};
