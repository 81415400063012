import { ContentStatusFilterType } from 'components/search-content/components/search-content-filters/components/status-filter/StatusFilter';
import { ItpIssueStatus } from 'data/generated/graphql';

export const DEFAULT_CONTENT_SEARCH_FILTERS = {
  contentTypes: [''],
  author: '',
  embargo: false,
  publishDates: {
    beforeUtc: null,
    afterUtc: null
  },
  contentStatuses: new Set<ContentStatusFilterType>(['PUBLISHED'])
};

export const ITP_STATUS_COLOR: Record<ItpIssueStatus, 'gray' | 'sunshine' | 'emerald' | 'lava'> = {
  [ItpIssueStatus.Draft]: 'gray',
  [ItpIssueStatus.Changed]: 'sunshine',
  [ItpIssueStatus.Published]: 'emerald',
  [ItpIssueStatus.Deleted]: 'lava'
};
