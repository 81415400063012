import { useEffect, useMemo } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  IconCode2,
  IconMinusCircle,
  IconRepeat,
  IconThreeDotsVer,
  List,
  Typography,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';
import cloneDeep from 'lodash.clonedeep';

import { ResponsiveLoader } from 'components';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { PageModuleItemCollection, PageModuleItemCollectionInput } from 'data/generated/graphql';
import { ContentCard } from 'features/page-edit/components/article-card/ContentCard';
import { addModuleItem } from 'features/page-edit/components/page-draft-section/PageDraftSectionUtils';
import { useAllessehContentQuery } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent, AllessehContentQueryBody } from 'hooks/useAllessehContentQuery';
import { mergeAllessehQueryBodies } from 'utils/queryUtils';
import styles from './CollectionModuleItem.module.scss';

interface CollectionModuleItemProps {
  collectionItem: PageModuleItemCollection;
  onRemove: (() => void) | null;
  onDetachFromCollection: (() => void) | null;
  availableModuleItemSlots: number | null;
  onNumItemsLoaded: (numItems: number) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  getDuplicateClassName?: (id: string) => string;
  moduleHierarchyId: string;
  index: number;
  isDraft?: boolean;
  handleCollectionQueryChange: (index: number, collectionItem: PageModuleItemCollection) => void;
  treatmentType?: string;
  isITP?: boolean;
}

export const CollectionModuleItem = ({
  collectionItem,
  onRemove,
  availableModuleItemSlots,
  onNumItemsLoaded,
  onDetachFromCollection,
  dragHandleProps,
  moduleHierarchyId,
  index,
  getDuplicateClassName,
  isDraft,
  handleCollectionQueryChange,
  treatmentType,
  isITP
}: CollectionModuleItemProps) => {
  const { currentProperty } = useAuth();
  const navigate = useNavigate();
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { setAllContentIds, hasRecentlyPublished, page } = usePagePublish();
  const jsonQuery = collectionItem.collection?.allessehJsonQuery;

  const manualContents = useMemo(() => {
    try {
      return collectionItem.collection?.contentItems
        ? (collectionItem.collection.contentItems
            .map((contentItem) => (contentItem.content ? (JSON.parse(contentItem.content) as AllessehContent) : null))
            .filter((x) => !!x) as AllessehContent[])
        : [];
    } catch (error: unknown) {
      return [];
    }
  }, [collectionItem.collection?.contentItems]);

  const numQueryItemsToLoad =
    availableModuleItemSlots != null ? Math.max(availableModuleItemSlots - manualContents.length, 0) : 0;
  let fullAllessehQuery: AllessehContentQueryBody = {};
  if (jsonQuery) {
    fullAllessehQuery = mergeAllessehQueryBodies(
      publicationSettingsResp?.publicationSetting.baseAllessehQuery,
      jsonQuery,
      JSON.stringify({ count: numQueryItemsToLoad })
    );
  }

  const {
    data,
    isLoading: isQueryLoad,
    fetchStatus
  } = useAllessehContentQuery(fullAllessehQuery, {
    enabled: !!jsonQuery && !!availableModuleItemSlots
  });
  const isQueryLoading = isQueryLoad && fetchStatus !== 'idle';
  const queryContents = useMemo(
    () =>
      data
        ? (data.pages
            .map((page) => page.data?.attributes)
            .flat()
            .filter((x) => !!x) as AllessehContent[])
        : [],
    [data]
  );

  const isLoading = !!jsonQuery && isQueryLoading;
  const allContents = manualContents.concat(queryContents);

  useEffect(() => {
    onNumItemsLoaded(allContents.length);
  }, [allContents, onNumItemsLoaded]);

  useEffect(() => {
    if ((jsonQuery && isDraft && !isLoading) || !collectionItem.collectionQueryCount) {
      const newCollection = cloneDeep(collectionItem) as PageModuleItemCollectionInput;
      const collectionQueryCount = (
        newCollection.collectionQueryCount ? newCollection.collectionQueryCount : { count: null }
      ) as { count: number | null };

      if (collectionQueryCount.count !== numQueryItemsToLoad) {
        newCollection.collectionQueryCount = { count: numQueryItemsToLoad };

        handleCollectionQueryChange(index, newCollection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionItem, treatmentType]);
  useEffect(() => {
    if (isDraft) {
      setAllContentIds((prevState) =>
        addModuleItem(
          prevState,
          { moduleHierarchyId, index },
          [...queryContents, ...manualContents].map((content) => content.data.id)
        )
      );
    }
  }, [
    queryContents,
    manualContents,
    setAllContentIds,
    moduleHierarchyId,
    index,
    isDraft,
    hasRecentlyPublished,
    page?.pageModules
  ]);

  const handleGoToCollection = () => {
    navigate(`/${currentProperty ?? ''}/collections/${collectionItem.idCreatedUtc}`);
  };

  return (
    <Box className={styles.cardContainer} {...dragHandleProps}>
      <Wrapper padding={{ all: 'sm' }} className={styles.cardHeader} data-testid="collection-list-items">
        <Typography size="md" weight="bold">
          {collectionItem.collection?.name ?? 'Collection'}
        </Typography>
        <Dropdown
          padding={{ all: 'none' }}
          position="right"
          trigger={<IconThreeDotsVer color="asphalt" />}
          data-testid="collection-ellipsis-icon"
        >
          {onRemove && (
            <>
              <Wrapper padding={{ all: 'md' }}>
                <Button
                  tertiary
                  icon={IconMinusCircle as SvgComponent}
                  color="lava"
                  onClick={onRemove}
                  data-testid="collection-remove-from-module"
                >
                  Remove from module
                </Button>
              </Wrapper>
              <Divider />
            </>
          )}
          <Wrapper padding={{ horizontal: 'md', top: 'md', bottom: 'md' }}>
            <Button tertiary icon={IconCode2 as SvgComponent} onClick={handleGoToCollection}>
              Edit collection
            </Button>
          </Wrapper>
          {onDetachFromCollection && (
            <Wrapper padding={{ horizontal: 'md', bottom: 'md' }}>
              <Button
                tertiary
                icon={IconRepeat as SvgComponent}
                onClick={onDetachFromCollection}
                disabled={allContents.length === 0}
              >
                Detach from Collection
              </Button>
            </Wrapper>
          )}
        </Dropdown>
      </Wrapper>
      {isLoading && <ResponsiveLoader />}
      {!isLoading && (
        <Wrapper padding={{ right: 'sm', left: 'xs', bottom: 'sm' }}>
          {allContents.length === 0 && <Typography size="sm">No items</Typography>}
          {allContents.length > 0 && (
            <List listStyle="bullet">
              {allContents.map((content, index) => (
                <Wrapper
                  key={index}
                  className={classnames(styles.card, getDuplicateClassName?.(content.data.id))}
                  padding={{ all: 'xs' }}
                >
                  <ContentCard content={content} isIconPresent />
                </Wrapper>
              ))}
            </List>
          )}
          {availableModuleItemSlots == null && allContents.length > 0 && !isITP && (
            <Typography variant="note" margin={{ left: 'sm' }}>
              + {(data?.pages[0].links?.total ?? 0) - numQueryItemsToLoad - manualContents.length} more
            </Typography>
          )}
        </Wrapper>
      )}
    </Box>
  );
};
