import { useEffect } from 'react';
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult
} from '@tanstack/react-query';

import useLatest from 'utils/hooks/useLatest';
import useNotifications from 'utils/notifications';

const useAppInfiniteQuery = <TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
    'queryKey' | 'queryFn'
  > & {
    shouldHandleError?: boolean;
  }
): UseInfiniteQueryResult<TData, TError> => {
  const shouldHandleErrorRef = useLatest(options?.shouldHandleError ?? true);
  const queryResult = useInfiniteQuery(queryKey, queryFn, options);

  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    if (shouldHandleErrorRef.current && queryResult.isError) {
      showErrorNotification();
    }
  }, [shouldHandleErrorRef, showErrorNotification, queryResult.isError]);

  return queryResult;
};

export default useAppInfiniteQuery;
